import React, { useEffect, useState } from "react";
import { useQuery, useReactiveVar } from "@apollo/client";
import { Button, IconButton } from "@material-ui/core";
import {
  GET_ACTIVE_MENU_TAB,
  GET_IS_MENU_MODIFIED,
} from "../../apollo/operations/queries/localQueries";

import LoaderHandler from "../shared/LoaderHandler";
import { STATUS } from "../../constants/constants";
import {
  cache,
  GET_CHANGED_MENU_STATUS,
  GET_CURRENT_VERSION,
  GET_LAST_MODIFIED,
  GET_LATEST_VERSION,
  GET_MENU_TO_SYNC,
  jsonVar,
  openModalVar,
} from "../../apollo/cache";
import StyledToast from "../shared/StyledToast";
import MenuTitle from "./MenuTitle";
import SyncControls from "./SyncControls";
import AggregatorDropdown from "./AggregatorDropdown";
import HeroImageUpload from "./HeroImageUpload";
import placeholderImg from "../../media/prestau-placeholder.png";
import deleteIcon from "../../media/images/ic-clear.svg";
import historyIcon from "../../media/images/ic-history.svg";
import { MODAL_OPEN } from "../../config/config";
import ReactTooltip from "react-tooltip";

import style from "./styles/MenuManagement.module.scss";
import MenuTabs from "./MenuTabs";

const MenuManagement = () => {
  const [isDiff, toggleDiff] = useState(false);

  const json = useReactiveVar(jsonVar);
  // const {
  //   data: { json },
  // }: any = useQuery(GET_JSON);
  const { data: tabsData }: any = useQuery(GET_ACTIVE_MENU_TAB);
  const {
    data: { changedMenuStatus },
  }: any = useQuery(GET_CHANGED_MENU_STATUS);
  const {
    data: { lastModified },
  }: any = useQuery(GET_LAST_MODIFIED);
  const {
    data: { currentVersion },
  }: any = useQuery(GET_CURRENT_VERSION);
  const {
    data: { latestVersion },
  }: any = useQuery(GET_LATEST_VERSION);
  const {
    data: { menuToSync },
  }: any = useQuery(GET_MENU_TO_SYNC);
  useEffect(() => {
    cache.writeQuery({
      query: GET_IS_MENU_MODIFIED,
      data: {
        isMenuModified: !isObjectSame(menuToSync, json),
      },
    });
  }, [json, menuToSync]);
  /**
   * Function to check if two obejcts provided are same or different
   * COnverts both obejcts to strings and compares strings
   *
   * @param orignalObject object to be checked against
   * @param changedObject Obejct to be checked for
   * @returns true if both objects are same, else false
   */
  const isObjectSame = (orignalObject: any, changedObject: any) => {
    let a = JSON.stringify(orignalObject),
      b = JSON.stringify(changedObject);
    if (!a) a = "";
    if (!b) b = "";
    const isEqual = a.split("").join("") === b.split("").join("");
    return isEqual;
  };
  //before anything is
  return (
    <>
      <LoaderHandler
        loading={
          changedMenuStatus === STATUS.PENDING ||
          (json && Object.keys(json).length === 0)
        }
        error=""
      >
        <div
          className={style.heroImgContainer}
          style={{
            backgroundImage: `url(${
              json?.menus &&
              tabsData &&
              json.menus[tabsData.activeMenuTab]?.heroImage
                ? json.menus[tabsData.activeMenuTab].heroImage
                : placeholderImg
            }`,
          }}
        >
          <HeroImageUpload menuKey={tabsData?.activeMenuTab} />
          <Button
            variant="contained"
            component="label"
            className={style.uiBtnDelete}
            onClick={() =>
              //only updating the heroImage in the activeMenu to ""
              jsonVar({
                ...json,
                menus: {
                  ...json.menus,
                  [tabsData.activeMenuTab]: {
                    ...json.menus[tabsData.activeMenuTab],
                    heroImage: "",
                  },
                },
              })
            }
          >
            <img alt="Delete" src={deleteIcon} />
          </Button>
        </div>
        <div className={style.menuContainer}>
          <span className={style.titleContainer}>
            <IconButton
              onClick={() => openModalVar(MODAL_OPEN.HISTORY)}
              color="primary"
              size="medium"
              data-tip
              data-for="menuHistory"
            >
              <img src={historyIcon} alt="sync file" />
            </IconButton>
            <div className={style.innerTitleContainer}>
              {lastModified && currentVersion !== latestVersion && (
                <span>
                  Older version from:{" "}
                  <span style={{ color: "#328277" }}>{lastModified}</span>{" "}
                </span>
              )}
              {(currentVersion === latestVersion || !currentVersion) && (
                <span style={{ color: "#328277" }}>Latest version</span>
              )}
              <MenuTitle />
            </div>
            <IconButton
              onClick={() => openModalVar(MODAL_OPEN.TIME_TRAVEL)}
              color="primary"
              size="medium"
              data-tip
              data-for="timeTravel"
            >
              <img
                src={historyIcon}
                alt="time travel"
                className={style.timeTravelIconReverse}
              />
            </IconButton>
          </span>
          <div>
            <SyncControls isDiff={isDiff} toggleDiff={toggleDiff} />
          </div>
          <AggregatorDropdown isMenuManagement />
        </div>

        <MenuTabs json={json} showDifference={isDiff} />

        <StyledToast />
        <ReactTooltip
          id="menuHistory"
          place="bottom"
          type="dark"
          effect="float"
          globalEventOff="click"
        >
          <span>see menu history</span>
        </ReactTooltip>
        <ReactTooltip
          id="timeTravel"
          place="bottom"
          type="dark"
          effect="float"
          globalEventOff="click"
        >
          <span>see time travel menu</span>
        </ReactTooltip>
      </LoaderHandler>
    </>
  );
};

export default MenuManagement;
