import React from "react";
import { LocalStorageWrapper, persistCache } from "apollo3-cache-persist";
import { useState } from "react";
import { useEffect } from "react";
import { cache } from "./cache";
/**
 * A cache wrapper for the app, app initiates after the this component
 * Returns when the cache is initialized
 * @param param0
 * @returns React Elemet with provided children when cache is initialized
 */
export const PersistentCache = ({ children }) => {
  const [cachePersistant, setPersistance] = useState(false);
  useEffect(() => {
    persistCache({
      cache,
      storage: new LocalStorageWrapper(window.localStorage),
    }).then(() => {
      setPersistance(true);
    });
  });

  return <>{cachePersistant && children}</>;
};
