import React, { useEffect } from "react";
import { FormControlLabel, Checkbox } from "@material-ui/core";
import { useState } from "react";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import styles from "../styles/Repeat.module.scss";
import { useQuery } from "@apollo/client";
import { CUSTOMIZATION_SCHEDULE } from "../../../../apollo/operations/queries/localQueries";
import { cache } from "../../../../apollo/cache";
import { DaysOfWeekDefault } from "../Utils/constants";
/**
 *
 * @returns Weekly Variant for the Repeat Secion in Schedule Model
 */
export const Weekly = () => {
  const {
    data: { schedule },
  }: any = useQuery(CUSTOMIZATION_SCHEDULE);

  const getWeekDaysFromArray = (days: any): typeof DaysOfWeekDefault => {
    //if days array is null, set it to default monday
    if (!days) days = ["Monday"];
    const mDays = { ...DaysOfWeekDefault };
    for (let day of days) {
      mDays[day].selected = true;
    }
    return mDays;
  };
  const [weekDays, setWeekDays] = useState<typeof DaysOfWeekDefault>(
    getWeekDaysFromArray(schedule.weekDays)
  );

  const onCheckBoxChange = (day: string) => {
    //updated weekdays object with the new state
    const updatedSelection = {
      ...weekDays,
      [day]: { ...weekDays[day], selected: !weekDays[day].selected },
    };
    //updating local UI state
    setWeekDays(updatedSelection);
  };

  useEffect(() => {
    if (schedule) {
      //getting selected days out of weekdays array
      const selectedDays = Object.keys(weekDays).filter(
        (key) => weekDays[key].selected
      );
      //creating an object to be sent to parent
      const weekDaysObject = { weekDays: selectedDays };
      cache.writeQuery({
        query: CUSTOMIZATION_SCHEDULE,
        data: {
          schedule: {
            ...schedule,
            ...weekDaysObject,
          },
        },
      });
    }
  }, [weekDays, schedule]);
  return (
    <div>
      {Object.keys(weekDays).map((day) => {
        return (
          <FormControlLabel
            control={
              <Checkbox
                icon={<RadioButtonUncheckedIcon fontSize="small" />}
                checkedIcon={<CheckCircleOutlineIcon fontSize="small" />}
                checked={weekDays[day].selected}
                onChange={() => onCheckBoxChange(day)}
                name={day}
                color="primary"
              />
            }
            label={
              <span className={styles.day_label}>{weekDays[day].short}</span>
            }
          />
        );
      })}
    </div>
  );
};
