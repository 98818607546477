/* eslint-disable @typescript-eslint/ban-types */
import React, { useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import classnames from "classnames";
import produce from "immer";
import { jsonVar, snackbarStateVar } from "../../../apollo/cache";
import { helpReorderList } from "../helpers/reorderListHelpers";
import Category from "./Category";
import CategoryItems from "./CategoryItems";
import {
  MasterMenu,
  MenuCategories,
  MenuCategory,
  ModifierOption,
} from "../../../models/json";

import style from "./styles/CategoryList.module.scss";
//update prop type
type Props = {
  json: MasterMenu;
  menuKey: string;
  tabs: string[];
};

type Provided = {
  innerRef: () => void;
  draggableProps: {};
  dragHandleProps: {};
  placeholder: {};
};

const CategoryList: React.FC<Props> = ({ json, menuKey, tabs }: Props) => {
  const [localJsonState, updateLocalJsonState] = useState(json);

  useEffect(() => {
    updateLocalJsonState(json);
  }, [json]);

  const getUpdatedCategory = (
    categoryIndex: number,
    categoryItems: ModifierOption[],
    category: MenuCategory
  ) => {
    //converting to collection
    const newItemsObject = {} as { [key: string]: ModifierOption };
    if (categoryItems?.length === 0) {
      snackbarStateVar({
        open: true,
        message: "No Items to move",
      });
      return;
    }

    for (let option of categoryItems) {
      if (option) newItemsObject[option.id] = option;
    }

    const updatedJson = produce(jsonVar(), (draftJson) => {
      if (draftJson?.categories[category.id]?.entities) {
        draftJson.categories[category.id].entities = newItemsObject;
      }
    });

    updateLocalJsonState(updatedJson);
    jsonVar(updatedJson);
  };
  /**
   * Data is reordered at the end of a DND action
   * @param result Object with DND result
   * @description
   * Source gives the index of item before drag
   *  Destination gives the location of source after drag
   */
  const reorderList = (result: {
    source: { index: number };
    destination: { index: number };
  }) => {
    const destinationIndex = !result.destination
      ? result.source.index
      : result.destination.index;
    const categoriesList = helpReorderList(
      result.source.index,
      destinationIndex,
      /**
       * Convering cagtegories object to an array to be used for DND
       */
      Object.values(json.categories)
    ) as MenuCategory[];
    /**
     * Reodering JSON after an item moved by DND
     */
    const reorderedJson = produce(jsonVar(), (draftJson: MasterMenu) => {
      draftJson.categories = keyValeObjectForMenuCategories(categoriesList);
      //updating categories for each menu
      for (const menu in draftJson.menus) {
        const categoryIds = {} as { [key: string]: string };
        for (const index in categoriesList) {
          const categoryObject = categoriesList[index];
          //converting to {key : value}
          categoryIds[categoryObject.title.translations.en_uk] =
            categoryObject.title.translations.en_uk;
        }
        draftJson.menus[menu].category_ids = categoryIds;
      }
    });

    updateLocalJsonState(reorderedJson);
    jsonVar(reorderedJson);
  };
  const keyValeObjectForMenuCategories = (
    categoriesArray: MenuCategory[]
  ): MenuCategories => {
    let categories: MenuCategories = {};
    for (let category of categoriesArray) {
      if (typeof category.title === "string") {
        categories[category.title] = category;
      } else {
        categories[category.title.translations.en_uk] = category;
      }
    }
    return categories;
  };

  return (
    <>
      <DragDropContext onDragEnd={reorderList}>
        <Droppable droppableId="droppable" isDropDisabled={false}>
          {(provided: Provided, snapshot: any) => {
            return (
              <div ref={provided.innerRef}>
                <>
                  {/* Converting categories object to array for DND */}
                  {Object.values(
                    localJsonState?.menus[menuKey]?.category_ids || {}
                  ).map((categoryId, index: number) => {
                    const category = localJsonState.categories[categoryId];
                    return (
                      <Draggable
                        key={category.id}
                        draggableId={category.id}
                        index={index}
                        isDragDisabled={false}
                      >
                        {(
                          provided: Provided,
                          snapshot: { isDragging: boolean }
                        ) => {
                          const categoryItemstyle = classnames(
                            style.categoryItem,
                            { [style.draggedItem]: snapshot.isDragging }
                          );

                          return (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              className={categoryItemstyle}
                            >
                              {category && (
                                <Category
                                  category={category}
                                  jsonItems={json.items}
                                  dragHandleProps={provided.dragHandleProps}
                                  menuKey={menuKey}
                                  tabs={tabs}
                                  localJsonState={localJsonState}
                                >
                                  <CategoryItems
                                    categoryIndex={index}
                                    category={category}
                                    getUpdatedCategory={getUpdatedCategory}
                                  />
                                </Category>
                              )}
                            </div>
                          );
                        }}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </>
              </div>
            );
          }}
        </Droppable>
      </DragDropContext>
    </>
  );
};

export default CategoryList;
