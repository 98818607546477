/* eslint-disable react/require-default-props */
/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import { useQuery } from "@apollo/client";
import { Button, Chip } from "@material-ui/core";
import classnames from "classnames";
import NutritionalLabelButton from "./NutritionalLabelButton";
import { MenuItemWithOptions } from "../../../models/menu";
import { changeItemInJsonItems } from "../../../apollo/operations/actions/actions";
import {
  GET_IS_SIDE_NAV_OPEN,
  openModalVar,
  suspendItemIdVar,
} from "../../../apollo/cache";
import TitleWithInput from "../../shared/TitleWithInput";
import DeleteItemButton from "./DeleteItemButton";
import ChangeCategoryButton from "./ChangeCategoryButton";
import MenuDiffIcons from "../../shared/MenuDiffIcons";
import { openItemModal } from "../../../helpers/menu";
import { useStyles } from "./useStylesMenuItem";
import suspendIcon from "../../../media/images/suspend-icon.svg";
import { MODAL_OPEN } from "../../../config/config";
import placeholderImg from "../../../media/prestau-placeholder.png";
import MealSetIndicator from "../../shared/MealSetIndicator";
import { NUTRITIONAL_LABEL_OPTIONS } from "../../../constants/constants";

import style from "./styles/MealItem.module.scss";
import NutritionalData from "./NutritionalData";

interface Props {
  item: MenuItemWithOptions;
  category: any;
  isReadOnly?: boolean;
}

const MealItem: React.FC<Props> = ({ item, category, isReadOnly }) => {
  const {
    data: { isOpened },
  }: any = useQuery(GET_IS_SIDE_NAV_OPEN);

  const classes = useStyles();

  const openModal = () => {
    openItemModal(item, category);
  };

  const openSuspendModal = () => {
    openModalVar(MODAL_OPEN.SUSPEND_ITEM);
    suspendItemIdVar(item.id);
  };

  const updateMenuTitle = (item: any) => {
    changeItemInJsonItems(item);
  };

  const itemTitle =
    item.title && item.title.translations
      ? (item.title.translations.en_uk as string)
      : (item.title as any) || (item.id as string);

  const itemDescription =
    item.description && item.description.translations
      ? (item.description.translations.en_uk as string)
      : (item.description as any);

  return (
    <div className={style.outterRow}>
      <div
        className={style.mealItem}
        style={{ width: `${isOpened ? "290px" : "350px"}` }}
      >
        <div className={style.uiFlex}>
          <MealSetIndicator
            item={item}
            indicatorFlagStyle={style.indicatorFlag}
          />
          <h1 className={style.itemTitle}>
            <MenuDiffIcons item={item} />
            <span className={style.titleAndPrice}>
              <span className={style.itemTitle}>
                {isReadOnly ? (
                  itemTitle
                ) : (
                  <TitleWithInput
                    item={item}
                    style={style}
                    stateUpdate={updateMenuTitle}
                    isContainerAbsolute
                  />
                )}
              </span>
            </span>
          </h1>
          {!isReadOnly && (
            <>
              <DeleteItemButton item={item} category={category} />
              <Button
                onClick={openSuspendModal}
                variant="contained"
                color="secondary"
                disableElevation
                classes={{ root: classes.root }}
              >
                <img src={suspendIcon} alt="Suspend" />
              </Button>
              <ChangeCategoryButton item={item} category={category} />
              <NutritionalLabelButton item={item} category={category} />
            </>
          )}
        </div>
        <div onClick={openModal} className="row">
          <div className={style.descPriceCol}>
            <p className={style.mealItemDesc}>{itemDescription}</p>
          </div>
          <img
            className={style.mealItemImg}
            src={item.image_url ? item.image_url : placeholderImg}
            alt="Meal card"
          />
        </div>
        <div style={{ display: "flex" }}>
          {item.price_info.price > 0 && (
            <span className={style.itemTitle_price}>{`£${
              item.price_info && item.price_info.price / 100
            }`}</span>
          )}
          <NutritionalData item={item} />
        </div>
        <div className={style.labels}>
          {item.dish_info &&
            item.dish_info.classifications.dietary_label_info.labels.map(
              (label) => (
                <Chip
                  label={
                    <span className={style.label}>
                      <img
                        className={style.labelIcon}
                        src={require(`../../../media/images/${label.toUpperCase()}-icon.png`)}
                        alt={label}
                      />
                    </span>
                  }
                  color="primary"
                  classes={{
                    root: classnames(
                      style.chipRoot,
                      {
                        [style.glutenColor]:
                          NUTRITIONAL_LABEL_OPTIONS[label] === "Gluten free",
                      },
                      {
                        [style.spicyColor]:
                          NUTRITIONAL_LABEL_OPTIONS[label] === "Spicy",
                      }
                    ),
                    label: style.chipLabel,
                  }}
                />
              )
            )}
        </div>
      </div>
    </div>
  );
};

export default MealItem;
