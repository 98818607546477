/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import classnames from "classnames";
import Switch from "@material-ui/core/Switch";
import { useQuery } from "@apollo/client";
import { Button, IconButton, makeStyles } from "@material-ui/core";
import liveIcon from "../../media/images/ic-live.svg";
import { GET_LIVE_TOGGLE } from "../../apollo/operations/queries/localQueries";
import CloseIcon from "@material-ui/icons/Close";
import {
  GET_TIME_TRAVEL_DATE,
  liveToggleVar,
  prevRootMenuVar,
  timeTravelDateVar,
} from "../../apollo/cache";
import logOutUser from "../../helpers/logOutUser";
import style from "./styles/TopNav.module.scss";
import { loadNewMenu } from "../../helpers/menu";

const useStyles = makeStyles({
  root: {
    color: "white",
  },
});

const TopNav = () => {
  const { data } = useQuery(GET_LIVE_TOGGLE);
  const timeTravel = useQuery(GET_TIME_TRAVEL_DATE);
  const classes = useStyles();

  const handleToggle = () => {
    if (data.liveToggle) {
      liveToggleVar(false);
    } else {
      liveToggleVar(true);
    }
  };

  const navbarStyle = classnames(style.navbar, {
    [style.live]: data?.liveToggle,
  });

  const switchOffTimeTravel = () => {
    timeTravelDateVar("");
    loadNewMenu(prevRootMenuVar());
    prevRootMenuVar(null);
  };

  return (
    <header className={navbarStyle} data-testid="top-nav">
      {timeTravel.data?.timeTravelDate && (
        <div className={style.navbarTimeTravel}>
          You are now viewing menu on:{" "}
          {new Date(timeTravel.data.timeTravelDate).toString()}
          <IconButton
            size="medium"
            onClick={() => switchOffTimeTravel()}
            classes={classes}
          >
            <CloseIcon />
          </IconButton>
        </div>
      )}
      <div className={style.navbarInner}>
        <div className={style.navbarLive}>
          <img src={liveIcon} alt="live" />
          <p>Live Mode</p>
          <p>Publishing will affect live stores</p>
        </div>
        <div className={style.navbarAction}>
          <label>Test stores</label>
          <Switch
            checked={data?.liveToggle}
            onChange={handleToggle}
            color="primary"
            name="checkedLive"
            inputProps={{ "aria-label": "primary checkbox" }}
          />
          <label>Live stores</label>
        </div>
        <div className={style.navbarUser}>
          <img
            className={style.navbarUserImg}
            alt="Profile Pic"
            src="https://img.alicdn.com/imgextra/i2/3217480302/TB2ljQ4cRfM8KJjSZFrXXXSdXXa_!!3217480302.jpg"
          />
          <div>
            <h3 className={style.navbarUserType}>User</h3>
            <h2 className={style.navbarUserName}>Evelyn Allen</h2>
          </div>
        </div>
        <Button
          color="primary"
          onClick={() => logOutUser()}
          style={{ margin: "0 0 0 7px", fontSize: "16px" }}
        >
          Log out
        </Button>
      </div>
    </header>
  );
};

export default TopNav;
