import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import Button from "@material-ui/core/Button";
import { v4 as uuid } from "uuid";
import { UPDATE_STORE_GROUP } from "../../../../apollo/operations/mutations/mutations";
import { GET_STORE_GROUPINGS } from "../../../../apollo/operations/queries/serverQueries";
import { deleteStoreFromGroup } from "../../../../apollo/operations/actions/actions";
import { StoreGroupType } from "../../../../models/stores";
import deleteIcon from "../../../../media/images/ic-meal-delete.svg";
import { useStyles } from "./useStylesStoreItem";
import WarningModal from "../../../shared/WarningModal";

import style from "../styles/StoreGroups.module.scss";

interface Props {
  visibleStores: string[];
  group: StoreGroupType;
  stores: { [id: string]: string };
}

export const StoresInGroup: React.FC<Props> = ({
  visibleStores,
  group,
  stores,
}) => {
  const classes = useStyles();
  const [updateStoreGroup] = useMutation(UPDATE_STORE_GROUP);
  const [selectedStore, setSelectedStore] = useState<string>("");
  const [isWarningModalOpen, toggleWarningModal] = useState<boolean>(false);

  const openModal = (store: string) => {
    setSelectedStore(store);
    toggleWarningModal(true);
  };

  const { data } = useQuery(GET_STORE_GROUPINGS);
  const storeGroups = data.listRestaurantGroupings.items;

  const deleteStore = () => {
    const { id, ...noIdGroup } = deleteStoreFromGroup(
      storeGroups,
      selectedStore,
      group.title
    );
    updateStoreGroup({ variables: { input: noIdGroup } });
    setSelectedStore("");
  };

  return (
    <>
      {visibleStores.includes(group.title) && (
        <>
          <div className={style.storesInGroup}>
            {group.storeIds.UberEats.map((store: string) => (
              <div key={uuid()} className={style.storeItem}>
                <div className={style.storeItemTop}>
                  <h1 className={style.storeItemTitle}>{stores[store]}</h1>
                  <Button
                    onClick={() => openModal(store)}
                    data-tip
                    data-for="deleteItem"
                    variant="contained"
                    color="secondary"
                    disableElevation
                    classes={{ root: classes.root }}
                  >
                    <img src={deleteIcon} alt="Delete" />
                  </Button>
                </div>
              </div>
            ))}
          </div>
          <WarningModal
            isOpen={isWarningModalOpen}
            modalMessage="Are you sure?"
            onConfirm={deleteStore}
            onClose={() => toggleWarningModal(false)}
          />
        </>
      )}
    </>
  );
};

export default StoresInGroup;
