import React from "react";
import AlertListControlTable from "./AlertListControlTable";
import { AlertListControlDataHandler } from "./AlertListHandler";

const AlertListControl = () => (
  <AlertListControlDataHandler>
    {(alertListData: any[]) => (
      <AlertListControlTable alertList={alertListData} />
    )}
  </AlertListControlDataHandler>
);

export default AlertListControl;
