import { MenuItemWithOptions } from "../../../models/menu";

export const computeOriginalIndex = (
  maxItems: number,
  chunkIndex: number,
  indexInChunk: number
) => {
  return chunkIndex * maxItems + indexInChunk;
};

export const computeOriginalIndexAfterDrop = (
  maxItems: number,
  sourceIndex: number,
  destinationIndex: number,
  indexInChunk: number
) => {
  return (
    destinationIndex * maxItems +
    indexInChunk +
    (sourceIndex < destinationIndex ? -1 : 0)
  );
};

export const splitCategoryItems = (
  categoryItems: MenuItemWithOptions[],
  splitSize = 3
) => {
  const splitArray: any[] = [];

  for (let i = 0; i < categoryItems.length; i += splitSize) {
    splitArray.push(categoryItems.slice(i, i + splitSize));
  }

  return splitArray;
};

export const helpReorderList = (
  sourceIndex: number,
  destinationIndex: number,
  list: any[]
): any[] => {
  if (destinationIndex === sourceIndex) {
    return list;
  }

  const mutableList = [...list];

  const indexDifference = sourceIndex - destinationIndex;
  const isDifferenceBiggerThanOne = indexDifference < -1 || indexDifference > 1;

  if (!isDifferenceBiggerThanOne) {
    const newItem = list[destinationIndex];
    mutableList.splice(destinationIndex, 1, mutableList[sourceIndex]);
    mutableList.splice(sourceIndex, 1, newItem);
  } else if (isDifferenceBiggerThanOne && destinationIndex > sourceIndex) {
    mutableList.splice(destinationIndex + 1, 0, mutableList[sourceIndex]);
    mutableList.splice(sourceIndex, 1);
  } else if (isDifferenceBiggerThanOne && destinationIndex < sourceIndex) {
    mutableList.splice(destinationIndex, 0, mutableList[sourceIndex]);
    mutableList.splice(sourceIndex + 1, 1);
  }

  return mutableList;
};
