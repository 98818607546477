/* eslint-disable react/require-default-props */
import React from "react";
import { useQuery } from "@apollo/client";
import { useCustomizationsState } from "../CustomizationsProvider";
import StoreGroupsAutocomplete from "../../../shared/StoreGroupsAutocomplete";
import { StoreGroupType } from "../../../../models/stores";
import { GET_STORE_GROUPINGS } from "../../../../apollo/operations/queries/serverQueries";
import { CUSTOMIZATION } from "../utils/config";
import { StoreGroupsDataFromCacheOrNetwork } from "../../../Providers/initalQueryProvider";

import style from "../styles/CustomizationModal.module.scss";

type Props = {
  label: string;
  moreInfoComponent?: JSX.Element;
};

const CustomizationStoreGroupsAutocomplete: React.FC<Props> = ({
  label,
  moreInfoComponent,
}) => {
  const { data } = useQuery(GET_STORE_GROUPINGS);
  const { state, dispatch } = useCustomizationsState();

  const handleStoreSelect = (items: StoreGroupType[]) => {
    dispatch({
      type: CUSTOMIZATION.STORE_GROUPS,
      payload: items.map((item) => item.id),
    });
  };

  const selectedGroups = data
    ? data.listRestaurantGroupings.items.filter((group) =>
        state[CUSTOMIZATION.STORE_GROUPS]?.includes(group.id)
      )
    : [];
  return (
    <>
      {!state[CUSTOMIZATION.GLOBAL] && (
        <>
          <span className={style.inputLabel}>
            {label}: {moreInfoComponent}
          </span>
          <div className={style.inputContainer}>
            <StoreGroupsDataFromCacheOrNetwork>
              {(groups) => (
                <StoreGroupsAutocomplete
                  selectedGroups={selectedGroups}
                  getSelectedGroups={handleStoreSelect}
                  storeGroups={groups}
                />
              )}
            </StoreGroupsDataFromCacheOrNetwork>
          </div>
        </>
      )}
    </>
  );
};

export default CustomizationStoreGroupsAutocomplete;
