import React from "react";
import TitleWithInput from "../../../shared/TitleWithInput";
import icon from "../../../../media/images/ic-card-menu.svg";
import { ProductCollection } from "../..";
import { useMutation } from "@apollo/client";
import { UPSERT_ITEM_COLLECTION } from "../../../../apollo/operations/mutations/mutations";

import style from "../styles/CollectionsList.module.scss";

interface Props {
  collection: any;
  deleteIconComponent: any;
}

const CollectionHeader: React.FC<Props> = ({
  collection,
  deleteIconComponent,
}) => {
  const [updateCollection] = useMutation(UPSERT_ITEM_COLLECTION);
  const changeTitle = (collection: ProductCollection) => {
    updateCollection({
      variables: {
        itemIds: collection.itemIds,
        title: collection.title,
        id: collection.id,
      },
    });
  };

  return (
    <>
      <div className={style.menuCardBodyContent}>
        <div className={style.uiFlexGrow}>
          <img src={icon} className={style.itemImg} alt="collection" />
          <h1 className={style.itemTitle}>
            <TitleWithInput
              item={collection}
              stateUpdate={changeTitle}
              style={style}
            />
          </h1>
          <p className={style.itemDesc}>{collection.itemIds.length} items</p>
        </div>
        <div className={style.uiTextRight}>{deleteIconComponent}</div>
      </div>
    </>
  );
};

export default CollectionHeader;
