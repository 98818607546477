/* eslint-disable react/require-default-props */
import React, { useState, useEffect } from "react";
import { TextField } from "@material-ui/core";
import {
  getCurrentDateString,
  getUnixInSeconds,
  isLessThanCurrentDate,
  isMoreThanYearLater,
} from "../../../helpers/scheduleDate";

type Props = {
  getDate: (date: string) => void;
  style: any;
  labelText?: string;
};

const ScheduleDate: React.FC<Props> = ({
  getDate,
  style,
  labelText = "Schedule:",
}) => {
  const [validDateMessage, setValidDateMessage] = useState("");
  const currentDate: string = getCurrentDateString();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const unix: number = getUnixInSeconds(e.target.value);

    if (isLessThanCurrentDate(unix)) {
      setValidDateMessage(
        "Please enter a date which is later than the current date"
      );
      return;
    }

    if (isMoreThanYearLater(unix)) {
      setValidDateMessage(
        "Please enter a date which is less than a year later"
      );
      return;
    }

    setValidDateMessage("");
    getDate(e.target.value);
  };

  useEffect(() => {
    getDate(currentDate);
  }, [currentDate, getDate]);

  return (
    <div className={style.scheduleContainer}>
      <h6 className="mr-1">{labelText}</h6>
      <TextField
        id="datetime-local"
        type="datetime-local"
        variant="outlined"
        defaultValue={currentDate}
        label={`${Intl.DateTimeFormat().resolvedOptions().timeZone} timezone`}
        onChange={handleChange}
        style={{ width: "100%" }}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <div className={style.validDateMessage}>{validDateMessage}</div>
    </div>
  );
};

export default ScheduleDate;
