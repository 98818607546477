import React, { useState, useEffect } from "react";
import { useQuery, useLazyQuery } from "@apollo/client";
import Button from "@material-ui/core/Button";
import uploadFileToBucket from "../../../menuApi/uploadFileToBucket";
import { GET_UPLOAD_URL } from "../../../apollo/operations/queries/serverQueries";
import {
  cache,
  GET_JSON_MENU_NAME,
  GET_MENU_KEY,
  jsonVar,
  menuToSyncVar,
} from "../../../apollo/cache";
import saveFinalJson from "../../../helpers/saveFinalJson";
import { CHANGE_SIZE } from "../../../constants/constants";

import style from "./styles/SaveModal.module.scss";
import { GET_IS_MENU_MODIFIED } from "../../../apollo/operations/queries/localQueries";

type Props = {
  commitMessage: string;
  getSavedMenuStatus: (status: string) => void;
  isRegenerate: boolean;
  changeSize: CHANGE_SIZE;
};

const SaveMenuButton = ({
  commitMessage,
  getSavedMenuStatus,
  isRegenerate,
  changeSize,
}: Props) => {
  const [getSaveUrl, { data }] = useLazyQuery(GET_UPLOAD_URL, {
    fetchPolicy: "network-only",
  });
  const {
    data: { menuKey },
  }: any = useQuery(GET_MENU_KEY);

  const {
    data: { jsonMenuName },
  }: any = useQuery(GET_JSON_MENU_NAME);
  const [warningMessage, setWarningMessage] = useState("");

  const uploadFile = () => {
    if (!commitMessage) {
      setWarningMessage(
        "Name your changes, in order to enable saving the menu"
      );
    } else {
      const params = {
        assetType: "menu",
        fileName: `${menuKey}.json`,
        fileType: "application/json",
        commitMessage,
        regenerate: isRegenerate,
        versionChange: Object.keys(CHANGE_SIZE)
          .find((key) => CHANGE_SIZE[key] === changeSize)
          ?.toLowerCase(),
      };
      getSaveUrl({ variables: params });
    }
  };

  useEffect(() => {
    if (data && data.uploadUrl) {
      const params = {
        uploadUrl: data.uploadUrl,
        file: saveFinalJson(jsonMenuName),
        setUploadMessage: getSavedMenuStatus,
        isJson: true,
      };
      uploadFileToBucket(params);
      /**
       * Update @isMenuModified when changes are pushed to the server
       */
      cache.writeQuery({
        query: GET_IS_MENU_MODIFIED,
        data: {
          isMenuModified: false,
        },
      });
      /**changing the base state for menus
       * this will effect comapre changes options
       */
      menuToSyncVar(jsonVar());
    }
  }, [data, commitMessage, getSavedMenuStatus, jsonMenuName]);

  return (
    <div className={style.saveMenuButtonContainer}>
      <div className={style.warningMessage}>{warningMessage}</div>
      <Button
        onClick={uploadFile}
        autoFocus
        color="primary"
        variant="contained"
        disableElevation
        size="large"
      >
        Save Menu
      </Button>
    </div>
  );
};

export default SaveMenuButton;
