import React from "react";
import style from "./styles/MealItem.module.scss";

const NutritionalData = ({ item }) => {
  return (
    <>
      {item.nutritional_info?.calories_per_serving?.upper_range && (
        <span style={{ marginLeft: "auto" }} className={style.itemTitle_price}>
          {`${item.nutritional_info?.calories_per_serving?.lower_range} - ${item.nutritional_info?.calories_per_serving?.upper_range} kcal`}
          {item.nutritional_info?.number_of_servings > 1
            ? ", " + item.nutritional_info?.number_of_servings + " servings"
            : ""}
        </span>
      )}
    </>
  );
};

export default NutritionalData;
