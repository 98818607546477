import React from "react";
import {
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";

const STATIC_HEADERS = [
  "Affected Stores",
  "Channels",
  "Reason",
  "Type",
  "Timestamp",
  "Action",
];

interface Props {
  orderDirection: "asc" | "desc" | undefined;
  valueToOrderBy: string;
  handleRequestSort: (
    event: React.MouseEvent<unknown>,
    property: string
  ) => void;
}

const TableHeader: React.FC<Props> = ({
  orderDirection,
  valueToOrderBy,
  handleRequestSort,
}) => {
  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {STATIC_HEADERS.map((title) => (
          <TableCell align="center" key={title}>
            {title === "Timestamp" ? (
              <TableSortLabel
                active={valueToOrderBy === "timestamp"}
                direction={
                  valueToOrderBy === "timestamp" ? orderDirection : "asc"
                }
                onClick={createSortHandler("timestamp")}
              >
                {title}
              </TableSortLabel>
            ) : (
              title
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
export default TableHeader;
