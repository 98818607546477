import { RadioGroup, FormControlLabel, Radio } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import ScheduleModal from "../../ScheduleModal";
import { useCustomizationsState } from "../CustomizationsProvider";
import style from "../styles/CustomizationModal.module.scss";
import { CUSTOMIZATION } from "../utils/config";
import { useQuery } from "@apollo/client";
import { CUSTOMIZATION_SCHEDULE } from "../../../../apollo/operations/queries/localQueries";
import { cache } from "../../../../apollo/cache";
import {
  DAYS,
  MONTHS,
  WeeksInMonth,
} from "../../ScheduleModal/Utils/constants";
import { Schedule } from "../../ScheduleModal/Utils/types";

const CustomizationSchedule: React.FC = () => {
  const {
    data: { schedule },
  }: any = useQuery(CUSTOMIZATION_SCHEDULE);
  const [radioValue, setRadioValue] = useState("none");
  const [isOpen, setIsOpen] = useState(false);
  const { state, dispatch } = useCustomizationsState();

  const removeUnwantedData = (repeat: string, payload: any) => {
    const dispatchData = { ...payload };
    switch (repeat) {
      case "daily":
        delete dispatchData.pos;
        delete dispatchData.day;
        delete dispatchData.month;
        delete dispatchData.weekDays;
        break;
      case "weekly":
        delete dispatchData.pos;
        delete dispatchData.day;
        delete dispatchData.month;
        break;
      case "monthly":
        delete dispatchData.month;
        break;
      default:
        break;
    }

    delete dispatchData.monthfor1;
    delete dispatchData.monthfor2;
    return dispatchData;
  };

  const onClose = () => {
    // console.log(state[CUSTOMIZATION.SCHEDULE]);
    //reset state
    cache.writeQuery({
      query: CUSTOMIZATION_SCHEDULE,
      data: {
        schedule: state[CUSTOMIZATION.SCHEDULE] || {},
      },
    });
    //close modal
    setIsOpen(false);
  };
  const onSave = () => {
    //waht to do when saved
    const updatedSchedule = removeUnwantedData(schedule.repeat, schedule);
    cache.writeQuery({
      query: CUSTOMIZATION_SCHEDULE,
      data: {
        schedule: updatedSchedule,
      },
    });
    dispatchChanges(updatedSchedule);
    //close model
    onClose();
  };
  const onModelStateChange = (state: boolean) => {
    setIsOpen(state);
    if (state) {
      //setting defaults
      if (!schedule.repeat || !schedule.interval) {
        cache.writeQuery({
          query: CUSTOMIZATION_SCHEDULE,
          data: {
            schedule: {
              repeat: "daily",
              interval: 1,
            },
          },
        });
      }
    }
  };
  const onRadioChange = (e: any) => {
    const value = e.target.value;
    //if null selected, set schedule to {}
    if (value === "none") {
      cache.writeQuery({
        query: CUSTOMIZATION_SCHEDULE,
        data: {
          schedule: {},
        },
      });
      dispatchChanges({});
    }

    setRadioValue(value);
  };
  const dispatchChanges = useCallback(
    (payload = null as any) => {
      if (!payload) {
        if (schedule && schedule.interval && schedule.repeat)
          payload = schedule;
        else {
          payload = {};
        }
      }

      dispatch({
        type: "schedule" as CUSTOMIZATION,
        payload: payload,
      });
    },
    [schedule, dispatch]
  );

  useEffect(() => {
    if (
      state[CUSTOMIZATION.SCHEDULE] &&
      state[CUSTOMIZATION.SCHEDULE].interval &&
      state[CUSTOMIZATION.SCHEDULE].repeat
    ) {
      cache.writeQuery({
        query: CUSTOMIZATION_SCHEDULE,
        data: {
          schedule: state[CUSTOMIZATION.SCHEDULE],
        },
      });
      setRadioValue("schedule");
    } else {
      setRadioValue("none");
    }
  }, [state]);

  return (
    <>
      <span className={style.inputLabel}>Customization Schedule</span>
      <div className={style.inputContainer}>
        <RadioGroup
          defaultValue="dayOfMonth"
          aria-label="Dat of the Month"
          name="repeat-week"
          value={radioValue}
          onChange={onRadioChange}
        >
          <FormControlLabel
            className={style.radio_item}
            value="none"
            control={<Radio color="primary" />}
            label={"applicable all day, every day"}
          />
          <FormControlLabel
            className={style.radio_item}
            value="schedule"
            control={<Radio color="primary" />}
            label={
              <CustomScheduleRadio
                setOpenState={(state: boolean) => onModelStateChange(state)}
                customSchedule={state[CUSTOMIZATION.SCHEDULE]}
              />
            }
          />
        </RadioGroup>
        <ScheduleModal
          isOpen={isOpen}
          onClose={() => onClose()}
          onSave={() => onSave()}
        />
      </div>
    </>
  );
};
/**
 *
 * @param param0
 * @returns Radio UI for Custom Schedule
 */
type CustomScheduleRadioProps = {
  setOpenState: (state: boolean) => void;
  customSchedule: Schedule;
};
const CustomScheduleRadio = ({
  setOpenState,
  customSchedule,
}: CustomScheduleRadioProps) => {
  const getRepeat = (val: any) => {
    switch (val) {
      case "daily":
        return "day";
      case "weekly":
        return "week";
      case "monthly":
        return "month";
      case "yearly":
        return "year";
      default:
        return "";
    }
  };
  const getCustomScheduleTextDescription = () => {
    if (!customSchedule?.interval) return "Click to add Custom Schedule";
    let text = "";
    //for repeat
    text = `${text} Repeat ${customSchedule.repeat};`;
    if (customSchedule.interval)
      text = `${text} Repeat Every ${customSchedule.interval} ${getRepeat(
        customSchedule.repeat
      )}(s)`;
    if (
      customSchedule.pos ||
      customSchedule.weekDays ||
      customSchedule.month ||
      customSchedule.day
    ) {
      if (customSchedule.repeat === "weekly") {
        if (customSchedule.weekDays)
          text = `${text} on ${customSchedule.weekDays}`;
      } else {
        if (customSchedule.day) text = `${text} on ${DAYS[customSchedule.day]}`;
        if (customSchedule.pos)
          text = `${text} on ${WeeksInMonth[customSchedule.pos]}`;
        if (customSchedule.weekDays)
          text = `${text} ${customSchedule.weekDays}`;
        if (customSchedule.month)
          text = `${text} of ${MONTHS[customSchedule.month]}`;
      }

      text = `${text};`;
    } else {
      text = `${text};`;
    }

    //stop condition
    if (customSchedule.count || customSchedule.until) {
      if (customSchedule.until)
        text = `${text} Run Until ${customSchedule.until.substring(0, 10)};`;
      if (customSchedule.count)
        text = `${text} Run Until it reaches ${customSchedule.count} occurance(s);`;
    } else {
      text = `${text} Never Stop;`;
    }
    if (customSchedule.openingTime) {
      text = `${text} Run from ${customSchedule.openingTime} Till ${customSchedule.closingTime};`;
    } else {
      text = `${text} Run All day;`;
    }

    return text;
  };
  return (
    <>
      <div
        className={style.customScheduleBox}
        onClick={() => {
          setOpenState(true);
        }}
      >
        <div className={style.scheduleDetails}>
          {getCustomScheduleTextDescription()}
        </div>
        <button className={style.editButton}>Edit</button>
      </div>
    </>
  );
};
export default CustomizationSchedule;
