import React, { memo, SetStateAction, Dispatch } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import { Button } from "@material-ui/core";
import { UPDATE_RESTAURANT_DATA } from "../../../../apollo/operations/mutations/mutations";
import { deleteIdFromChangedStores } from "../../../../apollo/operations/actions/actions";
import {
  GET_CHANGED_STORES,
  GET_SAVED_MENU_NAME,
} from "../../../../apollo/operations/queries/localQueries";
import {
  areChangesPublishedVar,
  areChangesSavedVar,
  userGroupVar,
} from "../../../../apollo/cache";
import { PUBLISH_MENU } from "../../../../apollo/operations/queries/serverQueries";
import { StoreType } from "../../../../models/stores";
import { ENV_VARS, PERMISSIONS } from "../../../../config/config";
import ReactTooltip from "react-tooltip";

interface Props {
  store: StoreType;
  setSaveStatus: Dispatch<SetStateAction<boolean>>;
  isStoreSaved: boolean;
}

const SaveHoursButton = memo(
  ({ store, setSaveStatus, isStoreSaved }: Props) => {
    const { data } = useQuery(GET_CHANGED_STORES);
    const menuName = useQuery(GET_SAVED_MENU_NAME);
    const [updateRestaurantData] = useMutation(UPDATE_RESTAURANT_DATA);
    const [publishMenu] = useLazyQuery(PUBLISH_MENU);

    const saveStore = () => {
      const { clientId, ...storeToUpdate } = store;
      updateRestaurantData({
        variables: { updaterestaurantdatainput: storeToUpdate },
      })
        .then((result) => {
          if (result.data) {
            toast("Changes saved succesfully.");
            areChangesPublishedVar(false);
            areChangesSavedVar(true);
            deleteIdFromChangedStores(store.uuid);
            setSaveStatus(true);
            setTimeout(() => setSaveStatus(false), 10000);
          }
        })
        .catch((err) => {
          toast("Oops. Something went wrong. Please check your connection.");
          console.log(err);
        });
    };

    const publishStore = () => {
      const params = {
        action: "publish",
        clientId: ENV_VARS.CLIENT_ID,
        menuName: `${menuName.data.savedMenuName}`,
        aggregatorUUID: store.aggregatorUUID,
      };
      publishMenu({ variables: params });
      toast("Changes published succesfully.");
      areChangesPublishedVar(true);
      areChangesSavedVar(true);
      setSaveStatus(false);
    };

    const saveButtonDisabled = () => {
      if (!PERMISSIONS[userGroupVar()]["save_opening_hours"]) {
        return true;
      }
      return !data.changedStores.includes(store.uuid);
    };
    const getSaveButtonToolTipText = () => {
      if (!data.changedStores.includes(store.uuid)) return null;
      if (!PERMISSIONS[userGroupVar()]["save_opening_hours"]) {
        return "You do not have permission to save.";
      }
      return null;
    };

    return (
      <>
        <span data-for="saveMenu" data-tip data-tip-disable={false}>
          {!isStoreSaved && (
            <Button
              color="primary"
              variant="contained"
              onClick={saveButtonDisabled() ? undefined : saveStore}
              disabled={saveButtonDisabled()}
              style={{ minWidth: 50 }}
              disableElevation
            >
              Save
            </Button>
          )}

          {isStoreSaved && (
            <Button
              color="primary"
              variant="contained"
              onClick={publishStore}
              style={{ minWidth: 50 }}
              disableElevation
            >
              Publish
            </Button>
          )}
        </span>
        {getSaveButtonToolTipText() && (
          <ReactTooltip id="saveMenu" place="bottom">
            {getSaveButtonToolTipText()}
          </ReactTooltip>
        )}
      </>
    );
  }
);

export default SaveHoursButton;
