import { makeStyles } from "@material-ui/core/styles";

// eslint-disable-next-line import/prefer-default-export
export const useStyles = makeStyles({
  root: {
    width: 24,
    height: 24,
    minWidth: 24,
    padding: 0,
    marginTop: 10,
    marginLeft: 4,
  },
});
