import { MenuItemWithOptions, ItemObject } from "../models/menu";
import {
  GetStoresQueryType,
  GetStoreType,
  AutocompleteValueType,
} from "../models/stores";

export const mapStoresToOptions = (
  stores: GetStoreType
): AutocompleteValueType[] => {
  return Object.keys(stores).map((key: string) => ({
    id: key,
    name: stores[key],
  }));
};

export const mapSelectedStoresToOptions = (
  selectedStores: string[],
  storeData: GetStoresQueryType | undefined
): AutocompleteValueType[] => {
  return selectedStores.map((id: string) => ({
    id,
    name: (storeData as GetStoresQueryType).stores[id],
  }));
};

export const mapItemsToOption = (
  items: MenuItemWithOptions[]
): AutocompleteValueType[] => {
  return items.map((item: MenuItemWithOptions) => ({
    id: item.id,
    name: item.title.translations.en_uk,
  }));
};

export const mapSelectedItemsToOptions = (
  selectedItems: string[],
  items: ItemObject
): any => {
  if (!selectedItems || selectedItems.length === 0) {
    return [];
  }

  return selectedItems.map((id: string) => ({
    id,
    name: items[id] ? items[id].title.translations.en_uk : "not found",
  }));
};
