import { ApolloClient, ApolloLink, HttpLink, from } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { getMainDefinition } from "@apollo/client/utilities";
import { createSubscriptionHandshakeLink } from "aws-appsync-subscription-link";
import { ENV_VARS } from "../config/config";
import logOutUser from "../helpers/logOutUser";
import omitDeep from "../helpers/omitDeep";
import { cache } from "./cache";
import { RetryLink } from "@apollo/client/link/retry";
import { snackbarStateVar } from "./cache";

const [initialRetryTimeDelay, maxRetryTimeDelay] = [1000, Infinity]; //in Miliseconds

const authAndTypeNameMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      ...operation.headers,
      Authorization: localStorage.getItem("id_token") || null,
    },
  });

  if (operation.variables) {
    operation.variables = omitDeep(operation.variables, "__typename");
  }

  return forward(operation);
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (networkError && networkError.statusCode === 401) logOutUser();
  else {
    // throw Error(graphQLErrors);
    console.error(graphQLErrors);
    snackbarStateVar({
      message: graphQLErrors[0].message,
      open: true,
    });
  }
});

const httpLink = new HttpLink({
  uri: ENV_VARS.GRAPHQL_URL,
  headers: {
    Authorization: localStorage.getItem("id_token"),
  },
});

const wsLink = createSubscriptionHandshakeLink(ENV_VARS.GRAPHQL_URL, httpLink);

const splitLink = new RetryLink({
  //for each failed attempt, this fucntion is called,
  //we can add conditions if we want to retry or not?
  attempts: (count, operation, error) => {
    //count is the number of times a query is being retired
    if (count > 99) return false;
    //add conditions where we don't want to try

    return !!error && operation.operationName === "MySubscription";
  },
  delay: {
    initial: initialRetryTimeDelay,
    max: maxRetryTimeDelay,
    jitter: true,
  },
}).split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);
export const client = new ApolloClient({
  cache,
  link: from([errorLink, authAndTypeNameMiddleware, splitLink]),
  connectToDevTools: true,
});
