import React from "react";
import style from "./styles/GridLayout.module.scss";

export const UiMainWrapper = ({ children }) => {
  return <main className={style.uiMain}>{children}</main>;
};

export const UiContentWrapper = ({ children }) => {
  return (
    <div className={style.uiMainInnerWr}>
      <div className={style.uiMainInner}>{children}</div>
    </div>
  );
};
