import React from "react";
import { StoreType } from "../../models/stores";
import MissionControlTable from "./MissionControlTable";
import { MissionControlDataHandler } from "./MissionControlDataHandlers";

const MissionControl = () => (
  <MissionControlDataHandler>
      {(posStoresData: StoreType[]) => (
        <MissionControlTable posStores={posStoresData} />
      )}
  </MissionControlDataHandler>
);

export default MissionControl;
