/* eslint-disable react/require-default-props */
/* eslint-disable no-nested-ternary */
import React from "react";
import ReactTooltip from "react-tooltip";
import Button from "@material-ui/core/Button";
import { toast } from "react-toastify";
import {
  createNewMealSet,
  createNewItem,
  addCategory,
  addCategoryId,
} from "../../apollo/operations/actions/actions";
import { CATEGORY_SCHEMA, ITEM_SCHEMA } from "../../models/schemas";
import { checkForDuplicateCategory } from "../../helpers/menu";
import { ADD_NEW_TYPE } from "../../constants/constants";
import {
  jsonVar,
  savedMenuNameVar,
  snackbarStateVar,
} from "../../apollo/cache";

interface Props {
  categoryTitle?: string;
  itemType: ADD_NEW_TYPE;
  variant?: "contained" | "text" | "outlined" | undefined;
  createModifierGroup?: () => void;
}

const CreateNewButton: React.FC<Props> = ({
  categoryTitle = "",
  itemType,
  variant = "contained",
  createModifierGroup,
}) => {
  const addItem = () => {
    const randomId = `${Math.floor(
      Math.random() * Math.floor(10000)
    )}-${categoryTitle.replace(" ", "-")}`;
    if (itemType === ADD_NEW_TYPE.SET) {
      createNewMealSet(
        {
          ...ITEM_SCHEMA,
          id: `set-mi-uk-compris-${randomId}`,
          title: { translations: { en_uk: "Meal Group Title" } },
          external_data: `S${randomId}`,
          modifier_group_ids: {
            ...ITEM_SCHEMA.modifier_group_ids,
            ids: { [`mc-S${randomId}`]: `mc-S${randomId}` },
          },
        },
        categoryTitle
      );
    }

    if (itemType === ADD_NEW_TYPE.SINGLE) {
      createNewItem(
        {
          ...ITEM_SCHEMA,
          id: `si-uk-compris-${randomId}`,
          title: { translations: { en_uk: "Enter Item Title Here" } },
          external_data: randomId,
          image_url: "",
        },
        categoryTitle
      );
    }

    if (itemType === ADD_NEW_TYPE.CATEGORY) {
      if (checkForDuplicateCategory(CATEGORY_SCHEMA.title.translations.en_uk)) {
        toast(
          "Please change the name of the previously added category. Before adding a new one."
        );
      }

      addCategory({
        ...CATEGORY_SCHEMA,
        id: randomId,
      });
      addCategoryId(CATEGORY_SCHEMA.title.translations.en_uk);
    }

    if (itemType === ADD_NEW_TYPE.MENU) {
      savedMenuNameVar("New Menu");
      // Update type of categories and menus
      //Deletes everything and adds new object to menus
      // TODO : Keep old menus object and add new menu into it
      jsonVar({
        ...jsonVar(),
        categories: {},
        menus: {
          "New Menu": {
            id: "New Menu",
            title: { translations: { en_uk: "New Menu" } },
            service_availability: {},
            category_ids: {},
            heroImage: "",
          },
        },
      });
      addCategory({
        ...CATEGORY_SCHEMA,
        id: randomId,
      });
      addCategoryId(CATEGORY_SCHEMA.title.translations.en_uk);
    }

    if (itemType === ADD_NEW_TYPE.MODIFIER) {
      if (createModifierGroup) {
        createModifierGroup();
      } else {
        snackbarStateVar({
          open: true,
          message: "Function Not Provided",
        });
        return;
      }
    }
  };

  const dataFor =
    itemType === ADD_NEW_TYPE.SET
      ? "createSet"
      : itemType === ADD_NEW_TYPE.CATEGORY
      ? "createCategory"
      : itemType === ADD_NEW_TYPE.MENU
      ? "createMenu"
      : itemType === ADD_NEW_TYPE.MODIFIER
      ? "createModifier"
      : "createItem";
  const buttonContent =
    itemType === ADD_NEW_TYPE.SET
      ? "+ Meal Group"
      : itemType === ADD_NEW_TYPE.SINGLE
      ? "+ Item"
      : itemType === ADD_NEW_TYPE.MENU
      ? "+ Menu"
      : itemType === ADD_NEW_TYPE.MODIFIER
      ? "+ Modifier Group"
      : "+ Category";
  return (
    <>
      <Button
        style={{ marginRight: 20 }}
        variant={variant}
        color="primary"
        disableElevation
        onClick={addItem}
        data-tip
        data-for={dataFor}
      >
        {buttonContent}
      </Button>
      {itemType === ADD_NEW_TYPE.SET && (
        <ReactTooltip
          id="createSet"
          place="bottom"
          type="dark"
          effect="float"
          globalEventOff="click"
        >
          <span>creates a new meal set</span>
        </ReactTooltip>
      )}

      {itemType === ADD_NEW_TYPE.SINGLE && (
        <ReactTooltip
          id="createItem"
          place="bottom"
          type="dark"
          effect="float"
          globalEventOff="click"
        >
          <span>creates a new single meal item</span>
        </ReactTooltip>
      )}

      {itemType === ADD_NEW_TYPE.CATEGORY && (
        <ReactTooltip
          id="createCategory"
          place="bottom"
          type="dark"
          effect="float"
          globalEventOff="click"
        >
          <span>creates a new category</span>
        </ReactTooltip>
      )}

      {itemType === ADD_NEW_TYPE.MENU && (
        <ReactTooltip
          id="createMenu"
          place="bottom"
          type="dark"
          effect="float"
          globalEventOff="click"
        >
          <span>creates a new menu</span>
        </ReactTooltip>
      )}

      {itemType === ADD_NEW_TYPE.MODIFIER && (
        <ReactTooltip
          id="createMenu"
          place="bottom"
          type="dark"
          effect="float"
          globalEventOff="click"
        >
          <span>creates a new modifier</span>
        </ReactTooltip>
      )}
    </>
  );
};

export default CreateNewButton;
