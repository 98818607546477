import React from "react";
import FadeLoader from "react-spinners/FadeLoader";

type Props = {
  loading: boolean;
  error: any;
  children: any;
  loaderSize?: number;
  loaderMargin?: number;
  marginTop?: number;
};

const LoaderHandler = ({
  loading,
  error,
  children,
  loaderSize = 10,
  loaderMargin = 4,
  marginTop = 0,
}: Props) => {
  if (loading) {
    return (
      <div
        className="row mx-auto"
        style={{
          justifyContent: "center",
          alignItems: "center",
          marginTop: `${marginTop}px`,
        }}
      >
        <FadeLoader
          height={loaderSize}
          width={loaderSize}
          radius={100}
          margin={loaderMargin}
          color="#336B6B"
          loading={loading}
        />
      </div>
    );
  }

  if (error) {
    console.log(error);
    return (
      <h3 className="mx-auto">
        Ooops. Something went wrong! Please, check your internet connection.
      </h3>
    );
  }

  return children;
};

export default LoaderHandler;
