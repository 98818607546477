import React, { useEffect, useState } from "react";
import { StoreObject, useLazyQuery, useQuery } from "@apollo/client";
import axios from "axios";
import {
  GET_INITIAL_DATA,
  GET_ITEM_COLLECTIONS,
  GET_MENU,
  GET_STORE_GROUPINGS,
} from "../../apollo/operations/queries/serverQueries";
import LoaderHandler from "../shared/LoaderHandler";
import {
  itemCollectionsVar,
  jsonStoresVar,
  menuKeyVar,
  savedMenuNameVar,
  savedMenusVar,
  selectedGroupsVar,
  storeGroupsVar,
} from "../../apollo/cache";
import { StoreType } from "../../models/stores";
import { loadNewMenu } from "../../helpers/menu";

import { AllSubscriptions } from "./InitialSubscriptionsProvider";

function InitialQueryProvider({ children }) {
  const { data, loading, error } = useQuery(GET_INITIAL_DATA);
  const [getMenuUrl, menuUrl] = useLazyQuery(GET_MENU, {
    fetchPolicy: "network-only",
  });
  const [isMenuSet, setMenu] = useState(false);

  useEffect(() => {
    if (data === undefined) {
      return;
    }
    if (
      data.listRestaurantData.items.length > 0 &&
      Object.keys(data.stores).length === 0
    ) {
      jsonStoresVar(mapRestaurantsToStoreObject(data.listRestaurantData.items));
    }
    if (data.listRestaurantGroupings.items.length !== 0) {
      storeGroupsVar(data.listRestaurantGroupings.items);
    }
    if (data.listItemCollections.items.length !== 0) {
      itemCollectionsVar(data.listItemCollections.items);
    }
    if (data.getClientMenus) {
      savedMenusVar(data.getClientMenus.menus);

      const menuToLoad = data.getClientMenus.menus[0];
      selectedGroupsVar(menuToLoad.storeGroups);
      menuKeyVar(menuToLoad.menuKey.replace(".json", ""));

      if (!savedMenuNameVar()) {
        savedMenuNameVar(menuToLoad.menuName.replace(".json", ""));
      }
      getMenuUrl({ variables: { fileName: menuToLoad.menuKey } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, getMenuUrl]);
  useEffect(() => {
    const url = menuUrl.data && menuUrl.data.getMenu.data.url;
    if (url) {
      axios({
        method: "get",
        url: `${url}`,
      })
        .then((res) => {
          loadNewMenu(res.data, true);
        })
        .then(() => {
          setMenu(true);
          localStorage.removeItem("randomCode");
        })
        .catch((err) => {
          console.log(err);
          console.warn(err);
        });
    }
  }, [menuUrl.data]);

  const mapRestaurantsToStoreObject = (restaurants: StoreType[]): any => {
    const storeObject: StoreObject = {};
    restaurants.forEach((restaurant: StoreType) => {
      if (!!restaurant.uuid && !!restaurant.restaurantName) {
        storeObject[restaurant.uuid] = restaurant.restaurantName;
      }
    });

    return storeObject;
  };
  const isLoading = () => {
    if (loading || !isMenuSet || !data.isAuthenticated) return true;
    return false;
  };
  /**
   * This function waits for the page to load
   * Initiates Subscriptions when the page has loaded
   * Reduce Load time
   * @returns SUbscriptions to be added at startup
   */
  const setSubscriptions = () => {
    if (!isLoading()) {
      return (
        <>
          <AllSubscriptions />
        </>
      );
    }
  };

  return (
    <>
      <LoaderHandler loading={false} error={error}>
        {children}
      </LoaderHandler>
      {!isLoading() && setSubscriptions()}
    </>
  );
}
/**
 *
 * @param param0 React Children depending on the itemCollection
 * @returns data from cache
 */
const ItemCollectionsFromCacheOrNetwork = ({ children }) => {
  const { data, loading, error } = useQuery(GET_ITEM_COLLECTIONS, {
    fetchPolicy: "cache-and-network",
  });
  // console.log("item data", data);

  return (
    <LoaderHandler loading={loading} error={error}>
      {children(data?.listItemCollections?.items)}
    </LoaderHandler>
  );
};

/**
 *
 * @param param0 React Children depending on StoreGroupsData
 * @returns data from Cache
 */
const StoreGroupsDataFromCacheOrNetwork = ({ children }) => {
  const { data, loading, error } = useQuery(GET_STORE_GROUPINGS);

  return (
    <LoaderHandler loading={loading} error={error}>
      {children(data?.listRestaurantGroupings?.items || {})}
    </LoaderHandler>
  );
};

export { StoreGroupsDataFromCacheOrNetwork, ItemCollectionsFromCacheOrNetwork };

export default InitialQueryProvider;
