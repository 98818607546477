import React from "react";
import { useQuery } from "@apollo/client";
import ReactJson from "react-json-view";
import { GET_JSON } from "../../apollo/operations/queries/localQueries";
import style from "./styles/JsonVisualization.module.scss";

const JsonVisualization = () => {
  const { data } = useQuery(GET_JSON);
  return (
    <div className="mt">
      <div className="row">
        <div className={style.jsonOutter}>
          <div className={style.jsonContainer}>
            <ReactJson src={data.json} collapsed={1} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default JsonVisualization;
