import React, { useState } from "react";
import { TextField } from "@material-ui/core";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { jsonVar } from "../../apollo/cache";
import { mapItemsToOption } from "../../helpers/autocompletes";

type Props = {
  selectedItems: string[];
  getSelectedItems: (items: string[]) => void;
  label?: string;
};

const ItemsAutocomplete: React.FC<Props> = ({
  selectedItems,
  getSelectedItems,
  label = "",
}) => {
  const [searchInput, setSearchInput] = useState<string>("");

  const handleSelection = (key: string) => {
    if (selectedItems.includes(key)) {
      const storeIndex = selectedItems.indexOf(key);
      const newStores = [...selectedItems];
      newStores.splice(storeIndex, 1);
      getSelectedItems(newStores);
    } else {
      const newStores = [...selectedItems];
      newStores.push(key);
      getSelectedItems(newStores);
    }
  };

  return (
    <Autocomplete
      //converting objects to array for map
      options={mapItemsToOption(Object.values(jsonVar().items))}
      onChange={(_, value) => {
        handleSelection((value as any).id);
      }}
      inputValue={searchInput}
      disableCloseOnSelect
      disableClearable
      getOptionLabel={(option) => option.name}
      renderOption={(option) => <>{option.name}</>}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={label}
          placeholder="Search Items"
          onChange={(
            e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
          ) => setSearchInput(e.target.value)}
        />
      )}
      onInputChange={(event, value, reason) => {
        if (reason === "clear") {
          setSearchInput("");
        }
      }}
      filterOptions={createFilterOptions({ limit: 35 })}
    />
  );
};

export default ItemsAutocomplete;
