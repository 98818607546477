export const getCurrentDateObject = (date: Date) => {
  const month = date.getMonth() + 1;
  const monthToDisplay = month < 10 ? `0${month}` : month;
  const hoursToDisplay =
    date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
  const minutesToDisplay =
    date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
  const dateToDisplay =
    date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();

  return {
    year: date.getFullYear(),
    month: monthToDisplay,
    date: dateToDisplay,
    hours: hoursToDisplay,
    minutes: minutesToDisplay,
  };
};

export const getCurrentDateString = (yearLater = 0): string => {
  const date = new Date();
  const dateObject = getCurrentDateObject(date);

  return `${dateObject.year + yearLater}-${dateObject.month}-${
    dateObject.date
  }T${dateObject.hours}:${dateObject.minutes}`;
};

export const getUnixInSeconds = (dateString: string): number =>
  Date.parse(dateString) / 1000;

export const isLessThanCurrentDate = (unixDate: number): boolean =>
  unixDate < Date.parse(getCurrentDateString()) / 1000;

export const isMoreThanYearLater = (unixDate: number): boolean =>
  unixDate > Date.parse(getCurrentDateString(1)) / 1000;

export const getDateToDisplay = (unixDateInSeconds: number) => {
  const date = new Date(unixDateInSeconds * 1000);
  const dateObject = getCurrentDateObject(date);

  return `${dateObject.date}.${dateObject.month}.${dateObject.year}, ${dateObject.hours}:${dateObject.minutes}`;
};

export const getLocaleDateString = (unixDateInSeconds: number) => {
  const date = new Date(unixDateInSeconds * 1000);
  return date.toLocaleDateString("en-US", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  });
};

export const getCurrentTimeString = (): string => {
  const date = new Date();
  const dateObject = getCurrentDateObject(date);

  return `${dateObject.hours}:${dateObject.minutes}`;
};
