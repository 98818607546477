import React from "react";
import { useQuery } from "@apollo/client";
import { GET_RESTAURANT_DATA } from "../../apollo/operations/queries/serverQueries";
import LoaderHandler from "../shared/LoaderHandler";

interface Props {
  children: any;
}

export const MissionControlDataHandler = ({ children }: Props) => {
  const { data, loading, error } = useQuery(GET_RESTAURANT_DATA);

  return (
    <LoaderHandler loading={loading} error={error}>
      {children(data?.listRestaurantData?.items || {})}
    </LoaderHandler>
  );
};
