import {
  Card,
  CardContent,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import styles from "./styles/StopConditions.module.scss";
import React, { useEffect, useState } from "react";
import { CustomStylesTextField } from "./Utils/CustomUI";
import { StopConditionRadioValue } from "./Utils/types";
import { CUSTOMIZATION_SCHEDULE } from "../../../apollo/operations/queries/localQueries";
import { useQuery } from "@apollo/client";
import { cache } from "../../../apollo/cache";
/**
 *
 * @param param0
 * @returns
 */

const StopCondition = () => {
  const {
    data: { schedule },
  }: any = useQuery(CUSTOMIZATION_SCHEDULE);

  //getting default date
  const getDateFromString = (date: string) => {
    if (date) {
      return new Date(date).toISOString().substring(0, 10);
    }
    return new Date().toISOString().substring(0, 10);
  };
  //on radio changed:
  const onRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value: StopConditionRadioValue = e.target
      .value as StopConditionRadioValue;

    setRadioValue(value);
  };
  const getRadioState = () => {
    if (schedule.until) {
      return "until";
    }
    if (schedule.count) {
      return "count";
    }
    return "none";
  };
  const [radioValue, setRadioValue] = useState<StopConditionRadioValue>(
    getRadioState()
  );
  const [data, setData] = useState({
    none: {} as any,
    until: getDateFromString(schedule.until),
    count: schedule.count,
  });

  //when the value of form is changed
  const onChange = (radio: StopConditionRadioValue, payload: any) => {
    //update state
    setRadioValue(radio);
    setData({ ...data, [radio]: payload });
  };
  useEffect(() => {
    if (schedule) {
      const tData = { ...schedule };
      //deleting all values
      delete tData.count;
      delete tData.until;
      cache.writeQuery({
        query: CUSTOMIZATION_SCHEDULE,
        data: {
          schedule: {
            ...tData,
            ...(radioValue === "count" && {
              count: data.count,
            }),
            ...(radioValue === "until" && {
              until: new Date(data.until).toISOString(),
            }),
          },
        },
      });
    }
  }, [data, radioValue, schedule]);
  return (
    <div>
      <h2>Stop Condition</h2>
      <Card variant="outlined">
        <CardContent>
          <RadioGroup
            defaultValue={radioValue}
            aria-label="stop-conditions"
            name="stop-condition"
            value={radioValue}
            onChange={onRadioChange}
          >
            <FormControlLabel
              className={styles.radio_item}
              value="none"
              control={<Radio color="primary" />}
              label={<NeverStop />}
            />
            <FormControlLabel
              className={styles.radio_item}
              value="until"
              control={<Radio color="primary" />}
              label={
                <RunUntilDate
                  date={data.until}
                  updateDate={(date: string) => onChange("until", date)}
                />
              }
            />
            <FormControlLabel
              className={styles.radio_item}
              value="count"
              control={<Radio color="primary" />}
              label={
                <RunUntilCount
                  count={data.count}
                  updateCount={(count: number) => onChange("count", count)}
                />
              }
            />
          </RadioGroup>
        </CardContent>
      </Card>
    </div>
  );
};

const NeverStop = () => {
  return (
    <div>
      <h3>Never Stop</h3>
      <span>This event will repeat indefinitely</span>
    </div>
  );
};
const RunUntilDate = ({ date, updateDate }) => {
  return (
    <div>
      <h3>
        Run until a specific date
        <CustomStylesTextField
          type="date"
          size="large"
          value={date}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            updateDate(e.target.value)
          }
        />
      </h3>
      <span>This event will run until it reaches a specific date</span>
    </div>
  );
};
const RunUntilCount = ({ count, updateCount }) => {
  const onCountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);
    updateCount(value > 1 ? value : 1);
  };
  return (
    <div>
      <h3>
        Run until it reachs{" "}
        <CustomStylesTextField
          type="number"
          defaultValue={1}
          value={count}
          onChange={onCountChange}
        />
        occurance(s){" "}
      </h3>
      <span>
        This event will repeat until it reaches a certain amount of occurance
      </span>
    </div>
  );
};

export default StopCondition;
