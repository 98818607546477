/* eslint-disable no-console */
import React from "react";
import { FormControl, MenuItem, Select } from "@material-ui/core";
import { CHANGE_SIZE } from "../../../constants/constants";

const ChangeSizeSelect = ({ changeSize, setChangeSize }) => {
  const handleChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    setChangeSize(e.target.value as CHANGE_SIZE);
  };

  return (
    <FormControl variant="outlined" color="primary">
      <Select
        value={changeSize}
        onChange={handleChange}
        label="Select change size"
      >
        {Object.keys(CHANGE_SIZE)
          .map((key) => CHANGE_SIZE[key])
          .map((changeText) => (
            <MenuItem value={changeText} key={changeText}>
              {changeText}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default ChangeSizeSelect;
