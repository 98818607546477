import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import useStyles from "../OpeningHours/OpeningHoursTable/useStylesHours";
import iconEnabled from "../../media/images/ic-enabled.svg";
import iconDisabled from "../../media/images/ic-disabled.svg";
import { customizationToEditVar, openModalVar } from "../../apollo/cache";
import { MODAL_OPEN } from "../../config/config";
import { Customization } from "../../models/customizations";
import { DELETE_CUSTOMIZATION } from "../../apollo/operations/mutations/mutations";
import WarningModal from "../shared/WarningModal";

import style from "./styles/Customizations.module.scss";

const STATIC_HEADERS = [
  "Global",
  "Short name",
  "Stores",
  "Add",
  "Edit",
  "Remove",
  "Action",
];

type Props = {
  customizations: Customization[];
};

const CustomizationsTable = ({ customizations }: Props) => {
  const [customizationToDelete, setCustomizationToDelete] = useState(
    {} as Customization
  );
  const [deleteCustomization] = useMutation(DELETE_CUSTOMIZATION);
  const classes = useStyles();

  const openModalToEdit = (customization: Customization) => {
    openModalVar(MODAL_OPEN.CUSTOMIZATION);
    customizationToEditVar(customization);
  };

  const openModalToDelete = (customization: Customization) => {
    openModalVar(MODAL_OPEN.WARNING);
    setCustomizationToDelete(customization);
  };

  const handleDeleteCustomization = (customization: Customization) => {
    deleteCustomization({
      variables: {
        input: {
          global: customization.global,
          sk: customization.sk,
        },
      },
    });
  };

  const handleClone = (customization: Customization) => {
    openModalVar(MODAL_OPEN.CUSTOMIZATION);
    customizationToEditVar({ ...customization, clone: true });
  };

  return (
    <Paper className={classes.root} elevation={0}>
      <TableContainer className={classes.container}>
        <Table aria-label="sticky table">
          <TableHead>
            <TableRow>
              {STATIC_HEADERS.map((title: string) => (
                <TableCell align="center" key={title}>
                  {title}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {customizations.map((customization: Customization) => (
              <TableRow key={customization.sk}>
                <TableCell align="center">
                  {customization.global ? (
                    <img src={iconEnabled} alt="enabled" />
                  ) : (
                    <img src={iconDisabled} alt="disabled" />
                  )}
                </TableCell>
                <TableCell align="center">{customization.slug}</TableCell>
                <TableCell align="center">
                  {!customization.global && (
                    <span>
                      {(customization.storeIds as string[]).length > 0 &&
                        `${customization.storeIds?.length} store${
                          (customization.storeIds as string[]).length === 1
                            ? ""
                            : "s"
                        }`}
                      {(customization.storeIds as string[]).length > 0 &&
                        (customization.storeGroups as string[]).length > 0 &&
                        ", "}
                      {(customization.storeGroups as string[]).length > 0 &&
                        `${customization.storeGroups?.length} store group${
                          (customization.storeGroups as string[]).length === 1
                            ? ""
                            : "s"
                        }`}
                    </span>
                  )}
                  {customization.global && "all"}
                </TableCell>
                <TableCell align="center">
                  {customization.add && customization.add.length !== 0 ? (
                    <img src={iconEnabled} alt="enabled" />
                  ) : (
                    <img src={iconDisabled} alt="disabled" />
                  )}
                </TableCell>
                <TableCell align="center">
                  {customization.edit && customization.edit.length !== 0 ? (
                    <img src={iconEnabled} alt="enabled" />
                  ) : (
                    <img src={iconDisabled} alt="disabled" />
                  )}
                </TableCell>
                <TableCell align="center">
                  {customization.remove && customization.remove.length !== 0 ? (
                    <img src={iconEnabled} alt="enabled" />
                  ) : (
                    <img src={iconDisabled} alt="disabled" />
                  )}
                </TableCell>
                <TableCell align="center">
                  <span
                    className={style.cloneLink}
                    onClick={() => handleClone(customization)}
                  >
                    Clone
                  </span>
                  <span> / </span>
                  <span
                    className={style.editLink}
                    onClick={() => openModalToEdit(customization)}
                  >
                    Edit
                  </span>
                  <span> / </span>
                  <span
                    className={style.removeLink}
                    onClick={() => openModalToDelete(customization)}
                  >
                    Remove
                  </span>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <WarningModal
        modalMessage="Are you sure?"
        onConfirm={() => handleDeleteCustomization(customizationToDelete)}
      />
    </Paper>
  );
};

export default CustomizationsTable;
