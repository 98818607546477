/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import classnames from "classnames";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { NavLink, withRouter } from "react-router-dom";
import { useStyles } from "./useStylesSideNav";

import style from "./styles/SideNav.module.scss";

const SideNavLink = ({
  toPath,
  icon,
  label,
  isOpened,
  location,
  dataFor,
}: any) => {
  const { menuList, hide } = useStyles();
  const labelStyle = classnames(style.sideNavListItemText, {
    [hide]: !isOpened,
  });
  const ListItemStyle = classnames(style.sideNavListItem, menuList);
  return (
    <ListItem
      className={ListItemStyle}
      component={NavLink}
      disableGutters
      to={toPath}
      selected={location.pathname === toPath}
      classes={{ selected: style.selected }}
      data-for={dataFor}
      data-tip
    >
      <ListItemIcon style={{ minWidth: 42 }}>{icon}</ListItemIcon>
      <label className={labelStyle}>{label}</label>
    </ListItem>
  );
};

export default withRouter(SideNavLink);
