import React from "react";
import classnames from "classnames";
import StoreGroupList from "./StoreGroupList";
import { StoreGroupsDataFromCacheOrNetwork } from "../Providers/initalQueryProvider";

import style from "./styles/StoreGroupings.module.scss";

const StoreGroupings = () => (
  <div className={style.storesContainer}>
    <div
      className={classnames(style.uiJustifySpaceBetween, style.uiAlignCenter)}
    >
      <div className={style.uiPageTitle}>UberEats</div>
    </div>
    <StoreGroupsDataFromCacheOrNetwork>
      {(groups) => <StoreGroupList storeGroups={groups} />}
    </StoreGroupsDataFromCacheOrNetwork>
  </div>
);

export default StoreGroupings;
