import React, { useEffect, useState } from "react";
import { Prompt, withRouter } from "react-router-dom";
import {
  areChangesPublishedVar,
  areChangesSavedVar,
  changedStoresVar,
  openModalVar,
} from "../../apollo/cache";
import { MODAL_OPEN } from "../../config/config";
import WarningModal from "./WarningModal";

type Props = {
  when: boolean;
  location: any;
  unblock: () => void;
  close: () => void;
  message: string;
  history: any;
  route: boolean;
};

const PageLeavingGuard: React.FC<Props> = ({
  close,
  when,
  unblock,
  message,
  route,
  location,
  history,
}) => {
  const [lastLocation, setLastLocation] = useState(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const showModal = (locationArg: any) => {
    openModalVar(MODAL_OPEN.WARNING);
    setLastLocation(locationArg);
  };

  const closeModal = () => {
    if (close) {
      close();
    } else {
      openModalVar(MODAL_OPEN.NONE);
    }
  };

  const handleBlockedRoute = (nextLocation: any) => {
    if (nextLocation.pathname !== location.pathname) {
      if (!confirmedNavigation && when) {
        showModal(nextLocation);
        return false;
      }
    }

    return true;
  };

  const handleUnblockPage = () => {
    if (unblock) {
      unblock();
    } else {
      setConfirmedNavigation(true);
    }
    areChangesSavedVar(true);
    areChangesPublishedVar(true);
    changedStoresVar([]);
  };

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      history.push(lastLocation);
    }
  }, [confirmedNavigation, lastLocation, history]);

  return (
    <>
      {route && <Prompt when={when} message={handleBlockedRoute} />}
      <WarningModal
        onClose={closeModal}
        modalMessage={message}
        onConfirm={handleUnblockPage}
      />
    </>
  );
};

export default withRouter(PageLeavingGuard);
