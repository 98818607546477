/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from "react";
import classnames from "classnames";
import { IconButton } from "@material-ui/core";
import TitleWithInput from "../../../shared/TitleWithInput";
import { addGroupToModifiers } from "../../../../apollo/operations/actions/actions";
import {
  MenuItem,
  MenuModifierGroup,
  ModifierOptionsCollection,
} from "../../../../models/json";
import ItemsAutocomplete from "../../../shared/ItemsAutocomplete";
import deleteIcon from "../../../../media/images/delete-icon.svg";

import style from "./styles/ModifierGroups.module.scss";
import ModifierOptions from "./ModifierOptions";

type Props = {
  modifier: MenuModifierGroup;
  category: any;
  id: string;
  getModifierToDelete: (id: string) => void;
  dragHandleProps: any;
  menuItem: MenuItem;
};

const ModifierItem: React.FC<Props> = ({
  modifier,
  category,
  id,
  getModifierToDelete,
  dragHandleProps,
  menuItem,
}) => {
  const [isToggled, toggleModifier] = useState(true);
  const [isAutocompleteToggled, toggleAutocomplete] = useState(false);
  const [selectedModifierOptions, selectModifierOptions] = useState<string[]>(
    []
  );

  const handleToggle = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    if (
      (e.target as HTMLDivElement).className === style.modifierHeader ||
      (e.target as HTMLDivElement).className === "fas fa-caret-right"
    ) {
      if (isToggled) {
        toggleModifier(false);
      } else {
        toggleModifier(true);
      }
    }
  };

  const handleToggleAutocomplete = () => {
    if (isAutocompleteToggled) {
      selectModifierOptions([]);
      toggleAutocomplete(false);
    } else {
      selectModifierOptions([]);
      toggleAutocomplete(true);
    }
  };

  const selectModifierOptionsToAdd = (items: string[]) => {
    //adding modifers
    let itemsObj = {} as ModifierOptionsCollection;
    for (let item of items) {
      itemsObj[item] = { id: item, type: "ITEM" };
    }
    addGroupToModifiers({
      ...modifier,
      modifier_options: {
        ...itemsObj,
        ...modifier.modifier_options,
      },
    });
  };

  const arrowstyle = classnames(style.arrowIcon, {
    [style.arrowIconRight]: isToggled,
  });

  return (
    <div className={style.modifierSelect} key={id}>
      <div
        className={style.modifierHeader}
        onClick={handleToggle}
        {...dragHandleProps}
      >
        <TitleWithInput
          item={modifier}
          style={style}
          stateUpdate={addGroupToModifiers}
        />
        <div className={style.modifierHeaderButtonsRow}>
          {isAutocompleteToggled && (
            <div className={style.itemAutocomplete}>
              <ItemsAutocomplete
                selectedItems={selectedModifierOptions}
                getSelectedItems={selectModifierOptionsToAdd}
              />
            </div>
          )}
          <IconButton
            onClick={() => getModifierToDelete(id)}
            className={style.addIconButton}
            color="secondary"
          >
            <img src={deleteIcon} alt="delete modifier" />
          </IconButton>
          <IconButton
            onClick={() => handleToggleAutocomplete()}
            className={style.addIconButton}
            color="secondary"
          >
            +
          </IconButton>
          <span className={arrowstyle}>
            <i className="fas fa-caret-right" />
          </span>
        </div>
      </div>
      <ModifierOptions
        modifier={modifier}
        isToggled={isToggled}
        category={category}
        menuItem={menuItem}
      />
    </div>
  );
};

export default ModifierItem;
