import React from "react";
import Button from "@material-ui/core/Button";
import { deleteItem } from "../../../apollo/operations/actions/actions";
import deleteIcon from "../../../media/images/ic-meal-delete.svg";
import { useStyles } from "./useStylesMenuItem";

const DeleteItemButton = ({ item, category }: any) => {
  const classes = useStyles();
  const handleDelete = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    deleteItem(item, category);
  };

  return (
    <>
      <Button
        onClick={handleDelete}
        variant="contained"
        color="secondary"
        disableElevation
        classes={{ root: classes.root }}
      >
        <img src={deleteIcon} alt="Delete" />
      </Button>
    </>
  );
};

export default DeleteItemButton;
