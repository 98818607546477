import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useQuery } from "@apollo/client";
import produce from "immer";
import ModifierItem from "./ModifierItem";
import { helpReorderList } from "../../../MenuManagement/helpers/reorderListHelpers";
import {
  addGroupToModifiers,
  changeItemInJsonItems,
} from "../../../../apollo/operations/actions/actions";
import { MenuItemWithOptions } from "../../../../models/menu";
import { MasterMenu, MenuModifierGroup } from "../../../../models/json";
import { ChangedIcon } from "../../../shared/MenuDiffIcons";
import {
  ADD_NEW_TYPE,
  CATEGORY_ITEM_PROPERTIES,
} from "../../../../constants/constants";
import CreateNewButton from "../../../shared/CreateNewButton";
import { MODIFIER_GROUP_SCHEMA } from "../../../../models/schemas";
import { GET_JSON } from "../../../../apollo/operations/queries/localQueries";

import style from "./styles/ModifierGroups.module.scss";
import WarningModal from "../../../shared/WarningModal";

type Props = {
  item: MenuItemWithOptions;
  category?: string;
  isReadOnly?: boolean;
};

const ModalModifiers: React.FC<Props> = ({ item, category, isReadOnly }) => {
  const { data } = useQuery(GET_JSON);

  const [isModalOpen, openModal] = useState(false);
  const [modifierToDelete, setModifierToDelete] = useState({} as any);
  const [jsonItem, updateJsonItem] = useState(item);

  const reorderList = (result: {
    source: { index: number };
    destination: { index: number };
  }) => {
    const destinationIndex = !result.destination
      ? result.source.index
      : result.destination.index;
    const itemsList = helpReorderList(
      result.source.index,
      destinationIndex,
      Object.values(jsonItem.modifier_group_ids.ids)
    );

    const updatedItem = {
      ...jsonItem,
      modifier_group_ids: {
        ...jsonItem.modifier_group_ids,
        ids: idsCollectionFromArray(itemsList),
      },
    };

    updateJsonItem(updatedItem);
    changeItemInJsonItems(updatedItem);
  };
  const idsCollectionFromArray = (itemsArray: string[]) => {
    let items = {} as { [key: string]: string };
    for (let item of itemsArray) {
      items[item] = item;
    }
    return items;
  };
  const getModifierToPass = (
    modifier: MenuModifierGroup
  ): MenuModifierGroup => {
    if (modifier.title.translations.en_uk === category) {
      return {
        ...modifier,
        title: { translations: { en_uk: "Items" } },
      };
    }

    return modifier;
  };

  const createNewGroup = () => {
    const newModifierGroupId = `mc-${Math.floor(
      Math.random() * Math.floor(10000)
    )}`;
    const updatedItem = {
      ...jsonItem,
      modifier_group_ids: {
        ...jsonItem.modifier_group_ids,
        ids: {
          ...{ [newModifierGroupId]: newModifierGroupId },
          ...jsonItem.modifier_group_ids.ids,
        },
      },
    };

    updateJsonItem(updatedItem);
    changeItemInJsonItems(updatedItem);
    addGroupToModifiers({
      ...MODIFIER_GROUP_SCHEMA,
      id: newModifierGroupId,
    });
  };

  const getModifierToDelete = (modifierId) => {
    setModifierToDelete(modifierId);
    openModal(true);
  };

  const deleteModifierGroup = (modifierId) => {
    const updatedItem = produce(jsonItem, (draftItem) => {
      delete draftItem.modifier_group_ids.ids[modifierId];
    });

    changeItemInJsonItems(updatedItem);
    updateJsonItem(updatedItem);
    setModifierToDelete("");
  };

  useEffect(() => {
    updateJsonItem(item);
  }, [item]);

  if (Object.keys(jsonItem.modifier_group_ids.ids).length === 0) {
    return null;
  }

  return (
    <>
      <div className="mt-3 mb-1 ml-2">Components:</div>
      <div className={style.addModifierRow}>
        <CreateNewButton
          itemType={ADD_NEW_TYPE.MODIFIER}
          variant="outlined"
          createModifierGroup={createNewGroup}
        />
      </div>
      <ChangedIcon
        item={jsonItem}
        change={CATEGORY_ITEM_PROPERTIES.MODIFIER_GROUP_IDS}
      />
      <DragDropContext onDragEnd={reorderList}>
        <Droppable droppableId="droppable" isDropDisabled={isReadOnly}>
          {(provided: any, snapshot: any) => (
            <div ref={provided.innerRef} className={style.droppableModifiers}>
              {Object.entries(jsonItem.modifier_group_ids.ids).map(
                ([id, index], ind) => {
                  const json: MasterMenu = data.json;
                  const modifier = json.modifier_groups[id];
                  if (!modifier) {
                    return null;
                  }

                  return (
                    <Draggable
                      key={id}
                      draggableId={id}
                      index={ind}
                      isDragDisabled={isReadOnly}
                    >
                      {(providedParam: any) => {
                        return (
                          <div
                            ref={providedParam.innerRef}
                            {...providedParam.draggableProps}
                          >
                            <ModifierItem
                              modifier={getModifierToPass(modifier)}
                              id={id}
                              menuItem={item}
                              category={category}
                              getModifierToDelete={getModifierToDelete}
                              dragHandleProps={providedParam.dragHandleProps}
                            />
                          </div>
                        );
                      }}
                    </Draggable>
                  );
                }
              )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {isModalOpen && (
        <WarningModal
          isOpen={isModalOpen}
          onClose={() => openModal(false)}
          modalMessage="Are you sure you want to delete the modifier group?"
          onConfirm={() => deleteModifierGroup(modifierToDelete)}
          confirmButtonText="Delete"
        />
      )}
    </>
  );
};
export default ModalModifiers;
