import { isMasterMenuVar, jsonVar } from "../apollo/cache";
import { ENV_VARS } from "../config/config";
import { JsonDataStorage } from "../models/json";

const saveFinalJson = (menuName: string): JsonDataStorage => {
  if (isMasterMenuVar()) {
    return {
      clientId: ENV_VARS.CLIENT_ID,
      menuName,
      master: jsonVar(),
      // heroImage: jsonVar().heroImage,
    };
  }

  return {
    clientId: ENV_VARS.CLIENT_ID,
    menuName,
    UberEats: jsonVar(),
    // heroImage: jsonVar().heroImage,
  };
};

export default saveFinalJson;
