import React from "react";
import { useQuery } from "@apollo/client";
import {
  GET_JSON,
  GET_SAVED_MENU_NAME,
} from "../../apollo/operations/queries/localQueries";
import TitleWithInput from "../shared/TitleWithInput";
import { jsonVar, menuKeyVar } from "../../apollo/cache";

import style from "./styles/MenuManagement.module.scss";
import { SubMenu, SubMenus } from "../../models/json";
import produce from "immer";

const MenuTitle = () => {
  const { data } = useQuery(GET_JSON);
  const menuName = useQuery(GET_SAVED_MENU_NAME);
  /**
   *
   * @param menu - SubMenu to update the name for
   */
  const updateJsonMenuName = (menu: SubMenu) => {
    menuKeyVar(menu.title.translations.en_uk);
    //delete new menu property
    jsonVar(
      produce(jsonVar(), (draftJson) => {
        draftJson.menus[menu.title.translations.en_uk] = menu;
      })
    );
  };

  return (
    <>
      {
        // TODO: Update to comapre the title with all submenus available in menus
        //Currenly Converting Menus to array and comapring first item
      }
      {Object.values((data?.json?.menus as SubMenus) || {})[0]?.id ===
        "New Menu" && (
        <TitleWithInput
          item={Object.values((data.json.menus as SubMenus) || {})[0]}
          stateUpdate={updateJsonMenuName}
          style={style}
        />
      )}
      {/* Converting the menus object to array of values */}
      {Object.values((data?.json?.menus as SubMenus) || {})[0]?.id !==
        "New Menu" && (
        <div className={style.uiPageTitle}>{menuName.data.savedMenuName}</div>
      )}
    </>
  );
};

export default MenuTitle;
