import produce from "immer";
import React, { useEffect, useState } from "react";
import DailyTime from "./DailyTime";
import ScheduleIcon from "@material-ui/icons/Schedule";
import style from "./styles/TimingsDrawer.module.scss";

import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { Divider } from "@material-ui/core";
import { jsonVar } from "../../../apollo/cache";
import { MasterMenu } from "../../../models/json";

const daysOfWeek = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];
const defaults = {
  startTime: "00:00",
  endTime: "23:59",
};
type DefaultsType = typeof defaults;

type Timings = {
  [k: string]: DefaultsType;
};

const getDefaults = (menuKey: string, day: string, json: MasterMenu) => {
  return {
    startTime:
      json.menus[menuKey]?.service_availability[day]?.time_periods[0]
        .start_time || "00:00",
    endTime:
      json.menus[menuKey]?.service_availability[day]?.time_periods[0]
        .end_time || "23:58",
  } as DefaultsType;
};

type Props = {
  isOpen: boolean;
  setDrawerOpen: (isOpen: boolean) => void;
  menuKey: string;
  json: MasterMenu;
};
const TimingsDrawer = ({ isOpen, setDrawerOpen, menuKey, json }: Props) => {
  const [error, setError] = useState("");
  const errorRef = React.useRef<HTMLInputElement>(null);
  const [form, updateForm] = useState<Timings>({} as Timings);

  /**
   * Updates all fields to the values of MONDAY
   * Triggered when user Clicks Update All Button
   */
  const updateAllFields = () => {
    const startTime = form[daysOfWeek[0]].startTime;
    const endTime = form[daysOfWeek[0]].endTime;
    setTimeForAllDays(startTime, endTime);
  };

  /**
   * Updates Form object when a TextField Changes
   * @param day Day of the week ['monday', 'tuesday'...]
   * @param time startTime || endTime
   * @param value 24 hours format => '21:45'
   */
  const handleChange = (day: string, time: string, value: string) => {
    //if user updates, remove error
    setError("");
    if (form && form[day]) {
      let dayObj = { ...form[day], [time]: value };
      updateForm({
        ...form,
        [day]: dayObj,
      });

      autoSaveNewData(
        day,
        time === "startTime" ? "start_time" : "end_time",
        value
      );
    }
  };

  const autoSaveNewData = (day: string, time: string, value: string) => {
    try {
      validateForm();
    } catch (error: any) {
      setError(error.message);
      errorRef?.current?.scrollIntoView();
      return;
    }
    jsonVar(
      produce(jsonVar(), (draftJson: MasterMenu) => {
        const oldTimes =
          draftJson.menus[menuKey].service_availability[day].time_periods[0];
        const newTimes = {
          ...oldTimes,
          [time]: value,
        };
        draftJson.menus[menuKey].service_availability[day].time_periods[0] = {
          ...newTimes,
        };
      })
    );
  };
  /**
   * Updates startTime and endTime of all days using
   * @param startTime
   * @param endTime
   */
  const setTimeForAllDays = (startTime: string, endTime: string) => {
    for (let day of daysOfWeek) {
      autoSaveNewData(day, "start_time", startTime);
      autoSaveNewData(day, "end_time", endTime);
    }
    updateForm(
      produce(form, (draftForm) => {
        for (const day in draftForm) {
          draftForm[day] = {
            startTime: startTime,
            endTime: endTime,
          };
        }
      })
    );
  };

  /**
   * Validates all fields of form
   * @returns true if valid
   * @throws error if invalid
   *
   */
  const validateForm = () => {
    for (const day in form) {
      //check all the fields are non empty
      if (
        !form[day].startTime ||
        form[day].startTime === "" ||
        form[day].startTime === "custom"
      )
        throw new Error(`Start Time For ${day} is empty`);
      if (
        !form[day].endTime ||
        form[day].endTime === "" ||
        form[day].endTime === "custom"
      )
        throw new Error(`End Time For ${day} is empty`);
      if (!isValidTimeCombination(form[day].startTime, form[day].endTime))
        throw new Error(`Start time greater than end time for ${day}`);
    }
    return true;
  };

  /**
   *
   * @param starTime
   * @param endTime
   * @return true if startTime < endTime
   */
  const isValidTimeCombination = (starTime: string, endTime: string) => {
    if (starTime === "storeOpeningTime" || endTime === "storeClosingTime") {
      return true;
    }
    const sTime = new Date();
    const sTimeArray = starTime.split(":");

    sTime.setHours(parseInt(sTimeArray[0]));
    sTime.setMinutes(parseInt(sTimeArray[1]));

    const eTime = new Date();
    const eTimeArray = endTime.split(":");
    eTime.setHours(parseInt(eTimeArray[0]));
    eTime.setMinutes(parseInt(eTimeArray[1]));

    return eTime > sTime;
  };

  useEffect(() => {
    updateForm(
      Object.fromEntries(
        daysOfWeek.map((day) => [day, getDefaults(menuKey, day, json)])
      )
    );
  }, [json, menuKey]);

  /* When the drawer is not open, show Icon to open Drawer */
  const DrawerClosed = () => {
    return (
      <div
        className={`${style.drawer} ${style.DrawerClosed}`}
        style={{ height: "500px" }}
      >
        <div
          className={style.closedWrapper}
          onClick={() => {
            setDrawerOpen(true);
          }}
        >
          <ArrowBackIosIcon style={{ marginRight: "-7px", width: "16px" }} />
          <ScheduleIcon />
        </div>
      </div>
    );
  };

  const DrawerOpen = () => {
    return (
      <div
        className={style.drawer}
        style={{ height: isOpen ? "fit-content" : "500px" }}
      >
        <div className={style.ContentContainer}>
          <ChevronRightIcon
            className={style.rightArrow}
            onClick={() => {
              setDrawerOpen(false);
            }}
          />
          {daysOfWeek.map((day, index) => {
            return (
              <DailyTime
                day={day}
                form={form}
                onFieldChange={handleChange}
                updateAllFields={updateAllFields}
                defaults={defaults}
                key={index}
              />
            );
          })}

          <div ref={errorRef} className={style.formError}>
            {error && error !== "" && <span>{error}</span>}
          </div>
          <Divider />
        </div>
      </div>
    );
  };

  if (isOpen) {
    return <DrawerOpen />;
  } else {
    return <DrawerClosed />;
  }
};
export default TimingsDrawer;
