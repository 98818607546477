import produce from "immer";
import { StoreType } from "../../../models/stores";
import { MenuItemWithOptions } from "../../../models/menu";
import { MasterMenu, MenuModifierGroup } from "../../../models/json";
import { addItemToDeletedCategory } from "./deleteActions";
import { jsonVar, localOpeningHoursVar } from "../../cache";

export * from "./storesActions";
export * from "./deleteActions";
export * from "./mealSetActions";
/**
 *
 * @param menuItem - MenuItemLocal to be changed
 */
export const changeItemInJsonItems = (menuItem: MenuItemWithOptions): void => {
  jsonVar(
    produce(jsonVar(), (draftJson: MasterMenu) => {
      draftJson.items[menuItem.id] = menuItem;
    })
  );
};

/**
 *
 * @param modifierGroup MenuModifierGroup to be added
 * Add given modifierGroup to json.modifier_groups
 * Uses modifierGroup.id as key
 */
export const addGroupToModifiers = (modifierGroup: MenuModifierGroup): void => {
  jsonVar(
    produce(jsonVar(), (draftJson) => {
      draftJson.modifier_groups[modifierGroup.id] = modifierGroup;
    })
  );
};
/**
 *
 * @param item -MenuItemLocal Item to be added
 */
export const addItemToJsonItems = (item: MenuItemWithOptions): void => {
  jsonVar({
    ...jsonVar(),

    items: { ...jsonVar().items, item },
  });
};
/**
 *
 * @param item
 * @param categoryTitle
 * @returns--void
 * Adds Items to category,
 * If title is not found in the data, add to deletedItems
 * If item found in category, update Json.
 */
export const addItemToCategory = (
  item: MenuItemWithOptions,
  categoryTitle: string
): void => {
  if (!jsonVar().categories[categoryTitle]) {
    addItemToDeletedCategory(item, categoryTitle);
    return;
  }

  if (jsonVar().categories[categoryTitle].entities[item.id]) {
    return;
  }

  jsonVar(
    produce(jsonVar(), (draftJson) => {
      draftJson.categories[categoryTitle].entities[item.id] = {
        id: item.id,
        type: "ITEM",
      };
    })
  );
};
/**
 *
 * @param newItemId string Id of modiferOption to be added
 * @param modifierGroupId string id of target modifier group
 */
export const addItemIdToModifierGroup = (
  newItemId: string,
  modifierGroupId: string
): void => {
  const modifier = jsonVar().modifier_groups[modifierGroupId];
  const newModifier: unknown = produce(
    modifier,
    (draftModifier: MenuModifierGroup) => {
      //unshifting a prop in object
      draftModifier.modifier_options = {
        [newItemId]: { id: newItemId, type: "ITEM" },
        ...draftModifier.modifier_options,
      };
    }
  );

  addGroupToModifiers(newModifier as MenuModifierGroup);
};

export const updateItemInLocalOpeningHours = (item: StoreType): void => {
  localOpeningHoursVar(
    localOpeningHoursVar().map((store: StoreType) => {
      if (store.aggregatorUUID === item.aggregatorUUID) {
        return item;
      }

      return store;
    })
  );
};

/**
 *
 * @param item - MenuItemLocal - item to be moved
 * @param oldCategory - string -- oldCategory Name
 * @param newCategory - string -- newCategory Name
 */

export const moveItemToDifferentCategory = (
  item: MenuItemWithOptions,
  oldCategory: string,
  newCategory: string
) => {
  jsonVar(
    produce(jsonVar(), (draftJson) => {
      //deleting entity from one category and adding into the other
      delete draftJson.categories[oldCategory].entities[item.id];
      //adding it into the other category
      draftJson.categories[newCategory].entities = {
        [item.id]: {
          id: item.id,
          type: "ITEM",
        },
        ...draftJson.categories[newCategory].entities,
      };
    })
  );
};
/**
 *
 * @param item - MenuItemLocal New Item created
 * @param categoryTitle -string
 * Takes the NewItems @item and saves it in the category with title @categoryTitle
 * Adds new item to items object in JSON
 */
export const createNewItem = (
  item: MenuItemWithOptions,
  categoryTitle: string
) => {
  jsonVar(
    produce(jsonVar(), (draftJson: MasterMenu) => {
      draftJson.items[item.id] = item;
    })
  );
  addItemToCategory(item, categoryTitle);
};
