/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from "react";
import { v4 as uuid } from "uuid";
import ReactTooltip from "react-tooltip";
import classnames from "classnames";
import Button from "@material-ui/core/Button";
import { moveItemToDifferentCategory } from "../../../apollo/operations/actions/actions";
import assignIcon from "../../../media/images/ic-meal-switch.svg";
import { useStyles } from "./useStylesMenuItem";
import { jsonVar } from "../../../apollo/cache";
import { MenuCategory } from "../../../models/json";

import style from "./styles/MealItem.module.scss";

const ChangeCategoryButton = ({ item, category }: any) => {
  const classes = useStyles();
  const [isOpen, toggleModal] = useState(false);

  const handleToggle = () => {
    if (isOpen) {
      toggleModal(false);
    } else {
      toggleModal(true);
    }
  };

  const chooseCategory = (newCategoryTitle: string) => {
    moveItemToDifferentCategory(item, category, newCategoryTitle);
  };

  const modalstyle = classnames(style.changeCategoryModal, {
    [style.visibleCategoryModal]: isOpen,
  });
  return (
    <>
      <div className={modalstyle}>
        {/* Converting Categories object to array for Map */}
        {Object.values(jsonVar().categories).map(
          (category: MenuCategory, index) => (
            <div
              className={style.changeCategoryRow}
              key={uuid()}
              onClick={() => chooseCategory(category.title.translations.en_uk)}
            >
              {category.title.translations.en_uk}
            </div>
          )
        )}
      </div>
      <Button
        onClick={() => handleToggle()}
        data-tip
        data-for="itemCategory"
        variant="contained"
        color="secondary"
        disableElevation
        classes={{ root: classes.root }}
      >
        <img src={assignIcon} alt="Assign" />
      </Button>
      <ReactTooltip
        id="itemCategory"
        place="bottom"
        type="dark"
        effect="float"
        globalEventOff="click"
      >
        <span>move item to different category</span>
      </ReactTooltip>
    </>
  );
};

export default ChangeCategoryButton;
