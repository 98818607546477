import React, { useState, useEffect } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { Button } from "@material-ui/core";
import FadeLoader from "react-spinners/FadeLoader";
import {
  PATCH_POS_STATUS,
  PATCH_POS_STATUS_LIVE,
} from "../../../apollo/operations/mutations/mutations";
import {
  GET_POS_STATUS,
  GET_POS_STATUS_LIVE,
} from "../../../apollo/operations/queries/serverQueries";
import { StoreType } from "../../../models/stores";
import { GET_LIVE_TOGGLE } from "../../../apollo/operations/queries/localQueries";
import ReactTooltip from "react-tooltip";
import { PERMISSIONS } from "../../../config/config";
import { userGroupVar } from "../../../apollo/cache";

interface Props {
  store: StoreType;
  toggleSpinner: (action: boolean) => void;
  isSpinner: boolean;
}

const ChangeStatusButton: React.FC<Props> = ({
  store,
  toggleSpinner,
  isSpinner,
}) => {
  const [shouldQuery, setShouldQuery] = useState("");
  const [buttonText, setButtonText] = useState("Check now");

  useQuery(GET_POS_STATUS, {
    variables: { aggregatorUUID: store.aggregatorUUID },
    skip: shouldQuery !== "test",
    fetchPolicy: "network-only",
    onCompleted: () => setShouldQuery(""),
  });

  useQuery(GET_POS_STATUS_LIVE, {
    variables: { aggregatorUUID: store.aggregatorUUID },
    skip: shouldQuery !== "live",
    fetchPolicy: "network-only",
    onCompleted: () => setShouldQuery(""),
  });

  const {
    data: { liveToggle },
  }: any = useQuery(GET_LIVE_TOGGLE);
  const [patchPosStatus] = useMutation(PATCH_POS_STATUS);
  const [patchPosStatusLive] = useMutation(PATCH_POS_STATUS_LIVE);

  const handlePosToggle = () => {
    if (buttonText === "Check now") {
      setShouldQuery(liveToggle ? "live" : "test");
      setButtonText("");
    } else {
      const toggleAction = !store.posIntegrated;
      const variables = {
        action: toggleAction,
        aggregatorUUID: store.aggregatorUUID,
      };
      // eslint-disable-next-line no-unused-expressions
      liveToggle
        ? patchPosStatusLive({ variables })
        : patchPosStatus({ variables });
      setButtonText("Check now");
    }
    toggleSpinner(true);
  };

  useEffect(() => {
    if (buttonText !== "Check now")
      setTimeout(() => setButtonText("Check now"), 10000);
  }, [buttonText]);

  const buttonToggle = store.posIntegrated ? "Disable" : "Enable";

  const saveButtonDisabled = () => {
    if (!PERMISSIONS[userGroupVar()]["mission_control_access"]) {
      return true;
    }
  };
  const getSaveButtonToolTipText = () => {
    if (!PERMISSIONS[userGroupVar()]["mission_control_access"]) {
      return "You do not have permission to checknow.";
    }
    return null;
  };
  return (
    <>
      {!isSpinner ? (
        <>
          <span data-for="checknowBtn" data-tip data-tip-disable={false}>
            <Button
              color="primary"
              variant="contained"
              disableElevation
              onClick={saveButtonDisabled() ? undefined : handlePosToggle}
              disabled={saveButtonDisabled()}
            >
              {buttonText || buttonToggle}
            </Button>
          </span>
          {getSaveButtonToolTipText() && (
            <ReactTooltip id="checknowBtn" place="bottom">
              {getSaveButtonToolTipText()}
            </ReactTooltip>
          )}
        </>
      ) : (
        <div style={{ margin: "0 auto", width: 66 }}>
          <FadeLoader
            height={10}
            width={10}
            radius={100}
            margin={4}
            color="#336B6B"
            loading={isSpinner}
          />
        </div>
      )}
    </>
  );
};

export default ChangeStatusButton;
