import React from "react";
import { useQuery } from "@apollo/client";
import {
  GET_ARE_CHANGES_SAVED,
  GET_ARE_CHANGES_PUBLISHED,
} from "../../apollo/operations/queries/localQueries";
import PageLeavingGuard from "../shared/PageLeavingGuard";
import OpeningHoursTable from "./OpeningHoursTable";
import OpeningHoursNav from "./OpeningHoursNav";
import { OpeningHoursDataHandler } from "./OpeningHoursDataHandlers";
import StyledToast from "../shared/StyledToast";
import { StoreType } from "../../models/stores";

import style from "./styles/OpeningHours.module.scss";

const OpeningHours = () => {
  const { data } = useQuery(GET_ARE_CHANGES_SAVED);
  const published = useQuery(GET_ARE_CHANGES_PUBLISHED);

  const warningModalMessage = data.areChangesSaved
    ? "You haven't published your changes. Are you sure you want to leave the page?"
    : "You have some unsaved changes. Are you sure you want to leave the page?";
  return (
    <div className={style.openingHoursContainer}>
      <PageLeavingGuard
        when={!data.areChangesSaved || !published.data.areChangesPublished}
        message={warningModalMessage}
        route
      />
      <OpeningHoursNav />
      <OpeningHoursDataHandler>
          {(openingHoursData: StoreType[]) => (
            <OpeningHoursTable openingHoursData={openingHoursData} />
          )}
      </OpeningHoursDataHandler>
      <StyledToast />
    </div>
  );
};

export default OpeningHours;
