import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 276;

// eslint-disable-next-line import/prefer-default-export
export const useStyles = makeStyles((theme) => ({
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  paper: {
    top: 48,
    borderRadius: "0 30px 0 0",
    border: 0,
    background: "#FFFFFF",
  },
  menuButtonClose: {
    transform: "rotate(-90deg)",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    // width: theme.spacing(7) + 1,
    // [theme.breakpoints.up('sm')]: {
    //     width: theme.spacing(9) + 1,
    // },
    width: 88,
  },
  menuList: {
    marginBottom: 10,
    paddingLeft: 32,
  },
}));
