/* eslint-disable react/require-default-props */
import React from "react";
import classnames from "classnames";
import { ToastContainer } from "react-toastify";
import style from "./styles/StyledToast.module.scss";

type Props = {
  isWarning?: boolean;
};

const StyledToast: React.FC<Props> = ({ isWarning }) => {
  const toastStyles = classnames(style.toastifyWrapper, {
    [style.errorWrapper]: isWarning,
  });
  const progressBarStyles = classnames(style.toastifyProgressBar, {
    [style.errorProgressBar]: isWarning,
  });

  return (
    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      className={toastStyles}
      bodyClassName={style.toastifyBody}
      progressClassName={progressBarStyles}
    />
  );
};

export default StyledToast;
