/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import { TextField } from "@material-ui/core";
import { UPSERT_ITEM_COLLECTION } from "../../../apollo/operations/mutations/mutations";
import newIcon from "../../../media/images/ic-modal-new.svg";
import ModalHeader from "../shared/ModalHeader";
import { openModalVar } from "../../../apollo/cache";
import CollectionItemsAutocomplete from "./CollectionItemsAutocomplete";
import { MODAL_OPEN } from "../../../config/config";
import { GET_ITEM_COLLECTIONS } from "../../../apollo/operations/queries/serverQueries";

import style from "./styles/StoreGroupModal.module.scss";

const CollectionsModal = ({ isOpen }) => {
  const [addCollection] = useMutation(UPSERT_ITEM_COLLECTION); // appropriate mutation
  const [collectionTitle, setCollectionTitle] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [itemsInCollection, setItemsInCollection] = useState([]);

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCollectionTitle(e.target.value);
    setErrorMessage("");
  };

  const handleClose = (_event: any, reason: string) => {
    if (reason === "backdropClick") {
      return false;
    }

    openModalVar(MODAL_OPEN.NONE);
  };

  const addItemsToCollection = (items: any) => setItemsInCollection(items);

  const createCollection = () => {
    if (!collectionTitle) {
      setErrorMessage("Please enter a Collection Name to save the collection");
      return;
    }

    // const newCollection: any = {
    //   title: collectionTitle,
    //   itemIds: itemsInCollection,
    // };

    // console.log("COLLECTION", newCollection);
    setItemsInCollection([]);
    setCollectionTitle("");
    addCollection({
      variables: { itemIds: itemsInCollection, title: collectionTitle },
      refetchQueries: [GET_ITEM_COLLECTIONS],
    });
    openModalVar(MODAL_OPEN.NONE);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={isOpen}
      maxWidth="md"
    >
      <ModalHeader
        onClose={() => openModalVar(MODAL_OPEN.NONE)}
        icon={newIcon}
        title="Create new store group"
      />
      <MuiDialogContent>
        <div className={style.uiModalContent}>
          {errorMessage && <div className={style.errorRow}>{errorMessage}</div>}
          <form className={style.uiForm} noValidate autoComplete="off">
            <label className={style.uiFormLabel}>Collection Name</label>
            <div className={style.inputContainer}>
              <TextField
                variant="outlined"
                placeholder="Insert Collection Name"
                value={collectionTitle}
                onChange={handleTitleChange}
                label="Collection Name"
                fullWidth
              />
            </div>
            <label className={style.uiFormLabel}>Collection Items</label>
            <div className={style.inputContainer}>
              <CollectionItemsAutocomplete
                getSelectedItems={addItemsToCollection}
                selectedItems={itemsInCollection}
              />
            </div>
          </form>
        </div>
      </MuiDialogContent>
      <MuiDialogActions>
        <div className={style.uiModalAction}>
          <Button
            onClick={() => openModalVar(MODAL_OPEN.NONE)}
            color="primary"
            variant="outlined"
            size="large"
          >
            Cancel
          </Button>
          <Button
            onClick={createCollection}
            autoFocus
            color="primary"
            variant="contained"
            disableElevation
            size="large"
          >
            Create
          </Button>
        </div>
      </MuiDialogActions>
    </Dialog>
  );
};

export default CollectionsModal;
