import React from "react";
import classnames from "classnames";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import IconButton from "@material-ui/core/IconButton";
import ReactTooltip from "react-tooltip";
import { NavLink } from "react-router-dom";
import { useQuery } from "@apollo/client";
import MenuIcon from "../../media/images/ic-menu-open.svg";
import Logo from "../../media/images/logo.svg";
import iconMenuMng from "../../media/images/ic-drawer-menu-management.svg";
import iconStoreMng from "../../media/images/ic-drawer-store-management.svg";
import iconOpeningHr from "../../media/images/ic-drawer-opening-hr.svg";
import iconMission from "../../media/images/ic-drawer-mission-control.svg";
import iconMenuBrief from "../../media/images/ic-drawer-menu-brief.svg";
import iconSettings from "../../media/images/ic-drawer-settings.svg";
import alertList from "../../media/images/alert-add.svg";
import iconCode from "../../media/images/ic-code.svg";
import DashboardSharpIcon from "@material-ui/icons/DashboardSharp";
import iconCollections from "../../media/images/ic-collections.svg";
import SideNavLink from "./SideNavLink";
import { useStyles } from "./useStylesSideNav";
import {
  GET_IS_SIDE_NAV_OPEN,
  isSideNavOpen,
  userGroupVar,
} from "../../apollo/cache";

import style from "./styles/SideNav.module.scss";
import { PERMISSIONS } from "../../config/config";

const SideNav = () => {
  const {
    data: { isOpened },
  }: any = useQuery(GET_IS_SIDE_NAV_OPEN);
  const { drawer, drawerOpen, drawerClose, paper, menuButtonClose, hide } =
    useStyles();

  const handleDrawerOpen = () => isSideNavOpen(true);

  const handleDrawerClose = () => isSideNavOpen(false);

  const DrawerStyle = classnames(
    drawer,
    { [drawerOpen]: isOpened },
    { [drawerClose]: !isOpened }
  );
  const PaperStyle = classnames(
    paper,
    { [drawerOpen]: isOpened },
    { [drawerClose]: !isOpened }
  );
  const IconButtonStyle = classnames(style.sideNavButton, menuButtonClose, {
    [hide]: isOpened,
  });
  const IconOpenButtonStyle = classnames(style.sideNavButton, {
    [hide]: !isOpened,
  });
  return (
    <>
      <Drawer
        variant="permanent"
        className={DrawerStyle}
        classes={{
          paper: PaperStyle,
        }}
      >
        <div className={style.sideNav}>
          <div className={style.sideNavBtn}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              size="small"
              className={IconButtonStyle}
            >
              <img src={MenuIcon} alt="menu icon" />
            </IconButton>
            <IconButton
              onClick={handleDrawerClose}
              size="small"
              className={IconOpenButtonStyle}
            >
              <img src={MenuIcon} alt="menu icon" />
            </IconButton>
          </div>
          <NavLink to="/">
            {isOpened && (
              <img src={Logo} className={style.sideNavLogo} alt="logo" />
            )}
            {!isOpened && <div className={style.sideNavLogoPlaceholder} />}
          </NavLink>
          <List className={style.sideNavList} disablePadding>
            <SideNavLink
              icon={<img src={iconMenuBrief} alt="icon" />}
              label="The Menu Brief"
              toPath="/menubrief"
              isOpened={isOpened}
              dataFor="menubrief"
            />
            <SideNavLink
              icon={<img src={iconMenuMng} alt="icon" />}
              label="Menu Management"
              toPath="/"
              isOpened={isOpened}
              dataFor="menumanagement"
            />
            <SideNavLink
              icon={<img src={iconStoreMng} alt="icon" />}
              label="Store Management"
              toPath="/store-management"
              isOpened={isOpened}
              dataFor="storemanagement"
            />
            <SideNavLink
              icon={<img src={iconOpeningHr} alt="icon" />}
              label="Opening Hours"
              toPath="/opening-hours"
              isOpened={isOpened}
              dataFor="openinghours"
            />
            <SideNavLink
              icon={<img src={iconMission} alt="icon" />}
              label="Mission Control"
              toPath="/mission-control"
              isOpened={isOpened}
              dataFor="missioncontrol"
            />
            <SideNavLink
              icon={<img src={iconCollections} alt="icon" />}
              label="Product Collections"
              toPath="/product-collections"
              isOpened={isOpened}
              dataFor="productcollections"
            />
            <SideNavLink
              icon={<DashboardSharpIcon htmlColor="black" />}
              label="Menu Analytics"
              toPath="/menu-analytics"
              isOpened={isOpened}
              dataFor="menuanalytics"
            />
            <SideNavLink
              icon={<img src={iconSettings} alt="icon" />}
              label="Settings"
              toPath="/settings"
              isOpened={isOpened}
              dataFor="settings"
            />
            <SideNavLink
              icon={<img src={alertList} alt="icon" />}
              label="Alert List"
              toPath="/alert-list"
              isOpened={isOpened}
              dataFor="alertlist"
            />
            {!PERMISSIONS[userGroupVar()]["not_allowed_routes"].includes(
              "/menu-source"
            ) && (
              <SideNavLink
                icon={<img src={iconCode} alt="code" />}
                label="Developer JSON"
                toPath="/menu-source"
                isOpened={isOpened}
                dataFor="json"
              />
            )}
          </List>
        </div>
      </Drawer>
      <ReactTooltip
        id="menubrief"
        place="bottom"
        type="dark"
        effect="float"
        globalEventOff="click"
      >
        <span>the menu brief</span>
      </ReactTooltip>
      <ReactTooltip
        id="menumanagement"
        place="bottom"
        type="dark"
        effect="float"
        globalEventOff="click"
      >
        <span>create and edit menus</span>
      </ReactTooltip>
      <ReactTooltip
        id="storemanagement"
        place="bottom"
        type="dark"
        effect="float"
        globalEventOff="click"
      >
        <span>manage store groups</span>
      </ReactTooltip>
      <ReactTooltip
        id="openinghours"
        place="bottom"
        type="dark"
        effect="float"
        globalEventOff="click"
      >
        <span>control opening hours</span>
      </ReactTooltip>
      <ReactTooltip
        id="missioncontrol"
        place="bottom"
        type="dark"
        effect="float"
        globalEventOff="click"
      >
        <span>mission control: pos integration & menu</span>
      </ReactTooltip>
      <ReactTooltip
        id="menuanalytics"
        place="bottom"
        type="dark"
        effect="float"
        globalEventOff="click"
      >
        <span>menu analytics</span>
      </ReactTooltip>
      <ReactTooltip
        id="productcollections"
        place="bottom"
        type="dark"
        effect="float"
        globalEventOff="click"
      >
        <span>manage product collections</span>
      </ReactTooltip>
      <ReactTooltip
        id="settings"
        place="bottom"
        type="dark"
        effect="float"
        globalEventOff="click"
      >
        <span>your settings</span>
      </ReactTooltip>
      <ReactTooltip
        id="json"
        place="bottom"
        type="dark"
        effect="float"
        globalEventOff="click"
      >
        <span>inspect menu JSON</span>
      </ReactTooltip>
      <ReactTooltip
        id="alertlist"
        place="bottom"
        type="dark"
        effect="float"
        globalEventOff="click"
      >
        <span>alert list</span>
      </ReactTooltip>
    </>
  );
};

export default SideNav;
