import { gql } from "@apollo/client";

import { RESTAURANT_DATA_FRAGMENT } from "../../fragments";

export const ADD_GROUP_SUBSCRIPTION = gql`
  subscription MySubscription($clientId: String!) {
    onCreateRestaurantGroupings(clientId: $clientId) {
      clientId
      id
      title
      storeIds {
        UberEats
      }
    }
  }
`;

export const DELETE_GROUP_SUBSCRIPTION = gql`
  subscription MySubscription($clientId: String!) {
    onDeleteRestaurantGroupings(clientId: $clientId) {
      id
      clientId
      title
    }
  }
`;

export const UPDATE_GROUP_SUBSCRIPTION = gql`
  subscription MySubscription {
    onUpdateRestaurantGroupings {
      id
      clientId
      title
      storeIds {
        UberEats
      }
    }
  }
`;

export const ON_UPDATE_OPENING_HOURS = gql`
  subscription MySubscription {
    onUpdateRestaurantData {
      aggregatorUUID
      uuid
      clientId
      ms2StoreNumber
      restaurantName
      menuName
      menuSlug
      posIntegrated
      posIntegratedSince
      lastChecked
      monday {
        close
        open
      }
      tuesday {
        close
        open
      }
      wednesday {
        close
        open
      }
      thursday {
        close
        open
      }
      friday {
        close
        open
      }
      saturday {
        close
        open
      }
      sunday {
        close
        open
      }
    }
  }
`;

export const ON_UPDATE_RESTAURANT_DATA = gql`
  ${RESTAURANT_DATA_FRAGMENT}

  subscription MySubscription {
    onUpdateRestaurantData {
      ...restaurantData
    }
  }
`;

export const ON_UPDATE_MISSION_CONTROL = gql`
  subscription MySubscription {
    onUpdateRestaurantData {
      uuid
      aggregatorUUID
      ms2StoreNumber
      restaurantName
      posIntegrated
      lastChecked
      menuSlug
      autoAcceptEnabled
      onlineStatus
      offlineReason
      partnerStoreId
    }
  }
`;

export const UPDATE_SAVED_MENUS = gql`
  subscription MySubscription($clientId: String!) {
    onUpdateClientMenus(clientId: $clientId) {
      menus {
        menuName
        versionId
      }
    }
  }
`;

export const CREATE_CUSTOMIZATION_SUBSCRIPTION = gql`
  subscription MySubscription($clientId: String!) {
    onCreateCustomisations(clientId: $clientId) {
      sk
      slug
      storeIds
      storeGroups
      description
      global
      schedule {
        repeat
        inteval
        weekDays
        day
        pos
        month
        until
        count
        closingTime
        openingTime
      }
      aggregators
      json
    }
  }
`;

export const EDIT_CUSTOMIZATION_SUBSCRIPTION = gql`
  subscription MySubscription($clientId: String!) {
    onUpdateCustomisations(clientId: $clientId) {
      sk
      slug
      storeIds
      storeGroups
      description
      global
      schedule {
        repeat
        interval
        weekDays
        day
        pos
        month
        until
        count
        closingTime
        openingTime
      }
      aggregators
      json
    }
  }
`;

export const DELETE_CUSTOMIZATION_SUBSCRIPTION = gql`
  subscription MySubscription($clientId: String!) {
    onDeleteCustomisations(clientId: $clientId) {
      sk
      slug
      storeIds
      storeGroups
      description
      global
      schedule {
        repeat
        interval
        weekDays
        day
        pos
        month
        until
        count
        closingTime
        openingTime
      }
      aggregators
      json
    }
  }
`;

export const UPSERT_COLLECTION_SUBSCRIPTION = gql`
  subscription MySubscription {
    onUpsertItemCollection {
      id
      itemIds
      title
    }
  }
`;

export const DELETE_COLLECTION_SUBSCRIPTION = gql`
  subscription MySubscription {
    onDeleteItemCollection {
      id
      itemIds
      title
    }
  }
`;
export const INITIAL_SUBSCRIPTION = gql`
  ${RESTAURANT_DATA_FRAGMENT}

  subscription InitialSubscription($clientId: String!) {
    onUpdateRestaurantData {
      ...restaurantData
    }
    onCreateCustomisations(clientId: $clientId) {
      sk
      slug
      storeIds
      storeGroups
      description
      global
      schedule {
        repeat
        interval
        weekDays
        day
        pos
        month
        until
        count
        closingTime
        openingTime
      }
      aggregators
      json
    }
    onUpdateCustomisations(clientId: $clientId) {
      sk
      slug
      storeIds
      storeGroups
      description
      global
      schedule {
        repeat
        interval
        weekDays
        day
        pos
        month
        until
        count
        closingTime
        openingTime
      }
      aggregators
      json
    }
    onDeleteCustomisations(clientId: $clientId) {
      sk
      slug
      storeIds
      storeGroups
      description
      global
      schedule {
        repeat
        interval
        weekDays
        day
        pos
        month
        until
        count
        closingTime
        openingTime
      }
      aggregators
      json
    }
    onUpdateClientMenus(clientId: $clientId) {
      menus {
        menuName
        versionId
      }
    }
    onUpsertItemCollection {
      id
      itemIds
      title
    }
    onDeleteItemCollection {
      id
      itemIds
      title
    }
    onCreateRestaurantGroupings(clientId: $clientId) {
      clientId
      id
      title
      storeIds {
        UberEats
      }
    }
    onUpdateRestaurantGroupings {
      id
      clientId
      title
      storeIds {
        UberEats
      }
    }
    onDeleteRestaurantGroupings(clientId: $clientId) {
      id
      clientId
      title
    }
  }
`;
