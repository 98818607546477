import React, { useEffect } from "react";
import { JsonEditor as Editor } from "jsoneditor-react";
import { useCustomizationsState } from "../CustomizationsProvider";
import "jsoneditor-react/es/editor.min.css";
import { CUSTOMIZATION } from "../utils/config";

import style from "../styles/CustomizationModal.module.scss";

const CustomizationEditor = () => {
  const { state, dispatch } = useCustomizationsState();

  const editorDispatch = (value: any): void => {
    dispatch({
      type: CUSTOMIZATION.JSON,
      payload: value,
    });
  };

  useEffect(() => {
    const changeTabUsage = function (this: any, e: any) {
      if (e.key === "Tab") {
        e.preventDefault();
        var start = this?.selectionStart;
        var end = this?.selectionEnd;

        // set textarea value to: text before caret + tab + text after caret
        this.value =
          this?.value.substring(0, start) + "\t" + this?.value.substring(end);

        // put caret at right position again
        this.selectionStart = this.selectionEnd = start + 1;
      }
    };

    document
      .getElementsByClassName("jsoneditor-text")[0]
      .addEventListener("keydown", changeTabUsage);
    //remove everything
    if (
      !state[CUSTOMIZATION.JSON] ||
      state[CUSTOMIZATION.JSON] === "" ||
      state[CUSTOMIZATION.JSON] === undefined
    ) {
      //reset the editor
      const editor = document.getElementsByClassName(
        "jsoneditor-text"
      )[0] as HTMLTextAreaElement | null;
      if (editor) {
        editor.value = "";
      }
    }
    return () => {
      document
        .getElementsByClassName("jsoneditor-text")[0]
        ?.removeEventListener("keydown", changeTabUsage);
    };
  }, [state]);

  return (
    <>
      <span className={style.inputLabel}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
        commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
        velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
        occaecat cupidatat non proident, sunt in culpa qui officia deserunt
        mollit anim id est laborum.
      </span>
      <Editor
        value={state[CUSTOMIZATION.JSON]}
        onChange={editorDispatch}
        mode="text"
        tabindex="0"
      />
    </>
  );
};

export default CustomizationEditor;
