import EmojiPicker from "emoji-picker-react";
import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import useOutsideAlerter from "../../hooks/useOutsideAlerter";
import { checkForDuplicateCategory } from "../../helpers/menu";
import insertEmojiIcon from "../../media/images/insert-emoji-icon.svg";

interface Props {
  item: any;
  stateUpdate: (item: any) => void;
  style: any;
  isContainerAbsolute?: boolean;
}

const emojiPickerStyle = {
  zIndex: "1",
  position: "absolute",
  height: "280px",
};

const TitleWithInput = ({
  item,
  stateUpdate,
  style,
  isContainerAbsolute,
}: Props) => {
  const [isInput, toggleInput] = useState(false);
  const itemTitle =
    item.title && item.title.translations
      ? item.title.translations.en_uk
      : item.title;
  const [inputValue, setInputValue] = useState(itemTitle);
  const [isEmojiPicker, toggleEmojiPicker] = useState(false);
  const wrapperRef = useRef(null);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleSubmit = (e) => {
    e?.preventDefault();
    if (item.title.translations) {
      stateUpdate({
        ...item,
        title: { translations: { en_uk: inputValue } },
      });
    } else {
      if (checkForDuplicateCategory(inputValue)) {
        toast("A category with the same title already exists!");
        setInputValue(item.title);
        toggleInput(false);
        return;
      }

      stateUpdate({
        ...item,
        title: inputValue,
      });
    }

    toggleInput(false);
  };

  const handleEmojiClick = (e, emojiObject) => {
    setInputValue(`${inputValue}${emojiObject.emoji}`);
  };

  const handleEmojiToggle = (e: React.MouseEvent<HTMLImageElement>) => {
    e.preventDefault();
    toggleEmojiPicker(!isEmojiPicker);
  };

  useOutsideAlerter(wrapperRef, isEmojiPicker || isInput, handleSubmit);

  useEffect(() => {
    const itemTitle =
      item.title && item.title.translations
        ? item.title.translations.en_uk
        : item.title;
    setInputValue(itemTitle);
  }, [item]);

  const position = isContainerAbsolute ? "static" : "relative";
  return (
    <div ref={wrapperRef} style={{ position: position }}>
      {!isInput && (
        <span className={style.title} onClick={() => toggleInput(true)}>
          {inputValue}
        </span>
      )}

      {isInput && (
        <>
          <form
            className={style.inputForm}
            onSubmit={handleSubmit}
            onBlur={handleSubmit}
          >
            <input
              className={style.inputTitle}
              value={inputValue}
              onChange={handleInputChange}
              size={inputValue.length}
            />
            <img
              src={insertEmojiIcon}
              alt="Insert emoji"
              onClick={handleEmojiToggle}
              style={{
                marginLeft: "-22px",
                marginTop: "-2.25px",
                cursor: "pointer",
              }}
            />
          </form>
          {isEmojiPicker && (
            <EmojiPicker
              onEmojiClick={handleEmojiClick}
              pickerStyle={emojiPickerStyle}
              // disableSearchBar={true}
              disableSkinTonePicker={true}
              preload
              native
            />
          )}
        </>
      )}
    </div>
  );
};

export default TitleWithInput;
