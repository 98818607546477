import CustomizationsTextField from "./CustomizationsMainTextField";
import CustomizationsStoresAutocomplete from "./CustomizationStoresAutocomplete";
import CustomizationStoreGroupsAutocomplete from "./CustomizationStoreGroupsAutocomplete";
import CustomizationsSelectField from "./CustomizationsSelectField";
import CustomizationsToggle from "./CustomizationsToggle";
import CustomizationSchedule from "./CustomizationSchedule";

export default {
  Text: CustomizationsTextField,
  StoresAutocomplete: CustomizationsStoresAutocomplete,
  StoreGroupsAutocomplete: CustomizationStoreGroupsAutocomplete,
  Select: CustomizationsSelectField,
  Toggle: CustomizationsToggle,
  CustomizationSchedule: CustomizationSchedule,
};
