import React from "react";
import { Droppable, Draggable } from "react-beautiful-dnd";
import { splitCategoryItems } from "../helpers/reorderListHelpers";
import MealItem from "../MealItem";
import maxItemsByScreenSize from "../helpers/maxItemsByScreenSize";
import { MenuItemWithOptions } from "../../../models/menu";
import { MenuCategory } from "../../../models/json";
import { jsonVar } from "../../../apollo/cache";

import style from "./styles/CategoryItemList.module.scss";

interface Props {
  category: MenuCategory;
}

const CategoryItemList: React.FC<Props> = ({ category }) => {
  const numberOfItems = maxItemsByScreenSize();
  let items: any[] = [];
  for (let entityKey in category.entities) {
    const entity = category.entities[entityKey];
    if (jsonVar().items[entity.id]) items.push(jsonVar().items[entity.id]);
  }

  const categoryItemsLists = items && splitCategoryItems(items, numberOfItems);
  return (
    <>
      {categoryItemsLists.length !== 0 &&
        categoryItemsLists.map((itemsLists, index) => (
          <Droppable
            droppableId={index.toString()}
            direction="horizontal"
            isCombineEnabled
            key={index.toString()}
          >
            {(dropProvided: any, dropSnapshot: any) => (
              <div {...dropProvided.droppableProps}>
                <div
                  className={style.outterCategoryContainer}
                  ref={dropProvided.innerRef}
                >
                  {itemsLists &&
                    itemsLists.map(
                      (item: MenuItemWithOptions, index: number) => (
                        <React.Fragment key={item.id}>
                          {item && (
                            <Draggable
                              key={item.id}
                              draggableId={item.id}
                              index={index}
                            >
                              {(dragProvided: any, dragSnapshot: any) => (
                                <div
                                  ref={dragProvided.innerRef}
                                  {...dragProvided.draggableProps}
                                  {...dragProvided.dragHandleProps}
                                >
                                  <MealItem
                                    key={item.id}
                                    item={item}
                                    category={
                                      typeof category !== "string"
                                        ? category.title.translations.en_uk
                                        : category
                                    }
                                  />
                                </div>
                              )}
                            </Draggable>
                          )}
                        </React.Fragment>
                      )
                    )}
                  {dropProvided.placeholder}
                </div>
              </div>
            )}
          </Droppable>
        ))}
    </>
  );
};

export default CategoryItemList;
