import React from "react";
import { v4 as uuid } from "uuid";
import { MenuCategory, ModifierOption } from "../../../models/json";
import {
  MenuItemWithOptions,
  MenuCategoryWithOptions,
} from "../../../models/menu";
import placeholderImage from "../../../media/prestau-placeholder.png";

import style from "./styles/CategoryList.module.scss";

interface Props {
  category: MenuCategory | MenuCategoryWithOptions;
  jsonItems: MenuItemWithOptions;
  isReadOnly: boolean;
}

const CategoryHeaderImages: React.FC<Props> = ({
  category,
  jsonItems,
  isReadOnly,
}) => {
  const itemImages = isReadOnly
    ? Object.values((category as MenuCategoryWithOptions).children)
        .slice(0, 3)
        .map((item) => item.image_url)
    : Object.values((category as MenuCategory).entities)
        .slice(0, 3)
        .map((entity: ModifierOption) => jsonItems[entity.id]?.image_url);
  const imageLength = isReadOnly
    ? Object.values((category as MenuCategoryWithOptions).children).length - 3
    : Object.values((category as MenuCategory).entities).length - 3;

  if (!itemImages) {
    return null;
  }

  return (
    <div className={style.menuImgBlock}>
      {itemImages.map((image) => (
        <img
          className={style.menuImgBlockImg}
          src={image || placeholderImage}
          alt="food item"
          key={uuid()}
        />
      ))}
      {itemImages.length > 2 && (
        <div className={style.menuImgBlockNumber}>{imageLength}+</div>
      )}
    </div>
  );
};

export default CategoryHeaderImages;
