import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import Button from "@material-ui/core/Button";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { GET_JSON } from "../../../../apollo/operations/queries/localQueries";
import { ProductCollection } from "../..";
import { AutocompleteValueType } from "../../../../models/stores";
import { UPSERT_ITEM_COLLECTION } from "../../../../apollo/operations/mutations/mutations";
import { itemsArrayFromObject } from "../../../MenuManagement/helpers/arraysAndObjects";

import style from "../styles/CollectionsList.module.scss";

type Props = {
  collection: ProductCollection;
};

const AddItemInCollection: React.FC<Props> = ({ collection }) => {
  const { data } = useQuery(GET_JSON);
  const [isInputVisible, toggleAddStoreInput] = useState(false);
  const [updateCollection] = useMutation(UPSERT_ITEM_COLLECTION);

  const addItemToCollection = (itemId: string) => {
    updateCollection({
      variables: {
        itemIds: [itemId, ...collection.itemIds],
        title: collection.title,
        id: collection.id,
      },
    });
  };

  const handleToggle = () => {
    toggleAddStoreInput(!isInputVisible);
  };

  const mapItemsToOptions = (items: any[]): AutocompleteValueType[] => {
    return items.map((item: any) => ({
      id: item.id,
      name: item.title.translations.en_uk,
    }));
  };

  const options = mapItemsToOptions(
    itemsArrayFromObject(data.json.items)
  ).filter((item) => !collection.itemIds.includes(item.id));
  return (
    <>
      <Button
        variant="contained"
        color="primary"
        disableElevation
        onClick={handleToggle}
      >
        Add Item
      </Button>
      {isInputVisible && (
        <div className={style.addStoreContainer}>
          <Autocomplete
            options={options}
            onChange={(
              event: any,
              value: string | AutocompleteValueType | null
            ) => {
              if (value)
                addItemToCollection((value as AutocompleteValueType).id);
            }}
            getOptionLabel={(option) => option.name}
            getOptionSelected={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Items"
                placeholder="Search Items"
              />
            )}
          />
        </div>
      )}
    </>
  );
};

export default AddItemInCollection;
