/* eslint-disable react/require-default-props */
import React from "react";
import { Checkbox, MenuItem, Select } from "@material-ui/core";
import { useCustomizationsState } from "../CustomizationsProvider";
import { CUSTOMIZATION } from "../utils/config";
import { UncheckIcon, CheckIcon } from "../../../shared/Icons";

import style from "../styles/CustomizationModal.module.scss";

type Props = {
  name: string;
  options: any[];
  moreInfoComponent?: JSX.Element;
  multiple?: boolean;
};

const CustomizationsSelectField: React.FC<Props> = ({
  name,
  options,
  moreInfoComponent,
  multiple,
}) => {
  const { state, dispatch } = useCustomizationsState();

  const selectFieldDispatch = (
    e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
    child: React.ReactNode
  ): void => {
    dispatch({
      type: e.target.name as CUSTOMIZATION,
      payload: e.target.value as string,
    });
  };

  return (
    <>
      <span className={style.inputLabel}>
        {name} {moreInfoComponent}
      </span>
      <div className={style.inputContainer}>
        {multiple && (
          <Select
            name={name}
            value={state[name]}
            variant="outlined"
            onChange={selectFieldDispatch}
            renderValue={(selected) => (selected as string[]).join(", ")}
            multiple
          >
            {options.map((option) => (
              <MenuItem key={option} value={option}>
                <Checkbox
                  icon={<UncheckIcon />}
                  checkedIcon={<CheckIcon />}
                  style={{ marginRight: 8 }}
                  color="default"
                  checked={state[name].indexOf(option) > -1}
                />
                {option}
              </MenuItem>
            ))}
          </Select>
        )}

        {!multiple && (
          <Select
            native
            name={name}
            value={state[name]}
            variant="outlined"
            onChange={selectFieldDispatch}
          >
            {options.map((option) => (
              <option key={option}>{option}</option>
            ))}
          </Select>
        )}
      </div>
    </>
  );
};

export default CustomizationsSelectField;
