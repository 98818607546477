import React from "react";
import { useQuery, useSubscription } from "@apollo/client";
import { GET_CLIENT_CUSTOMISATIONS } from "../../apollo/operations/queries/serverQueries";
import LoaderHandler from "../shared/LoaderHandler";
import {
  CREATE_CUSTOMIZATION_SUBSCRIPTION,
  EDIT_CUSTOMIZATION_SUBSCRIPTION,
  DELETE_CUSTOMIZATION_SUBSCRIPTION,
} from "../../apollo/operations/subscriptions/subscriptions";
import { ENV_VARS } from "../../config/config";

interface Props {
  children: any;
}

const MenuBriefInitialQuery = ({ children }) => {
  const { loading, error } = useQuery(GET_CLIENT_CUSTOMISATIONS);

  return (
    <LoaderHandler loading={loading} error={error}>
      {children}
    </LoaderHandler>
  );
};

const MenuBriefSubscription = () => {
  useSubscription(CREATE_CUSTOMIZATION_SUBSCRIPTION, {
    variables: { clientId: ENV_VARS.CLIENT_ID },
  });
  useSubscription(EDIT_CUSTOMIZATION_SUBSCRIPTION, {
    variables: { clientId: ENV_VARS.CLIENT_ID },
  });
  useSubscription(DELETE_CUSTOMIZATION_SUBSCRIPTION, {
    variables: { clientId: ENV_VARS.CLIENT_ID },
  });

  return null;
};

const MenuBriefFromCacheOrNetwork = ({ children }) => {
  const { data, loading, error } = useQuery(GET_CLIENT_CUSTOMISATIONS);

  return (
    <LoaderHandler loading={loading} error={error}>
      {children(data?.listClientCustomisations?.items || {})}
    </LoaderHandler>
  );
};

export {
  MenuBriefInitialQuery,
  MenuBriefSubscription,
  MenuBriefFromCacheOrNetwork,
};
