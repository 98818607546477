import React, { useEffect } from "react";
import { useQuery } from "@apollo/client";
import { GET_RESTAURANT_DATA } from "../../../apollo/operations/queries/serverQueries";
import { StoreGroupType, StoreType } from "../../../models/stores";
import StoreGroupsAutocomplete from "../../shared/StoreGroupsAutocomplete";
import StoresAutocomplete from "../../shared/StoresAutocomplete";
import { GET_SELECTED_GROUPS } from "../../../apollo/operations/queries/localQueries";
import { selectedGroupsVar } from "../../../apollo/cache";
import { StoreGroupsDataFromCacheOrNetwork } from "../../Providers/initalQueryProvider";

import style from "./styles/PublishModal.module.scss";

type Props = {
  menuKey: string;
  selectedStores: string[];
  selectStores: (stores: string[]) => void;
  selectedGroups: string[];
  selectGroups: (groups: string[]) => void;
};

const SelectedStoresAndGroups: React.FC<Props> = ({
  menuKey,
  selectedStores,
  selectStores,
  selectGroups,
  selectedGroups,
}) => {
  const { data } = useQuery(GET_RESTAURANT_DATA);
  const groups = useQuery(GET_SELECTED_GROUPS);

  const getSelectedGroups = (groupsParam: StoreGroupType[]) => {
    selectedGroupsVar(groupsParam.map((group) => group.title));
  };

  useEffect(() => {
    if (data.listRestaurantData.items) {
      const restaurantsWithMenu = data.listRestaurantData.items
        .filter((restaurant: StoreType) => restaurant.masterMenu === menuKey)
        .map((restaurant: StoreType) => restaurant.uuid);

      selectStores(restaurantsWithMenu);
    }
  }, [data, menuKey, selectStores]);

  useEffect(() => {
    if (groups.data.selectedGroups) {
      selectGroups(groups.data.selectedGroups);
    }
  }, [groups.data, selectGroups]);
  return (
    <>
      <h5 className={style.contentHeading}>Selected groups to push to: </h5>
      <div className={style.inputContainer}>
        <StoreGroupsDataFromCacheOrNetwork>
          {(groupsParam: StoreGroupType[]) => {
            /**
             * Add array.filter() with araay.map()
             * This is a workaround to remove undefined values from the array
             */
            const selectedGroupsToPass = selectedGroups
              .map((groupName: string) => {
                return groupsParam.find((group) => group.title === groupName);
              })
              .filter((group) => group !== undefined);

            return (
              <StoreGroupsAutocomplete
                getSelectedGroups={getSelectedGroups}
                selectedGroups={selectedGroupsToPass as StoreGroupType[]}
                storeGroups={groupsParam}
              />
            );
          }}
        </StoreGroupsDataFromCacheOrNetwork>
      </div>
      <h5 className={style.contentHeading}>Selected stores to push to: </h5>
      <div className={style.inputContainer}>
        <StoresAutocomplete
          getSelectedStores={selectStores}
          selectedStores={selectedStores}
        />
      </div>
    </>
  );
};

export default SelectedStoresAndGroups;
