/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import IconButton from "@material-ui/core/IconButton";
import {
  addedOptionIdsVar,
  jsonVar,
  snackbarStateVar,
} from "../../../../apollo/cache";
import {
  addGroupToModifiers,
  changeItemInJsonItems,
  deleteOptionFromModifier,
} from "../../../../apollo/operations/actions/actions";
import TitleWithInput from "../../../shared/TitleWithInput";
import { helpReorderList } from "../../../MenuManagement/helpers/reorderListHelpers";
import deleteIcon from "../../../../media/images/ic-delete.svg";
import { MenuItemWithOptions } from "../../../../models/menu";
import {
  ModifierOption,
  MenuItem,
  MenuModifierGroup,
  ModifierOptionsCollection,
} from "../../../../models/json";

import { openItemModal } from "../../../../helpers/menu";
import UndoOptionButton from "./UndoOptionButton";

import style from "./styles/ModifierGroups.module.scss";
import { updatePricesOnRemoveOption } from "../../../MenuManagement/helpers/MealSetPricing";

type Props = {
  modifier: MenuModifierGroup;
  isToggled: boolean;
  category: string;
  menuItem: MenuItem;
};

const ModifierOptions: React.FC<Props> = ({
  modifier,
  isToggled,
  category,
  menuItem,
}) => {
  const [modifierOptions, updateModifierOptions] = useState(
    modifier.modifier_options
  );
  const openModal = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    item: MenuItemWithOptions
  ) => {
    if (
      (e.target as HTMLDivElement).tagName !== "IMG" &&
      (e.target as HTMLDivElement).tagName !== "BUTTON" &&
      (e.target as HTMLDivElement).tagName !== "SPAN" &&
      (e.target as HTMLDivElement).tagName !== "FORM" &&
      (e.target as HTMLDivElement).tagName !== "INPUT" &&
      (e.target as HTMLDivElement).className !== "native"
    ) {
      openItemModal(item, category);
    }
  };

  const reorderList = (result: {
    source: { index: number };
    destination: { index: number };
  }) => {
    const destinationIndex = !result.destination
      ? result.source.index
      : result.destination.index;
    const itemsList = helpReorderList(
      result.source.index,
      destinationIndex,
      Object.values(modifierOptions)
    ) as ModifierOption[];
    let itemsCollection = {} as ModifierOptionsCollection;
    for (let item of itemsList) {
      itemsCollection[item.id] = item;
    }
    updateModifierOptions(itemsCollection);
    addGroupToModifiers({
      ...modifier,
      modifier_options: itemsCollection,
    });
  };

  const handleDelete = (item: MenuItemWithOptions, isUndo?: boolean) => {
    const newModifier = deleteOptionFromModifier(item, modifier.id, isUndo);
    updateModifierOptions(newModifier.modifier_options);
    addGroupToModifiers(newModifier);
    addedOptionIdsVar(
      addedOptionIdsVar().filter((id: string) => id !== item.id)
    );
    updatePricesOnRemoveOption(
      newModifier.modifier_options,
      modifier.id,
      menuItem.id
    );
  };

  const updateOptionTitle = (item: MenuItemWithOptions) => {
    changeItemInJsonItems(item);
  };

  useEffect(() => {
    updateModifierOptions(modifier.modifier_options);
  }, [modifier]);

  return (
    <DragDropContext onDragEnd={reorderList}>
      <Droppable droppableId="droppable" isDropDisabled={false}>
        {(provided: any, snapshot: any) => {
          return (
            <div ref={provided.innerRef} className={style.droppableModifiers}>
              {isToggled
                ? Object.entries(modifierOptions).map(
                    ([key, option], index) => {
                      const item = jsonVar().items[option.id];

                      if (!item) {
                        snackbarStateVar({
                          open: true,
                          message: "Item not Found",
                        });
                        return null;
                      }
                      let matchingOverrides = item.price_info.overrides.filter(
                        (overrideObject) =>
                          overrideObject.context_type === "MODIFIER_GROUP" &&
                          overrideObject.context_value === modifier.id
                      );
                      let priceSurcharge = 0;
                      if (matchingOverrides.length > 0)
                        priceSurcharge = matchingOverrides[0].price;
                      return (
                        <Draggable
                          key={item?.id}
                          draggableId={item?.id}
                          index={index}
                          isDragDisabled={false}
                        >
                          {(providedParam: any) => (
                            <div
                              ref={providedParam.innerRef}
                              className={style.modifierOptionContainer}
                              onClick={(e) => openModal(e, item)}
                              {...providedParam.draggableProps}
                              {...providedParam.dragHandleProps}
                            >
                              <div className="row" style={{ width: "100%" }}>
                                <div className={style.mainColumn}>
                                  <span className={style.modifierOption}>
                                    <TitleWithInput
                                      item={item}
                                      style={style}
                                      stateUpdate={updateOptionTitle}
                                    />
                                  </span>
                                  <p>
                                    {item.description &&
                                      item.description.translations.en_uk}
                                  </p>
                                </div>
                                <div className={style.leftColumn}>
                                  <span className={style.priceSurchargText}>
                                    {priceSurcharge > 0 &&
                                      `+ £${Number(
                                        priceSurcharge / 100
                                      ).toFixed(2)}`}
                                  </span>
                                  <div>
                                    <IconButton
                                      aria-label="delete"
                                      className={style.actionIcon}
                                      onClick={() => handleDelete(item)}
                                    >
                                      <img
                                        className={style.deleteOptionIcon}
                                        src={deleteIcon}
                                        alt="delete"
                                      />
                                    </IconButton>
                                    <UndoOptionButton
                                      item={item}
                                      modifier={modifier}
                                      categoryTitle={category}
                                      handleDelete={handleDelete}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className={style.modifierOptionStroke} />
                            </div>
                          )}
                        </Draggable>
                      );
                    }
                  )
                : null}
              {provided.placeholder}
            </div>
          );
        }}
      </Droppable>
    </DragDropContext>
  );
};

export default ModifierOptions;
