import React, { useEffect, useState } from "react";
import axios from "axios";
import qs from "qs";
import { useQuery } from "@apollo/client";
import FadeLoader from "react-spinners/FadeLoader";
import { GET_IS_AUTH } from "../../apollo/operations/queries/localQueries";
import { isAuthenticatedVar, userGroupVar } from "../../apollo/cache";
import { ENV_VARS } from "../../config/config";

const AuthChecker = ({ children }: any) => {
  const { data } = useQuery(GET_IS_AUTH);
  const [token, setToken] = useState<string | null>(
    localStorage.getItem("token")
  );
  const setUserGroup = (token: string) => {
    const decodedToken = JSON.parse(atob(token.split(".")[1]));
    userGroupVar(decodedToken["cognito:groups"][0]);
  };

  useEffect(() => {
    const codesArray = window.location.search
      .replace("?code=", "")
      .replace("state=", "")
      .split("&");
    const code = codesArray[0];
    const randomCode = codesArray[1];

    if (localStorage.getItem("randomCode") && !randomCode) {
      localStorage.removeItem("randomCode");
    }

    if (localStorage.getItem("randomCode") === randomCode) {
      axios({
        method: "post",
        url: `${ENV_VARS.AUTH_URL}/token`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "no-cache",
          Accept: "*/*",
        },
        data: qs.stringify({
          grant_type: "authorization_code",
          code,
          client_id: ENV_VARS.AUTH_CLIENT_ID,
          redirect_uri: ENV_VARS.REDIRECT_URI,
          token_endpoint: `${ENV_VARS.AUTH_URL}/token`,
        }),
      })
        .then((data: any) => {
          isAuthenticatedVar(true);
          localStorage.setItem("token", data.data.access_token);
          localStorage.setItem("id_token", data.data.id_token);
          localStorage.removeItem("randomCode");
          setToken(data.data.access_token);
          setUserGroup(data.data.id_token);
        })
        .catch((err) => console.log(err));
    }
    if (token) {
      setUserGroup(token);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!data.isAuthenticated) {
    return (
      <div className="app-loader">
        <FadeLoader
          height={10}
          width={10}
          radius={100}
          margin={4}
          color="#336B6B"
          loading={!data.isAuthenticated}
        />
      </div>
    );
  }
  return token && children;
};

export default AuthChecker;
