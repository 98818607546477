/* eslint-disable react/require-default-props */
import React from "react";
import { Switch } from "@material-ui/core";
import { useCustomizationsState } from "../CustomizationsProvider";
import { CUSTOMIZATION } from "../utils/config";

import style from "../styles/CustomizationModal.module.scss";

type Props = {
  name: string;
  moreInfoComponent?: JSX.Element;
};

const CustomizationsToggle: React.FC<Props> = ({ name, moreInfoComponent }) => {
  const { state, dispatch } = useCustomizationsState();

  const toggleDispatch = (
    e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
    child: React.ReactNode
  ): void => {
    dispatch({
      type: e.target.name as CUSTOMIZATION,
      payload: !state[name],
    });
  };

  return (
    <>
      <span className={style.inputLabel}>
        {name === CUSTOMIZATION.GLOBAL ? "apply to all stores" : name}
        {moreInfoComponent}
      </span>
      <div className={style.inputContainer}>
        <Switch
          checked={state[name]}
          onChange={toggleDispatch}
          color="primary"
          name={name}
          inputProps={{ "aria-label": "primary checkbox" }}
        />
      </div>
    </>
  );
};

export default CustomizationsToggle;
