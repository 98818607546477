/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect, useRef } from "react";
import produce from "immer";
import { jsonVar } from "../../../apollo/cache";
import { MenuCategory } from "../../../models/json";
import { MenuCategoryWithOptions } from "../../../models/menu";
import EmojiPicker from "emoji-picker-react";
import useOutsideAlerter from "../../../hooks/useOutsideAlerter";
import insertEmojiIcon from "../../../media/images/insert-emoji-icon.svg";

import style from "./styles/CategoryList.module.scss";

type Props = {
  category: MenuCategory | MenuCategoryWithOptions;
  isReadOnly: boolean;
};

const emojiPickerStyle = {
  zIndex: "1",
  position: "absolute",
  height: "280px",
  right: "0",
};

const CategoryDescription = ({ category, isReadOnly }: Props) => {
  const [isInput, toggleInput] = useState(false);
  const categoryDesc = isReadOnly
    ? (category as MenuCategoryWithOptions).description
    : (category as MenuCategory).subtitle?.translations.en_uk;
  const [inputValue, setInputValue] = useState(categoryDesc);
  const [isEmojiPicker, toggleEmojiPicker] = useState(false);
  const wrapperRef = useRef(null);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputValue(e.target.value);
  };

  const applyCategoryChange = () => {
    jsonVar(
      produce(jsonVar(), (draftJson: any) => {
        draftJson.categories[category.title.translations.en_uk] = {
          ...category,
          subtitle: {
            translations: {
              en_uk: inputValue,
            },
          },
        };
      })
    );

    toggleInput(false);
  };

  const handleEnter = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      applyCategoryChange();
    }
  };

  const handleEmojiClick = (e, emojiObject) => {
    setInputValue(`${inputValue}${emojiObject.emoji}`);
  };

  const handleEmojiToggle = (e: React.MouseEvent<HTMLImageElement>) => {
    e.preventDefault();
    toggleEmojiPicker(!isEmojiPicker);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e && e.preventDefault();
    applyCategoryChange();
  };

  useOutsideAlerter(wrapperRef, isEmojiPicker || isInput, handleSubmit);

  useEffect(() => {
    setInputValue(categoryDesc);
  }, [categoryDesc]);

  return (
    <div ref={wrapperRef}>
      {isInput && (
        <div className={style.itemDesc} onKeyDown={handleEnter}>
          <form onBlur={handleSubmit} style={{ position: "relative" }}>
            <textarea
              value={inputValue}
              onChange={handleChange}
              style={{ width: "100%" }}
            />
            <img
              src={insertEmojiIcon}
              alt="Insert emoji"
              onClick={handleEmojiToggle}
              style={{
                cursor: "pointer",
                zIndex: 100,
                position: "absolute",
                bottom: "12px",
                right: "6px",
              }}
            />
          </form>
          {isEmojiPicker && (
            <EmojiPicker
              onEmojiClick={handleEmojiClick}
              pickerStyle={emojiPickerStyle}
              // disableSearchBar={true}
              disableSkinTonePicker={true}
              preload
              native
            />
          )}
        </div>
      )}

      {!isInput && (
        <p className={style.itemDesc} onClick={() => toggleInput(true)}>
          {inputValue}
        </p>
      )}
    </div>
  );
};

export default CategoryDescription;
