import React, { memo } from "react";
import { useQuery } from "@apollo/client";
import Paper from "@material-ui/core/Paper";
import { v4 as uuid } from "uuid";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { StoreType } from "../../../models/stores";
import StoreListPagination from "./StoreListPagination";
import {
  GET_SELECTED_CHANNEL,
  GET_STORE_LIST_PAGINATION,
  GET_STORE_SEARCH_VALUE,
} from "../../../apollo/operations/queries/localQueries";
import useStyles from "./useStylesHours";
import { OPENING_HOURS_HEADERS } from "../../../constants/constants";
import StoreRow from "./StoreRow";

import style from "./styles/OpeningHours.module.scss";
import { TableFooter } from "@material-ui/core";

type Props = {
  openingHoursData: StoreType[];
};

const OpeningHoursTable = memo(({ openingHoursData }: Props) => {
  const classes = useStyles();
  const {
    data: { selectedChannel },
  }: any = useQuery(GET_SELECTED_CHANNEL);
  const {
    data: { storeSearch },
  }: any = useQuery(GET_STORE_SEARCH_VALUE);
  const {
    data: { storeListPagination },
  }: any = useQuery(GET_STORE_LIST_PAGINATION);

  const getStoresToDisplay = (
    openingHoursDataParam: StoreType[]
  ): StoreType[] => {
    if (storeSearch) {
      return openingHoursDataParam.filter(
        (item: StoreType) =>
          item.restaurantName &&
          item.restaurantName.toLowerCase().includes(storeSearch)
      );
    }

    return openingHoursData;
  };

  const storesToDisplay = getStoresToDisplay(openingHoursData);
  return (
    <Paper className={classes.root} elevation={0}>
      <div className={style.currentChannel}>{selectedChannel}</div>
      <TableContainer className={classes.container}>
        <Table aria-label="sticky table">
          <TableHead>
            <TableRow>
              {OPENING_HOURS_HEADERS.map(
                (header: { label: string; minWidth: number }) => (
                  <TableCell
                    align="center"
                    style={{ minWidth: header.minWidth }}
                    key={uuid()}
                  >
                    <span>{header.label}</span>
                  </TableCell>
                )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {storesToDisplay.length <= 10 &&
              storesToDisplay.map((store: StoreType) => (
                <StoreRow store={store} key={uuid()} />
              ))}

            {storesToDisplay.length > 10 &&
              storesToDisplay
                .slice(storeListPagination.start, storeListPagination.end)
                .map((store: StoreType) => (
                  <StoreRow store={store} key={uuid()} />
                ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              {storesToDisplay.length > 10 && (
                <StoreListPagination storesLength={storesToDisplay.length} />
              )}
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Paper>
  );
});

export default OpeningHoursTable;
