/* eslint-disable no-nested-ternary */
import React, { useRef, useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import { FileDrop } from "react-file-drop";
import { GET_UPLOAD_URL } from "../../../apollo/operations/queries/serverQueries";
import syncIcon from "../../../media/images/ic-modal-sync.svg";
import syncFileIcon from "../../../media/images/ic-sync-menu-file.svg";
import ModalHeader from "../shared/ModalHeader";
import { ENV_VARS, MODAL_OPEN } from "../../../config/config";
import uploadFileToBucket from "../../../menuApi/uploadFileToBucket";
import { STATUS } from "../../../constants/constants";
import { openModalVar } from "../../../apollo/cache";
import ActionButtons from "./ActionButtons";

import style from "./styles/SyncModal.module.scss";

type Props = {
  isOpen: boolean;
};

const SyncModal = ({ isOpen }: Props) => {
  const [posKey, setPosKey] = useState<string>("");
  const [uploadMessage, setUploadMessage] = useState(STATUS.START);
  const [file, setFile] = useState({} as any);
  const [getUploadUrl, { data, error }] = useLazyQuery(GET_UPLOAD_URL, {
    fetchPolicy: "network-only",
  });

  const fileInputRef = useRef(null);

  const handleFilesFromInput = (files) => {
    setUploadMessage(STATUS.START);
    if (files && files.length > 0) {
      const fileInstance = files[0];
      setFile(fileInstance);
      uploadFile(fileInstance);
    }
  };

  const onInputClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleFilesFromInput(e.target.files);
  };

  const onTargetClick = () => {
    (fileInputRef.current as any).click();
  };

  const uploadFile = (fileParam: any) => {
    const params = {
      assetType: "xml",
      clientId: ENV_VARS.CLIENT_ID,
      fileName: fileParam.name,
      fileType: fileParam.type,
    };
    getUploadUrl({ variables: params });
  };

  useEffect(() => {
    if (error) {
      setUploadMessage(STATUS.FAILED);
    }

    if (data && data.uploadUrl) {
      const params = {
        uploadUrl: data.uploadUrl,
        file,
        fileName: file.name,
        setUploadMessage,
      };
      uploadFileToBucket(params);
      setPosKey(data.uploadUrl.posKey);
    }
  }, [data, error, file]);

  const uploadPercentage =
    uploadMessage === STATUS.PENDING
      ? 50
      : uploadMessage === STATUS.FULFILLED
      ? 100
      : 0;

  const closeModal = () => {
    openModalVar(MODAL_OPEN.NONE);
  };
  const handleClose = (_event: any, reason: string) => {
    if (reason === "backdropClick") {
      return false;
    }

    closeModal();
  };
  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="Sync menus"
        open={isOpen}
        maxWidth="md"
        fullWidth
      >
        <ModalHeader onClose={closeModal} icon={syncIcon} title="Sync menus" />
        <MuiDialogContent>
          <div className={style.modalContentContainer}>
            <div className={style.uploadContainer}>
              <div className={style.fileUploadZone}>
                <input
                  onChange={onInputClick}
                  ref={fileInputRef}
                  type="file"
                  className={style.hidden}
                />
                <FileDrop
                  className={style.dropZone}
                  targetClassName={style.dropTarget}
                  draggingOverTargetClassName={style.activeDropTarget}
                  onDrop={(files, e) => handleFilesFromInput(files)}
                  onTargetClick={onTargetClick}
                  children={undefined}
                />
              </div>
            </div>
            <div className={style.fileUploadItem}>
              <img src={syncFileIcon} alt="upload file icon" />
              <div className={style.uiFlexGrow} style={{ padding: "0 15px" }}>
                <p
                  className={style.uiJustifySpaceBetween}
                  style={{ fontSize: 12, fontWeight: 500, margin: "6px 0 8px" }}
                >
                  {file.name} <span>{uploadPercentage}%</span>
                </p>
                <div
                  style={{
                    backgroundColor: "#8EE5AE1A",
                    borderRadius: 4,
                    overflow: "hidden",
                  }}
                >
                  <span
                    style={{
                      display: "block",
                      backgroundColor: "#328277",
                      height: 4,
                      width: `${uploadPercentage}%`,
                      transition: `all ${uploadPercentage !== 0 ? 2 : 0}s`,
                    }}
                  />
                </div>
              </div>
            </div>
            <div className={style.fileUploadItem}>
              {uploadMessage === STATUS.FAILED && (
                <span>
                  Please select a valid file. Only XML files are permitted.
                </span>
              )}

              {uploadMessage === STATUS.FULFILLED && (
                <span>Your file has been succesfully uploaded!</span>
              )}
            </div>
          </div>
        </MuiDialogContent>
        <MuiDialogActions>
          <div className={style.uiModalAction}>
            <ActionButtons posKey={posKey} status={uploadMessage} />
          </div>
        </MuiDialogActions>
      </Dialog>
    </div>
  );
};

export default SyncModal;
