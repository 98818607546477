import React, { forwardRef, useState } from "react";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
  Divider,
} from "@material-ui/core";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import { eo } from "date-fns/esm/locale";
import CheckIcon from "@material-ui/icons/Check";
import style from "./styles/TimingsDrawer.module.scss";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
type Props = {
  day: string;
  form: any;
  onFieldChange: any;
  updateAllFields: any;
  defaults: any;
};
type RadioValue = "custom" | "storeOpeningTime" | "storeClosingTime";
type RadioState = {
  startTime: RadioValue;
  endTime: RadioValue;
};
/**
 *
 * @param day - Day of the week, monday tuesday ...
 * @param form - Form Data Object
 * @param onFieldChange - function triggered onChange in TextField
 * @param updateAllFields - function triggerd onClick on Update All button
 * @param defaults - Object with default values for fields
 *
 * Adds Row of @TextFields for @startTime and @endTime in Form
 * If @param day is monday .. Add a button to update all as monday.
 */
/** */
const DailyTime = ({
  day,
  form,
  onFieldChange,
  updateAllFields,
  defaults,
}: Props) => {
  const ExampleCustomInput = forwardRef<any, { value: any; onClick: any }>(
    (props, ref) => (
      <Button
        variant="outlined"
        color="primary"
        onClick={props.onClick}
        endIcon={<ExpandMoreIcon />}
      >
        {props.value}
      </Button>
    )
  );
  const [radios, setRadios] = useState<RadioState>({
    startTime: "storeOpeningTime",
    endTime: "custom",
  });
  /**
   *
   * @param day day of the week
   * @param time startTime || endTime
   * @returns @string to be used as name for TextField
   */
  const getFieldName = (day: string, time: string) => {
    return `${day}-${time}`;
  };

  /**
   *
   * @param e event fired on radio Change
   */
  const handleRadioChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    onFieldChange(
      day,
      e.target.name,
      e.target.value === "custom" ? "00:00" : e.target.value
    );
    setRadios({ ...radios, [e.target.name]: e.target.value });
  };

  /**
   *
   * @param radio name of the radio passed
   * @returns value of radio to be used
   */
  const getValueForRadio = (radio: "endTime" | "startTime") => {
    if (form && form[day])
      if (radio === "startTime") {
        return form[day].startTime === "storeOpeningTime"
          ? "storeOpeningTime"
          : "custom";
      } else {
        return form[day].endTime === "storeClosingTime"
          ? "storeClosingTime"
          : "custom";
      }
  };

  const onTimeChange = (name, val) => {
    const day = name.split("-")[0];
    const time = name.split("-")[1];
    const date = new Date(val);
    //coverting to 24 hours format
    const dateString = date.toLocaleString("de-CH");

    const dateTime = dateString.split(",")[1].trim();
    const [hours, minutes] = dateTime.split(":");
    onFieldChange(day, time, hours + ":" + minutes);
  };

  const getValueToSet = (time: string) => {
    if (
      time &&
      time !== "storeOpeningTime" &&
      time !== "storeClosingTime" &&
      time !== "custom"
    ) {
      const hours = time.split(":")[0];
      const mins = time.split(":")[1];
      const date = new Date();
      date.setMinutes(Number(mins));
      date.setHours(Number(hours));
      return date;
    } else return new Date();
  };
  return (
    <>
      <Divider />
      <div className={style.dayLabel}>{day}</div>
      {day === "monday" && (
        <Button
          className={style.sameButton}
          variant="text"
          color="primary"
          size="small"
          disableElevation
          onClick={updateAllFields}
          startIcon={<CheckIcon />}
        >
          Apply down
        </Button>
      )}
      <div className={style.dayRow}>
        <div className={style.inputElementsStart}>
          <FormControl className={style.inputRadioGroup}>
            <FormLabel className={style.timeLabel}>Start Time</FormLabel>
            <RadioGroup
              value={getValueForRadio("startTime")}
              onChange={handleRadioChange}
              aria-label="start-time"
              name="startTime"
            >
              <FormControlLabel
                value="storeOpeningTime"
                control={<Radio size="small" color="primary" />}
                label="Store Opening Time"
              />
              <FormControlLabel
                value="custom"
                control={<Radio size="small" color="primary" />}
                label={
                  <DatePicker
                    className={style.timeField}
                    selected={getValueToSet(
                      form?.[day] ? form[day].startTime : defaults.startTime
                    )}
                    onChange={(date) =>
                      onTimeChange(getFieldName(day, "startTime"), date)
                    }
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={10}
                    locale={eo}
                    timeCaption="Time"
                    dateFormat="H:mm"
                    customInput={
                      <ExampleCustomInput
                        value={undefined}
                        onClick={undefined}
                      />
                    }
                  />
                }
              />
            </RadioGroup>
          </FormControl>
        </div>
        <div className={style.inputElementsEnd}>
          <FormControl className={style.inputRadioGroup}>
            <FormLabel className={style.timeLabel}>End Time</FormLabel>
            <RadioGroup
              onChange={handleRadioChange}
              value={getValueForRadio("endTime")}
              aria-label="end-time"
              name="endTime"
            >
              <FormControlLabel
                value="storeClosingTime"
                control={<Radio size="small" color="primary" />}
                label="Store Closing Time"
              />
              <FormControlLabel
                value="custom"
                control={<Radio size="small" color="primary" />}
                label={
                  <DatePicker
                    className={style.timeField}
                    selected={getValueToSet(
                      form?.[day] ? form[day].endTime : defaults.endTime
                    )}
                    onChange={(date) =>
                      onTimeChange(getFieldName(day, "endTime"), date)
                    }
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={10}
                    locale={eo}
                    timeCaption="Time"
                    dateFormat="H:mm"
                  />
                }
              />
            </RadioGroup>
          </FormControl>
        </div>
      </div>
    </>
  );
};
export default DailyTime;
/*
 */
