import React, { useState } from "react";
import { Button } from "@material-ui/core";
import { useMutation } from "@apollo/client";
import produce from "immer";
import { UPDATE_CLIENT_CONFIG } from "../../apollo/operations/mutations/mutations";
import { FormState } from "./SettingsForm";
import { ENV_VARS } from "../../config/config";

type Props = {
  formData: any;
  aggTemplate: string;
  form: FormState;
};

const SettingsFormButton = ({ formData, aggTemplate, form }: Props) => {
  const [isError, setIsError] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [updateConfig] = useMutation(UPDATE_CLIENT_CONFIG);

  const handleSave = () => {
    updateConfig({
      variables: {
        input: {
          clientId: ENV_VARS.CLIENT_ID,
          ...produce(formData, (newData) => {
            newData[aggTemplate] = form;
          }),
        },
      },
    })
      .then((res) => {
        if (res.data) {
          setIsSuccessful(true);
          setTimeout(() => {
            setIsSuccessful(false);
          }, 2500);
        }
      })
      .catch((err) => {
        if (err) {
          setIsError(true);
          setTimeout(() => {
            setIsError(false);
          }, 2500);
        }
      });
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={handleSave}
        style={{ margin: "10px auto 0" }}
      >
        Save
      </Button>

      {isSuccessful && <span>Succesfully edited!</span>}

      {isError && <span>Oops... something went wrong!</span>}
    </>
  );
};

export default SettingsFormButton;
