/* eslint-disable react/require-default-props */
import React from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { useQuery } from "@apollo/client";
import { v4 as uuid } from "uuid";
import {
  currentAggregatorVar,
  GET_CURRENT_AGGREGATOR,
  GET_IS_MASTER_MENU,
  GET_ROOT_MENU_DATA,
} from "../../apollo/cache";
import { AGGREGATORS } from "../../constants/constants";
import { loadNewMenu } from "../../helpers/menu";

type Props = {
  isMenuManagement?: boolean;
};

const AggregatorDropdown: React.FC<Props> = ({ isMenuManagement = false }) => {
  const { data } = useQuery(GET_IS_MASTER_MENU);
  const jsonMenus = useQuery(GET_ROOT_MENU_DATA);
  const agg = useQuery(GET_CURRENT_AGGREGATOR);

  const handleChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    currentAggregatorVar(e.target.value as AGGREGATORS);
    if (isMenuManagement)
      loadNewMenu(
        jsonMenus.data.rootMenuData,
        false,
        e.target.value as AGGREGATORS
      );
  };

  if (data.isMasterMenu && isMenuManagement) {
    return null;
  }

  return (
    <FormControl variant="outlined" color="primary">
      <InputLabel>Switch Channel</InputLabel>
      <Select
        value={agg.data.currentAggregator}
        onChange={handleChange}
        label="Switch Channel"
        displayEmpty
      >
        {Object.keys(AGGREGATORS)
          .map((key) => AGGREGATORS[key])
          .map((agg) => (
            <MenuItem value={agg} key={uuid()}>
              {agg}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default AggregatorDropdown;
