export const OPENING_HOURS_HEADERS: { label: string; minWidth: number }[] = [
  { label: "Stores", minWidth: 100 },
  { label: "Assigned Menu", minWidth: 100 },
  { label: "Monday", minWidth: 100 },
  { label: "Tuesday", minWidth: 100 },
  { label: "Wednesday", minWidth: 100 },
  { label: "Thursday", minWidth: 100 },
  { label: "Friday", minWidth: 100 },
  { label: "Saturday", minWidth: 100 },
  { label: "Sunday", minWidth: 100 },
  { label: "Actions", minWidth: 100 },
];

export enum STATUS {
  START = "start",
  PENDING = "pending",
  FULFILLED = "fulfilled",
  FAILED = "failed",
}

export enum CATEGORY_ITEM_PROPERTIES {
  TITLE = 0,
  EXTERNAL_DATA = 1,
  DESCRIPTION = 2,
  IMAGE_URL = 3,
  PRICE = 4,
  QUANTITY_INFO = 5,
  MODIFIER_GROUP_IDS = 6,
  TAX_INFO = 7,
  NUTRITIONAL_INFO = 8,
  EFFECTIVE_DATE = 9,
  EXPIRY_DATE = 10,
}

export enum ADD_NEW_TYPE {
  SINGLE = "single",
  SET = "set",
  CATEGORY = "category",
  MENU = "menu",
  MODIFIER = "modifier",
}

export enum SUSPEND_REPEAT {
  NEVER = "Never",
  DAILY = "Daily",
  WEEKDAYS = "Weekdays",
  WEEKENDS = "Weekends",
  MONDAY = "Monday",
  TUESDAY = "Tuesday",
  WEDNESDAY = "Wednesday",
  THURSDAY = "Thursday",
  FRIDAY = "Friday",
  SATURDAY = "Saturday",
  SUNDAY = "Sunday",
}

export enum AGGREGATORS {
  UBER_EATS = "UberEats",
  DELIVEROO = "Deliveroo",
  JUST_EAT = "JustEat",
}

export enum CHANGE_SIZE {
  PATCH = "Just a few textual / image changes",
  MINOR = "Introduction or removal of new menu items",
  MAJOR = "Major new release with lots of changes",
}

export enum NUTRITIONAL_LABEL_OPTIONS {
  VEGAN = "Vegan",
  VEGETARIAN = "Vegetarian",
  GLUTEN_FREE = "Gluten free",
  SPICY = "Spicy",
}
