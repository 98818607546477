import { useQuery } from "@apollo/client";
import { MenuItem, Select } from "@material-ui/core";
import React from "react";
import { areChangesSavedVar } from "../../../../apollo/cache";
import {
  addIdToChangedStores,
  updateItemInLocalOpeningHours,
} from "../../../../apollo/operations/actions/actions";
import { GET_SAVED_MENUS } from "../../../../apollo/operations/queries/serverQueries";
import { StoreType } from "../../../../models/stores";

type Props = {
  store: StoreType;
  handleSaveStatus: () => void;
};

const MasterMenusColumn = ({ store, handleSaveStatus }: Props) => {
  const { data } = useQuery(GET_SAVED_MENUS);

  const handleChange = (event: React.ChangeEvent<{ value: any }>) => {
    handleSaveStatus();
    addIdToChangedStores(store.uuid);

    const newStore: any = {
      ...store,
      masterMenu: event.target.value as string,
    };

    updateItemInLocalOpeningHours(newStore);
  };

  const handleBlur = () => {
    areChangesSavedVar(false);
  };

  if (!data) {
    return <span>{store.masterMenu ? store.masterMenu : "no menu"}</span>;
  }

  const options = [
    "no menu",
    ...data.getClientMenus.menus.map((menu) => menu.menuKey),
  ];

  return (
    <Select
      defaultValue={
        store.masterMenu && options.includes(store.masterMenu)
          ? store.masterMenu
          : "no menu"
      }
      onChange={handleChange}
      onBlur={handleBlur}
      variant="filled"
    >
      {options.map((option, key) => (
        <MenuItem key={key} value={option}>
          {option}
        </MenuItem>
      ))}
    </Select>
  );
};

export default MasterMenusColumn;
