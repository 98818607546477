import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import Button from "@material-ui/core/Button";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { UPDATE_STORE_GROUP } from "../../../../apollo/operations/mutations/mutations";
import { addStoreToExistingGroup } from "../../../../apollo/operations/actions/actions";
import { mapStoresToOptions } from "../../../../helpers/autocompletes";
import {
  GetStoreType,
  StoreGroupType,
  AutocompleteValueType,
} from "../../../../models/stores";
import style from "../styles/StoreGroups.module.scss";

export type Props = {
  stores: GetStoreType;
  group: StoreGroupType;
};

const AddStoreSearchbar: React.FC<Props> = ({ stores, group }) => {
  const [isInputVisible, toggleAddStoreInput] = useState(false);
  const [updateStoreGroup] = useMutation(UPDATE_STORE_GROUP);

  const addStoreToGroup = (storeId: string) => {
    const { id, ...noIdGroup } = addStoreToExistingGroup(storeId, group.title);
    if (noIdGroup) {
      updateStoreGroup({ variables: { input: noIdGroup } });
    }
  };

  const handleToggle = () => {
    toggleAddStoreInput(!isInputVisible);
  };

  const options = mapStoresToOptions(stores).filter(
    (store) => !group.storeIds.UberEats.includes(store.id)
  );
  return (
    <>
      <Button
        variant="contained"
        color="primary"
        disableElevation
        onClick={handleToggle}
      >
        Add Store
      </Button>
      {isInputVisible && (
        <div className={style.addStoreContainer}>
          <Autocomplete
            options={options}
            onChange={(
              event: any,
              value: string | AutocompleteValueType | null
            ) => {
              if (value) addStoreToGroup((value as AutocompleteValueType).id);
            }}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Stores"
                placeholder="Search Stores"
              />
            )}
          />
        </div>
      )}
    </>
  );
};

export default AddStoreSearchbar;
