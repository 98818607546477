/* eslint-disable react/require-default-props */
import React from "react";
import classnames from "classnames";
import { Button } from "@material-ui/core";
import { useQuery } from "@apollo/client";
import style from "./styles/WarningModal.module.scss";
import { GET_OPEN_MODAL, openModalVar } from "../../apollo/cache";
import { MODAL_OPEN } from "../../config/config";

type Props = {
  isOpen?: boolean;
  onClose?: () => void;
  modalMessage: string;
  onConfirm: () => void;
  confirmButtonText?: string;
};

const WarningModal: React.FC<Props> = ({
  isOpen,
  onClose,
  modalMessage,
  onConfirm,
  confirmButtonText,
}) => {
  const {
    data: { openModal },
  }: any = useQuery(GET_OPEN_MODAL);

  const handleConfirm = () => {
    onConfirm();
    handleClose();
  };

  const handleClose = () =>
    onClose ? onClose() : openModalVar(MODAL_OPEN.NONE);

  const isModalVisible =
    isOpen !== undefined ? isOpen : openModal === MODAL_OPEN.WARNING;
  const modalStyles = classnames(
    { [style.modalClosed]: !isModalVisible },
    { [style.modalOpened]: isModalVisible }
  );
  return (
    <div
      className={modalStyles}
      id="exampleModal"
      role="dialog"
      aria-labelledby="exampleModalLabel"
    >
      <div className={style.outerModal} role="document">
        <div className={classnames("modal-content", style.innerModal)}>
          <h4>{modalMessage}</h4>
          <div className={style.buttonsRow}>
            <Button
              color="primary"
              variant="contained"
              disableElevation
              onClick={handleConfirm}
            >
              {confirmButtonText || "Confirm"}
            </Button>
            <Button
              color="primary"
              variant="outlined"
              disableElevation
              onClick={handleClose}
            >
              Close
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WarningModal;
