import React from "react";
import FadeLoader from "react-spinners/FadeLoader";
import MuiDialogContent from "@material-ui/core/DialogContent";
import { Button } from "@material-ui/core";
import classnames from "classnames";
import { isScheduledPublishVar, openModalVar } from "../../../apollo/cache";
import ModalHeader from "../shared/ModalHeader";
import newIcon from "../../../media/images/ic-modal-new.svg";
import saveImage from "../../../media/images/img-temp-save.jpg";
import { STATUS } from "../../../constants/constants";
import { MODAL_OPEN } from "../../../config/config";
import style from "./styles/SaveStatusView.module.scss";

type Props = {
  savedMenuStatus: string;
  getSavedMenuStatus: (status: string) => void;
};

const SaveStatusView = ({ savedMenuStatus, getSavedMenuStatus }: Props) => {
  const handlePublishRedirect = () => {
    openModalVar(MODAL_OPEN.PUBLISH_MENU);
    isScheduledPublishVar(false);
  };

  const handleScheduleRedirect = () => {
    openModalVar(MODAL_OPEN.PUBLISH_MENU);
    isScheduledPublishVar(true);
  };

  const handleClose = () => {
    openModalVar(MODAL_OPEN.NONE);
    isScheduledPublishVar(false);
  };
  /**
   *  Checks if user is allowed to publish a newly saved menu
   * @returns {boolean} true or false based on @var userType
   */
  const isAllowedToPublish = () => {
    /**
     * TODO: Check if user is an Admin or SuperUser or if Publish Request has been approved
     *
     * default to false
     */
    return false;
  };

  /**
   * Requests Approval to publish a newly saved menu
   * @returns {void}
   */
  const requestApproval = () => {
    /**
     * TODO: Send Approval Request to Admin
     */
  };

  return (
    <div>
      <ModalHeader icon={newIcon} title="Proceed to publish menu" />
      <MuiDialogContent>
        <div className={style.uiModalContent}>
          <div className={style.innerContentContainer}>
            <img src={saveImage} alt="save icon" />

            <div>
              {savedMenuStatus === STATUS.PENDING && (
                <h1>Menu is being saved...</h1>
              )}

              {savedMenuStatus === STATUS.FULFILLED && (
                <h1>Menu has been saved successfully</h1>
              )}

              {savedMenuStatus === STATUS.FAILED && (
                <h1>Oops...Something went wrong!</h1>
              )}
            </div>

            <p>Do you want to continue to publish the menu?</p>

            <div className={style.spinnerContainer}>
              {savedMenuStatus === STATUS.PENDING ? (
                <FadeLoader
                  height={30}
                  width={30}
                  radius={15}
                  margin={10}
                  color="#336B6B"
                  loading={savedMenuStatus === STATUS.PENDING}
                />
              ) : null}
            </div>

            {savedMenuStatus === STATUS.FULFILLED && (
              <>
                <div
                  className={classnames(
                    style.uiModalAction,
                    style.noBorder,
                    style.uiJustifyCenter
                  )}
                >
                  <div className={style.buttons}>
                    <Button
                      onClick={handleClose}
                      color="primary"
                      variant="outlined"
                      size="large"
                    >
                      Not Now
                    </Button>
                    <Button
                      disabled={!isAllowedToPublish()}
                      onClick={handlePublishRedirect}
                      autoFocus
                      color="primary"
                      variant="contained"
                      disableElevation
                      size="large"
                    >
                      Publish
                    </Button>
                    {isAllowedToPublish() ? (
                      <Button
                        onClick={handleScheduleRedirect}
                        autoFocus
                        color="primary"
                        variant="contained"
                        disableElevation
                        size="large"
                      >
                        Schedule Publish
                      </Button>
                    ) : (
                      <Button
                        onClick={requestApproval}
                        autoFocus
                        color="primary"
                        variant="contained"
                        disableElevation
                        size="large"
                      >
                        Request Approval To Publish
                      </Button>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </MuiDialogContent>
    </div>
  );
};

export default SaveStatusView;
