import { ReactiveVar, makeVar, gql } from "@apollo/client";
import { Customization } from "../../../models/customizations";

export const customizationFields = {
  customizationToEdit: {
    read() {
      return customizationToEditVar();
    },
  },
};

export const customizationToEditVar: ReactiveVar<Customization> =
  makeVar<Customization>({} as Customization);
export const GET_CUSTOMIZATION_TO_EDIT = gql`
  query GetCustomizationToEdit {
    customizationToEdit @client
  }
`;
