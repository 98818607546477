import produce from "immer";
import { computeOriginalIndex } from "./reorderListHelpers";
import {
  addedOptionIdsVar,
  jsonVar,
  snackbarStateVar,
} from "../../../apollo/cache";
import { addItemIdToModifierGroup } from "../../../apollo/operations/actions/actions";
import { MenuCategory, ModifierOption } from "../../../models/json";
import { updatePriceOnAddOption } from "./MealSetPricing";
/**
 *
 * @param category - MenuCategory to find the ModifiergroupId in
 * @param categoryIndex - number Index of category in DND list
 * @param mealSetId - string EntityId in the provided category to match
 * @returns-string ModiferGroupId
 */
const findModifierGroupId = (
  category: MenuCategory,
  categoryIndex: number,
  mealSetId: string
): string => {
  const json = jsonVar();
  if (!json) {
    snackbarStateVar({
      open: true,
      message: "Json Empty",
    });
    return "";
  }
  if (category) {
    const categoryTitle = category.title.translations.en_uk;
    const categoryInObjet = json.categories[categoryTitle];
    if (!!categoryInObjet) {
      const item = json.items[mealSetId];
      return Object.values(item.modifier_group_ids.ids)[0];
    }
  } else {
    snackbarStateVar({
      open: true,
      message: "Category Not Provided",
    });
    return "";
  }

  //default return value
  return "";
};

const dragItemToMealSet = (
  numberOfItemsToDisplay: number,
  result: any,
  category: MenuCategory,
  categoryItems: ModifierOption[],
  categoryIndex: number,
  getUpdatedCategory: (
    index: number,
    items: ModifierOption[],
    category: MenuCategory
  ) => void
) => {
  const modifierGroupId = findModifierGroupId(
    category,
    categoryIndex,
    result.combine.draggableId
  );

  if (!modifierGroupId) return;

  addedOptionIdsVar([...addedOptionIdsVar(), result.draggableId]);
  addItemIdToModifierGroup(result.draggableId, modifierGroupId);
  const newItemsInCategory = produce(categoryItems, (draftItems) => {
    const sourceIndex = computeOriginalIndex(
      numberOfItemsToDisplay,
      result.source.droppableId,
      result.source.index
    );

    draftItems.splice(sourceIndex, 1);
  });

  getUpdatedCategory(categoryIndex, newItemsInCategory, category);

  let mealSetId = result.combine.draggableId;
  let options = jsonVar().modifier_groups[modifierGroupId].modifier_options;
  updatePriceOnAddOption(options, modifierGroupId, mealSetId);
};

export default dragItemToMealSet;
