import React, { useState } from "react";
import { useMutation, useReactiveVar } from "@apollo/client";
import { MenuItem, Select } from "@material-ui/core";
import { updateItemInLocalOpeningHours } from "../../../apollo/operations/actions/actions";

import { StoreType } from "../../../models/stores";
import WarningModal from "../../shared/WarningModal";
import { UPDATE_RESTAURANT_DATA } from "../../../apollo/operations/mutations/mutations";
import { savedMenusVar } from "../../../apollo/cache";

type Props = {
  store: StoreType;
};

const MasterMenuDropdown = ({ store }: Props) => {
  const [isModalOpen, openModal] = useState(false);
  const [updatedStore, setUpdatedStore] = useState({} as StoreType);
  /**
   * Adding a refecth query option to update queries after mutiton
   */
  const [updateRestaurantData] = useMutation(UPDATE_RESTAURANT_DATA);
  const menus = useReactiveVar(savedMenusVar);

  const handleChange = (event: React.ChangeEvent<{ value: any }>) => {
    const newStore: any = {
      ...store,
      masterMenu: event.target.value as string,
    };

    setUpdatedStore(newStore);
    updateItemInLocalOpeningHours(newStore);
  };

  const handleSave = () => {
    updateRestaurantData({
      variables: { updaterestaurantdatainput: updatedStore },
    });
  };

  if (!menus) {
    return <span>{store.masterMenu ? store.masterMenu : "no menu"}</span>;
  }
  const options = ["no menu", ...menus.map((menu) => menu.menuKey)];
  return (
    <>
      <Select
        defaultValue={
          store.masterMenu && options.includes(store.masterMenu)
            ? store.masterMenu
            : "no menu"
        }
        onChange={handleChange}
        variant="filled"
      >
        {options.map((option) => (
          <MenuItem key={option} value={option} onClick={() => openModal(true)}>
            {option}
          </MenuItem>
        ))}
      </Select>

      {isModalOpen && (
        <WarningModal
          isOpen={isModalOpen}
          onClose={() => openModal(false)}
          modalMessage="Do you wish to save the changes to that store?"
          onConfirm={handleSave}
          confirmButtonText="Save"
        />
      )}
    </>
  );
};

export default MasterMenuDropdown;
