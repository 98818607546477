import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import Paper from "@material-ui/core/Paper";
import { v4 as uuid } from "uuid";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { TableFooter, TablePagination } from "@material-ui/core";
import TablePaginationActions from "@material-ui/core/TablePagination/TablePaginationActions";
import useStyles from "../../OpeningHours/OpeningHoursTable/useStylesHours";
import MissionControlTableRow from "./MissionControlTableRow";
import StoreSearchbar from "../../shared/StoreSearchbar";
import { GET_STORE_SEARCH_VALUE } from "../../../apollo/operations/queries/localQueries";

import style from "./styles/MissionControlTable.module.scss";
/* 
type Props = {
    posStores: StoreType[]
}; */

const STATIC_HEADERS = [
  "Store",
  "Menu",
  "Last checked",
  "Auto Accept",
  "Online",
  "Store ID",
  "POS Integration",
  "Action",
];

const MissionControlTable = ({ posStores }) => {
  const classes = useStyles();
  const search = useQuery(GET_STORE_SEARCH_VALUE);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const storesToDisplay =
    posStores && search.data.storeSearch
      ? posStores.filter(
          (item) =>
            item &&
            item.restaurantName &&
            item.restaurantName.toLowerCase().includes(search.data.storeSearch)
        )
      : posStores;

  return (
    <div className={style.missionControlContainer}>
      <div style={{ paddingBottom: 50, margin: "0 0 0 auto" }}>
        <StoreSearchbar />
      </div>
      <Paper className={classes.root} elevation={0}>
        <TableContainer className={classes.container}>
          <Table aria-label="sticky table">
            <TableHead>
              <TableRow>
                {STATIC_HEADERS.map((title) => (
                  <TableCell align="center" key={title}>
                    {title}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {storesToDisplay &&
                storesToDisplay
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((store, index) => (
                    <MissionControlTableRow
                      store={store}
                      index={index}
                      key={uuid()}
                    />
                  ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                {storesToDisplay && storesToDisplay.length > 10 && (
                  <TablePagination
                    rowsPerPageOptions={[10, 20]}
                    colSpan={3}
                    count={storesToDisplay.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: { "aria-label": "rows per page" },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                )}
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
};

export default MissionControlTable;
