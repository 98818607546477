import React, { useImperativeHandle, useState } from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import { openModalVar } from "../../../../apollo/cache";
import deleteIcon from "../../../../media/images/ic-delete.svg";
import { MODAL_OPEN } from "../../../../config/config";
import CollectionHeader from "./CollectionHeader";
import { ProductCollection } from "../..";
import ItemInCollection from "./ItemInCollection";
import { useMutation } from "@apollo/client";
import { DELETE_ITEM_COLLECTION } from "../../../../apollo/operations/mutations/mutations";
import AddItemInCollection from "./AddItemInCollection";
import { v4 as uuid } from "uuid";

import style from "../styles/CollectionsList.module.scss";

interface Props {
  collection: ProductCollection;
  deleteCollectionRef: any;
}

const useStyles = makeStyles(() => ({
  expandIcon: {
    "&$expanded": {
      transform: "rotate(90deg)",
    },
  },
  expanded: {},
}));

const Collection: React.FC<Props> = ({ collection, deleteCollectionRef }) => {
  const classes = useStyles();
  const [collectionToDelete, setCollectionToDelete] = useState<any>({} as any);
  const [deleteItemCollection] = useMutation(DELETE_ITEM_COLLECTION);
  const [visibleCollections, setVisibleCollections] = useState([] as any);

  const handleToggle = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const eventTarget: HTMLDivElement = e.target as HTMLDivElement;
    if (
      eventTarget.className === style.searchInput ||
      eventTarget.className === style.searchedStores ||
      eventTarget.className === style.storeOption ||
      eventTarget.className === "fas fa-plus-circle" ||
      eventTarget.className === style.deleteButton ||
      eventTarget.className === "fas fa-trash-alt"
    ) {
      return;
    }

    if (!visibleCollections.includes(collection.title)) {
      setVisibleCollections([...visibleCollections, collection.title]);
    } else {
      const newCollections = [...visibleCollections];
      newCollections.splice(visibleCollections.indexOf(collection.title), 1);
      setVisibleCollections(newCollections);
    }
  };

  const handleDelete = () => {
    setCollectionToDelete(collection);
    openModalVar(MODAL_OPEN.WARNING);
  };

  const deleteCollection = (collection: ProductCollection) => {
    const { id } = collection;
    deleteItemCollection({
      variables: { id },
      update(cache) {
        const normalizedId = cache.identify({
          id,
          __typename: "ItemCollection",
        });
        cache.evict({ id: normalizedId });
        cache.gc();
      },
    });
  };

  useImperativeHandle(deleteCollectionRef, () => ({
    deleteCollection,
    collectionToDelete,
  }));

  return (
    <div key={collection.id} className={style.menuCard}>
      <div className={style.menuCardOuter}>
        <div className={style.menuCardBody}>
          <CollectionHeader
            collection={collection}
            deleteIconComponent={
              <IconButton
                aria-label="delete"
                className={style.itemDelete}
                onClick={handleDelete}
              >
                <img src={deleteIcon} alt="delete icon" />
              </IconButton>
            }
          />
          <div className={style.menuCardBodyAccordion}>
            <Accordion elevation={0} TransitionProps={{ unmountOnExit: true }}>
              <AccordionSummary
                classes={{
                  expandIcon: classes.expandIcon,
                  expanded: classes.expanded,
                }}
                expandIcon={<ChevronRightIcon />}
                onClick={handleToggle}
              />
              <AccordionDetails className={style.menuCardBodyAccordionDetail}>
                <div className={style.menuCardBodyAccordionDetailTop}>
                  <h3>Current Items</h3>
                  <AddItemInCollection collection={collection} />
                </div>
                <div className={style.menuCardBodyAccordionMeals}>
                  {visibleCollections.includes(collection.title) && (
                    <>
                      {collection.itemIds.map((id) => (
                        <ItemInCollection
                          collection={collection}
                          itemId={id}
                          key={uuid()}
                        />
                      ))}
                    </>
                  )}
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Collection;
