import React from "react";
import { useQuery } from "@apollo/client";
import { GET_CLIENT_CONFIG } from "../../apollo/operations/queries/serverQueries";
import SettingsForm from "./SettingsForm";

const Settings = () => {
  const { data } = useQuery(GET_CLIENT_CONFIG);

  return (
    <SettingsForm
      formData={data?.getClientConfig ? data.getClientConfig : {}}
    />
  );
};

export default Settings;
