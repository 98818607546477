import React, { useState } from "react";
import Paper from "@material-ui/core/Paper";
import { v4 as uuid } from "uuid";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import { TablePagination } from "@material-ui/core";
import useStyles from "../../OpeningHours/OpeningHoursTable/useStylesHours";
import AlertListControlTableRow from "./AlertListControlTableRow";

import style from "./styles/AlertListControlTable.module.scss";
import TableHeader from "./TableHeader";

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const getComparator = (order, orderBy) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const sortedRowInformation = (rowArray, comparator) => {
  const stabilizedThis = rowArray.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

const AlertListControlTable = ({ alertList }) => {
  const classes = useStyles();
  const storesToDisplay = alertList;
  const [orderDirection, setOrderDirection] = useState<
    "asc" | "desc" | undefined
  >("asc");
  const [valueToOrderBy, setValueToOrderBy] = useState("timestamp");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = valueToOrderBy === property && orderDirection === "asc";
    setOrderDirection(isAsc ? "desc" : "asc");
    setValueToOrderBy(property);
  };

  return (
    <div className={style.alertListControlContainer}>
      <Paper className={classes.root} elevation={0}>
        <TableContainer className={classes.container}>
          <Table aria-label="sticky table">
            <TableHeader
              orderDirection={orderDirection}
              valueToOrderBy={valueToOrderBy}
              handleRequestSort={handleRequestSort}
            />
            <TableBody>
              {sortedRowInformation(
                storesToDisplay,
                getComparator(orderDirection, valueToOrderBy)
              )
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((store, index) => (
                  <AlertListControlTableRow
                    store={store}
                    index={index}
                    key={uuid()}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 20, 100]}
          colSpan={3}
          count={storesToDisplay.length}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            inputProps: { "aria-label": "rows per page" },
            native: true,
          }}
          component="div"
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};

export default AlertListControlTable;
