/**
 * @file contians inital Subscription fired at app initialization
 * keeps the cache updated with new data
 */
import React from "react";
import { useSubscription } from "@apollo/client";

import { INITIAL_SUBSCRIPTION } from "../../apollo/operations/subscriptions/subscriptions";
import { ENV_VARS } from "../../config/config";

const AllSubscriptions = () => {
  useSubscription(INITIAL_SUBSCRIPTION, {
    variables: { clientId: ENV_VARS.CLIENT_ID },
  });
  return <></>;
};

export { AllSubscriptions };
