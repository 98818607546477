import React from "react";
import classnames from "classnames";
import { ItemCollectionsFromCacheOrNetwork } from "../Providers/initalQueryProvider";
import CollectionsList from "./CollectionsList";

import style from "./styles/ProductCollections.module.scss";

export type ProductCollection = {
  id: string;
  itemIds: string[];
  title: string;
};

const ProductCollections = () => (
  <div className={style.storesContainer}>
    <div
      className={classnames(style.uiJustifySpaceBetween, style.uiAlignCenter)}
    >
      <div className={style.uiPageTitle}>UberEats</div>
    </div>
    <ItemCollectionsFromCacheOrNetwork>
      {(productCollections) => (
        <CollectionsList productCollections={productCollections} />
      )}
    </ItemCollectionsFromCacheOrNetwork>
  </div>
);

export default ProductCollections;
