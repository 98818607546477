import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_AUTH_GRAFANA } from "../../apollo/operations/queries/serverQueries";

const DashboardIframe = () => {
  const { data } = useQuery(GET_AUTH_GRAFANA);
  const [url, setUrl] = useState<string>("");
  const iFrameId = "grafana-dashboard-id";
  const iFrameTitle = "grafana-dashboard";

  useEffect(() => {
    if (data?.getAuthGrafana) {
      setUrl(data.getAuthGrafana.location);
      document.cookie = data.getAuthGrafana.setCookie;
    }
  }, [data]);

  return (
    <iframe
      title={iFrameTitle}
      id={iFrameId}
      src={url}
      scrolling="no"
      frameBorder="0"
      referrerPolicy="no-referrer"
    />
  );
};

export default DashboardIframe;
