import React from "react";
import movedIcon from "../../media/images/moved-icon.svg";
import deletedIcon from "../../media/images/delete-icon.svg";
import newIcon from "../../media/images/new-icon.svg";
import changeIcon from "../../media/images/change-icon.svg";
import {
  MenuItemWithOptions,
  MenuCategoryWithOptions,
} from "../../models/menu";
import { CATEGORY_ITEM_PROPERTIES } from "../../constants/constants";

import style from "./styles/MenuDiffIcons.module.scss";

type ChangedIconProps = {
  item: MenuItemWithOptions;
  change: CATEGORY_ITEM_PROPERTIES;
};

type Props = {
  item: MenuItemWithOptions | MenuCategoryWithOptions;
};

export const ChangedIcon = ({ item, change }: ChangedIconProps) => (
  <>
    {item.changes && item.changes.includes(change) && (
      <div className={style.changedIcon}>
        <img src={changeIcon} alt="change icon" />
      </div>
    )}
  </>
);

const MenuDiffIcons = ({ item }: Props) => (
  <>
    {item.isMoved && (
      <div className={style.movedIcon}>
        <img src={movedIcon} alt="moved icon" />
      </div>
    )}
    {item.isChanged && (
      <div className={style.changedIcon}>
        <img src={changeIcon} alt="change icon" />
      </div>
    )}
    {item.isDeleted && (
      <div className={style.deletedIcon}>
        <img src={deletedIcon} alt="delete icon" />
      </div>
    )}
    {item.isNew && (
      <div className={style.newIcon}>
        <img src={newIcon} alt="delete icon" />
      </div>
    )}
  </>
);

export default MenuDiffIcons;
