import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import Button from "@material-ui/core/Button";
import { GET_JSON } from "../../../../apollo/operations/queries/localQueries";
import deleteIcon from "../../../../media/images/ic-meal-delete.svg";
import { useStyles } from "./useStylesCollection";
import WarningModal from "../../../shared/WarningModal";
import { UPSERT_ITEM_COLLECTION } from "../../../../apollo/operations/mutations/mutations";
import { ProductCollection } from "../..";
import { itemsArrayFromObject } from "../../../MenuManagement/helpers/arraysAndObjects";

import style from "../styles/CollectionsList.module.scss";

interface Props {
  itemId: string;
  collection: ProductCollection;
}

const ItemInCollection: React.FC<Props> = ({ itemId, collection }) => {
  const classes = useStyles();
  const { data } = useQuery(GET_JSON);
  const [updateCollection] = useMutation(UPSERT_ITEM_COLLECTION);
  const [isWarningModalOpen, toggleWarningModal] = useState<boolean>(false);

  const deleteItemFromCollection = () => {
    updateCollection({
      variables: {
        itemIds: collection.itemIds.filter((id) => id !== itemId),
        title: collection.title,
        id: collection.id,
      },
    });
  };

  const itemToDisplay: any = itemsArrayFromObject(data.json.items).find(
    (jsonItem) => jsonItem.id === itemId
  );

  return (
    <>
      <div className={style.storeItem}>
        <div className={style.storeItemTop}>
          <h1 className={style.storeItemTitle}>
            {itemToDisplay?.title?.translations?.en_uk}
          </h1>
          <Button
            onClick={() => toggleWarningModal(true)}
            data-tip
            data-for="deleteItem"
            variant="contained"
            color="secondary"
            disableElevation
            classes={{ root: classes.root }}
          >
            <img src={deleteIcon} alt="Delete" />
          </Button>
        </div>
      </div>
      <WarningModal
        isOpen={isWarningModalOpen}
        modalMessage="Are you sure?"
        onConfirm={deleteItemFromCollection}
        onClose={() => toggleWarningModal(false)}
      />
    </>
  );
};

export default ItemInCollection;
