/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from "react";
import { Button, Checkbox, Dialog } from "@material-ui/core";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import { openModalVar } from "../../../apollo/cache";
import SaveMenuButton from "./SaveMenuButton";
import SaveStatusView from "./SaveStatusView";
import newIcon from "../../../media/images/ic-modal-new.svg";
import ModalHeader from "../shared/ModalHeader";
import { MODAL_OPEN } from "../../../config/config";
import { CHANGE_SIZE } from "../../../constants/constants";
import ChangeSizeSelect from "./ChangeSizeSelect";
import MoreInfoTooltip from "../../shared/MoreInfoTooltip";

import style from "./styles/SaveModal.module.scss";

type Props = {
  isOpen: boolean;
};

const SaveModal = ({ isOpen }: Props) => {
  const [isRegenerate, setIsRegenerate] = useState<boolean>(false);
  const [savedMenuStatus, setSavedMenuStatus] = useState<string>("");
  const [commitMessage, setCommitMessage] = useState<string>("");
  const [changeSize, setChangeSize] = useState<CHANGE_SIZE>(CHANGE_SIZE.PATCH);

  const handleClose = (_event: any, reason: string) => {
    if (reason === "backdropClick") {
      return false;
    }

    closeModal();
  };
  const closeModal = () => {
    openModalVar(MODAL_OPEN.NONE);
  };
  const handleCommitMessageChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCommitMessage(e.target.value);
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsRegenerate(e.target.checked);
  };

  if (savedMenuStatus) {
    return (
      <Dialog
        onClose={handleClose}
        aria-labelledby="Save Menu"
        open={isOpen}
        maxWidth="md"
      >
        <SaveStatusView
          savedMenuStatus={savedMenuStatus}
          getSavedMenuStatus={setSavedMenuStatus}
        />
      </Dialog>
    );
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="Save Menu"
      open={isOpen}
      maxWidth="md"
    >
      <ModalHeader
        onClose={closeModal}
        icon={newIcon}
        title="Save updated menu"
      />
      <MuiDialogContent>
        <div className={style.uiModalContent}>
          <form className={style.uiForm} noValidate autoComplete="off">
            <label className={style.uiFormLabel}>
              Give your changes a name
            </label>
            <div className={style.inputContainer}>
              <TextField
                name="menuName"
                variant="outlined"
                placeholder="e.g. changed descriptions"
                value={commitMessage}
                onChange={handleCommitMessageChange}
                fullWidth
              />
            </div>
            <label className={style.uiFormLabel}>
              Regenerate stores associated with this menu
            </label>
            <div className={style.inputContainer}>
              <Checkbox
                checked={isRegenerate}
                onChange={handleCheckboxChange}
                color="primary"
              />
            </div>
            <label className={style.uiFormLabel}>
              Indicate the scope of menu changes
              <MoreInfoTooltip tooltipText="versionDescription">
                <span>
                  This is important information for providing a version number
                  to the menu. <br />
                  E.g. a current menu with v1.1.2 with just a few textual /
                  image changes will become v1.1.3 <br />
                  A menu with v1.1.2 with introduction of new meal items will
                  become v1.2.2 <br />
                  And a menu with v1.1.2 receiving a major update with lots of
                  changes will become v2.1.2
                </span>
              </MoreInfoTooltip>
            </label>
            <ChangeSizeSelect
              changeSize={changeSize}
              setChangeSize={setChangeSize}
            />
          </form>
        </div>
      </MuiDialogContent>
      <MuiDialogActions>
        <div className={style.uiModalAction}>
          <Button
            onClick={closeModal}
            color="primary"
            variant="outlined"
            size="large"
          >
            Cancel
          </Button>
          <SaveMenuButton
            commitMessage={commitMessage}
            getSavedMenuStatus={setSavedMenuStatus}
            isRegenerate={isRegenerate}
            changeSize={changeSize}
          />
        </div>
      </MuiDialogActions>
    </Dialog>
  );
};

export default SaveModal;
