import { createTheme } from "@material-ui/core/styles";

const font = "'Baloo Chettan 2', cursive";

const ThemeDefault = createTheme({
  palette: {
    primary: {
      // light: '#00eedd',// light: will be calculated from palette.primary.main,
      main: "#328277",
      // dark: '#44ff00',// dark: will be calculated from palette.primary.main,
      // contrastText: '#ff4400',// contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      // light: palette.secondary.A200,
      main: "#D6E5DC",
      // dark: palette.secondary.A700,
      // contrastText: getContrastText(palette.secondary.A400),
    },
    text: {
      primary: "#334455",
    },
    type: "light",
    // type: 'dark',
    background: {
      paper: "#fafcfe",
      default: "#fafcfe",
    },
  },
  typography: {
    fontFamily: font,
  },
  /* typography: {
        fontFamily: [
            'font',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        // fontFamily: ""Gelion", "Helvetica", "Arial", sans-serif",
        fontSize: 14,
        // fontWeightLight: 300,
        // fontWeightRegular: 400,
        // fontWeightMedium: 500,
        // fontWeightBold: 700,
    }, */
  overrides: {
    // Style sheet name ⚛️
    MuiButton: {
      // Name of the rule
      root: {
        // Some CSS
        color: "#ffffff",
        fontWeight: 500,
        textTransform: "capitalize",
        fontSize: "14px",
        borderRadius: "5px",
        height: "35px",
        minWidth: "124px",
        border: 0,
      },
      sizeLarge: {
        height: "45px",
      },
      outlinedPrimary: {
        borderWidth: 2,
        backgroundColor: "#ffffff",
      },
      endIcon: {
        marginLeft: "30px",
      },
    },
    MuiInput: {
      root: {
        height: 90,
      },
      input: {
        height: 60,
      },
    },
    MuiInputBase: {
      input: {
        padding: "0",
      },
    },
    MuiFilledInput: {
      underline: {
        "&:before": {
          display: "none",
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        minHeight: 55,
        borderRadius: 5,
        "& $notchedOutline": {
          borderColor: "#A6B4B2",
        },
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
          borderColor: "#328277",
          borderWidth: "1px",
        },
        "&:focused $notchedOutline": {
          // borderColor: '#000277',
          borderWidth: 1,
        },
        "&.Mui-error.Mui-focused $notchedOutline": {
          borderColor: "#328277",
          borderWidth: 2,
        },
        "&.Mui-error $notchedOutline": {
          borderColor: "#A6B4B2",
        },
      },
      input: {
        fontSize: "14px",
        color: "#328277",
        padding: "9px 15px 9px 15px",
        lineHeight: "20px",
        fontWeight: 500,
        border: 0,

        "&::placeholder": {
          color: "#444444",
        },
      },
      multiline: {
        padding: 0,
      },
      inputMultiline: {
        padding: "10px 15px 9px 17px",
      },
    },
    MuiSelect: {
      filled: {
        backgroundColor: "transparent",
        borderColor: "transparent",
      },
      root: {
        height: "35px",
        borderRadius: "5px",
      },
      select: {
        // color: '#ffffff',
        // fontWeight: '600',
        // textTransform: "capitalize",
        // fontSize: '14px',
        // borderRadius: '5px',
        minHeight: "35px",
        borderRadius: "5px",
        // minWidth: '124px',
        // border: 0,
      },
      selectMenu: {
        color: "#328277",
        fontWeight: 600,
        borderRadius: "5px",
        textTransform: "capitalize",
        fontSize: "14px",
        minHeight: "35px",
        minWidth: "165px",
        border: 0,
        textAlign: "left",
        padding: "7px 69px 8px 20px",
        boxSizing: "border-box",
        backgroundColor: "#eff5f4",
      },
      icon: {},
    },
    MuiListItem: {
      root: {
        "&:hover": {
          backgroundColor: "transparent",
        },
        "&$selected:hover": {
          backgroundColor: "transparent",
          cursor: "default",
        },
        "&$selected": {
          backgroundColor: "transparent",
        },
      },
    },
    MuiAccordion: {
      root: {
        backgroundColor: "transparent",
        borderRadius: 0,
        margin: 0,

        "&::before": {
          display: "none",
        },
      },
    },
    MuiAccordionSummary: {
      root: {
        width: 42,
        padding: 0,
        margin: 0,
      },
    },
    MuiAccordionDetails: {
      root: {
        flexDirection: "column",
        padding: "15px 0 30px",
        borderTop: "1px solid #D8D8D8B3",
      },
    },
    MuiSwitch: {
      track: {
        backgroundColor: "#328277",

        "&:checked": {
          backgroundColor: "white",
          color: "beige",
        },
      },
      thumb: {
        color: "#ffffff",
      },
    },
    MuiPaper: {
      root: {
        // boxShadow: "0px 6px 6px -3px rgba(0, 0, 0, 0.05), 0px 5px 10px 6px rgba(0, 0, 0, 0.03), 0px 4px 6px 3px rgba(0, 0, 0, 0.02)",
      },
    },
    MuiDrawer: {
      paper: {
        zIndex: "unset",
      },
    },
    MuiDialog: {
      paper: {
        minHeight: 620,
        borderRadius: 20,
      },
      paperWidthLg: {
        maxWidth: 1100,
      },
    },
    MuiDialogTitle: {
      root: {
        padding: 0,
      },
    },
    MuiDialogContent: {
      root: {
        padding: "0 50px",
      },
    },
    MuiDialogActions: {
      root: {
        padding: 0,
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: "rgba(58, 58, 58, 0.62)",
      },
    },
    MuiTableHead: {
      root: {
        height: 50,
        backgroundColor: "#328277",
        color: "#ffffff",
        fontSize: "16px",
        lineHeight: "28px",
        paddingTop: "0",
        paddingBottom: "0",
      },
    },
    MuiTableContainer: {
      root: {
        borderRadius: "20px 20px 0 0",
      },
    },
    MuiTableFooter: {
      root: {
        backgroundColor: "blue",
      },
    },
    MuiTableRow: {
      root: {
        backgroundColor: "white",
      },
    },
    MuiTableBody: {
      root: {
        // padding: '20px',
      },
    },
    MuiTableCell: {
      root: {
        fontSize: "14px",
        lineHeight: "14px",
        // color: variables.primaryTextColor,
        padding: "10px 20px",
        backgroundColor: "transparent",
        border: 0,
      },
      head: {
        height: 50,
        backgroundColor: "#328277",
        color: "#ffffff",
        fontSize: "16px",
        lineHeight: "28px",
        paddingTop: "0",
        paddingBottom: "0",
      },
      stickyHeader: {
        height: 50,
        backgroundColor: "#328277",
        color: "#ffffff",
        fontSize: "16px",
        lineHeight: "28px",
        paddingTop: "0",
        paddingBottom: "0",
      },
      body: {
        maxWidth: "200px",
        minHeight: "60px",
        padding: "20px 7px 20px 7px",
        // color: variables.primaryTextColor,
        border: 0,
        borderBottom: "1px solid #d8d8d8b3",
        fontSize: "14px",
        lineHeight: "15px",
      },
      footer: {
        // borderColor: "#eef0f2",
        // backgroundColor: '#00ff00',
        // color: '#00ff00'
      },
    },
    MuiTablePagination: {
      toolbar: {
        height: 73,
        marginBottom: 20,
        paddingRight: 20,
      },
    },
    // MuiPaginationItem: {
    //     // selected: {
    //     //     backgroundColor: "#328277",
    //     //     color: '#ffffff',
    //     // },
    //     root: {
    //         borderRadius: 5,
    //         color: "#328277",
    //         backgroundColor: "#32827738"
    //     },
    //     page: {
    //         fontWeight: 500,
    //
    //         "&.Mui-selected": {
    //             backgroundColor: "#328277",
    //             color: '#ffffff'
    //         }
    //     },
    //     ellipsis: {
    //         height: 32,
    //         fontSize: 20
    //     }
    // },
    MuiMenuItem: {
      root: {
        minHeight: 30,
        // color: variables.primaryTextColor,
        fontSize: 12,
      },
    },
    MuiFormControlLabel: {
      label: {
        // color: variables.primaryTextColor,
        fontSize: 12,
      },
    },
    MuiChip: {
      root: {
        fontSize: "12px",
        fontWeight: 600,
        height: 30,
      },
    },
    // MuiAutocomplete:{
    //     root: {
    //         height: 40
    //     },
    //     input: {
    //         marginTop: '-5px'
    //     },
    //     option: {
    //         margin: '5px 8px',
    //         height:40,
    //         borderRadius: 5,
    //
    //         "&:hover":{
    //             backgroundColor: '#32827724',
    //         },
    //
    //         "&[aria-selected='true']": {
    //             backgroundColor: '#32827724',
    //         }
    //     },
    //     tag: {
    //         display: 'none'
    //     },
    //     clearIndicator: {
    //         display: 'none'
    //     },
    //
    // },
    // MuiTabPanel: {
    //     root: {
    //         display: "flex",
    //         flexDirection: "column",
    //         backgroundColor: "aliceblue"
    //     }
    // },
    MuiTabs: {
      flexContainer: {
        paddingLeft: 15,
      },
      indicator: {
        height: "3px",
      },
    },
    MuiTab: {
      root: {
        borderRadius: "20px 20px 0px 0px",
        background: "#d8d8d8b3",
        fontSize: 14,
        fontWeight: 600,
        margin: "5px",
        marginRight: "25px",
      },
    },
  },
});

export default ThemeDefault;
