import React, { useRef, useState } from "react";
import { useQuery } from "@apollo/client";
import { Button, Dialog } from "@material-ui/core";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import { GET_MODAL_VALUE } from "../../../apollo/operations/queries/localQueries";
import { modalValueVar, openModalVar } from "../../../apollo/cache";
import { MenuItemWithOptions } from "../../../models/menu";
import ModalForm from "./ModalForm";
import TitleWithInput from "../../shared/TitleWithInput";
import { changeItemInJsonItems } from "../../../apollo/operations/actions/actions";
import ModalHeader from "../shared/ModalHeader";
import { CATEGORY_ITEM_PROPERTIES } from "../../../constants/constants";
import { MODAL_OPEN } from "../../../config/config";
import { ChangedIcon } from "../../shared/MenuDiffIcons";
import ModalBreadcrumbs from "./ModalBreadcrumbs";
import MealSetIndicator from "../../shared/MealSetIndicator";
import { openItemModal } from "../../../helpers/menu";

import style from "./styles/MealItemModal.module.scss";

type Props = {
  isOpen: boolean;
};

type QueryResult = {
  modalValue: {
    item: MenuItemWithOptions;
    category: string;
  };
};

const MealItemModal = ({ isOpen }: Props) => {
  const [newImage, updateImage] = useState<string>("");
  const [breadcrumbs, setBreadcrumbs] = useState<MenuItemWithOptions[]>([]);
  const {
    data: {
      modalValue: { item, category },
    },
  }: any = useQuery<{ data: QueryResult | undefined }>(GET_MODAL_VALUE);
  const submitFormRef = useRef<any>();

  const handleClose = () => {
    if (breadcrumbs.length > 1) {
      openModalFromBreadcrumbs(breadcrumbs[breadcrumbs.length - 2]);
    } else {
      openModalVar(MODAL_OPEN.NONE);
      modalValueVar({} as any);
    }
  };

  const openModalFromBreadcrumbs = (item: MenuItemWithOptions) => {
    const itemIndex = breadcrumbs.findIndex(
      (i: MenuItemWithOptions) => i.id === item.id
    );
    setBreadcrumbs(breadcrumbs.slice(0, itemIndex));
    openItemModal(item, category);
  };

  const updateMealItemTitle = (item: MenuItemWithOptions) => {
    changeItemInJsonItems(item);
    openItemModal(item, category);
  };

  const modalTitle = item && item.title && item.title.translations.en_uk;
  const backgroundImageUrl = `url(${newImage || item.image_url})`;

  return (
    <Dialog
      id="exampleModal"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      maxWidth="lg"
      open={isOpen}
      fullWidth
    >
      <ModalHeader>
        <div
          className={style.modalHeader}
          style={{ backgroundImage: backgroundImageUrl }}
        >
          <h1 className={style.changedTitle}>
            <ModalBreadcrumbs
              item={item}
              category={category}
              breadcrumbs={breadcrumbs}
              setBreadcrumbs={setBreadcrumbs}
              openModal={openModalFromBreadcrumbs}
            />
            {modalTitle && (
              <TitleWithInput
                item={item}
                stateUpdate={updateMealItemTitle}
                style={style}
              />
            )}
            <ChangedIcon item={item} change={CATEGORY_ITEM_PROPERTIES.TITLE} />
          </h1>
        </div>
      </ModalHeader>
      <MuiDialogContent>
        <div className={style.modalContent}>
          <MealSetIndicator
            item={item}
            indicatorFlagStyle={style.indicatorFlag}
          />
          {item && (
            <ModalForm
              modalItem={{ item, category }}
              newImage={newImage}
              updateNewImage={updateImage}
              ref={submitFormRef}
              handleClose={handleClose}
            />
          )}
        </div>
      </MuiDialogContent>
      <MuiDialogActions>
        <div className={style.modalAction}>
          <Button
            data-dismiss="modal"
            onClick={handleClose}
            color="primary"
            variant="outlined"
            size="large"
          >
            Close
          </Button>
          <Button
            autoFocus
            color="primary"
            variant="contained"
            disableElevation
            size="large"
            onClick={() => submitFormRef.current?.handleSubmit()}
          >
            Update
          </Button>
        </div>
      </MuiDialogActions>
    </Dialog>
  );
};

export default MealItemModal;
