import { ReactiveVar, makeVar } from "@apollo/client";

export const collectionFields = {
  itemCollections: {
    read() {
      return itemCollectionsVar();
    },
  },
};

export const itemCollectionsVar: ReactiveVar<any[]> = makeVar<any[]>([]);
