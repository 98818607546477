import React, { useEffect } from "react";
import { useQuery, useLazyQuery } from "@apollo/client";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import axios from "axios";
import {
  GET_MENU,
  GET_RESTAURANT_DATA,
  GET_SAVED_MENUS,
} from "../../apollo/operations/queries/serverQueries";
import {
  changedMenuStatusVar,
  isReadOnlyVar,
  lastModifiedVar,
  menuKeyVar,
  savedMenuNameVar,
  selectedGroupsVar,
} from "../../apollo/cache";

import { StoreType } from "../../models/stores";
import { loadNewMenu } from "../../helpers/menu";
import { STATUS } from "../../constants/constants";

import style from "./styles/Nav.module.scss";

type SearchbarOption = {
  name: string;
  value: string;
  menu: string;
  masterMenu: string;
  isStoreLevel?: boolean;
  storeGroups?: string[];
};

const MenuSearchBar = () => {
  const { data: storeMenus } = useQuery(GET_RESTAURANT_DATA, {
    fetchPolicy: "cache-only",
  });
  const masterMenus = useQuery(GET_SAVED_MENUS, {
    fetchPolicy: "cache-only",
  });

  const [getMenu, menu] = useLazyQuery(GET_MENU);

  const handleChange = (option: SearchbarOption) => {
    if (option) {
      if (option.storeGroups) {
        selectedGroupsVar(option.storeGroups);
      }

      menuKeyVar(option.masterMenu ? option.masterMenu : option.value);
      changedMenuStatusVar(STATUS.PENDING);

      if (option.isStoreLevel) {
        isReadOnlyVar(true);
      } else {
        isReadOnlyVar(false);
      }

      lastModifiedVar("");
      getMenu({
        variables: {
          fileName: option.value,
        },
      });
    }
  };

  const mapGroupsToOptions = (groups: any): SearchbarOption[] => {
    return groups.flatMap((group: any) => {
      return group.items.map((item: any) => ({
        ...item,
        menu: group.name,
      }));
    });
  };

  useEffect(() => {
    const url = menu.data && menu.data.getMenu.data.url;

    if (url) {
      axios({ method: "get", url: `${url}` })
        .then((res) => {
          loadNewMenu(res.data, true);
          savedMenuNameVar(res.data.menuName);
          changedMenuStatusVar(STATUS.FULFILLED);
        })
        .catch((err) => console.log(err));
    }
  }, [menu.data]);

  if (!storeMenus || !masterMenus.data) {
    return null;
  }

  return (
    <>
      <Autocomplete
        id="grouped-demo"
        options={mapGroupsToOptions(
          generateSearchBarData(
            masterMenus.data.getClientMenus.menus,
            storeMenus.listRestaurantData.items
          )
        )}
        groupBy={(option: SearchbarOption) => option.menu}
        getOptionLabel={(option: SearchbarOption) => option.name}
        getOptionSelected={(option, value) => option.name === value.name}
        filterOptions={createFilterOptions({
          stringify: (option: SearchbarOption) =>
            `${option.menu} ${option.name}`,
        })}
        style={{ width: 300 }}
        renderInput={(params) => (
          <TextField {...params} label="Menus" variant="outlined" />
        )}
        classes={{ groupLabel: style.groupLabel }}
        onChange={(_, option) => handleChange(option as SearchbarOption)}
      />
    </>
  );
};

export default MenuSearchBar;

const generateSearchBarData = (masterMenus, storeMenus) => {
  const result = [] as any[];

  // populate the master menus
  result.push({
    name: "Master menus",
    items: masterMenus.map((masterMenu) => ({
      name: masterMenu.menuName,
      value: masterMenu.menuKey,
      storeGroups: masterMenu.storeGroups,
    })),
    type: "group",
  });

  // populate the individual restaurants
  // skip if menuSlug is empty,
  // else add it to the array of objects with key: menuSlug
  storeMenus.forEach((item: StoreType) => {
    if (!item.menuSlug) return;

    if (
      result.findIndex(
        (res) => res.name === item.menuSlug || res.name === item.menuSlug
      ) >= 0
    ) {
      const foundItem = result.find(
        (res) => res.name === item.menuSlug || res.name === item.menuSlug
      );
      foundItem.items.push({
        name: `${item.restaurantName} - ${item.ms2StoreNumber} - ${item.refId}`,
        value: item.aggregatorUUID,
        isStoreLevel: true,
        masterMenu: item.masterMenu,
      });
    } else {
      result.push({
        name: `${item.menuSlug}`,
        items: [
          {
            name: `${item.restaurantName} - ${item.ms2StoreNumber} - ${item.refId}`,
            value: item.aggregatorUUID,
            isStoreLevel: true,
            masterMenu: item.masterMenu,
          },
        ],
        type: "group",
      });
    }
  });

  return result;
};
