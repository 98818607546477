import React, { useState, useEffect } from "react";
import { useQuery, useLazyQuery } from "@apollo/client";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import { toast } from "react-toastify";
import { GET_LIVE_TOGGLE } from "../../../apollo/operations/queries/localQueries";
import {
  PUBLISH_MENU,
  PUBLISH_MENU_LIVE,
} from "../../../apollo/operations/queries/serverQueries";
import {
  GET_MENU_KEY,
  GET_SCHEDULED_PUBLISH_TOGGLE,
  menuKeyVar,
  openModalVar,
} from "../../../apollo/cache";
import SelectedStoresAndGroups from "./SelectedStoresAndGroups";
import ModalHeader from "../shared/ModalHeader";
import newIcon from "../../../media/images/ic-modal-new.svg";
import UploadButton from "../../shared/UploadButton";
import { MODAL_OPEN } from "../../../config/config";
import { AGGREGATORS, STATUS } from "../../../constants/constants";
import ScheduleDate from "../shared/ScheduleDate";
import StyledToast from "../../shared/StyledToast";
import AggregatorSelect from "./AggregatorSelect";
import {
  getDateToDisplay,
  getUnixInSeconds,
} from "../../../helpers/scheduleDate";

import style from "./styles/PublishModal.module.scss";

type Props = {
  isOpen: boolean;
};

type PublishParams = {
  action: string;
  menuKey: string;
  publishTimestamp?: string;
  storeGroups: string[];
  storeIds: string[];
  aggregators: AGGREGATORS[];
};

const PublishModal = ({ isOpen }: Props) => {
  const {
    data: { liveToggle },
  }: any = useQuery(GET_LIVE_TOGGLE);
  const {
    data: { isScheduledPublish },
  }: any = useQuery(GET_SCHEDULED_PUBLISH_TOGGLE);
  const {
    data: { menuKey },
  }: any = useQuery(GET_MENU_KEY);
  const [scheduledDate, setScheduledDate] = useState("");
  const [selectedStores, selectStores] = useState<string[]>([]);
  const [selectedGroups, selectGroups] = useState<string[]>([]);
  const [selectedAggregators, selectAggregators] = useState<AGGREGATORS[]>([]);

  const [publishMenu, { data }] = useLazyQuery(PUBLISH_MENU, {
    fetchPolicy: "network-only",
    onCompleted: () => openModalVar(MODAL_OPEN.NONE),
  });
  const [publishMenuLive, liveData] = useLazyQuery(PUBLISH_MENU_LIVE, {
    fetchPolicy: "network-only",
    onCompleted: () => openModalVar(MODAL_OPEN.NONE),
  });
  const [publishMessage, setPublishMessage] = useState("" as STATUS);

  const handleClose = (_event: any, reason: string) => {
    if (reason === "backdropClick") {
      return false;
    }

    openModalVar(MODAL_OPEN.NONE);
  };
  const publish = () => {
    setPublishMessage(STATUS.PENDING);

    const params: PublishParams = {
      action: "publish",
      menuKey: menuKeyVar(),
      storeGroups: selectedGroups,
      storeIds: selectedStores,
      aggregators: selectedAggregators,
    };

    if (scheduledDate) {
      params.publishTimestamp = `${scheduledDate}Z`;
    }

    if (!liveToggle) {
      publishMenu({ variables: params });
    } else {
      publishMenuLive({ variables: params });
    }
  };

  useEffect(() => {
    const toggledData = !liveToggle ? data : liveData.data;
    if (toggledData) {
      const returnedData = !liveToggle
        ? data.publishMenu.data
        : liveData.data.publishMenuLive.data;
      if (returnedData) setPublishMessage(STATUS.FULFILLED);
    }

    if (toggledData) {
      toast(
        scheduledDate
          ? `Scheduled publish for ${getDateToDisplay(
              getUnixInSeconds(scheduledDate)
            )} was successful`
          : "Publish was successful"
      );
      setScheduledDate("");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, liveData, liveToggle]);

  return (
    <>
      <Dialog
        onClose={handleClose}
        aria-labelledby="Publish Menu"
        open={isOpen}
        maxWidth="md"
      >
        <ModalHeader
          onClose={() => openModalVar(MODAL_OPEN.NONE)}
          icon={newIcon}
          title="Publish updated menu"
        />
        <MuiDialogContent>
          <div className={style.publishContentContainer}>
            <div className="col">
              <h5 className={style.contentHeading}>
                Selected channels to push to:{" "}
              </h5>
              <AggregatorSelect
                selectAggregators={selectAggregators}
                selectedAggregators={selectedAggregators}
              />
              <SelectedStoresAndGroups
                selectStores={selectStores}
                selectedStores={selectedStores}
                selectGroups={selectGroups}
                selectedGroups={selectedGroups}
                menuKey={menuKey}
              />
            </div>
          </div>
        </MuiDialogContent>
        <MuiDialogActions>
          <div className={style.uiModalAction}>
            {isScheduledPublish && (
              <ScheduleDate getDate={setScheduledDate} style={style} />
            )}
            <UploadButton
              handleClick={publish}
              buttonText="Publish menu"
              status={publishMessage}
            />
          </div>
        </MuiDialogActions>
      </Dialog>
      <StyledToast />
    </>
  );
};

export default PublishModal;
