import React from "react";
import { Button } from "@material-ui/core";
import CustomizationsTable from "./MenuBriefTable";
import { MODAL_OPEN } from "../../config/config";
import { openModalVar } from "../../apollo/cache";
import { MenuBriefFromCacheOrNetwork } from "./MenuBriefDataHandler";
import { Customization } from "../../models/customizations";

import style from "./styles/Customizations.module.scss";

const Customizations = () => (
  <>
    <div className={style.topNav}>
      <Button
        color="primary"
        variant="outlined"
        disableElevation
        size="small"
        onClick={() => openModalVar(MODAL_OPEN.CUSTOMIZATION)}
      >
        + Customisation
      </Button>
    </div>
    <MenuBriefFromCacheOrNetwork>
      {(customizations: Customization[]) => (
        <CustomizationsTable customizations={customizations} />
      )}
    </MenuBriefFromCacheOrNetwork>
  </>
);

export default Customizations;
