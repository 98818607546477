import axios from "axios";
import { ENV_VARS } from "../config/config";
import { STATUS } from "../constants/constants";

interface Params {
  setUploadMessage: (message: STATUS) => void;
  uploadUrl: any;
  file: any;
  fileName?: string;
  updateImage?: (image: any) => void;
  isJson?: boolean;
}

const uploadFileToBucket = ({
  uploadUrl,
  file,
  fileName,
  setUploadMessage,
  updateImage,
  isJson,
}: Params): void => {
  setUploadMessage(STATUS.PENDING);

  // eslint-disable-next-line no-nested-ternary
  const contentType = isJson
    ? "application/json"
    : file.type === "text/xml"
    ? "application/xml"
    : file.type;

  const putConfig = {
    method: "put",
    url: `${uploadUrl.url}`,
    headers: {
      "Content-Type": contentType,
    },
    data: !isJson ? file : JSON.stringify(file, null, 2),
  };

  axios(putConfig as any)
    .then(() => {
      setUploadMessage(STATUS.FULFILLED);
      if (updateImage)
        updateImage(`${ENV_VARS.ASSETS_URL}/${ENV_VARS.CLIENT_ID}/${fileName}`);
    })
    .catch((err) => {
      // eslint-disable-next-line no-console
      setUploadMessage(STATUS.FAILED);
    });
};

export default uploadFileToBucket;
