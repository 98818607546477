import { ReactiveVar, makeVar, gql } from "@apollo/client";

export const savePublishFields = {
  // selectedStores: {
  //   read() {
  //     return selectedStoresVar();
  //   },
  // },
  savedMenuName: {
    read() {
      return savedMenuNameVar();
    },
  },
  isScheduledPublish: {
    read() {
      return isScheduledPublishVar();
    },
  },
  jsonMenuName: {
    read() {
      return jsonMenuNameVar();
    },
  },
};

// export const selectedStoresVar: ReactiveVar<any> = makeVar<any>([]);
// export const GET_SELECTED_STORES_TO_SAVE = gql`
//   query GetSelectedStores {
//     selectedStores @client
//   }
// `;

// SAVED MENU NAME
export const savedMenuNameVar: ReactiveVar<string> = makeVar<string>("");

// SCHEDULED PUBLISH
export const isScheduledPublishVar: ReactiveVar<boolean> =
  makeVar<boolean>(false);
export const GET_SCHEDULED_PUBLISH_TOGGLE = gql`
  query GetScheduledPublishToggle {
    isScheduledPublish @client
  }
`;

// JSON MENU NAME
export const jsonMenuNameVar: ReactiveVar<string> = makeVar<string>("");
export const GET_JSON_MENU_NAME = gql`
  query GetJsonMenuName {
    jsonMenuName @client
  }
`;
