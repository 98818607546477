import { Box, Tab } from "@material-ui/core";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import React, { useEffect, useRef, useState } from "react";
import style from "./styles/TimingsDrawer.module.scss";
import { MasterMenu, SubMenu } from "../../../models/json";
import CategoryList from "../CategoryList";
import TimingsDrawer from "./TimingsDrawer";
import EditIcon from "@material-ui/icons/Edit";
import {
  cache,
  expandedCategoriesVar,
  GET_EXPANDED_CATEGORIES,
  jsonVar,
} from "../../../apollo/cache";
import { useQuery } from "@apollo/client";
import DiffCategoryList from "../DiffCategoryList";
import { GET_ACTIVE_MENU_TAB } from "../../../apollo/operations/queries/localQueries";
import produce from "immer";

type Props = {
  json: MasterMenu;
  showDifference: boolean;
};
const MenuTabs: React.FC<Props> = ({ json, showDifference }: Props) => {
  const [tabValue, setValue] = useState("0");
  const [tabs, setTabs] = useState(["Loading"]);
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [editableTab, setEditableTab] = useState("");

  //refs for tabs
  const tabRefs = useRef<HTMLElement[]>([] as HTMLElement[]);
  const {
    data: { expandedCategories },
  }: any = useQuery(GET_EXPANDED_CATEGORIES);

  const toggleDrawer = (open: boolean) => {
    if (open && expandedCategories.length > 0) {
      expandedCategoriesVar([]);
    }
    setDrawerOpen(open);
  };
  useEffect(() => {
    if (expandedCategories.length > 0) {
      setDrawerOpen(false);
    }
  }, [expandedCategories]);
  const handleChange = (event: any, newValue: string) => {
    setValue(newValue);
  };
  const onTabEditClicked = (tab: string) => {
    setEditableTab(tab);
  };
  const handleInputBlur = (event, menuKey: string) => {
    //update menu name
    const newName = event.target.value;
    if (newName)
      jsonVar(
        produce(jsonVar(), (draftJson: MasterMenu) => {
          const newMenusObject = Object.fromEntries(
            Object.entries(draftJson.menus).map(([key, value]) => {
              if (key === menuKey) {
                const subMenuObejct: SubMenu = {
                  ...value,
                  id: newName,
                  title: {
                    translations: {
                      en_uk: newName,
                    },
                  },
                };
                return [newName, subMenuObejct];
              } else {
                return [key, value];
              }
            })
          );
          draftJson.menus = { ...newMenusObject };
        })
      );
    setEditableTab("");
  };

  useEffect(() => {
    cache.writeQuery({
      query: GET_ACTIVE_MENU_TAB,
      data: {
        activeMenuTab: tabs[tabValue],
      },
    });
  }, [tabs, tabValue]);
  useEffect(() => {
    //udpate position of edit icon
    for (let tab of tabs) {
      const editButton = document.getElementById(`${tab}-edit`);
      const tabButton = tabRefs?.current?.[tabs.indexOf(tab)];
      const width = tabButton ? tabButton.offsetWidth : 0;
      if (editButton) editButton.style.marginLeft = width - 10 + "px";
    }
  }, [tabs, editableTab]);
  useEffect(() => {
    setTabs(Object.keys(json.menus || {}));
  }, [json]);

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <TabContext value={tabValue}>
          <Box style={{ borderBottom: "solid 1px #d8d8d8b3" }}>
            <TabList
              onChange={handleChange}
              style={{ maxHeight: "100px" }}
              textColor="primary"
              indicatorColor="primary"
            >
              {tabs.map((tab, index) => {
                return (
                  <Tab
                    label={
                      editableTab && editableTab === tab ? (
                        <input
                          onBlur={(e) => handleInputBlur(e, tab)}
                          className={style.editTabInput}
                          type="text"
                          placeholder={tab}
                        />
                      ) : (
                        tab
                      )
                    }
                    value={`${index}`}
                    key={index}
                    ref={(element) => {
                      if (tabRefs && tabRefs.current)
                        return (tabRefs.current[index] =
                          element as HTMLElement);
                    }}
                  />
                );
              })}
            </TabList>
          </Box>
          {/* For each tab add an edit button */}
          <div className={style.editWrapper}>
            {tabs.map((tab, index) => {
              return (
                <div
                  className={style.editIconWrapper}
                  key={index}
                  id={`${tab}-edit`}
                  onClick={() => onTabEditClicked(tab)}
                >
                  <EditIcon className={style.editIcon} />
                </div>
              );
            })}
          </div>
          <>
            {tabs.map((tab, index) => {
              return (
                <TabPanel value={`${index}`} key={index}>
                  <div style={{ display: "flex" }}>
                    <div style={{ width: "100%" }}>
                      {showDifference && <DiffCategoryList menuKey={tab} />}
                      {!showDifference && (
                        <CategoryList
                          tabs={tabs.filter((t) => t !== tab)}
                          json={json}
                          menuKey={tab}
                        />
                      )}
                    </div>
                    <div
                      style={{
                        width: isDrawerOpen ? "32%" : "8%",
                        marginRight: "-60px",
                      }}
                      className={style.container}
                    >
                      <TimingsDrawer
                        isOpen={isDrawerOpen}
                        setDrawerOpen={toggleDrawer}
                        menuKey={tab}
                        json={json}
                      />
                    </div>
                  </div>
                </TabPanel>
              );
            })}
          </>
        </TabContext>
      </Box>
    </>
  );
};
export default MenuTabs;
