import React, { Dispatch, SetStateAction } from "react";
import { Checkbox, Chip, TextField } from "@material-ui/core";
import { CheckIcon, UncheckIcon } from "../../shared/Icons";
import { useQuery } from "@apollo/client";
import { GET_ITEM_COLLECTIONS } from "../../../apollo/operations/queries/serverQueries";
// import { suspendItemIdVar } from "../../../apollo/cache";
import { Autocomplete } from "@material-ui/lab";

type Props = {
  selectedCollections: any[];
  setSelectedCollections: Dispatch<SetStateAction<any[]>>;
};

const OtherMembersAutocomplete: React.FC<Props> = ({
  selectedCollections,
  setSelectedCollections,
}) => {
  const { data } = useQuery(GET_ITEM_COLLECTIONS);

  const handleSelection = (collection: any) => {
    if (selectedCollections.includes(collection)) {
      const storeIndex = selectedCollections.findIndex(
        (item: any) => item.title === collection?.title
      );
      const newCollections = [...selectedCollections];
      newCollections.splice(storeIndex, 1);
      setSelectedCollections(newCollections);
    } else {
      const newCollections = [...selectedCollections];
      newCollections.push(collection);
      setSelectedCollections(newCollections);
    }
  };

  // console.log(data.listItemCollections.items);

  // const collections = data.listItemCollections.items.filter((collection) =>
  //   collection.itemIds.includes(suspendItemIdVar())
  // );

  // console.log(collections, suspendItemIdVar());

  return (
    <Autocomplete
      multiple
      options={data.listItemCollections.items}
      onChange={(_, values: any[]) => {
        values.forEach((value) => handleSelection(value));
      }}
      value={selectedCollections}
      disableCloseOnSelect
      disableClearable
      getOptionLabel={(option) => option.title}
      renderOption={(option) => (
        <>
          <Checkbox
            icon={<UncheckIcon />}
            checkedIcon={<CheckIcon />}
            style={{ marginRight: 8 }}
            color="default"
            checked={
              !!selectedCollections.find(
                (item: any) => item.title === option.title
              )
            }
          />
          {option.title}
        </>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label="Collections"
          placeholder="Search Collections"
        />
      )}
      renderTags={(values: any[], getTagProps) => {
        // eslint-disable-next-line no-console
        return values.map((value: any, index: number) => (
          <Chip
            variant="default"
            color="primary"
            label={value?.title}
            {...getTagProps({ index })}
            onDelete={() => handleSelection(value)}
          />
        ));
      }}
    />
  );
};

export default OtherMembersAutocomplete;
