import React, { useEffect, useState } from "react";
import {
  changedMenuStatusVar,
  jsonMenuNameVar,
  openModalVar,
  prevRootMenuVar,
  rootMenuDataVar,
  savedMenuNameVar,
  timeTravelDateVar,
} from "../../../apollo/cache";
import { MODAL_OPEN } from "../../../config/config";
import { Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import ModalHeader from "../shared/ModalHeader";
import timeTravelIcon from "../../../media/images/ic-time-travel-menu.svg";
import DateTimePicker from "../../shared/DateTimePicker";
import { useLazyQuery } from "@apollo/client";
import { GET_MENU_TRAVEL } from "../../../apollo/operations/queries/serverQueries";
import { getCurrentDateString } from "../../../helpers/scheduleDate";
import axios from "axios";
import { makeStyles } from "@material-ui/styles";
import { loadNewMenu } from "../../../helpers/menu";
import { STATUS } from "../../../constants/constants";

import style from "./styles/TImeTravelModal.module.scss";

type Props = {
  isOpen: boolean;
};

const useStyles = makeStyles({
  paper: {
    minHeight: "fit-content !important",
  },
});

const TimeTravelModal = ({ isOpen }: Props) => {
  const classes = useStyles();
  const [getMenuTravel, { data }] = useLazyQuery(GET_MENU_TRAVEL);
  const [timeMachineDate, setTimeMachineDate] = useState(
    getCurrentDateString()
  );

  const onClick = () => {
    getMenuTravel({
      variables: {
        dateTime: `${timeMachineDate}Z`,
        menuKey: jsonMenuNameVar(),
      },
    });
  };

  const onClose = () => {
    changedMenuStatusVar(STATUS.FULFILLED);
    openModalVar(MODAL_OPEN.NONE);
  };

  useEffect(() => {
    if (data?.menuTravel) {
      changedMenuStatusVar(STATUS.PENDING);
      axios({ method: "get", url: `${data.menuTravel.data.url}` })
        .then((res) => {
          prevRootMenuVar(rootMenuDataVar());
          loadNewMenu(res.data);
          savedMenuNameVar(res.data.menuName);
          timeTravelDateVar(timeMachineDate);
          onClose();
        })
        .catch((err) => console.log(err));
    }
  }, [data, timeMachineDate]);

  const handleClose = (_event: any, reason: string) => {
    if (reason === "backdropClick") {
      return false;
    }

    onClose();
  };
  return (
    <>
      <Dialog
        onClose={handleClose}
        aria-labelledby="Publish Menu"
        open={isOpen}
        maxWidth="md"
        classes={classes}
      >
        <ModalHeader
          onClose={() => onClose()}
          icon={timeTravelIcon}
          title="Time travel menu"
        />
        <MuiDialogContent>
          <div className={style.menuTravelContainer}>
            <DateTimePicker
              onChange={(e) => setTimeMachineDate(e.target.value)}
              value={timeMachineDate}
              customClass={style.menuTravelBar}
            />
            <Button
              className={style.menuTravelBtn}
              variant="contained"
              color="primary"
              onClick={onClick}
            >
              Go
            </Button>
          </div>
        </MuiDialogContent>
      </Dialog>
    </>
  );
};

export default TimeTravelModal;
