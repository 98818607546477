import React from "react";
import FadeLoader from "react-spinners/FadeLoader";
import Button from "@material-ui/core/Button";
import style from "./styles/UploadButton.module.scss";
import { STATUS } from "../../constants/constants";

interface Props {
  handleClick: any;
  buttonText: string;
  status: string;
}

const UploadButton = ({ handleClick, buttonText, status }: Props) => (
  <>
    {status !== STATUS.PENDING && (
      <div className={style.pendingContainer}>
        <Button
          onClick={handleClick}
          color="primary"
          variant="contained"
          size="large"
        >
          {buttonText}
        </Button>
      </div>
    )}
    {status === STATUS.PENDING && (
      <div className={style.pendingContainer}>
        <FadeLoader
          height={8}
          width={8}
          radius={100}
          margin={1}
          color="#336B6B"
          loading={status === STATUS.PENDING}
        />
      </div>
    )}
  </>
);

export default UploadButton;
