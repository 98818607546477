import produce from "immer";
import { StoreGroupType } from "../../../models/stores";
import { storeGroupsVar, changedStoresVar } from "../../cache";

export const addStoreToExistingGroup = (
  storeId: string,
  groupTitle: string
): StoreGroupType => {
  const group = storeGroupsVar().find(
    (group: StoreGroupType) => group.title === groupTitle
  );

  if (!group.storeIds.UberEats.includes(storeId)) {
    return {
      ...group,
      storeIds: {
        ...group.storeIds,
        UberEats: [...group.storeIds.UberEats, storeId],
      },
    };
  }

  return group;
};

export const deleteStoreFromGroup = (
  storeGroups,
  storeId: string,
  groupTitle: string
) => {
  
  const group = storeGroups.find(
    (group: StoreGroupType) => group.title === groupTitle
  );

  return {
    ...group,
    storeIds: {
      ...group.storeIds,
      UberEats: group.storeIds.UberEats.filter((id: string) => id !== storeId),
    },
  };
};

export const replaceGroup = (group: StoreGroupType): void => {
  storeGroupsVar(
    produce(storeGroupsVar(), (draftGroups: StoreGroupType[]) => {
      const index = draftGroups.findIndex(
        (item: StoreGroupType) => item.id === group.id
      );
      draftGroups[index] = group;
    })
  );
};

export const addItemsToSelectStores = (items) => {
  storeGroupsVar([...storeGroupsVar(), ...items]);
};

export const addIdToChangedStores = (id: string): void => {
  changedStoresVar([...new Set([...changedStoresVar(), id])]);
};

export const deleteIdFromChangedStores = (id: string): void => {
  changedStoresVar(
    changedStoresVar().filter((storeId: string) => storeId !== id)
  );
};
