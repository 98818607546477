import { InMemoryCache } from "@apollo/client";
import { menuFields } from "./operations/reactiveVars/menuVars";
import { savePublishFields } from "./operations/reactiveVars/savePublishVars";
import { openingHoursFields } from "./operations/reactiveVars/openingHoursVars";
import { storeFields } from "./operations/reactiveVars/storeVars";
import { authFields } from "./operations/reactiveVars/authVars";
import { customizationFields } from "./operations/reactiveVars/customizationVars";
import { collectionFields } from "./operations/reactiveVars/collectionVars";

export const cache: InMemoryCache = new InMemoryCache({
  typePolicies: {
    RestaurantData: {
      // In restaurant data, aggregatorUUID is the unique identifier.
      // TODO: This is a workaround. If we would use the id field instead and then this becomes obsolete.
      keyFields: ["aggregatorUUID"],
      fields: {
        uuid: {
          merge(existing = "", incoming) {
            if (incoming) return incoming;
            return existing;
          },
        },
      },
    },
    RestaurantGroupings: {
      keyFields: ["id"],
    },
    Customisation: {
      keyFields: ["clientId", "sk"],
    },
    ClientMenu: {
      keyFields: ["menuKey"],
    },
    Query: {
      fields: {
        ...menuFields,
        ...savePublishFields,
        ...openingHoursFields,
        ...storeFields,
        ...authFields,
        ...customizationFields,
        ...collectionFields,
        /**
         * Adding a default value in case it is undefined
         */
        isMenuModified: {
          read(isMenuModified = false) {
            return isMenuModified;
          },
        },
        schedule: {
          read(schedule = {}) {
            return schedule;
          },
        },
      },
    },
  },
  addTypename: false,
});

export * from "./operations/reactiveVars/menuVars";
export * from "./operations/reactiveVars/savePublishVars";
export * from "./operations/reactiveVars/openingHoursVars";
export * from "./operations/reactiveVars/storeVars";
export * from "./operations/reactiveVars/authVars";
export * from "./operations/reactiveVars/customizationVars";
export * from "./operations/reactiveVars/collectionVars";
