import React, { useState, memo } from "react";
import { useQuery } from "@apollo/client";
import TableRow from "@material-ui/core/TableRow";
import { v4 as uuid } from "uuid";
import TableCell from "@material-ui/core/TableCell";
import { GET_CHANGED_STORES } from "../../../../apollo/operations/queries/localQueries";
import { StoreType } from "../../../../models/stores";
import {
  addIdToChangedStores,
  deleteIdFromChangedStores,
} from "../../../../apollo/operations/actions/actions";
import HoursColumn from "./HoursColumn";
import MasterMenusColumn from "./MasterMenusColumn";
import SaveHoursButton from "./SaveHoursButton";

import style from "../styles/StaticColumn.module.scss";

type Props = {
  store: StoreType;
};

const WEEK_DAYS = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

const StoreRow: React.FC<Props> = memo(({ store }) => {
  const { data } = useQuery(GET_CHANGED_STORES);
  const [invalidDays, setInvalidDays] = useState<string[]>([]);
  const [isStoreSaved, setSaveStatus] = useState(false);

  const setValidDays = (isValid: boolean, weekDay: string) => {
    if (!isValid) {
      if (!invalidDays.includes(weekDay)) {
        setInvalidDays([...invalidDays, weekDay]);
      }

      if (data.changedStores.includes(store.uuid)) {
        deleteIdFromChangedStores(store.uuid);
      }
    } else {
      if (invalidDays.includes(weekDay)) {
        setInvalidDays(invalidDays.filter((day) => weekDay !== day));
      }

      if (
        invalidDays.length === 0 &&
        !data.changedStores.includes(store.uuid)
      ) {
        addIdToChangedStores(store.uuid);
      }
    }
  };

  const handleSaveStatus = () => {
    if (isStoreSaved) {
      setSaveStatus(false);
    }
  };

  return (
    <TableRow>
      <TableCell align="center" className={style.staticColumn}>
        <div className={style.staticRow}>{store.restaurantName}</div>
      </TableCell>
      <TableCell align="center">
        <span className={style.menuName}>
          <MasterMenusColumn
            store={store}
            handleSaveStatus={handleSaveStatus}
          />
        </span>
      </TableCell>
      {WEEK_DAYS.map((day: string) => (
        <TableCell align="center" key={uuid()}>
          <HoursColumn
            weekDay={day.toLowerCase()}
            store={store}
            setValidDay={setValidDays}
            handleSaveStatus={handleSaveStatus}
          />
        </TableCell>
      ))}
      <TableCell align="center">
        <SaveHoursButton
          store={store}
          setSaveStatus={setSaveStatus}
          isStoreSaved={isStoreSaved}
        />
      </TableCell>
    </TableRow>
  );
});

export default StoreRow;
