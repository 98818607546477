import React, { useState, useRef, useEffect } from "react";
import EmojiPicker from "emoji-picker-react";
import useOutsideAlerter from "../../../hooks/useOutsideAlerter";
import insertEmojiIcon from "../../../media/images/insert-emoji-icon.svg";

interface Props {
  name: string;
  value: any;
  onChange: (e: any) => void;
}

const emojiPickerStyle = {
  zIndex: "1",
  position: "absolute",
  height: "280px",
  right: "0",
};

const ModalTextArea = ({ name, value, onChange }: Props) => {
  const [inputValue, setInputValue] = useState(value);
  const [isEmojiPicker, toggleEmojiPicker] = useState(false);
  const wrapperRef = useRef(null);
  const [textAreaHeight, setHeight] = useState(null);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputValue(e.target.value);
    onChange(e);
  };

  const handleEmojiClick = (e, emojiObject) => {
    setInputValue(`${inputValue}${emojiObject.emoji}`);
  };

  const handleEmojiToggle = () => {
    toggleEmojiPicker(!isEmojiPicker);
  };

  useOutsideAlerter(wrapperRef, isEmojiPicker, handleEmojiToggle);

  const textArea = useRef({} as any);
  useEffect(() => {
    if (textArea.current) {
      setHeight(textArea.current.scrollHeight + 35);
    }
  }, [name]);

  return (
    <div ref={wrapperRef} style={{ position: "relative" }}>
      <div style={{ position: "relative" }}>
        <textarea
          value={inputValue}
          name={name}
          className="form-control"
          onChange={handleChange}
          style={{ width: "100%", height: `${textAreaHeight}px` }}
        />
        <img
          src={insertEmojiIcon}
          alt="Insert emoji"
          onClick={handleEmojiToggle}
          style={{
            cursor: "pointer",
            zIndex: 100,
            position: "absolute",
            bottom: "12px",
            right: "6px",
          }}
        />
      </div>
      {isEmojiPicker && (
        <EmojiPicker
          onEmojiClick={handleEmojiClick}
          pickerStyle={emojiPickerStyle}
          // disableSearchBar={true}
          disableSkinTonePicker={true}
          preload
          native
        />
      )}
    </div>
  );
};

export default ModalTextArea;
