import produce from "immer";
import React, { createContext, useContext, useReducer } from "react";
import { CUSTOMIZATION } from "./utils/config";
import {
  CustomizationsState,
  MainDispatch,
  MainCustomizationsAction,
} from "./utils/types";

const CustomizationsContext = createContext<
  { state: CustomizationsState; dispatch: MainDispatch } | undefined
>(undefined);

const initialState: CustomizationsState = {
  [CUSTOMIZATION.SLUG]: "",
  [CUSTOMIZATION.DESCRIPTION]: "",
  [CUSTOMIZATION.GLOBAL]: true,
  [CUSTOMIZATION.AGGREGATORS]: [],
  [CUSTOMIZATION.STORES]: [],
  [CUSTOMIZATION.STORE_GROUPS]: [],
  /* [CUSTOMIZATION.ADD]: [],
  [CUSTOMIZATION.EDIT]: [],
  [CUSTOMIZATION.REMOVE]: [],*/
  [CUSTOMIZATION.CLONE]: false,
  [CUSTOMIZATION.JSON]: {},
};

const reducer = (
  state: CustomizationsState,
  action: MainCustomizationsAction
): CustomizationsState => {
  /* if (
    action.type === CUSTOMIZATION.ADD ||
    action.type === CUSTOMIZATION.EDIT ||
    action.type === CUSTOMIZATION.REMOVE
  ) {
    return {
      ...state,
      [action.type]: [action.payload as CustomizationsActionType],
    };
  } */

  if (action.type === "DELETE") {
    return {
      ...state,
      [action.payload as CUSTOMIZATION]: [],
    };
  }

  return produce(state, (draft) => {
    draft[action.type] = action.payload;
  });
};

export const useCustomizationsState = (): {
  state: CustomizationsState;
  dispatch: MainDispatch;
} => {
  const context = useContext(CustomizationsContext);

  if (context === undefined) {
    throw new Error(
      "useCustomizationsState must be used within a ContextProvider"
    );
  }

  return context;
};

type Props = {
  children: React.ReactNode;
  populatedState: CustomizationsState;
};

const CustomizationsProvider = ({ children, populatedState }: Props) => {
  const stateToPass =
    Object.keys(populatedState).length > 0 ? populatedState : initialState;
  const [state, dispatch] = useReducer(reducer, stateToPass);

  return (
    <CustomizationsContext.Provider value={{ state, dispatch }}>
      {children}
    </CustomizationsContext.Provider>
  );
};

export default CustomizationsProvider;
