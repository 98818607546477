import React from "react";
import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

type Props = {
  value: string;
  name?: string;
  customClass?: string;
  onChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
};

const useStyles = makeStyles({
  root: {
    minHeight: "55px",
  },
});

const DateTimePicker = ({ value, onChange, name = "", customClass }: Props) => {
  const classes = useStyles();
  const valueToPass = value
    ?.split("Z")
    .join("")
    .split(":")
    .splice(0, 2)
    .join(":");
  return (
    <TextField
      type="datetime-local"
      variant="outlined"
      color="primary"
      name={name}
      className={`form-control ${classes.root} ${customClass}`}
      value={valueToPass || "2022-10-18T00:00"}
      onChange={onChange}
    />
  );
};

export default DateTimePicker;
