/* eslint-disable react/no-unescaped-entities */
import React, { useRef } from "react";
import { v4 as uuid } from "uuid";
import StoreGroup from "./StoreGroup";
import { StoreGroupType } from "../../../models/stores";
import WarningModal from "../../shared/WarningModal";

import style from "./styles/StoreGroups.module.scss";

type Props = {
  storeGroups: StoreGroupType[];
};

const StoreGroupList: React.FC<Props> = ({ storeGroups }) => {
  const deleteGroupRef = useRef<any>();
  return (
    <>
      {storeGroups.length === 0 && (
        <div>
          <hr className={style.uiSpacer} />
          <h2 className={style.uiTextCenter}>
            You haven't created any menu groupings yet. Go ahead and create one.
          </h2>
        </div>
      )}

      {storeGroups.map((group: StoreGroupType) => (
        <StoreGroup
          group={group}
          key={uuid()}
          deleteGroupRef={deleteGroupRef}
        />
      ))}
      <WarningModal
        modalMessage="Are you sure?"
        onConfirm={() =>
          deleteGroupRef.current.deleteGroup(
            deleteGroupRef.current.groupToDelete
          )
        }
      />
    </>
  );
};

export default StoreGroupList;
