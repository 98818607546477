/* eslint-disable react/require-default-props */
import React from "react";
import StoresAutocomplete from "../../../shared/StoresAutocomplete";
import { useCustomizationsState } from "../CustomizationsProvider";
import { CUSTOMIZATION } from "../utils/config";

import style from "../styles/CustomizationModal.module.scss";

type Props = {
  label: string;
  moreInfoComponent?: JSX.Element;
};

const CustomizationsStoresAutocomplete: React.FC<Props> = ({
  label,
  moreInfoComponent,
}) => {
  const { state, dispatch } = useCustomizationsState();

  const handleStoreSelect = (items: string[]) => {
    dispatch({
      type: CUSTOMIZATION.STORES,
      payload: items,
    });
  };

  return (
    <>
      {!state[CUSTOMIZATION.GLOBAL] && (
        <>
          <span className={style.inputLabel}>
            {label}: {moreInfoComponent}
          </span>
          <div className={style.inputContainer}>
            <StoresAutocomplete
              selectedStores={
                state[CUSTOMIZATION.STORES] ? state[CUSTOMIZATION.STORES] : []
              }
              getSelectedStores={handleStoreSelect}
            />
          </div>
        </>
      )}
    </>
  );
};

export default CustomizationsStoresAutocomplete;
