import { ReactiveVar, makeVar, gql } from "@apollo/client";

export const storeFields = {
  stores: {
    read() {
      return jsonStoresVar();
    },
  },
  storeGroups: {
    read() {
      return storeGroupsVar();
    },
  },
  selectedGroups: {
    read() {
      return selectedGroupsVar();
    },
  },
};

// STORES
export const jsonStoresVar: ReactiveVar<any> = makeVar<any>({});
export const GET_STORES = gql`
  query GetStores {
    stores @client
  }
`;

// STORES TO GROUP
export const storeGroupsVar: ReactiveVar<any> = makeVar<any>([]);

// SELECTED GROUPS
export const selectedGroupsVar: ReactiveVar<any> = makeVar<any>([]);
