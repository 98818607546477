import React from "react";
import { TableCell, TableRow } from "@material-ui/core";
import DeleteButton from "./DeleteButton";

type Props = {
  store: any;
  index: number;
};

const AlertListControlTableRow = ({ store, index }: Props) => {
  return (
    <TableRow key={index} style={{ width: "100%" }}>
      <TableCell align="center">{store.affectedStores}</TableCell>
      <TableCell align="center">{store.channels}</TableCell>
      <TableCell align="center">{store.reason}</TableCell>
      <TableCell align="center">{store.type}</TableCell>
      <TableCell align="center">{store.timestamp}</TableCell>
      <TableCell align="center">
        <DeleteButton />
      </TableCell>
    </TableRow>
  );
};

export default AlertListControlTableRow;
