import React from "react";
import { Switch, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import { ThemeProvider } from "@material-ui/styles";
import ThemeDefault from "./styles/ThemeOverride";
import {
  UiContentWrapper,
  UiMainWrapper,
} from "./components/shared/Grid/GridLayout";

import Nav from "./components/Nav";
import SideNav from "./components/SideNav";
import TopNav from "./components/TopNav";
import ModalManager from "./components/ModalManager";
import MenuBrief from "./components/MenuBrief";
import MissionControl from "./components/MissionControl";
import AlertList from "./components/AlertList";
import GrafanaDashboard from "./components/GrafanaDashboard";
import OpeningHours from "./components/OpeningHours";
import StoreGroupings from "./components/StoreGroupings";
import MenuManagement from "./components/MenuManagement";
import PageNotFound from "./components/PageNotFound";
import Authentication from "./components/Authentication";
import Settings from "./components/Settings";
import JsonVisualization from "./components/JsonVisualization";
import ProductCollections from "./components/ProductCollections";
import InitialQueryProvider from "./components/Providers/initalQueryProvider";
import { useReactiveVar } from "@apollo/client";
import { snackbarStateVar } from "./apollo/cache";
import { Snackbar } from "@material-ui/core";

function App() {
  const openSnack = useReactiveVar(snackbarStateVar);
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    snackbarStateVar({
      open: false,
      message: "",
    });
  };
  return (
    <div className="App">
      <Authentication.Redirect>
        <Authentication.Checker>
          <ThemeProvider theme={ThemeDefault}>
            <InitialQueryProvider>
              <SideNav />
              <UiMainWrapper>
                <TopNav />
                <UiContentWrapper>
                  <Nav />
                  <Switch>
                    <Route exact path="/" component={MenuManagement} />
                    <Authentication.PrivateRoute
                      path="/settings"
                      component={Settings}
                    />
                    <Authentication.PrivateRoute
                      path="/store-management"
                      component={StoreGroupings}
                    />
                    <Authentication.PrivateRoute
                      path="/opening-hours"
                      component={OpeningHours}
                    />
                    <Authentication.PrivateRoute
                      path="/mission-control"
                      component={MissionControl}
                    />
                    <Authentication.PrivateRoute
                      path="/menu-analytics"
                      component={GrafanaDashboard}
                    />

                    <Authentication.PrivateRoute
                      path="/product-collections"
                      component={ProductCollections}
                    />
                    <Authentication.PrivateRoute
                      path="/menubrief"
                      component={MenuBrief}
                    />
                    <Authentication.PrivateRoute
                      path="/menu-source"
                      component={JsonVisualization}
                    />
                    <Authentication.PrivateRoute
                      path="/alert-list"
                      component={AlertList}
                    />
                    <Route component={PageNotFound} />
                  </Switch>
                  <Snackbar
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    open={openSnack.open}
                    autoHideDuration={2000}
                    onClose={handleSnackbarClose}
                    message={openSnack.message}
                  />
                </UiContentWrapper>
                <ModalManager />
              </UiMainWrapper>
            </InitialQueryProvider>
          </ThemeProvider>
        </Authentication.Checker>
      </Authentication.Redirect>
    </div>
  );
}

export default App;
