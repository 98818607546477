/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import { v4 as uuid } from "uuid";
import classnames from "classnames";
import ReactTooltip from "react-tooltip";
import produce from "immer";
import labelIcon from "../../../media/images/new-label.svg";
import { useStyles } from "./useStylesMenuItem";
import { changeItemInJsonItems } from "../../../apollo/operations/actions/actions";
import { NUTRITIONAL_LABEL_OPTIONS } from "../../../constants/constants";

import style from "./styles/MealItem.module.scss";

const NutritionalLabelButton: React.FC<any> = ({ item, category }) => {
  const [isOpen, toggleModal] = useState(false);
  const classes = useStyles();

  const handleToggle = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (isOpen) {
      toggleModal(false);
    } else {
      toggleModal(true);
    }
  };

  const selectNutritionalLabel = (label: NUTRITIONAL_LABEL_OPTIONS) => {
    let newLabels: any = [];
    const labelKey = (Object.entries(NUTRITIONAL_LABEL_OPTIONS) as any).find(
      (arr) => arr.includes(label)
    )[0];

    if (item.dish_info) {
      const { labels } = item.dish_info.classifications.dietary_label_info;
      newLabels = labels.includes(labelKey)
        ? produce(labels, (draftLabels: string[]) => {
            draftLabels.splice(draftLabels.indexOf(labelKey), 1);
          })
        : [...labels, labelKey];
    } else {
      newLabels = [labelKey];
    }

    const itemWithLabels = {
      ...item,
      dish_info: {
        classifications: {
          alcoholic_items: 0,
          dietary_label_info: {
            labels: newLabels,
          },
        },
      },
    };

    changeItemInJsonItems(itemWithLabels);
    toggleModal(false);
  };

  const modalstyle = classnames(style.nutritionalLabelModal, {
    [style.visibleCategoryModal]: isOpen,
  });
  return (
    <>
      <div className={modalstyle}>
        {Object.keys(NUTRITIONAL_LABEL_OPTIONS)
          .map((key) => NUTRITIONAL_LABEL_OPTIONS[key])
          .map((label: any) => (
            <div
              className={style.changeCategoryRow}
              key={uuid()}
              onClick={() => selectNutritionalLabel(label)}
            >
              {label}
            </div>
          ))}
      </div>
      <Button
        onClick={handleToggle}
        variant="contained"
        color="secondary"
        disableElevation
        data-tip
        data-for="newLabel"
        classes={{ root: classes.root }}
      >
        <img src={labelIcon} alt="Delete" />
      </Button>
      <ReactTooltip
        id="newLabel"
        place="bottom"
        type="dark"
        effect="float"
        globalEventOff="click"
      >
        <span>add / remove nutritional labels</span>
      </ReactTooltip>
    </>
  );
};

export default NutritionalLabelButton;
