import React from "react";
import { useQuery } from "@apollo/client";
import { GET_OPEN_MODAL } from "../../apollo/cache";
import { MODAL_OPEN } from "../../config/config";

import PublishModal from "./PublishModal";
import MealItemModal from "./MealItemModal";
import SuspendItemModal from "./SuspendItemModal";
import SaveModal from "./SaveModal";
import StoreGroupModal from "./StoreGroupModal";
import SyncModal from "./SyncModal";
import CustomizationModal from "./CustomizationModal";
import HistoryModal from "./HistoryModal";
import TimeTravelModal from "./TimeTravelModal";
import CollectionsModal from "./CollectionsModal";

const ModalManager = () => {
  const {
    data: { openModal },
  }: any = useQuery(GET_OPEN_MODAL);

  return (
    <>
      {openModal === MODAL_OPEN.MEAL_ITEM && (
        <MealItemModal isOpen={openModal === MODAL_OPEN.MEAL_ITEM} />
      )}

      {openModal === MODAL_OPEN.SUSPEND_ITEM && (
        <SuspendItemModal isOpen={openModal === MODAL_OPEN.SUSPEND_ITEM} />
      )}

      {openModal === MODAL_OPEN.PUBLISH_MENU && (
        <PublishModal isOpen={openModal === MODAL_OPEN.PUBLISH_MENU} />
      )}

      {openModal === MODAL_OPEN.SAVE_MENU && (
        <SaveModal isOpen={openModal === MODAL_OPEN.SAVE_MENU} />
      )}

      {openModal === MODAL_OPEN.STORE_GROUP && (
        <StoreGroupModal isOpen={openModal === MODAL_OPEN.STORE_GROUP} />
      )}

      {openModal === MODAL_OPEN.SYNC && (
        <SyncModal isOpen={openModal === MODAL_OPEN.SYNC} />
      )}

      {openModal === MODAL_OPEN.CUSTOMIZATION && (
        <CustomizationModal isOpen={openModal === MODAL_OPEN.CUSTOMIZATION} />
      )}

      {openModal === MODAL_OPEN.HISTORY && (
        <HistoryModal isOpen={openModal === MODAL_OPEN.HISTORY} />
      )}

      {openModal === MODAL_OPEN.TIME_TRAVEL && (
        <TimeTravelModal isOpen={openModal === MODAL_OPEN.TIME_TRAVEL} />
      )}

      {openModal === MODAL_OPEN.COLLECTIONS && (
        <CollectionsModal isOpen={openModal === MODAL_OPEN.COLLECTIONS} />
      )}
    </>
  );
};

export default ModalManager;
