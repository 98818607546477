/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect } from "react";
import classnames from "classnames";
import { IconButton } from "@material-ui/core";
import { MenuItemWithOptions } from "../../../models/menu";
import backIcon from "../../../media/images/icon-arrow-back.svg";

import style from "./styles/MealItemModal.module.scss";

type Props = {
  item: MenuItemWithOptions;
  category: string;
  breadcrumbs: MenuItemWithOptions[];
  setBreadcrumbs: React.Dispatch<React.SetStateAction<MenuItemWithOptions[]>>;
  openModal: (item: MenuItemWithOptions) => void;
};

const ModalBreadcrumbs = ({
  item,
  category,
  breadcrumbs,
  setBreadcrumbs,
  openModal,
}: Props) => {
  useEffect(() => {
    if (!breadcrumbs.some((i: MenuItemWithOptions) => i.id === item.id)) {
      setBreadcrumbs([...breadcrumbs, item]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, category]);

  if (breadcrumbs.length < 2) {
    return null;
  }

  return (
    <div className={style.breadcrumbs}>
      {breadcrumbs.length > 1 && (
        <IconButton
          color="primary"
          style={{ marginTop: "5px" }}
          onClick={() => openModal(breadcrumbs[breadcrumbs.length - 2])}
        >
          <img src={backIcon} alt="back button" />
        </IconButton>
      )}

      {breadcrumbs.length > 1 &&
        breadcrumbs.map((item: MenuItemWithOptions, index: number) => {
          const isLastItem =
            item.id === breadcrumbs[breadcrumbs.length - 1]?.id;
          const itemStyle = classnames(style.item, {
            [style.currentItem]: isLastItem,
          });
          return (
            <span onClick={() => !isLastItem && openModal(item)} key={index}>
              ­­{index !== 0 && <span className={style.separator}> » </span>}
              <span className={itemStyle}>{item.title.translations.en_uk}</span>
            </span>
          );
        })}
    </div>
  );
};

export default ModalBreadcrumbs;
