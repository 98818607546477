import produce from "immer";
import {
  openModalVar,
  modalValueVar,
  jsonVar,
  savedMenuNameVar,
  jsonMenuNameVar,
  // selectedStoresVar,
  menuToSyncVar,
  isMasterMenuVar,
  rootMenuDataVar,
} from "../apollo/cache";
import { MODAL_OPEN } from "../config/config";
import { AGGREGATORS } from "../constants/constants";
import { MasterMenu } from "../models/json";
import { MenuItemWithOptions, MenuCategoryWithOptions } from "../models/menu";
/**
 *
 * @param categoryTitle Title to find a duplicate for
 * This function is not of use if we use key value pairs instead of arrays
 */
export const checkForDuplicateCategory = (categoryTitle: string): boolean => {
  for (let category in jsonVar().categories)
    if (category === categoryTitle) {
      return true;
    }
  return false;
};

export const removeDuplicateCategories = (
  categories: MenuCategoryWithOptions[]
): MenuCategoryWithOptions[] => {
  const titlesArray = categories.map(
    (item: MenuCategoryWithOptions) => item.title
  );
  const uniqueArray = categories.filter(
    (item: MenuCategoryWithOptions, index: number) =>
      titlesArray.indexOf(item.title) === index
  );

  return uniqueArray;
};

export const openItemModal = (
  item: MenuItemWithOptions,
  categoryTitle: string
): void => {
  openModalVar(MODAL_OPEN.MEAL_ITEM);
  modalValueVar({
    item,
    category: categoryTitle,
  });
};

export const loadNewMenu = (
  menuData: any,
  isToSync = false,
  aggregator: AGGREGATORS = AGGREGATORS.UBER_EATS
): void => {
  const menuObject = menuData[aggregator]
    ? menuData[aggregator]
    : menuData.master;

  rootMenuDataVar(menuData);
  isMasterMenuVar(!!menuData.master);
  jsonVar(menuObject);
  savedMenuNameVar(menuData.menuName);
  jsonMenuNameVar(menuData.menuName);
  // selectedStoresVar(menuObject.storeIds);

  if (isToSync) {
    menuToSyncVar(menuObject);
  }
};

export const parseImageName = (imageName: string): string =>
  imageName.split(" ").join("_");

export const getNewMenuNameForCategory = (categoryTitle: string) => {
  return `${categoryTitle} Menu`;
};
/**
 *
 * @param categoryTitle Title of category to be suspended
 * @param serviceAvailability service availability object for all week days
 * @return void
 */
export const addNewMenuToJson = (
  currentMenuKey: string,
  categoryTitle: string,
  serviceAvailability: {
    [key: string]: { day_of_week: string; time_periods: any[] };
  }
) => {
  jsonVar(
    produce(jsonVar(), (draftJson: MasterMenu) => {
      //menu name => Name of Category + Menu
      const newMenuName = getNewMenuNameForCategory(categoryTitle);
      //copy old menu
      const oldMenuKey = currentMenuKey;
      const newMenu = { ...draftJson.menus[oldMenuKey] };
      const oldMenu = { ...draftJson.menus[oldMenuKey] };
      //set availability of the new Menu
      const serviceAvailabilityNewMenu = { ...serviceAvailability };
      // const serviceAvailabilityOldMenu = {} as typeof serviceAvailability;
      // for (let nameOfDay in serviceAvailability) {
      //   //if endTime of newMenu is storeClosingTime, set startTime = storeOpeningTime
      //   serviceAvailabilityOldMenu[nameOfDay] = {
      //     day_of_week: nameOfDay,
      //     time_periods: [
      //       {
      //         start_time:
      //           serviceAvailability[nameOfDay].time_periods[0].end_time ===
      //           "storeClosingTime"
      //             ? "storeOpeningTime"
      //             : serviceAvailability[nameOfDay].time_periods[0].end_time,
      //         end_time:
      //           serviceAvailability[nameOfDay].time_periods[0].end_time ===
      //           "storeClosingTime"
      //             ? serviceAvailability[nameOfDay].time_periods[0].start_time
      //             : oldMenu?.service_availability[nameOfDay]?.time_periods?.[0]
      //                 ?.end_time || "storeClosingTime",
      //       },
      //     ],
      //   };
      // }

      // we don't need to update the availability
      //oldMenu.service_availability = serviceAvailabilityOldMenu;
      //finally, delete this category from the oldMenuObject and adding it to the newMenu
      delete oldMenu.category_ids[categoryTitle];

      newMenu.service_availability = serviceAvailabilityNewMenu;
      newMenu.id = newMenuName;
      newMenu.title.translations.en_uk = newMenuName;
      newMenu.category_ids = {
        [categoryTitle]: categoryTitle,
        ...oldMenu.category_ids,
      };

      draftJson.menus = {
        ...draftJson.menus,
        [oldMenu.id]: oldMenu,
        [newMenu.id]: newMenu,
      };
    })
  );
};
