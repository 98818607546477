/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";

type Props = {
  form: {
    description: any;
    price: number;
    kcal_lower: any;
    kcal_upper: any;
    servings: any;
    expiryDate: any;
    effectiveDate: any;
  };
  handleChange: (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => void;
};

const ModalNutritionalData: React.FC<Props> = ({ form, handleChange }) => {
  return (
    <div className="row">
      <div className="col-md-4 col-xs-12">
        <label className="col-form-label ml-2">Kcal - lower / serving:</label>
        <input
          type="text"
          name="kcal_lower"
          className="form-control"
          value={form.kcal_lower}
          onChange={handleChange}
        />
      </div>

      <div className="col-md-4 col-xs-12">
        <label className="col-form-label ml-2">Kcal - upper / serving:</label>
        <input
          type="text"
          name="kcal_upper"
          className="form-control"
          value={form.kcal_upper}
          onChange={handleChange}
        />
      </div>

      {form.servings !== 0 && (
        <div className="col-md-4 col-xs-12">
          <label className="col-form-label ml-2">Servings:</label>

          <select
            defaultValue={"{i+1}"}
            onChange={handleChange}
            name="servings"
            className="form-control"
          >
            <option value="" hidden>
              choose
            </option>
            {[...Array(20)].map((option, i) => {
              if (i + 1 === form.servings)
                return (
                  <option value="{i+1}" key={i}>
                    {i + 1}
                  </option>
                );
              else
                return (
                  <option value={i + 1} key={i}>
                    {i + 1}
                  </option>
                );
            })}
          </select>
        </div>
      )}
    </div>
  );
};

export default ModalNutritionalData;
