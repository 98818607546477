/* eslint-disable no-restricted-syntax */
import { MenuCategories, MenuCategory, ModifierOption } from "../models/json";
import {
  MenuItemWithOptions,
  MenuCategoryWithOptions,
  ItemObject,
  MenuLocal,
  MenuCategoryCollection,
} from "../models/menu";
import { MenuModifierGroup } from "../models/json";

type CategoryToTree = {
  id: string;
  title: string;
  children: { [key: string]: ModifierOption | MenuItemWithOptions };
  description: string;
};

// MAP ITEMS TO AN OBJECT WITH EACH ID AS KEY, SO THAT WE CAN ACCESS ITEMS WITHOUT ITERATION
export const mapItemsToObjectWithKeys = (
  items: MenuItemWithOptions[] | MenuModifierGroup[]
) => {
  const itemObject: ItemObject = {};
  items.forEach((item: MenuItemWithOptions | MenuModifierGroup) => {
    itemObject[item.id] = item;
  });
  return itemObject;
};

// CATEGORY TREE STRUCTURE
const categoryToTree = (category: MenuCategory): CategoryToTree => ({
  id: category.id,
  title: category.id,
  children: category.entities,
  description: category.subtitle
    ? category.subtitle.translations.en_uk
    : "No description yet.",
});

// MAP CATEGORIES TO A READABLE STRUCTURE BY SORTABLE TREE
const mapCategoriesToTree = (key: string, json: any) => {
  const jsonTree: { title: string; children: MenuCategories } = {
    title: key,
    children: json[key],
  };

  const categoriesTree: {
    [key: string]: CategoryToTree | MenuCategoryWithOptions;
  } = {};
  for (let key in jsonTree.children) {
    let catToTree = categoryToTree(jsonTree.children[key]);
    if (catToTree) categoriesTree[catToTree.id] = catToTree;
  }

  const ITEMS_BY_ID = mapItemsToObjectWithKeys(
    Object.values(json.items as MenuItemWithOptions)
  );
  for (const category in categoriesTree) {
    const categoryChildren = categoriesTree[category].children;
    const newChildren = {};
    for (let key in categoryChildren) {
      let child = categoryChildren[key];
      newChildren[child.id] = ITEMS_BY_ID[child.id];
    }

    categoriesTree[category].children = newChildren;
  }

  const finalJsonTree = {} as MenuLocal;
  finalJsonTree.title = jsonTree.title;
  finalJsonTree.children = categoriesTree as MenuCategoryCollection;
  return finalJsonTree;
};

// RETURN SORTABLE TREE
// eslint-disable-next-line @typescript-eslint/ban-types
const mapJsonToArray = (json: {}): MenuLocal => {
  let jsonTree = {} as MenuLocal;
  // eslint-disable-next-line no-prototype-builtins
  if ("categories" in json) {
    jsonTree = mapCategoriesToTree("categories", json);
  }
  const jsonArray = jsonTree;
  return jsonArray;
};

export default mapJsonToArray;
