/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import React from "react";
import ReactTooltip from "react-tooltip";
import { jsonVar, snackbarStateVar } from "../../apollo/cache";
import { MenuItemWithOptions } from "../../models/menu";

type Props = {
  item: MenuItemWithOptions;
  indicatorFlagStyle: any;
};

const MealSetIndicator: React.FC<Props> = ({ item, indicatorFlagStyle }) => {
  const isObjectEmpty = (obj: any) => {
    for (var i in obj) return false;
    return true;
  };
  if (!item.id.includes("set") || isObjectEmpty(item.modifier_group_ids.ids)) {
    return null;
  }

  const modifier =
    jsonVar().modifier_groups[Object.values(item.modifier_group_ids.ids)[0]];
  if (!modifier) {
    snackbarStateVar({
      open: true,
      message: "Modifier Not Found",
    });
    return null;
  }
  if (isObjectEmpty(modifier.modifier_options)) {
    return null;
  }

  return (
    <>
      <div className={indicatorFlagStyle} data-tip data-for="mealSetItems">
        {!isObjectEmpty(modifier.modifier_options) &&
          Object.keys(modifier.modifier_options).length <= 9 && (
            <img
              src={require(`../../media/images/filter_${
                Object.keys(modifier.modifier_options).length
              }_white_18dp.svg`)}
              alt="meal set indicator"
            />
          )}

        {Object.keys(modifier.modifier_options).length > 9 && (
          <img
            src={require(`../../media/images/filter_9_plus_white_18dp.svg`)}
            alt="meal set indicator"
          />
        )}
      </div>
      <ReactTooltip
        id="mealSetItems"
        place="bottom"
        type="dark"
        effect="float"
        globalEventOff="click"
      >
        <span>amount of items in meal group</span>
      </ReactTooltip>
    </>
  );
};

export default MealSetIndicator;
