import { ReactiveVar, makeVar, gql } from "@apollo/client";
import { ItemWithCategory } from "../../../models/menu";
import { MODAL_OPEN } from "../../../config/config";
import { AGGREGATORS, STATUS } from "../../../constants/constants";
import { MasterMenu } from "../../../models/json";

/**
 * Documentation: these fields are used in the cache.ts file
 * as typePolicies, in order for the read function to return the reactiveVar
 * so e.g. json can be returned as an Apollo cache query
 * TO DO: why not use the reactiveVar directly? Why use the Apollo cache for this?
 */
export const menuFields = {
  json: {
    read() {
      return jsonVar();
    },
  },
  openModal: {
    read() {
      return openModalVar();
    },
  },
  modalValue: {
    read() {
      return modalValueVar();
    },
  },
  recentlyDeletedItems: {
    read() {
      return recentlyDeletedVar();
    },
  },
  savedMenus: {
    read() {
      return savedMenusVar();
    },
  },
  changedMenuStatus: {
    read() {
      return changedMenuStatusVar();
    },
  },
  liveToggle: {
    read() {
      return liveToggleVar();
    },
  },
  menuToSync: {
    read() {
      return menuToSyncVar();
    },
  },
  expandedCategories: {
    read() {
      return expandedCategoriesVar();
    },
  },
  suspendItemId: {
    read() {
      return suspendItemIdVar();
    },
  },
  addedOptionIds: {
    read() {
      return addedOptionIdsVar();
    },
  },
  isReadOnly: {
    read() {
      return isReadOnlyVar();
    },
  },
  menuKey: {
    read() {
      return menuKeyVar();
    },
  },
  isMasterMenu: {
    read() {
      return isMasterMenuVar();
    },
  },
  rootMenuData: {
    read() {
      return rootMenuDataVar();
    },
  },
  currentAggregator: {
    read() {
      return currentAggregatorVar();
    },
  },
  isOpened: {
    read() {
      return isSideNavOpen();
    },
  },
  lastModified: {
    read() {
      return lastModifiedVar();
    },
  },
  currentVersion: {
    read() {
      return currentVersionVar();
    },
  },
  latestVersion: {
    read() {
      return latestVersionVar();
    },
  },
  timeTravelDate: {
    read() {
      return timeTravelDateVar();
    },
  },
};

export const prevRootMenuVar: ReactiveVar<any> = makeVar<any>(null);
//reactive var type MasterMenu
export const jsonVar: ReactiveVar<MasterMenu> = makeVar<any>({});

export const timeTravelDateVar: ReactiveVar<string> = makeVar<string>("");
export const GET_TIME_TRAVEL_DATE = gql`
  query GetTimeTravelDate {
    timeTravelDate @client
  }
`;

// MODAL TOGGLE
export const openModalVar: ReactiveVar<MODAL_OPEN> = makeVar<MODAL_OPEN>(
  MODAL_OPEN.NONE
);
export const GET_OPEN_MODAL = gql`
  query GetOpenModal {
    openModal @client
  }
`;

// MODAL ITEM
const initialModalValue: ItemWithCategory = {} as ItemWithCategory;
export const modalValueVar: ReactiveVar<ItemWithCategory> =
  makeVar<ItemWithCategory>(initialModalValue);

// DELETED ITEMS
export const recentlyDeletedVar: ReactiveVar<any> = makeVar<any>([]);

export const savedMenusVar: ReactiveVar<any> = makeVar<any>([]);

export const changedMenuStatusVar: ReactiveVar<STATUS> = makeVar<STATUS>(
  STATUS.START
);
export const GET_CHANGED_MENU_STATUS = gql`
  query GetChangedMenuStatus {
    changedMenuStatus @client
  }
`;

export const liveToggleVar: ReactiveVar<boolean> = makeVar<boolean>(false);

// SYNC MENU
export const menuToSyncVar: ReactiveVar<any> = makeVar<any>({});
export const GET_MENU_TO_SYNC = gql`
  query GetMenuToSync {
    menuToSync @client
  }
`;

// EXPANDED CATEGORIES
export const expandedCategoriesVar: ReactiveVar<string[]> = makeVar<string[]>(
  []
);
export const GET_EXPANDED_CATEGORIES = gql`
  query GetExpandedCategories {
    expandedCategories @client
  }
`;

// SUSPEND ITEM ID VAR
export const suspendItemIdVar: ReactiveVar<string> = makeVar<string>("");
export const GET_SUSPEND_ITEM_ID = gql`
  query GetSuspendtemId {
    suspendItemId @client
  }
`;

// ADDED OPTIONS TO MEAL SET
export const addedOptionIdsVar: ReactiveVar<string[]> = makeVar<string[]>([]);
export const GET_ADDED_OPTION_IDS = gql`
  query GetAddedOptionIds {
    addedOptionIds @client
  }
`;

export const isReadOnlyVar: ReactiveVar<boolean> = makeVar<boolean>(false);
export const GET_IS_READ_ONLY = gql`
  query GetIsReadOnly {
    isReadOnly @client
  }
`;

// MENU KEY
export const menuKeyVar: ReactiveVar<string> = makeVar<string>("");
export const GET_MENU_KEY = gql`
  query GetMenuKey {
    menuKey @client
  }
`;

// IS MASTER MENU
export const isMasterMenuVar: ReactiveVar<boolean> = makeVar<boolean>(true);
export const GET_IS_MASTER_MENU = gql`
  query GetIsMasterMenu {
    isMasterMenu @client
  }
`;

// ROOT MENU DATA
export const rootMenuDataVar: ReactiveVar<any> = makeVar<any>({});
export const GET_ROOT_MENU_DATA = gql`
  query GetRootMenuData {
    rootMenuData @client
  }
`;

// CURRENT AGGREGATOR
export const currentAggregatorVar: ReactiveVar<AGGREGATORS> =
  makeVar<AGGREGATORS>(AGGREGATORS.UBER_EATS);
export const GET_CURRENT_AGGREGATOR = gql`
  query GetCurrentAggregator {
    currentAggregator @client
  }
`;

// IS SIDE NAV OPEN
export const isSideNavOpen: ReactiveVar<boolean> = makeVar<boolean>(false);
export const GET_IS_SIDE_NAV_OPEN = gql`
  query GetIsSideNavOpen {
    isOpened @client
  }
`;

// LAST MODIFIED
export const lastModifiedVar: ReactiveVar<string> = makeVar<string>("");
export const GET_LAST_MODIFIED = gql`
  query GetLastModified {
    lastModified @client
  }
`;

export const currentVersionVar: ReactiveVar<string> = makeVar<string>("");
export const GET_CURRENT_VERSION = gql`
  query GetCurrentVersion {
    currentVersion @client
  }
`;

export const latestVersionVar: ReactiveVar<string> = makeVar<string>("");
export const GET_LATEST_VERSION = gql`
  query GetLatestVersion {
    latestVersion @client
  }
`;

type SnackbarState = {
  open: boolean;
  message: string;
};
//Snackbar state
export const snackbarStateVar: ReactiveVar<SnackbarState> =
  makeVar<SnackbarState>({
    open: false,
    message: "",
  });
