/* eslint-disable no-nested-ternary */
import produce from "immer";
import {
  MasterMenu,
  MenuCategory,
  ModifierOption,
  TranslationObject,
} from "../../../models/json";
import {
  MenuItemWithOptions,
  MenuCategoryWithOptions,
  RecentDeletedItem,
} from "../../../models/menu";
import { MenuModifierGroup } from "../../../models/json";
import { recentlyDeletedVar, jsonVar, snackbarStateVar } from "../../cache";

export const addToRecentlyDeleted = (
  item:
    | MenuItemWithOptions
    | MenuCategoryWithOptions
    | MenuCategory
    | ModifierOption
    | { id: string; title: TranslationObject },
  deletedFrom?: string | undefined,
  isModifier?: boolean,
  menuKey?: string
): void => {
  if (!item) {
    snackbarStateVar({
      open: true,
      message: "No Item to delete",
    });
    return;
  }
  const deletedItem: RecentDeletedItem = isModifier
    ? { item, modifier: deletedFrom }
    : deletedFrom
    ? { item, category: deletedFrom }
    : { item };
  if (
    recentlyDeletedVar().find(
      (item: RecentDeletedItem) =>
        item.item.id === deletedItem.item.id && item.menuKey === menuKey
    )
  ) {
    snackbarStateVar({
      open: true,
      message: "Item Already Deleted",
    });
    return;
  }
  //add menu key to delete object
  if (menuKey) {
    deletedItem.menuKey = menuKey;
  }
  recentlyDeletedVar([deletedItem, ...recentlyDeletedVar()]);
};
/**
 *  Search for the item in category and delete item
 * @param menuItem - MenuItemLocal -- Item to be deleted
 * @param categoryTitle - string -- category Name from where the item will be deleted
 */
export const deleteItem = (
  menuItem: MenuItemWithOptions,
  categoryTitle: string
): void => {
  try {
    addToRecentlyDeleted(menuItem, categoryTitle);
    jsonVar(
      produce(jsonVar(), (draftJson) => {
        delete draftJson.categories[categoryTitle].entities[menuItem.id];
      })
    );
  } catch {
    //report error with specified error logging function
  }
};
/**
 *  Find and delete category with the given name from Json Data
 * @param categoryTitle - string -- Title of category to be deleted
 */
export const deleteCategoryFromJson = (
  categoryTitle: string,
  menuKey: string
): void => {
  const deletedItem = jsonVar().categories[categoryTitle];
  addToRecentlyDeleted(
    deletedItem as MenuCategory,
    null as unknown as string,
    false,
    menuKey
  );

  jsonVar(
    produce(jsonVar(), (draftJson: MasterMenu) => {
      delete draftJson.menus[menuKey].category_ids[categoryTitle];
    })
  );
  snackbarStateVar({
    message: `${categoryTitle} moved to trash`,
    open: true,
  });
};
/**
 *
 * @param categoryTitle title of category to add in category_ids
 * @param menuKey title of the menu
 */
export const addCategoryId = (
  categoryTitle: string,
  menuKey: string = ""
): void => {
  jsonVar(
    produce(jsonVar(), (draftJson: MasterMenu) => {
      //if key provided add it to specific menu only
      if (menuKey && menuKey !== "") {
        draftJson.menus[menuKey].category_ids[categoryTitle] = categoryTitle;
      } else {
        for (const menu in draftJson.menus) {
          //if the title doesn't already exist, add it to category_ids
          if (!draftJson.menus[menu].category_ids[categoryTitle]) {
            draftJson.menus[menu].category_ids[categoryTitle] = categoryTitle;
          }
        }
      }
    })
  );
};
/**
 *  Add a category to jsonVar
 * @param category - MenuCategory to add into JsonVar
 */
export const addCategory = (category: MenuCategory): void => {
  jsonVar(
    produce(jsonVar(), (draftJson: MasterMenu) => {
      draftJson.categories[category.title.translations.en_uk] = category;
    })
  );
};

export const addItemToDeletedCategory = (
  item: MenuItemWithOptions,
  categoryTitle: string
): void => {
  recentlyDeletedVar(
    produce(recentlyDeletedVar(), (draftDeletedItems: RecentDeletedItem[]) => {
      const index = draftDeletedItems.findIndex((item: RecentDeletedItem) => {
        if (
          "title" in item.item &&
          item.item.title.translations.en_uk === categoryTitle
        )
          return true;
        return false;
      });
      (draftDeletedItems[index].item as MenuCategory).entities = {
        [item.id]: {
          id: item.id,
          type: "ITEM",
        },
        ...(draftDeletedItems[index].item as MenuCategory).entities,
      };
    })
  );
};
/**
 * Delete an option from Modifier Group
 * @param item - MenuItemLocal to be deleted from
 * @param modifierGroupId -string id to delete
 * @param isUndo - boolean
 * @returns void
 */
export const deleteOptionFromModifier = (
  item: MenuItemWithOptions,
  modifierGroupId: string,
  isUndo?: boolean
): MenuModifierGroup => {
  const modifier = jsonVar().modifier_groups[modifierGroupId];
  //the result of find() can be undefined
  //adding condition as a WorkAround
  if (modifier) {
    if (!isUndo) {
      addToRecentlyDeleted(
        { id: item.id, title: item.title },
        modifierGroupId,
        true
      );
    }
    const getFilteredModfierOptionsCollection = (
      modifierOptions: { [key: string]: ModifierOption },
      item: MenuItemWithOptions
    ) => {
      const finalModifierOptions: { [key: string]: ModifierOption } = {};
      for (let option in modifierOptions) {
        if (modifierOptions[option].id !== item.id)
          finalModifierOptions[option] = modifierOptions[option];
      }
      return finalModifierOptions;
    };
    return {
      ...modifier,
      modifier_options: getFilteredModfierOptionsCollection(
        modifier.modifier_options,
        item
      ),
    };
  } else {
    return {} as MenuModifierGroup;
  }
};
