import {
  Card,
  CardContent,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import styles from "../styles/Repeat.module.scss";
import React, { useEffect, useState } from "react";
import { CustomStyledDropDown } from "../Utils/CustomUI";
import {
  DAYS,
  DaysOfWeekDefaultArray,
  MONTHS,
  WeeksInMonth,
} from "../Utils/constants";
import {
  RadioValue,
  RepeatByMonthDates,
  RepeatByWeekDays,
} from "../Utils/types";
import { useQuery } from "@apollo/client";
import { CUSTOMIZATION_SCHEDULE } from "../../../../apollo/operations/queries/localQueries";
import { cache } from "../../../../apollo/cache";

/**
 *
 *
 * @returns Yearly Variant for the Repeat Secion in Schedule Model
 */
const Yearly = () => {
  const {
    data: { schedule },
  }: any = useQuery(CUSTOMIZATION_SCHEDULE);

  const [radioValue, setRadioValue] = useState<RadioValue>(
    schedule.weekDays?.length > 0 && schedule.pos && schedule.month
      ? "dayOfWeek"
      : "dayOfMonth"
  );

  //When radio value changed, send filed values to parent
  const onRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value: RadioValue = e.target.value as RadioValue;
    setRadioValue(value);
  };

  const [data, setData] = useState({
    day: schedule.day,
    month: schedule.month,
    pos: schedule.pos,
    weekDays: schedule.weekDays?.[0],
    monthfor1: schedule.month,
    monthfor2: schedule.month,
  });

  const onUpdate = (
    radio: RadioValue,
    payload: RepeatByMonthDates | RepeatByWeekDays
  ) => {
    setData({ ...data, ...payload });
    setRadioValue(radio);
  };

  useEffect(() => {
    if (schedule && schedule.repeat === "yearly") {
      const tData = { ...schedule };
      //deleting all values to reset the state before updating cache
      delete tData.weekDays;
      delete tData.pos;
      delete tData.day;
      delete tData.month;
      //updaing cache with the updated data
      //adding default with an OR (||)operator incase user misses
      //add only those props that are required, using AND (&&)
      cache.writeQuery({
        query: CUSTOMIZATION_SCHEDULE,
        data: {
          schedule: {
            ...tData,
            ...(radioValue === "dayOfWeek" && {
              weekDays: [data.weekDays] || ["Monday"],
              month: data.month || "0",
              pos: data.pos || "0",
              monthfor2: data.month || "0",
            }),
            ...(radioValue === "dayOfMonth" && {
              month: data.month || "0",
              day: data.day || "0",
              monthfor1: data.month || "0",
            }),
          },
        },
      });
    }
  }, [data, radioValue, schedule]);

  return (
    <Card variant="outlined">
      <CardContent>
        <RadioGroup
          defaultValue="dayOfMonth"
          aria-label="Dat of the Month"
          name="repeat-week"
          value={radioValue}
          onChange={onRadioChange}
        >
          <FormControlLabel
            className={styles.radio_item}
            value="dayOfMonth"
            control={<Radio color="primary" />}
            label={
              <DayOfMonth
                data={schedule}
                onUpdteDayOfMonth={(data: RepeatByMonthDates) =>
                  onUpdate("dayOfMonth", data)
                }
              />
            }
          />
          <FormControlLabel
            className={styles.radio_item}
            value="dayOfWeek"
            control={<Radio color="primary" />}
            label={
              <DayOfWeek
                data={schedule}
                onUpdteDayOfWeek={(data: RepeatByWeekDays) =>
                  onUpdate("dayOfWeek", data)
                }
              />
            }
          />
        </RadioGroup>
      </CardContent>
    </Card>
  );
};
type DayOfMonthProps = {
  data: RepeatByMonthDates;
  onUpdteDayOfMonth: (data: RepeatByMonthDates) => void;
};
/**
 *
 * @returns Radio UI for when the Day Of month Radio Item is selected
 */
const DayOfMonth = ({ onUpdteDayOfMonth, data }: DayOfMonthProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const dataUpdate = { ...data, [e.target.name]: e.target.value };
    onUpdteDayOfMonth(dataUpdate);
  };

  return (
    <>
      <CustomStyledDropDown
        size={"large"}
        name="day"
        onChange={handleChange}
        value={data.day}
      >
        {DAYS.map((value, index) => (
          <option value={index}>{value}</option>
        ))}
      </CustomStyledDropDown>{" "}
      of
      <CustomStyledDropDown
        name="month"
        onChange={handleChange}
        value={data.monthfor1}
      >
        {MONTHS.map((value, index) => (
          <option value={index}>{value}</option>
        ))}
      </CustomStyledDropDown>{" "}
    </>
  );
};

type DayOfWeekProps = {
  data: RepeatByWeekDays;
  onUpdteDayOfWeek: (data: RepeatByWeekDays) => void;
};
/**
 *
 * @returns Radio UI for when the Day Of week Radio Item is selected
 */
const DayOfWeek = ({ onUpdteDayOfWeek, data }: DayOfWeekProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const dataUpdate = { ...data, [e.target.name]: e.target.value };
    onUpdteDayOfWeek(dataUpdate);
  };
  return (
    <>
      {" "}
      <CustomStyledDropDown name="pos" onChange={handleChange} value={data.pos}>
        {WeeksInMonth.map((value, index) => (
          <option value={index}>{value}</option>
        ))}
      </CustomStyledDropDown>
      <CustomStyledDropDown
        name="weekDays"
        onChange={handleChange}
        defaultValue={"Monday"}
        value={data.weekDays}
      >
        {DaysOfWeekDefaultArray.map((day) => (
          <option value={day.long}>{day.short}</option>
        ))}
      </CustomStyledDropDown>
      of
      <CustomStyledDropDown
        name="month"
        defaultValue={0}
        onChange={handleChange}
        value={data.monthfor2}
      >
        {MONTHS.map((value, index) => (
          <option value={index}>{value}</option>
        ))}
      </CustomStyledDropDown>
    </>
  );
};
export default Yearly;
