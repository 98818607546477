import React from "react";
import { useQuery } from "@apollo/client";
import { Button, Dialog } from "@material-ui/core";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import newIcon from "../../../media/images/ic-modal-new.svg";
import ModalHeader from "../shared/ModalHeader";
import {
  cache,
  customizationToEditVar,
  GET_CUSTOMIZATION_TO_EDIT,
  openModalVar,
} from "../../../apollo/cache";
import { MODAL_OPEN } from "../../../config/config";
import CustomizationsProvider from "./CustomizationsProvider";
import CustomizationFields from "./CustomizationFields";
import CustomizationEditor from "./CustomizationEditor";
import { CUSTOMIZATION } from "./utils/config";
import CustomizationsSubmitButton from "./CustomizationsSubmitButton";
import { Customization } from "../../../models/customizations";
import MoreInfoTooltip from "../../shared/MoreInfoTooltip";
import { makeStyles } from "@material-ui/styles";

import style from "./styles/CustomizationModal.module.scss";
import { CUSTOMIZATION_SCHEDULE } from "../../../apollo/operations/queries/localQueries";

type Props = {
  isOpen: boolean;
};

const useStyles = makeStyles({
  paperWidthMd: {
    maxWidth: "900px",
    width: "100%",
  },
});

const CustomizationModal = ({ isOpen }: Props) => {
  const classes = useStyles();
  const { data } = useQuery(GET_CUSTOMIZATION_TO_EDIT);

  const closeModal = () => {
    //reset schedule object in cache
    openModalVar(MODAL_OPEN.NONE);
    cache.writeQuery({
      query: CUSTOMIZATION_SCHEDULE,
      data: {
        schedule: {},
      },
    });
    customizationToEditVar({} as Customization);
  };
  const handleClose = (_event: any, reason: string) => {
    if (reason === "backdropClick") {
      return false;
    }

    closeModal();
  };

  // switch case or if else is more readble
  const action =
    // eslint-disable-next-line no-nested-ternary
    Object.values(data.customizationToEdit).length === 0
      ? "Add"
      : data.customizationToEdit.clone
      ? "Clone"
      : "Edit";

  // console.log(data.customizationToEdit);
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="Save Menu"
      open={isOpen}
      maxWidth="md"
      classes={classes}
    >
      <ModalHeader
        onClose={closeModal}
        icon={newIcon}
        title={`${action} customisation`}
      />
      <CustomizationsProvider populatedState={data.customizationToEdit}>
        <MuiDialogContent>
          <div className={style.modalContent}>
            <div className={style.uiForm}>
              {!data.customizationToEdit.clone && (
                <>
                  <CustomizationFields.Toggle name={CUSTOMIZATION.GLOBAL} />
                  <CustomizationFields.StoresAutocomplete
                    label="limit applicable stores"
                    moreInfoComponent={
                      <MoreInfoTooltip tooltipText="more info about stores" />
                    }
                  />
                  <CustomizationFields.StoreGroupsAutocomplete label="limit applicable groups" />
                  <CustomizationFields.Select
                    name={CUSTOMIZATION.AGGREGATORS}
                    options={["UberEats", "Deliveroo", "JustEat"]}
                    multiple
                  />
                  <CustomizationFields.CustomizationSchedule />
                </>
              )}
              <CustomizationFields.Text name={CUSTOMIZATION.SLUG} />
              {!data.customizationToEdit.clone && (
                <>
                  <CustomizationFields.Text
                    name={CUSTOMIZATION.DESCRIPTION}
                    moreInfoComponent={
                      <MoreInfoTooltip tooltipText="more info about description" />
                    }
                  />
                </>
              )}
            </div>

            {!data.customizationToEdit.clone && <CustomizationEditor />}

            {/* 
              ---- ACTION FORMS ARE NO LONGER USED - ARE REPLACED WITH CODE EDITOR
              ---- FOR NOW COMMENTED OUT
              <CustomizationActionForms />} 
            */}
          </div>
        </MuiDialogContent>
        <MuiDialogActions>
          <div className={style.uiModalAction}>
            {/* <CustomizationsList /> */}
            <Button
              onClick={closeModal}
              color="primary"
              variant="outlined"
              size="large"
            >
              Cancel
            </Button>
            <CustomizationsSubmitButton action={action} />
          </div>
        </MuiDialogActions>
      </CustomizationsProvider>
    </Dialog>
  );
};

export default CustomizationModal;
