import React from "react";
import ReactTooltip from "react-tooltip";
import IconButton from "@material-ui/core/IconButton";
import { useQuery } from "@apollo/client";
import { addItemToCategory } from "../../../../apollo/operations/actions/actions";
import { MenuItemWithOptions } from "../../../../models/menu";
import { MenuModifierGroup } from "../../../../models/json";
import undoIcon from "../../../../media/images/undo-icon.svg";
import { GET_ADDED_OPTION_IDS } from "../../../../apollo/cache";

import style from "./styles/ModifierGroups.module.scss";

type Props = {
  item: MenuItemWithOptions;
  categoryTitle: string;
  modifier: MenuModifierGroup;
  handleDelete: (item: MenuItemWithOptions, isUndo: boolean) => void;
};

const UndoOptionButton = ({
  item,
  modifier,
  categoryTitle,
  handleDelete,
}: Props) => {
  const {
    data: { addedOptionIds },
  }: any = useQuery(GET_ADDED_OPTION_IDS);

  const handleUndo = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    handleDelete(item, true);
    addItemToCategory(item, categoryTitle);
  };

  if (
    modifier.title.translations.en_uk !== "Your main order" &&
    !addedOptionIds.includes(item.id)
  ) {
    return null;
  }

  return (
    <>
      <IconButton
        aria-label="undo"
        data-tip
        data-for="undo"
        className={style.actionIcon}
        onClick={handleUndo}
      >
        <img src={undoIcon} alt="undo" />
      </IconButton>
      <ReactTooltip
        id="undo"
        place="bottom"
        type="dark"
        effect="float"
        globalEventOff="click"
      >
        <span>Unpack item from meal group to category</span>
      </ReactTooltip>
    </>
  );
};

export default UndoOptionButton;
