/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { areChangesSavedVar, cache } from "../../../../apollo/cache";
import { updateItemInLocalOpeningHours } from "../../../../apollo/operations/actions/actions";
import {
  hoursToString,
  stringToHours,
  validateEmptyField,
  validateHours,
  validateMinutes,
} from "../../helpers/openingHoursHelpers";
import { HoursType, StoreType } from "../../../../models/stores";

import style from "../styles/HoursColumn.module.scss";
import { GET_RESTAURANT_DATA } from "../../../../apollo/operations/queries/serverQueries";

interface Props {
  weekDay: string;
  store: StoreType;
  setValidDay: (isValid: boolean, weekDay: string) => void;
  handleSaveStatus: () => void;
}

const HoursColumn = ({
  store,
  weekDay,
  setValidDay,
  handleSaveStatus,
}: Props) => {
  const [hours, setHours] = useState<HoursType>(
    store[weekDay] && {
      openHours: stringToHours(store[weekDay].open).hours,
      openMinutes: stringToHours(store[weekDay].open).minutes,
      closeHours: stringToHours(store[weekDay].close).hours,
      closeMinutes: stringToHours(store[weekDay].close).minutes,
    }
  );

  const [validationError, setValidationError] = useState("");
  const [isEnterPressed, setEnter] = useState(false);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    handleSaveStatus();
    const updatedHours = {
      ...hours,
      [e.target.name]: e.target.value,
    };

    setHours(updatedHours);
  };

  const hanldeValidationAndSave = () => {
    const isFieldEmpty = Object.values(hours).some((field) =>
      validateEmptyField(field)
    );
    const isInputValid = validateHours(hours) && validateMinutes(hours);

    if (isInputValid) {
      setValidationError("");
      areChangesSavedVar(false);
      setValidDay(true, weekDay);
      const readResult = cache.readQuery({ query: GET_RESTAURANT_DATA });
      cache.writeQuery({
        query: GET_RESTAURANT_DATA,
        data: readResult,
      });
    } else {
      setValidationError(
        "The input is invalid. Please only use numbers between 00 and 23 for hours and numbers between 00 and 59 for minutes."
      );
      setValidDay(false, weekDay);
    }

    if (isFieldEmpty) {
      setValidationError("Please fill out field!");
      setValidDay(false, weekDay);
    }
  };

  const handleSubmit = (updatedHours: { [hours: string]: string }) => {
    const isFieldEmpty = Object.values(updatedHours).some((field) =>
      validateEmptyField(field)
    );

    if (!isFieldEmpty) {
      setTimeout(
        () =>
          updateItemInLocalOpeningHours({
            ...store,
            [weekDay]: {
              open: hoursToString(
                updatedHours.openHours,
                updatedHours.openMinutes
              ),
              close: hoursToString(
                updatedHours.closeHours,
                updatedHours.closeMinutes
              ),
            },
          }),
        500
      );
    }
  };

  // TO DO - Validation allows for a number and a string in a string e.g. '2d' would pass
  const handleBlur = () => {
    hanldeValidationAndSave();
    setEnter(false);
    handleSubmit(hours);
  };

  const handleEnter = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      setEnter(true);
      hanldeValidationAndSave();
      handleSubmit(hours);
    }
  };

  useEffect(() => {
    setHours(
      store[weekDay] && {
        openHours: stringToHours(store[weekDay].open).hours,
        openMinutes: stringToHours(store[weekDay].open).minutes,
        closeHours: stringToHours(store[weekDay].close).hours,
        closeMinutes: stringToHours(store[weekDay].close).minutes,
      }
    );
  }, [store]);

  if (!hours) {
    return null;
  }

  const formstyle = classnames(style.hoursRow, {
    [style.submitedRow]: isEnterPressed,
  });
  return (
    <div
      className={style.hoursColumn}
      onKeyDown={handleEnter}
      onClick={() => setEnter(false)}
    >
      <form className={formstyle}>
        <span>Opens:</span>
        <input
          value={hours.openHours}
          name="openHours"
          onChange={handleChange}
          maxLength={2}
          onBlur={handleBlur}
        />
        <span>:</span>
        <input
          value={hours.openMinutes}
          name="openMinutes"
          onChange={handleChange}
          maxLength={2}
          onBlur={handleBlur}
        />
      </form>
      <form className={formstyle}>
        <span>Closes:</span>
        <input
          value={hours.closeHours}
          name="closeHours"
          onChange={handleChange}
          maxLength={2}
          onBlur={handleBlur}
        />
        <span>:</span>
        <input
          value={hours.closeMinutes}
          name="closeMinutes"
          onChange={handleChange}
          maxLength={2}
          onBlur={handleBlur}
        />
      </form>
      <div>{validationError}</div>
    </div>
  );
};

export default HoursColumn;
