import React from "react";
import { SvgIcon } from "@material-ui/core";

export const CheckIcon = () => (
  <SvgIcon width="15" height="15" viewBox="0 0 15 15">
    <rect id="Rectangle" width="15" height="15" rx="3" fill="#328277" />
    <g
      id="icon-lib_feather_check"
      data-name="icon-lib/feather/check"
      fill="#FFF"
    >
      <g id="Group" transform="translate(2.708 3.75)">
        <path
          id="Path"
          d="M8.516.183a.625.625,0,0,1,.936.825L9.4,1.067,3.671,6.8a.625.625,0,0,1-.825.052L2.787,6.8l-2.6-2.6a.625.625,0,0,1,.825-.936l.059.052L3.229,5.47Z"
        />
      </g>
    </g>
  </SvgIcon>
);

export const UncheckIcon = () => (
  <SvgIcon width="15" height="15" viewBox="0 0 15 15">
    <g id="Rectangle" fill="none" stroke="#328277">
      <rect width="15" height="15" rx="3" stroke="none" />
      <rect x="0.5" y="0.5" width="14" height="14" rx="2.5" fill="none" />
    </g>
  </SvgIcon>
);

export const SearchIcon = () => (
  <SvgIcon>
    <g id="Group" transform="translate(4 4)">
      <path
        id="Oval"
        d="M0,7.667a7.667,7.667,0,1,1,7.667,7.666A7.667,7.667,0,0,1,0,7.667Z"
        transform="translate(-0.5 -0.5)"
      />
      <path
        id="Path"
        d="M.293.293A1,1,0,0,1,1.613.21l.094.083L5.332,3.918a1,1,0,0,1-1.32,1.5l-.094-.083L.293,1.707A1,1,0,0,1,.293.293Z"
        transform="translate(10.875 10.875)"
      />
    </g>
  </SvgIcon>
);

export const DisabledIcon = () => (
  <SvgIcon width="15" height="15" viewBox="0 0 15 15">
    <rect id="Rectangle" width="15" height="15" rx="3" fill="crimson" />
    <g xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white">
      <g id="Group" transform="translate(-0.7 -0.7) scale(0.7)">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z" />
      </g>
    </g>
  </SvgIcon>
);
