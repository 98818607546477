import React from "react";
import { useQuery } from "@apollo/client";
import { Checkbox, TextField, Chip } from "@material-ui/core";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import {
  mapSelectedStoresToOptions,
  mapStoresToOptions,
} from "../../helpers/autocompletes";
import { GET_STORES } from "../../apollo/cache";
import { GetStoresQueryType, AutocompleteValueType } from "../../models/stores";
import { CheckIcon, UncheckIcon } from "./Icons";

type Props = {
  selectedStores: string[];
  getSelectedStores: (stores: string[]) => void;
};

const StoresAutocomplete = ({ getSelectedStores, selectedStores }: Props) => {
  const { data } = useQuery<GetStoresQueryType>(GET_STORES);

  const handleSelection = (key: string) => {
    if (selectedStores.includes(key)) {
      const storeIndex = selectedStores.indexOf(key);
      const newStores = [...selectedStores];
      newStores.splice(storeIndex, 1);
      getSelectedStores(newStores);
    } else {
      const newStores = [...selectedStores];
      newStores.push(key);
      getSelectedStores(newStores);
    }
  };

  return (
    <Autocomplete
      multiple
      options={mapStoresToOptions((data as GetStoresQueryType).stores)}
      onChange={(_, values: AutocompleteValueType[]) => {
        values.forEach((value) => handleSelection(value.id));
      }}
      value={mapSelectedStoresToOptions(selectedStores, data)}
      disableCloseOnSelect
      disableClearable
      getOptionSelected={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option.name}
      renderOption={(option) => (
        <>
          <Checkbox
            icon={<UncheckIcon />}
            checkedIcon={<CheckIcon />}
            style={{ marginRight: 8 }}
            color="default"
            checked={selectedStores.includes(option.id)}
          />
          {option.name}
        </>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label="Stores"
          placeholder="Search Stores"
        />
      )}
      renderTags={(values: AutocompleteValueType[], getTagProps) =>
        values.map((value: AutocompleteValueType, index: number) => (
          <Chip
            variant="default"
            label={value.name}
            color="primary"
            {...getTagProps({ index })}
            onDelete={() => handleSelection(value.id)}
          />
        ))
      }
      filterOptions={createFilterOptions({ limit: 35 })}
    />
  );
};

export default StoresAutocomplete;
