import { ReactiveVar, makeVar } from "@apollo/client";
import { StoreType } from "../../../models/stores";

export const openingHoursFields = {
  selectedChannel: {
    read() {
      return selectedChannelVar();
    },
  },
  storeSearch: {
    read() {
      return storeSearchVar();
    },
  },
  storeListPagination: {
    read() {
      return storeListPaginationVar();
    },
  },
  localOpeningHours: {
    read() {
      return localOpeningHoursVar();
    },
  },
  areChangesSaved: {
    read() {
      return areChangesSavedVar();
    },
  },
  areChangesPublished: {
    read() {
      return areChangesPublishedVar();
    },
  },
  changedStores: {
    read() {
      return changedStoresVar();
    },
  }
};

// SELECTED CHANNEL
export const selectedChannelVar: ReactiveVar<string> =
  makeVar<string>("UberEats");

// STORE SEARCH
export const storeSearchVar: ReactiveVar<string> = makeVar<string>("");

// STORE LIST PAGINATION
const initialPagination = { start: 0, end: 13 };
export const storeListPaginationVar: ReactiveVar<any> =
  makeVar<any>(initialPagination);

// LOCAL OPENING HOURS
export const localOpeningHoursVar: ReactiveVar<any> = makeVar<any>(
  [] as StoreType[]
);

// CURRENT CHANGES
export const areChangesSavedVar: ReactiveVar<any> = makeVar<any>(true);
export const areChangesPublishedVar: ReactiveVar<any> = makeVar<any>(true);

// CHANGED STORES
export const changedStoresVar: ReactiveVar<any> = makeVar<any>([]);
