import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { client } from "./apollo/ApolloClient";
import { PersistentCache } from "./apollo/PersistentCache";

import "./index.scss";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <PersistentCache>
    <ApolloProvider client={client}>
      <Router>
        <App />
      </Router>
    </ApolloProvider>
  </PersistentCache>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
