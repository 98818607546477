import { MenuCategories, MenuCategory, MenuItems } from "../../../models/json";

export const keyValeObjectForMenuCategories = (
  categoriesArray: MenuCategory[]
): MenuCategories => {
  let categories: MenuCategories = {};
  for (let category of categoriesArray) {
    if (typeof category.title === "string") {
      categories[category.title] = category;
    } else {
      categories[category.title.translations.en_uk] = category;
    }
  }
  return categories;
};
export const categoriesArrayFromObject = (
  categories: MenuCategories
): MenuCategory[] => {
  return Object.values(categories);
};

export const itemsArrayFromObject = (items: MenuItems) => {
  return Object.values(items);
};
