import React, { useEffect, useState } from "react";
import { Button, Dialog, DialogActions, IconButton } from "@material-ui/core";
import styles from "./styles/ScheduleModal.module.scss";
import MuiDialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import ScheduleTabs from "./ScheduleTabs";
import Repeat from "./SectionRepeat";
import ScheduleTime from "./ScheduleTime";
import StopCondition from "./StopCondition";
import { ScheduleTabNames } from "./Utils/types";
import { cache } from "../../../apollo/cache";
import { CUSTOMIZATION_SCHEDULE } from "../../../apollo/operations/queries/localQueries";
import { useQuery } from "@apollo/client";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
};
/**
 *
 * Modal To set Customization Schedule of activation
 * @param isOpen : true when model is Open
 * @param onClose : Function to call when closing
 * @param onSave : Function to call when saving
 * @customSchedule : dispatch schedule data to parent
 *
 * Contains 3 sections for getting schedule, REPEAT, STOP CONDITION and TIME
 */
/** */
const ScheduleModal = ({ isOpen, onClose, onSave }: Props) => {
  const {
    data: { schedule },
    loading,
  }: any = useQuery(CUSTOMIZATION_SCHEDULE);

  const [activeTab, setActiveTab] = useState(
    schedule?.repeat
      ? schedule.repeat[0].toUpperCase() + schedule.repeat.slice(1)
      : "Daily"
  );

  //Closing Modal
  const handleClose = () => {
    onClose();
  };

  //handle tabl change
  const handleTabChange = (tab: ScheduleTabNames) => {
    setActiveTab(tab);
    //reset

    cache.writeQuery({
      query: CUSTOMIZATION_SCHEDULE,
      data: {
        schedule: {
          ...schedule,
          repeat: tab.toLowerCase(),
        },
      },
    });
  };
  useEffect(() => {
    setActiveTab(
      schedule?.repeat
        ? schedule.repeat[0].toUpperCase() + schedule.repeat.slice(1)
        : "Daily"
    );
  }, [schedule, schedule.repeat, activeTab]);
  if (loading) return <>Loading</>;

  return (
    <>
      <Dialog
        onClose={handleClose}
        aria-labelledby="Save Schedule"
        open={isOpen}
        disableBackdropClick
        maxWidth="md"
      >
        <div className={styles.header}>
          <h1 style={{ marginLeft: "20px" }}>{"Customization Schedule"}</h1>
          <IconButton
            aria-label="close"
            className={styles.btn_close}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <MuiDialogContent>
          {/* TOP TABS */}
          <ScheduleTabs activeTab={activeTab} onTabChanged={handleTabChange} />
          <div className={styles.panel}>
            {/* SECTION REPEAT */}
            <div>
              <Repeat activeTab={activeTab} />
            </div>
            {/* SECTION STOP CONDITIONS */}
            <StopCondition />
            {/* SECTION TIME */}
            <ScheduleTime />
          </div>
        </MuiDialogContent>
        <DialogActions className={styles.actionsContainer}>
          <Button color="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={onSave}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ScheduleModal;
