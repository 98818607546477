import React, { useRef } from "react";
import { ProductCollection } from "..";
import WarningModal from "../../shared/WarningModal";
import Collection from "./Collection";
import { v4 as uuid } from "uuid";

import style from "./styles/CollectionsList.module.scss";

type Props = {
  productCollections: ProductCollection[];
};

const CollectionsList: React.FC<Props> = ({ productCollections }) => {
  const deleteCollectionRef = useRef<any>();
  return (
    <>
      {productCollections.length === 0 && (
        <div>
          <hr className={style.uiSpacer} />
          <h2 className={style.uiTextCenter}>
            You haven't created any product collections yet. Go ahead and create
            one.
          </h2>
        </div>
      )}

      {productCollections.map((collection: ProductCollection) => (
        <Collection
          collection={collection}
          deleteCollectionRef={deleteCollectionRef}
          key={uuid()}
        />
      ))}
      <WarningModal
        modalMessage="Are you sure?"
        onConfirm={() =>
          deleteCollectionRef.current.deleteCollection(
            deleteCollectionRef.current.collectionToDelete
          )
        }
      />
    </>
  );
};

export default CollectionsList;
