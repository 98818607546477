/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  TableContainer,
  TableFooter,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import { useLazyQuery, useQuery } from "@apollo/client";
import TablePaginationActions from "@material-ui/core/TablePagination/TablePaginationActions";
import axios from "axios";
import useStyles from "../../OpeningHours/OpeningHoursTable/useStylesHours";
import ModalHeader from "../shared/ModalHeader";
import {
  changedMenuStatusVar,
  currentVersionVar,
  GET_CHANGED_MENU_STATUS,
  GET_CURRENT_VERSION,
  latestVersionVar,
  menuKeyVar,
  openModalVar,
  savedMenuNameVar,
} from "../../../apollo/cache";
import { MODAL_OPEN } from "../../../config/config";
import historyIcon from "../../../media/images/ic-history.svg";
import {
  GET_MENU,
  GET_MENU_HISTORY,
} from "../../../apollo/operations/queries/serverQueries";
import LoaderHandler from "../../shared/LoaderHandler";
import { getLocaleDateString } from "../../../helpers/scheduleDate";
import ViewMenuButton from "./ViewMenuButton";
import { STATUS } from "../../../constants/constants";
import { loadNewMenu } from "../../../helpers/menu";

import style from "./styles/HistoryModal.module.scss";

const STATIC_HEADERS = ["changes", "Modified", "Modified by", ""];

/* type HistoryItem = {
    authorName: string;
    clientId: string;
    menuVersion: string;
    commitMessage: string;
    savedTimestamp: number;
} */

const HistoryModal = ({ isOpen }) => {
  const {
    data: { currentVersion },
  } = useQuery(GET_CURRENT_VERSION);
  const { data, loading, error } = useQuery(GET_MENU_HISTORY, {
    fetchPolicy: "network-only",
    variables: {
      menuKey: menuKeyVar(),
    },
  });

  const {
    data: { changedMenuStatus },
  } = useQuery(GET_CHANGED_MENU_STATUS);

  const [getMenu, menu] = useLazyQuery(GET_MENU, {
    fetchPolicy: "network-only",
  });

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const classes = useStyles();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      return false;
    }

    openModalVar(MODAL_OPEN.NONE);
  };
  useEffect(() => {
    const url = menu.data && menu.data.getMenu.data.url;

    if (url) {
      axios({ method: "get", url: `${url}` })
        .then((res) => {
          loadNewMenu(res.data, true);
          savedMenuNameVar(res.data.menuName);
          changedMenuStatusVar(STATUS.FULFILLED);
          openModalVar(MODAL_OPEN.NONE);
        })
        .catch((err) => console.log(err));
    }
  }, [menu.data]);

  useEffect(() => {
    if (data && data.getMenus?.items.length > 0) {
      if (data.getMenus.items[0].s3VersionId && !currentVersionVar()) {
        latestVersionVar(data.getMenus.items[0].s3VersionId);
        currentVersionVar(data.getMenus.items[0].s3VersionId);
      }
    }
  }, [data]);

  return (
    <>
      <Dialog
        onClose={handleClose}
        aria-labelledby="History"
        open={isOpen}
        maxWidth="md"
        fullWidth
      >
        <ModalHeader
          onClose={() => openModalVar(MODAL_OPEN.NONE)}
          icon={historyIcon}
          title="Menu history"
          titleStyle={style.modalTitle}
        />
        <MuiDialogContent>
          <LoaderHandler
            loading={loading || changedMenuStatus === STATUS.PENDING}
            error={error}
            marginTop={5}
          >
            <TableContainer className={classes.container}>
              <Table aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center" style={{ width: "250px" }}>
                      Version
                    </TableCell>
                    {STATIC_HEADERS.map((title) => (
                      <TableCell align="center" key={title}>
                        {title}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data &&
                    data.getMenus.items
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((item, index) => (
                        <TableRow
                          key={index}
                          style={{
                            backgroundColor: `${
                              currentVersion === item.s3VersionId
                                ? "#E5F9EE"
                                : ""
                            }`,
                          }}
                        >
                          <TableCell align="center">
                            {item.menuVersion}
                          </TableCell>
                          <TableCell align="center">
                            {item.commitMessage}
                          </TableCell>
                          <TableCell align="center">
                            {getLocaleDateString(item.savedTimestamp)}
                          </TableCell>
                          <TableCell align="center">
                            {item.authorName}
                          </TableCell>
                          <TableCell>
                            <div className={style.viewButtonColumn}>
                              {currentVersion !== item.s3VersionId && (
                                <ViewMenuButton
                                  lastModified={getLocaleDateString(
                                    item.savedTimestamp
                                  )}
                                  versionId={item.s3VersionId}
                                  getMenu={getMenu}
                                  currentVersion={currentVersion}
                                />
                              )}
                              {currentVersion === item.s3VersionId && (
                                <span>Currently viewing</span>
                              )}
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    {data && data.getMenus.items.length > 5 && (
                      <TablePagination
                        rowsPerPageOptions={[5, 10]}
                        colSpan={3}
                        count={data.getMenus.items.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                          inputProps: { "aria-label": "rows per page" },
                          native: true,
                        }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                      />
                    )}
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </LoaderHandler>
        </MuiDialogContent>
      </Dialog>
    </>
  );
};

export default HistoryModal;
