/* eslint-disable react/require-default-props */
import React from "react";
import produce from "immer";
import ReactTooltip from "react-tooltip";
import { useQuery } from "@apollo/client";
import classnames from "classnames";
import IconButton from "@material-ui/core/IconButton";
import Accordion from "@material-ui/core/Accordion";
import Menu from "@material-ui/core/Menu";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Tooltip,
} from "@material-ui/core";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { makeStyles } from "@material-ui/core/styles";
import suspendIcon from "../../../media/images/suspend-icon.svg";
import deleteIcon from "../../../media/images/ic-delete.svg";
import driveFileMoveIcon from "../../../media/images/drive_file_move.svg";
import icon from "../../../media/images/ic-card-menu.svg";
import CategoryHeaderImages from "./CategoryHeaderImages";
import { deleteCategoryFromJson } from "../../../apollo/operations/actions/actions";
import TitleWithInput from "../../shared/TitleWithInput";
import {
  GET_EXPANDED_CATEGORIES,
  expandedCategoriesVar,
  jsonVar,
  recentlyDeletedVar,
  snackbarStateVar,
} from "../../../apollo/cache";
import CreateNewButton from "../../shared/CreateNewButton";
import MenuDiffIcons from "../../shared/MenuDiffIcons";
import CategoryDescription from "./CategoryDescription";
import { ADD_NEW_TYPE } from "../../../constants/constants";
import { MasterMenu, MenuCategory } from "../../../models/json";
import {
  MenuItemWithOptions,
  MenuCategoryWithOptions,
} from "../../../models/menu";

import style from "./styles/CategoryList.module.scss";

import { addNewMenuToJson } from "../../../helpers/menu";
import { restoreItem } from "../../Nav/RecentlyDeleted";

interface Props {
  category: MenuCategory | MenuCategoryWithOptions;
  jsonItems: any;
  dragHandleProps?: any;
  children: any;
  isReadOnly?: boolean;
  isExpanded?: boolean;
  menuKey: string;
  tabs?: string[];
  localJsonState?: MasterMenu;
}

type SelectedMeus = {
  title: string;
  disabled: boolean;
};

const useStyles = makeStyles(() => ({
  expandIcon: {
    "&$expanded": {
      transform: "rotate(90deg)",
    },
  },
  expanded: {},
}));

const Category = ({
  category,
  jsonItems,
  dragHandleProps,
  children,
  isReadOnly,
  isExpanded,
  menuKey,
  tabs,
  localJsonState,
}: Props) => {
  const {
    data: { expandedCategories },
  }: any = useQuery(GET_EXPANDED_CATEGORIES);
  const classes = useStyles();
  const [selectedMeus, setSelectedMeus] = React.useState<SelectedMeus[]>([]);
  const [makeCopy, setMakeCopy] = React.useState(false);
  const autoC = React.useRef<any>(null);
  /**
   * Called When title of a Category Is changed
   * @param item - MenuCategory to be renamed
   */
  const handleTitleChange = (item: MenuCategory) => {
    jsonVar(
      //adding type to draftJson
      produce(jsonVar(), (draftJson: MasterMenu) => {
        const recentlyDeleted = JSON.parse(
          JSON.stringify(recentlyDeletedVar())
        );
        const index = recentlyDeleted.findIndex(
          (cat) => cat.item.id === category.id
        );
        if (index !== -1) {
          recentlyDeleted[index].id = item.title.translations.en_uk;
          recentlyDeleted[index].item.title = item.title;
          recentlyDeletedVar(recentlyDeleted);
        }

        // Change title in category_ids
        for (const menu in draftJson.menus) {
          const newCategoriesIds = {} as { [key: string]: string };
          Object.keys(draftJson.menus[menu].category_ids).forEach(
            (cat: string) => {
              if (cat === category.title.translations.en_uk) {
                newCategoriesIds[item.title.translations.en_uk] =
                  item.title.translations.en_uk;
              } else {
                newCategoriesIds[cat] = cat;
              }
            }
          );
          draftJson.menus[menu].category_ids = { ...newCategoriesIds };
        }
        // Change title in categories
        //we have to create a new object to retain the order of categories.

        // delete draftJson.categories[category.title.translations.en_uk];
        // draftJson.categories[item.title.translations.en_uk] = item;
        let newCategoriesObejct = {};
        for (let cat in draftJson.categories) {
          if (draftJson.categories[cat].title.translations.en_uk !== cat) {
            draftJson.categories[cat].title.translations.en_uk = cat;
          }
          if (cat === category.title.translations.en_uk) {
            newCategoriesObejct[item.title.translations.en_uk] = item;
          } else {
            newCategoriesObejct[cat] = draftJson.categories[cat];
          }
        }
        draftJson.categories = newCategoriesObejct;
      })
    );
  };

  const addNewMenu = () => {
    //create a default service_availability object
    let serviceAvailability = {} as {
      [key: string]: { day_of_week: string; time_periods: any[] };
    };
    const daysOfWeek = [
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
      "sunday",
    ];
    for (let day of daysOfWeek) {
      serviceAvailability[day] = {
        day_of_week: day,
        time_periods: [
          {
            start_time: "storeOpeningTime",
            end_time: "storeClosingTime",
          },
        ],
      };
    }
    addNewMenuToJson(
      menuKey,
      category.title.translations.en_uk,
      serviceAvailability
    );
  };
  const handleCategoryExpand = () => {
    if (expandedCategories.includes(category.id)) {
      expandedCategoriesVar(
        expandedCategories.filter((id: string) => id !== category.id)
      );
    } else {
      expandedCategoriesVar([...expandedCategories, category.id]);
    }
  };

  const deleteCategory = () =>
    deleteCategoryFromJson(
      (category as MenuCategory).title.translations.en_uk,
      menuKey
    );

  /**
   * checks if the user is allowed to suspend category or not
   * User only allowed to suspend category if < 3 menu in menus object
   * not allowed if multiple menus
   * @returns true if allowed, else false
   */
  const isAllowedToSuspendCategory = () => {
    return Object.keys(jsonVar().menus).length < 3;
  };
  const menuCardStyles = classnames(style.menuCard, {
    [style.expandedMenuCard]: isExpanded,
  });

  //Menu
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getTabs = () => {
    const activeMenus: SelectedMeus[] = [];
    tabs?.map((tab) => {
      const isExist = Object.values(
        localJsonState?.menus[tab]?.category_ids || {}
      ).filter((title) => {
        return title === category.title.translations.en_uk;
      });
      if (isExist.length) {
        activeMenus.push({
          title: tab,
          disabled: true,
        });
      } else {
        activeMenus.push({
          title: tab,
          disabled: false,
        });
      }
    });
    return activeMenus.sort((a: any, b: any) => {
      return a.disabled - b.disabled;
    });
  };

  const moveCategoryToTab = () => {
    if (selectedMeus.length) {
      selectedMeus.forEach((menu) => {
        const newJson = produce(jsonVar(), (draftJson: MasterMenu) => {
          draftJson.menus[menu.title].category_ids = {
            [category.title.translations.en_uk]:
              category.title.translations.en_uk,
            ...draftJson.menus[menu.title].category_ids,
          };
          restoreItem({
            item: category,
            menuKey: menu.title,
          });
          if (!makeCopy) {
            delete draftJson.menus[menuKey].category_ids[
              category.title.translations.en_uk
            ];
          }
        });
        jsonVar(newJson);
      });
      setSelectedMeus([]);
      const ele = autoC?.current.getElementsByClassName(
        "MuiAutocomplete-clearIndicator"
      )[0];
      if (ele) ele.click();
      handleClose();
      snackbarStateVar({
        open: true,
        message: "Category moved successfully",
      });
    }
  };

  return (
    <>
      <div className={menuCardStyles}>
        <div className={style.menuCardOuter}>
          <div className={style.menuCardSide}>
            {!isReadOnly && (
              <>
                <span
                  className={style.dragButton}
                  {...dragHandleProps}
                  data-tip
                  data-for="dragCategory"
                >
                  <i className="fas fa-arrows-alt" />
                </span>
                <MenuDiffIcons
                  item={
                    category as MenuCategoryWithOptions | MenuItemWithOptions
                  }
                />
              </>
            )}
            <div className={style.menuCardSideLine} />
          </div>
          <div className={style.menuCardBody}>
            <div className={style.menuCardBodyContent}>
              <div className={style.uiFlexGrow}>
                <img src={icon} className={style.itemImg} alt="item" />
                <h1 className={style.itemTitle}>
                  {isReadOnly ? (
                    (category as MenuCategoryWithOptions).title
                  ) : (
                    <TitleWithInput
                      item={category}
                      stateUpdate={handleTitleChange}
                      style={style}
                    />
                  )}
                </h1>
                <CategoryDescription
                  category={category}
                  isReadOnly={Boolean(isReadOnly)}
                />
              </div>
              <div className={style.uiTextRight}>
                {!isReadOnly && (
                  <>
                    <IconButton
                      aria-label="suspend"
                      onClick={addNewMenu}
                      disabled={!isAllowedToSuspendCategory()}
                      className={
                        isAllowedToSuspendCategory()
                          ? style.itemDelete
                          : `${style.disabledButton} ${style.itemDelete}`
                      }
                    >
                      <img src={suspendIcon} alt="Suspend" />
                    </IconButton>
                    <IconButton
                      aria-label="delete"
                      className={style.itemDelete}
                      onClick={deleteCategory}
                    >
                      <img src={deleteIcon} alt="delete icon" />
                    </IconButton>
                    <Tooltip
                      placement="top"
                      title={
                        getTabs().filter((tab: any) => !tab.disabled).length
                          ? "Move to another menu"
                          : "All menus have this category"
                      }
                      aria-label="add"
                    >
                      <span>
                        <IconButton
                          disabled={
                            !getTabs().filter((tab: any) => !tab.disabled)
                              .length
                          }
                          aria-label="move"
                          className={style.itemDriveFileMove}
                          aria-controls="simple-menu"
                          aria-haspopup="true"
                          onClick={
                            getTabs().filter((tab: any) => !tab.disabled).length
                              ? handleClick
                              : undefined
                          }
                        >
                          <img
                            style={{
                              opacity: getTabs().filter(
                                (tab: any) => !tab.disabled
                              ).length
                                ? 1
                                : 0.5,
                            }}
                            src={driveFileMoveIcon}
                            alt="category move icon"
                          />
                        </IconButton>
                      </span>
                    </Tooltip>
                    <Menu
                      id="simple-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                      PaperProps={{
                        style: {
                          width: "30ch",
                          padding: "10px 20px",
                        },
                      }}
                    >
                      <Autocomplete
                        multiple
                        id="tags-outlined"
                        ref={autoC}
                        options={getTabs()}
                        placeholder="Move to Menu"
                        getOptionLabel={(option: any) => option.title}
                        getOptionDisabled={(option) => option.disabled}
                        getOptionSelected={(option, value) =>
                          option.title === value.title
                        }
                        filterSelectedOptions
                        onChange={(event, newInputValue) => {
                          setSelectedMeus(newInputValue);
                        }}
                        size="small"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            variant="outlined"
                            label="Menus"
                          />
                        )}
                      />
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              size="small"
                              color="primary"
                              checked={makeCopy}
                              onChange={(e) => setMakeCopy(e.target.checked)}
                            />
                          }
                          label="Make a copy"
                        />
                      </FormGroup>
                      <Button
                        disabled={selectedMeus.length === 0}
                        variant="contained"
                        color="primary"
                        onClick={moveCategoryToTab}
                        size="small"
                        style={{ marginTop: "10px" }}
                      >
                        Move
                      </Button>
                    </Menu>
                  </>
                )}
                <CategoryHeaderImages
                  category={category}
                  jsonItems={jsonItems}
                  data-tip
                  data-for="summary"
                  isReadOnly={Boolean(isReadOnly)}
                />
              </div>
            </div>
            <div className={style.menuCardBodyAccordion}>
              <Accordion
                elevation={0}
                TransitionProps={{ unmountOnExit: true }}
                defaultExpanded={isExpanded}
                expanded={expandedCategories.includes(category.id)}
                onChange={handleCategoryExpand}
              >
                <AccordionSummary
                  classes={{
                    expandIcon: classes.expandIcon,
                    expanded: classes.expanded,
                  }}
                  expandIcon={<ChevronRightIcon />}
                />
                <AccordionDetails className={style.menuCardBodyAccordionDetail}>
                  <div
                    className={classnames(
                      style.uiJustifySpaceBetween,
                      style.uiAlignCenter
                    )}
                  >
                    <h3
                      className={classnames(style.uiFlexGrow, style.uiTextLeft)}
                    >
                      Current Meals
                    </h3>
                    {!isReadOnly && (
                      <>
                        <CreateNewButton
                          categoryTitle={
                            (category as MenuCategory).title.translations.en_uk
                          }
                          itemType={ADD_NEW_TYPE.SET}
                        />
                        <CreateNewButton
                          categoryTitle={
                            (category as MenuCategory).title.translations.en_uk
                          }
                          itemType={ADD_NEW_TYPE.SINGLE}
                        />
                      </>
                    )}
                  </div>
                  <div className={style.menuCardBodyAccordionMeals}>
                    {children}
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
      <ReactTooltip
        id="dragCategory"
        place="bottom"
        type="dark"
        effect="float"
        globalEventOff="click"
      >
        <span>hold to drag</span>
      </ReactTooltip>
      <ReactTooltip
        id="summary"
        place="bottom"
        type="dark"
        effect="float"
        globalEventOff="click"
      >
        <span>category overview</span>
      </ReactTooltip>
    </>
  );
};

export default Category;
