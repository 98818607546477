import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    width: "100%",
    borderRadius: 20,
  },
  container: {
    minHeight: "calc( 100vh - 340px )",
  },
  search: {
    display: "flex",
    alignItems: "center",
    width: 268,
    height: 35,
  },
  input: {
    height: 35,
    color: "#3456ff",
    ".MuiOutlinedInput-input::placeholder": {
      color: "#9ff09a",
      opacity: "1",
    },
    "&::placeholder": {
      color: "gray",
    },
  },
  iconButton: {
    marginLeft: "-40px",
  },
});

export default useStyles;
