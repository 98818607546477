import { MenuCategory } from "./json";
import { MenuItemWithOptions } from "./menu";
import { MenuModifierGroup } from "./json";

export const ITEM_SCHEMA: MenuItemWithOptions = {
  id: "",
  availability: false,
  productId: -1,
  external_data: "",
  title: { translations: { en_uk: "Enter Title here" } },
  description: { translations: { en_uk: "" } },
  image_url: "",
  price_info: { price: 0, overrides: [] },
  quantity_info: null,
  modifier_group_ids: { ids: { testItem: "testItem" }, overrides: [] },
  tax_info: { tax_rate: null, vat_rate_percentage: null },
  nutritional_info: { allergens: null, kilojoules: null, calories: null },
};

export const CATEGORY_SCHEMA: MenuCategory = {
  id: "Enter Category Title Here",
  title: {
    translations: {
      en_uk: "Enter Category Title Here",
    },
  },
  entities: {},
  subtitle: {
    translations: {
      en_uk: "Enter Category Description Here",
    },
  },
};

export const MODIFIER_GROUP_SCHEMA: MenuModifierGroup = {
  id: "testItem",
  quantity_info: {
    overrides: [],
    quantity: { max_permitted: 1, min_permitted: 1 },
  },
  title: { translations: { en_uk: "new modifier group" } },
  external_data: "",
  modifier_options: {},
  display_type: null,
};

export const MODIFIER_OPTION_SCHEMA = {
  id: "",
  type: "",
};
