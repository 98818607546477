import React from "react";
import { Switch } from "@material-ui/core";

type Props = {
  toggleDiff: () => void;
  isDiff: boolean;
};

const DiffToggle = ({ toggleDiff, isDiff }: Props) => (
  <div>
    <label style={{ color: "#328277" }} />
    <Switch
      checked={isDiff}
      onChange={toggleDiff}
      color="primary"
      name="checkedLive"
      inputProps={{ "aria-label": "primary checkbox" }}
    />
    <label style={{ color: "#328277" }}>Show changes</label>
  </div>
);

export default DiffToggle;
