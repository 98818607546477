import React from "react";
import { Button } from "@material-ui/core";
import visibilityIcon from "../../../media/images/ic-visibility.svg";
import {
  changedMenuStatusVar,
  currentVersionVar,
  lastModifiedVar,
  menuKeyVar,
} from "../../../apollo/cache";
import { STATUS } from "../../../constants/constants";

type Props = {
  lastModified: string;
  versionId: string;
  getMenu: (params: any) => void;
  currentVersion: string;
};

const ViewMenuButton = ({
  lastModified,
  versionId,
  getMenu,
  currentVersion,
}: Props) => {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    lastModifiedVar(lastModified);
    currentVersionVar(versionId);
    getMenu({
      variables: {
        fileName: menuKeyVar(),
        versionId,
      },
    });

    changedMenuStatusVar(STATUS.PENDING);
  };

  return (
    <Button
      variant="contained"
      color="primary"
      size="small"
      onClick={handleClick}
      disabled={currentVersion === versionId}
    >
      <img src={visibilityIcon} alt="view" />
    </Button>
  );
};

export default ViewMenuButton;
