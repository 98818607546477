import React, { useState } from "react";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import { Button } from "@material-ui/core";
import { useMutation, useQuery } from "@apollo/client";
import { openModalVar, suspendItemIdVar } from "../../../apollo/cache";
import ModalHeader from "../shared/ModalHeader";
import newIcon from "../../../media/images/ic-modal-new.svg";
import ScheduleDate from "../shared/ScheduleDate";
import StyledToast from "../../shared/StyledToast";
import { MODAL_OPEN } from "../../../config/config";
import StoresAutocomplete from "../../shared/StoresAutocomplete";
import StoreGroupsAutocomplete from "../../shared/StoreGroupsAutocomplete";
import { StoreGroupType } from "../../../models/stores";
import { SUSPEND_ITEM } from "../../../apollo/operations/mutations/mutations";
import { GET_JSON } from "../../../apollo/operations/queries/localQueries";
import { StoreGroupsDataFromCacheOrNetwork } from "../../Providers/initalQueryProvider";
import OtherMembersAutocomplete from "./OtherMembersAutocomplete";
import { SUSPEND_REPEAT } from "../../../constants/constants";
import { getUnixInSeconds } from "../../../helpers/scheduleDate";
import { v4 as uuid } from "uuid";

import style from "./styles/SuspendItemModal.module.scss";

type Props = {
  isOpen: boolean;
};

const SuspendItemModal = ({ isOpen }: Props) => {
  const json = useQuery(GET_JSON);
  const [suspendSelectedItem, { data }] = useMutation(SUSPEND_ITEM);
  const [suspendDate, setSuspendDate] = useState<string>("");
  const [selectedStores, selectStores] = useState<string[]>([]);
  const [selectedGroups, selectGroups] = useState<StoreGroupType[]>([]);
  // const [repeat, setRepeat] = useState<SUSPEND_REPEAT[]>([
  //   SUSPEND_REPEAT.NEVER,
  // ]);
  //const [repeatTime, setRepeatTime] = useState<string>("");
  const repeat = [SUSPEND_REPEAT.NEVER];
  const repeatTime = "";

  const [selectedCollections, setSelectedCollections] = useState<any[]>([]);

  const suspendItem = () => {
    const storeIdsInGroups: string[] = selectedGroups.flatMap(
      (group: StoreGroupType) => group.storeIds.UberEats
    );
    const selectedCollectionIds = selectedCollections.map(
      (collection) => collection.id
    );
    if (repeat.includes(SUSPEND_REPEAT.NEVER)) {
      suspendSelectedItem({
        variables: {
          input: {
            aggregatorUUIDs: [...storeIdsInGroups, ...selectedStores],
            productId: suspendItemIdVar(),
            suspendTimestamp: getUnixInSeconds(suspendDate),
            repeat,
            selectedCollections: selectedCollectionIds,
          },
        },
      });
    } else {
      suspendSelectedItem({
        variables: {
          input: {
            aggregatorUUIDs: [...storeIdsInGroups, ...selectedStores],
            productId: suspendItemIdVar(),
            suspendTime: suspendDate,
            repeat,
            repeatOn: repeatTime,
            selectedCollections: selectedCollectionIds,
          },
        },
      });
    }
  };

  const otherMembers = selectedCollections
    .flatMap((collection) => collection.itemIds)
    .map((id) => json.data.json.items[id]?.title.translations.en_uk);

  const closeModal = () => {
    openModalVar(MODAL_OPEN.NONE);
  };
  const handleClose = (_event: any, reason: string) => {
    if (reason === "backdropClick") {
      return false;
    }

    closeModal();
  };
  return (
    <>
      <Dialog
        onClose={handleClose}
        aria-labelledby="Publish Menu"
        open={isOpen}
        maxWidth="md"
      >
        <ModalHeader
          onClose={closeModal}
          icon={newIcon}
          title="Suspend selected item"
        />
        <MuiDialogContent>
          <div className={style.publishContentContainer}>
            <h6>Select stores or groups:</h6>
            <div className={style.inputContainer}>
              <StoreGroupsDataFromCacheOrNetwork>
                {(groups) => (
                  <StoreGroupsAutocomplete
                    getSelectedGroups={selectGroups}
                    selectedGroups={selectedGroups}
                    storeGroups={groups}
                  />
                )}
              </StoreGroupsDataFromCacheOrNetwork>
            </div>
            <div className={style.inputContainer}>
              <StoresAutocomplete
                getSelectedStores={selectStores}
                selectedStores={selectedStores}
              />
            </div>
            {/* <h6 className="mt-3">Set repeat for suspending item:</h6>
            <div className={style.inputContainer}>
              <RepeatSuspendDropdown
                repeat={repeat}
                setRepeat={setRepeat}
                repeatTime={repeatTime}
                setRepeatTime={setRepeatTime}
              />
            </div> */}
            <div className={style.inputContainer}>
              <h6>Also suspend other members:</h6>
              <OtherMembersAutocomplete
                selectedCollections={selectedCollections}
                setSelectedCollections={setSelectedCollections}
              />
            </div>
            <div className={style.inputContainer}>
              <h6>Items in this product collection:</h6>
              <div className={style.otherMembersTextbox}>
                {otherMembers.map((item) => (
                  <p key={uuid()}>{item}</p>
                ))}
              </div>
            </div>
            <div style={{ textAlign: "center" }}>
              {data && data.suspendProduct && (
                <span>Item has been suspended!</span>
              )}
            </div>
          </div>
        </MuiDialogContent>
        <MuiDialogActions>
          <div className={style.uiModalAction}>
            <ScheduleDate
              getDate={setSuspendDate}
              style={style}
              labelText="Suspend until:"
            />

            <Button
              variant="contained"
              color="primary"
              size="large"
              disableElevation
              onClick={suspendItem}
              style={{ margin: "0 auto" }}
            >
              Suspend
            </Button>
          </div>
        </MuiDialogActions>
      </Dialog>
      <StyledToast />
    </>
  );
};

export default SuspendItemModal;
