import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import {
  GET_ARE_CHANGES_PUBLISHED,
  GET_ARE_CHANGES_SAVED,
} from "../../../apollo/operations/queries/localQueries";
import { selectedChannelVar, openModalVar } from "../../../apollo/cache";
import PageLeavingGuard from "../../shared/PageLeavingGuard";
import { MODAL_OPEN } from "../../../config/config";

import style from "./styles/OpeningHoursNav.module.scss";

const channels = ["UberEats", "Deliveroo", "JustEat"];

const ChannelDropdown = () => {
  const [chosenChannel, setChosenChannel] = useState("");
  const { data } = useQuery(GET_ARE_CHANGES_SAVED);
  const published = useQuery(GET_ARE_CHANGES_PUBLISHED);

  const selectChannel = (e: React.ChangeEvent<{ value: unknown }>) => {
    if (!data.areChangesSaved || !published.data.areChangesPublished) {
      setChosenChannel(e.target.value as string);
      openModalVar(MODAL_OPEN.WARNING);
    } else {
      selectedChannelVar(e.target.value as string);
      setChosenChannel(e.target.value as string);
    }
  };

  const warningModalMessage = data.areChangesSaved
    ? "You haven't published your changes. Are you sure you want to switch channels?"
    : "You have some unsaved changes. Are you sure you want to switch channels?";
  return (
    <div className={style.dropdownContainer}>
      <>
        <FormControl variant="outlined" color="primary">
          <InputLabel>Switch Channel</InputLabel>
          <Select
            value={chosenChannel}
            onChange={selectChannel}
            label="Switch Channel"
            displayEmpty
          >
            {channels.map((channel) => (
              <MenuItem value={channel} key={channel}>
                {channel}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {chosenChannel && (
          <PageLeavingGuard
            message={warningModalMessage}
            unblock={() => selectedChannelVar(chosenChannel)}
          />
        )}
      </>
    </div>
  );
};

export default ChannelDropdown;
