import React, { useImperativeHandle, useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import { GET_STORES, openModalVar } from "../../../../apollo/cache";
import StoreGroupHeader from "./StoreGroupHeader";
import StoresInGroup from "./StoresInGroup";
import { StoreGroupType } from "../../../../models/stores";
import AddStoreSearchbar from "./AddStoreSearchbar";
import { DELETE_STORE_GROUP } from "../../../../apollo/operations/mutations/mutations";
import deleteIcon from "../../../../media/images/ic-delete.svg";
import { MODAL_OPEN } from "../../../../config/config";

import style from "../styles/StoreGroups.module.scss";

interface Props {
  group: StoreGroupType;
  deleteGroupRef: any;
}

const useStyles = makeStyles(() => ({
  expandIcon: {
    "&$expanded": {
      transform: "rotate(90deg)",
    },
  },
  expanded: {},
}));

const StoreGroup: React.FC<Props> = ({ group, deleteGroupRef }) => {
  const classes = useStyles();
  const [groupToDelete, setGroupToDelete] = useState<StoreGroupType>(
    {} as StoreGroupType
  );
  const [deleteStoreGroup] = useMutation(DELETE_STORE_GROUP);
  const { data } = useQuery(GET_STORES);
  const [visibleStores, setVisibleStores] = useState([] as any);

  const handleToggle = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const eventTarget: HTMLDivElement = e.target as HTMLDivElement;
    if (
      eventTarget.className === style.searchInput ||
      eventTarget.className === style.searchedStores ||
      eventTarget.className === style.storeOption ||
      eventTarget.className === "fas fa-plus-circle" ||
      eventTarget.className === style.deleteButton ||
      eventTarget.className === "fas fa-trash-alt"
    ) {
      return;
    }

    if (!visibleStores.includes(group.title)) {
      setVisibleStores([...visibleStores, group.title]);
    } else {
      const newStores = [...visibleStores];
      newStores.splice(visibleStores.indexOf(group.title), 1);
      setVisibleStores(newStores);
    }
  };

  const handleDelete = () => {
    setGroupToDelete(group);
    openModalVar(MODAL_OPEN.WARNING);
  };

  const deleteGroup = (group: StoreGroupType) => {
    const { storeIds, id, ...restOfGroup } = group;
    deleteStoreGroup({ variables: { input: restOfGroup } });
  };

  useImperativeHandle(deleteGroupRef, () => ({
    deleteGroup,
    groupToDelete,
  }));

  // console.log(data.stores);
  // console.log(group);
  return (
    <div key={group.id} className={style.menuCard}>
      <div className={style.menuCardOuter}>
        <div className={style.menuCardBody}>
          <StoreGroupHeader
            group={group}
            deleteIconComponent={
              <IconButton
                aria-label="delete"
                className={style.itemDelete}
                onClick={handleDelete}
              >
                <img src={deleteIcon} alt="delete icon" />
              </IconButton>
            }
          />
          <div className={style.menuCardBodyAccordion}>
            <Accordion elevation={0} TransitionProps={{ unmountOnExit: true }}>
              <AccordionSummary
                classes={{
                  expandIcon: classes.expandIcon,
                  expanded: classes.expanded,
                }}
                expandIcon={<ChevronRightIcon />}
                onClick={handleToggle}
              />
              <AccordionDetails className={style.menuCardBodyAccordionDetail}>
                <div className={style.menuCardBodyAccordionDetailTop}>
                  <h3>Current Stores</h3>
                  <AddStoreSearchbar stores={data.stores} group={group} />
                </div>
                <div className={style.menuCardBodyAccordionMeals}>
                  <StoresInGroup
                    group={group}
                    stores={data.stores}
                    visibleStores={visibleStores}
                  />
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StoreGroup;
