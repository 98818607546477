import produce from "immer";
import { jsonVar } from "../../../apollo/cache";
import { ModifierOptionsCollection, MenuItems } from "../../../models/json";
/**
 * When A new item is added in a modfierGroup in the meal set
 * Update the price of the item at master.items[@mealSetid].price_info
 * Update the overides in price in all options in @modiferGroupId
 * @param mealSetId - string /id Of the mealset where the items is dropped
 * @param modiferGroupId - string/id of the modifierGroup from the mealset
 * @param newModiferOptions - modiferOptionsCollection after adding a new item
 */
export const updatePriceOnAddOption = (
  newModiferOptions: ModifierOptionsCollection,
  modiferGroupId: string,
  mealSetId: string
) => {
  updatePrice(newModiferOptions, modiferGroupId, mealSetId);
};
/**
 *
 * @param modifierOptionsAfterDelete  new options in @modiferGroupId after delete
 * @param modiferGroupId id of modifier from where item is deleted
 * @param mealSetId Id of the orignal mealset where the modifierGroup Belongs
 */
export const updatePricesOnRemoveOption = (
  modifierOptionsAfterDelete: ModifierOptionsCollection,
  modiferGroupId: string,
  mealSetId: string
) => {
  updatePrice(modifierOptionsAfterDelete, modiferGroupId, mealSetId);
};
/**
 *
 * @param itemId ItemId to update the overides for
 * @param modiferGroupId modfierGroupId to use for Overides
 * @param basePrice Base price of the items in the provided @modiferGroupId
 */
const updateSurchargePriceForItem = (
  itemId: string,
  modiferGroupId: string,
  basePrice: number
) => {
  jsonVar(
    produce(jsonVar(), (draftJson) => {
      const item = draftJson.items[itemId];
      const surchargePrice = item.price_info.price - basePrice;

      //check if an override already exists
      let isPresent = false,
        key = -1;
      for (let overrideObject of item.price_info.overrides) {
        if (
          overrideObject.context_type === "MODIFIER_GROUP" &&
          overrideObject.context_value === modiferGroupId
        ) {
          //there is already an overide present
          isPresent = true;
          key = item.price_info.overrides.indexOf(overrideObject);
        }
      }
      if (isPresent) {
        draftJson.items[itemId].price_info.overrides[key].price =
          surchargePrice;
      } else {
        const newOverideObject = {
          context_type: "MODIFIER_GROUP",
          context_value: modiferGroupId,
          price: surchargePrice,
        };
        draftJson.items[itemId].price_info.overrides.push(newOverideObject);
      }
    })
  );
};

/**
 *
 * @param modiferGroupOptions Options Inside modifer group to search items for
 * @param items all items in master.items
 * @returns lowest price of all the options in modiferGroupOptions
 */
const getBasePriceForModifierGroup = (
  modiferGroupOptions: ModifierOptionsCollection,
  items: MenuItems
): number => {
  //get all the items in the mofierer group
  let lowestPrice = 99999;
  for (let option in modiferGroupOptions) {
    if (items[option].price_info.price < lowestPrice)
      lowestPrice = items[option].price_info.price;
  }
  return lowestPrice;
};
/**
 * Updates the price of a MealSet Item /-master.items[mealSetId].price_info.price
 * @param mealSetId id of the mealSet to update
 * @param price new Price to be used
 */
const updateMealSetPrice = (mealSetId: string, price: number) => {
  jsonVar(
    produce(jsonVar(), (draftJson) => {
      draftJson.items[mealSetId].price_info.price = price;
    })
  );
};

/**
 *
 * @param modiferOptions options provided to calculate and update base and override price
 * @param modiferGroupId Id of modfierGroup to update
 * @param mealSetId Id of the mealSet to update Item's Price
 */
const updatePrice = (
  modiferOptions: ModifierOptionsCollection,
  modiferGroupId: string,
  mealSetId: string
) => {
  const menuItems = jsonVar().items;
  const newBasePrice = getBasePriceForModifierGroup(modiferOptions, menuItems);
  //updating each option in modifer group
  for (let option in modiferOptions) {
    updateSurchargePriceForItem(option, modiferGroupId, newBasePrice);
  }
  //updaing the meal set price
  updateMealSetPrice(mealSetId, newBasePrice);
};
