import React from "react";
import DashboardIframe from "./DashboardIframe";
import style from "./styles/GrafnaDashboard.module.scss";

const GrafanaDashboard = () => {
  return (
    <div className={style.dashboardIframe}>
      <DashboardIframe />
    </div>
  );
};
export default GrafanaDashboard;
