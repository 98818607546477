import React from "react";
import classnames from "classnames";
import { useQuery } from "@apollo/client";
import { withRouter } from "react-router-dom";
import { Button } from "@material-ui/core";
import {
  GET_IS_READ_ONLY,
  openModalVar,
  userGroupVar,
} from "../../apollo/cache";
import { MODAL_OPEN, PERMISSIONS, ROUTES } from "../../config/config";
import RecentlyDeleted from "./RecentlyDeleted";
import CreateNewButton from "../shared/CreateNewButton";
import { ADD_NEW_TYPE } from "../../constants/constants";
import MenuSearchbar from "./MenuSearchbar";

import style from "./styles/Nav.module.scss";
import { GET_IS_MENU_MODIFIED } from "../../apollo/operations/queries/localQueries";
import ReactTooltip from "react-tooltip";

type Props = {
  location: {
    pathname: string;
    search: string;
    hash: string;
    state: undefined;
    key: string;
  };
};

const Nav = ({ location }: Props) => {
  const { data } = useQuery(GET_IS_READ_ONLY);
  const isStoreManagement = location.pathname === "/store-management";
  const isProductCollections = location.pathname === "/product-collections";
  const isMenuManagement = location.pathname === "/";

  const {
    data: { isMenuModified },
  }: any = useQuery(GET_IS_MENU_MODIFIED);
  /**
   * If read only mode or menu is not modified, Save Menu will be disabled
   * @returns state of Save Menu Button
   */
  const isSaveMenuButtonDisabled = () => {
    if (!PERMISSIONS[userGroupVar()]["save_menu"]) {
      return true;
    }
    return data.isReadOnly || !isMenuModified;
  };
  /**
   *
   * @returns text for Save Menu Button Tooltip
   */
  const getMenuButtonToolTipText = () => {
    if (data.isReadOnly) return "Can not save changes in read-only mode";
    if (isMenuModified) {
      if (PERMISSIONS[userGroupVar()]["save_menu"]) {
        return isMenuModified && "Save Menu";
      } else {
        return "You do not have permission to save menu";
      }
    }

    return "Menu is unchanged, Please add/remove something to activate";
  };
  const saveButtonDisabled = () => {
    if (!PERMISSIONS[userGroupVar()]["create_product_collection"]) {
      return true;
    }
  };
  const getSaveButtonToolTipText = () => {
    if (!PERMISSIONS[userGroupVar()]["create_product_collection"]) {
      return "You do not have permission to create product collection";
    }
    return null;
  };
  return (
    <div className={style.navContainer}>
      <div className={classnames(style.uiFlex, style.uiAlignCenter)}>
        <div className={style.uiPageTitle}>{ROUTES[location.pathname]}</div>
        {isMenuManagement && (
          <>
            <div className={style.menuDropdownContainer}>
              <MenuSearchbar />
            </div>
            <RecentlyDeleted />
          </>
        )}
      </div>
      <div className="row">
        {isStoreManagement && (
          <Button
            variant="contained"
            color="primary"
            disableElevation
            onClick={() => openModalVar(MODAL_OPEN.STORE_GROUP)}
            data-tip
            data-for="createGroup"
          >
            Create Group
          </Button>
        )}

        {isProductCollections && (
          <>
            <span data-for="createPC" data-tip data-tip-disable={false}>
              <Button
                variant="contained"
                color="primary"
                disableElevation
                onClick={
                  saveButtonDisabled()
                    ? undefined
                    : () => openModalVar(MODAL_OPEN.COLLECTIONS)
                }
                data-tip
                data-for="createCollection"
                disabled={saveButtonDisabled()}
              >
                Create Collection
              </Button>
              {getSaveButtonToolTipText() && (
                <ReactTooltip id="createPC" place="bottom">
                  {getSaveButtonToolTipText()}
                </ReactTooltip>
              )}
            </span>
          </>
        )}

        {isMenuManagement && (
          <>
            <CreateNewButton itemType={ADD_NEW_TYPE.MENU} variant="outlined" />
            <CreateNewButton
              itemType={ADD_NEW_TYPE.CATEGORY}
              variant="outlined"
            />
            <div className={style.saveButtonContainer}>
              <span data-for="saveMenu" data-tip data-tip-disable={false}>
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation
                  onClick={() => openModalVar(MODAL_OPEN.SAVE_MENU)}
                  disabled={isSaveMenuButtonDisabled()}
                >
                  Save Menu
                  {isMenuModified && (
                    <span style={{ paddingLeft: "4px" }}> * </span>
                  )}
                </Button>
              </span>
              <ReactTooltip id="saveMenu" place="bottom">
                {getMenuButtonToolTipText()}
              </ReactTooltip>
              {data.isReadOnly && (
                <span className={style.readOnlyLabel}>Read only</span>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default withRouter(Nav);
