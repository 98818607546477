/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import { TextField } from "@material-ui/core";
import { ADD_STORE_GROUP } from "../../../apollo/operations/mutations/mutations";
import newIcon from "../../../media/images/ic-modal-new.svg";
import ModalHeader from "../shared/ModalHeader";
import { openModalVar } from "../../../apollo/cache";
import StoresAutocomplete from "../../shared/StoresAutocomplete";
import { MODAL_OPEN } from "../../../config/config";

import style from "./styles/StoreGroupModal.module.scss";

// TODO adding an existig title of a group - changes the existing group - is this desired behaviour?

const StoreGroupModal = ({ isOpen }) => {
  const [addStoreGroup] = useMutation(ADD_STORE_GROUP);
  const [groupTitle, setGroupTitle] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [storesInGroup, setStoresInGroup] = useState([]);

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGroupTitle(e.target.value);
    setErrorMessage("");
  };

  const closeModal = () => {
    openModalVar(MODAL_OPEN.NONE);
  };
  const handleClose = (_event: any, reason: string) => {
    if (reason === "backdropClick") {
      return false;
    }

    closeModal();
  };

  const addStoresToGroup = (stores: any) => setStoresInGroup(stores);

  const createGroup = () => {
    if (!groupTitle) {
      setErrorMessage("Please enter a Group Name to save the group");
      return;
    }

    const newGroup: any = {
      title: groupTitle,
      storeIds: { UberEats: storesInGroup },
    };
    setStoresInGroup([]);
    setGroupTitle("");
    addStoreGroup({ variables: { input: newGroup } });
    openModalVar(MODAL_OPEN.NONE);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={isOpen}
      maxWidth="md"
    >
      <ModalHeader
        onClose={closeModal}
        icon={newIcon}
        title="Create new store group"
      />
      <MuiDialogContent>
        <div className={style.uiModalContent}>
          {errorMessage && <div className={style.errorRow}>{errorMessage}</div>}
          <form className={style.uiForm} noValidate autoComplete="off">
            <label className={style.uiFormLabel}>Group Name</label>
            <div className={style.inputContainer}>
              <TextField
                variant="outlined"
                placeholder="Insert Group Name"
                value={groupTitle}
                onChange={handleTitleChange}
                label="Group Name"
                fullWidth
              />
            </div>
            <label className={style.uiFormLabel}>Store Grouping</label>
            <div className={style.inputContainer}>
              <StoresAutocomplete
                getSelectedStores={addStoresToGroup}
                selectedStores={storesInGroup}
              />
            </div>
          </form>
        </div>
      </MuiDialogContent>
      <MuiDialogActions>
        <div className={style.uiModalAction}>
          <Button
            onClick={closeModal}
            color="primary"
            variant="outlined"
            size="large"
          >
            Cancel
          </Button>
          <Button
            onClick={createGroup}
            autoFocus
            color="primary"
            variant="contained"
            disableElevation
            size="large"
          >
            Create
          </Button>
        </div>
      </MuiDialogActions>
    </Dialog>
  );
};

export default StoreGroupModal;
