import React from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Checkbox,
} from "@material-ui/core";
import { AGGREGATORS } from "../../../constants/constants";
import { CheckIcon, UncheckIcon } from "../../shared/Icons";

const AggregatorSelect: React.FC<any> = ({
  selectedAggregators,
  selectAggregators,
}) => {
  const handleChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    // eslint-disable-next-line no-console
    selectAggregators(e.target.value as AGGREGATORS[]);
  };

  return (
    <FormControl variant="outlined" color="primary">
      <InputLabel>Switch Channel</InputLabel>
      <Select
        value={selectedAggregators}
        onChange={handleChange}
        label="Switch Channel"
        renderValue={(selected) => (selected as AGGREGATORS[]).join(",")}
        displayEmpty
        multiple
      >
        {Object.keys(AGGREGATORS)
          .map((key) => AGGREGATORS[key])
          .map((agg) => (
            <MenuItem value={agg} key={agg}>
              <Checkbox
                icon={<UncheckIcon />}
                checkedIcon={<CheckIcon />}
                style={{ marginRight: 8 }}
                color="default"
                checked={selectedAggregators.indexOf(agg) > -1}
              />
              {agg}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default AggregatorSelect;
