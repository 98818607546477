import styles from "../styles/CustomUI.module.scss";
import React from "react";
export const CustomStyledDropDown = ({
  size = "small",
  children,
  ...props
}) => {
  return (
    <select {...props} className={`${styles.custom_select}  ${styles[size]}`}>
      {children}
    </select>
  );
};
export const CustomStylesTextField = ({ size = "small", ...props }) => {
  return (
    <input
      {...props}
      className={`${styles.custom_textfield} ${styles[size]}`}
    />
  );
};
