import produce from "immer";
import { jsonVar } from "../../cache";
import { MODIFIER_GROUP_SCHEMA } from "../../../models/schemas";
import { addItemToCategory } from "./actions";
import { MenuItemWithOptions } from "../../../models/menu";
import { MenuModifierGroup } from "../../../models/json";
import { MasterMenu } from "../../../models/json";
/**
 *
 * @param mealSet - MenuItemLocal
 * @param categoryTitle - string
 * Create a new MenuItem @mealSet and add it to catogory with title @categoryTitle
 */
export const createNewMealSet = (
  mealSet: MenuItemWithOptions,
  categoryTitle: string
): void => {
  const newModifierGroup = produce(
    MODIFIER_GROUP_SCHEMA,
    (draftModifier: MenuModifierGroup) => {
      draftModifier.title.translations.en_uk = mealSet.title.translations.en_uk;
      // eslint-disable-next-line prefer-destructuring
      draftModifier.id = Object.keys(mealSet.modifier_group_ids.ids)[0];
    }
  );
  //using newModifierGroup.id as key
  jsonVar(
    produce(jsonVar(), (draftJson: MasterMenu) => {
      draftJson.items[mealSet.id] = mealSet;
      draftJson.modifier_groups[newModifierGroup.id] = newModifierGroup;
    })
  );

  addItemToCategory(mealSet, categoryTitle);
};

// FOR THE MOMENT WE DON'T REMOVE MEAL SETS FROM MODIFIER GROUPS
/* export const deleteMealSetFromModifiers = (modifierItem: ModifierGroupType, mealSet: CategoryItemType): void => {
    const json = JSON.parse(JSON.stringify(jsonVar()));
    const mealSetIndex = json.modifier_groups.findIndex(item => item.id === mealSet.modifier_group_ids.ids[0]);
    const itemIndex = json.modifier_groups[mealSetIndex].modifier_options.findIndex(item => item.id === modifierItem.id);
    if (itemIndex === -1) return;
    json.modifier_groups[mealSetIndex].modifier_options.splice(itemIndex, 1);
    jsonVar(json);
} */
