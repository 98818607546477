import React from "react";
import { Button } from "@material-ui/core";

const ChangeStatusButton: React.FC = () => {
  return (
    <Button
      color="primary"
      variant="contained"
      disableElevation
      onClick={() => {}}
    >
      Delete
    </Button>
  );
};

export default ChangeStatusButton;
