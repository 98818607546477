import React, { useMemo, useState } from "react";
import { useQuery } from "@apollo/client";
import { Pagination } from "@material-ui/lab";
import {
  GET_ARE_CHANGES_PUBLISHED,
  GET_ARE_CHANGES_SAVED,
  GET_STORE_LIST_PAGINATION,
} from "../../../../apollo/operations/queries/localQueries";
import { generatePaginationPages } from "../../helpers/openingHoursHelpers";
import { openModalVar, storeListPaginationVar } from "../../../../apollo/cache";
import PageLeavingGuard from "../../../shared/PageLeavingGuard";
import { MODAL_OPEN } from "../../../../config/config";

import style from "./styles/StoreListPagination.module.scss";
import { TableCell } from "@material-ui/core";

interface Props {
  storesLength: number;
}

const StoreListPagination = ({ storesLength }: Props) => {
  const { data } = useQuery(GET_ARE_CHANGES_SAVED);
  const {
    data: { areChangesPublished },
  }: any = useQuery(GET_ARE_CHANGES_PUBLISHED);
  const {
    data: { storeListPagination },
  }: any = useQuery(GET_STORE_LIST_PAGINATION);

  const [chosenPagePointers, setChosenPagePointers] = useState({} as any);
  const [chosenPage, chosePage] = useState(1);

  const handlePageChange = (pageNumber: number) => {
    const itemsPerPage = storeListPagination.end - storeListPagination.start;
    const endPointer = itemsPerPage * pageNumber;
    if (!data.areChangesSaved || !areChangesPublished) {
      const newPointers = { start: endPointer - itemsPerPage, end: endPointer };
      setChosenPagePointers(newPointers);
      openModalVar(MODAL_OPEN.WARNING);
    } else {
      const newPointers = { start: endPointer - itemsPerPage, end: endPointer };
      storeListPaginationVar(newPointers);
    }
    chosePage(pageNumber);
  };

  const handleUnblock = () => {
    storeListPaginationVar(chosenPagePointers);
    setChosenPagePointers({} as any);
  };

  const handleModalClose = () => {
    openModalVar(MODAL_OPEN.NONE);
    setChosenPagePointers({} as any);
  };

  const warningModalMessage = data.areChangesSaved
    ? "You haven't published your changes. Are you sure you want to see other stores?"
    : "You have some unsaved changes. Are you sure you want to see other stores?";

  const totalPages = useMemo(
    () => generatePaginationPages(storesLength, storeListPagination),
    [storesLength, storeListPagination]
  );
  return (
    <TableCell colSpan={2}>
      <div className={style.paginationContainer}>
        <Pagination
          count={totalPages}
          onChange={(_, page: number) => handlePageChange(page)}
          page={chosenPage}
          shape="rounded"
          variant="outlined"
          color="primary"
        />
      </div>

      {Object.keys(chosenPagePointers).length !== 0 && (
        <PageLeavingGuard
          message={warningModalMessage}
          unblock={handleUnblock}
          close={handleModalClose}
        />
      )}
    </TableCell>
  );
};

export default StoreListPagination;
