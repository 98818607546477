/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-console */
import React, { useEffect, useState } from "react";
import { TextField } from "@material-ui/core";
import { useQuery } from "@apollo/client";
import { GET_CURRENT_AGGREGATOR } from "../../apollo/cache";
import AggregatorDropdown from "../MenuManagement/AggregatorDropdown";

import style from "./styles/Settings.module.scss";
import SettingsFormButton from "./SettingsFormButton";

enum FORM_FIELDS {
  SINGLE_ITEM_TEMPLATE = "singleItemTemplate",
  MEAL_ITEM_TEMPLATE = "mealItemTemplate",
  MEAL_COMPONENT_TEMPLATE = "mealComponentTemplate",
  MODIFIER_GROUP_TEMPLATE = "modifierGroupTemplate",
  MODIFIER_NAME_TEMPLATE = "modifierNameTemplate",
}

export type FormState = {
  [FORM_FIELDS.SINGLE_ITEM_TEMPLATE]: string;
  [FORM_FIELDS.MEAL_ITEM_TEMPLATE]: string;
  [FORM_FIELDS.MEAL_COMPONENT_TEMPLATE]: string;
  [FORM_FIELDS.MODIFIER_GROUP_TEMPLATE]: string;
  [FORM_FIELDS.MODIFIER_NAME_TEMPLATE]: string;
};

const convertNameToTemplateKey = (aggregator: string) => {
  const lowerCaseName =
    aggregator.charAt(0).toLowerCase() + aggregator.slice(1);

  return `${lowerCaseName}Templates`;
};

const SettingsForm = ({ formData }) => {
  const { data } = useQuery(GET_CURRENT_AGGREGATOR);
  const templateKey = convertNameToTemplateKey(data.currentAggregator);
  const initialState = formData[templateKey]
    ? formData[templateKey]
    : {
        [FORM_FIELDS.SINGLE_ITEM_TEMPLATE]: "",
        [FORM_FIELDS.MEAL_ITEM_TEMPLATE]: "",
        [FORM_FIELDS.MEAL_COMPONENT_TEMPLATE]: "",
        [FORM_FIELDS.MODIFIER_GROUP_TEMPLATE]: "",
        [FORM_FIELDS.MODIFIER_NAME_TEMPLATE]: "",
      };

  const [form, updateForm] = useState<FormState>(initialState);

  const handleChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    updateForm({
      ...form,
      [e.target.name as FORM_FIELDS]: e.target.value,
    });
  };

  useEffect(() => {
    updateForm(formData[templateKey] ? formData[templateKey] : form);
  }, [formData, templateKey, form]);

  return (
    <div className={style.settingsContainer}>
      <div className={style.uiPageTitle}>Settings</div>
      <div className={style.nav}>
        <AggregatorDropdown />
      </div>
      <div className={style.formBody}>
        <div className={style.form}>
          <label className={style.uiFormLabel}>Single item name template</label>
          <div className={style.inputContainer}>
            <TextField
              name={FORM_FIELDS.SINGLE_ITEM_TEMPLATE}
              variant="outlined"
              placeholder="Single item name template"
              value={form[FORM_FIELDS.SINGLE_ITEM_TEMPLATE]}
              onChange={handleChange}
              className={style.formInput}
              fullWidth
            />
          </div>
          <label className={style.uiFormLabel}>Meal item name template</label>
          <div className={style.inputContainer}>
            <TextField
              name={FORM_FIELDS.MEAL_ITEM_TEMPLATE}
              variant="outlined"
              placeholder="Meal item name template"
              value={form[FORM_FIELDS.MEAL_ITEM_TEMPLATE]}
              onChange={handleChange}
              className={style.formInput}
              fullWidth
            />
          </div>
          <label className={style.uiFormLabel}>
            Meal component name template
          </label>
          <div className={style.inputContainer}>
            <TextField
              name={FORM_FIELDS.MEAL_COMPONENT_TEMPLATE}
              variant="outlined"
              placeholder="Meal component name template"
              value={form[FORM_FIELDS.MEAL_COMPONENT_TEMPLATE]}
              onChange={handleChange}
              className={style.formInput}
              fullWidth
            />
          </div>
          <label className={style.uiFormLabel}>Modifier group template</label>
          <div className={style.inputContainer}>
            <TextField
              name={FORM_FIELDS.MODIFIER_GROUP_TEMPLATE}
              variant="outlined"
              placeholder="Modifier name template"
              value={form[FORM_FIELDS.MODIFIER_GROUP_TEMPLATE]}
              onChange={handleChange}
              className={style.formInput}
              fullWidth
            />
          </div>
          <label className={style.uiFormLabel}>Modifier name template</label>
          <div className={style.inputContainer}>
            <TextField
              name={FORM_FIELDS.MODIFIER_NAME_TEMPLATE}
              variant="outlined"
              placeholder="Modifier name template"
              value={form[FORM_FIELDS.MODIFIER_NAME_TEMPLATE]}
              onChange={handleChange}
              className={style.formInput}
              fullWidth
            />
          </div>
        </div>
        <SettingsFormButton
          formData={formData}
          aggTemplate={templateKey}
          form={form}
        />
      </div>
    </div>
  );
};

export default SettingsForm;
