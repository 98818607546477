/* eslint-disable react/require-default-props */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import classnames from "classnames";

import style from "./styles/Modal.module.scss";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: 30,
    top: 20,
    color: theme.palette.grey[500],
  },
}));

type Props = {
  children?: any;
  onClose?: () => void;
  icon?: any;
  titleStyle?: string;
  title?: string;
};

const ModalHeader: React.FC<Props> = ({
  onClose,
  icon,
  title,
  titleStyle,
  children,
}) => {
  const { closeButton } = useStyles();

  if (children) return children;

  return (
    <MuiDialogTitle disableTypography>
      <div className={classnames(style.uiModalTitle, titleStyle)}>
        <img src={icon} alt="modal icon" />
        <h1>{title}</h1>
      </div>

      {onClose && (
        <IconButton
          aria-label="close"
          className={closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      )}
    </MuiDialogTitle>
  );
};

export default ModalHeader;
