/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import { CATEGORY_ITEM_PROPERTIES } from "../../../constants/constants";
import {
  CategoryOrItem,
  MenuItemsCollection,
  CategoryOrItemsCollection,
} from "../../../models/menu";
import {
  MenuItemWithOptions,
  MenuCategoryWithOptions,
} from "../../../models/menu";

export const checkIfItemsInCategoryHaveChanged = (
  diffedCategoryItems: MenuItemsCollection
) => {
  // if any entity has had any sort of change - category has CHANGED
  const checkIfItemHasChanged = (item: MenuItemWithOptions) =>
    !!item.isChanged || !!item.isDeleted || !!item.isMoved || !!item.isNew;
  if (Object.values(diffedCategoryItems).some(checkIfItemHasChanged)) {
    return true;
  }

  return false;
};

export const diffItemModifierIds = (
  oldModifierIds: string[],
  newModifierIds: string[]
): CATEGORY_ITEM_PROPERTIES.MODIFIER_GROUP_IDS | null => {
  const onlyOneArrayIsEmpty =
    oldModifierIds.length !== newModifierIds.length &&
    (oldModifierIds.length === 0 || newModifierIds.length === 0);
  if (onlyOneArrayIsEmpty) {
    return CATEGORY_ITEM_PROPERTIES.MODIFIER_GROUP_IDS;
  }

  const areAllItemSame = oldModifierIds.every(
    (id: string, index: number) => id === newModifierIds[index]
  );
  if (!areAllItemSame) {
    return CATEGORY_ITEM_PROPERTIES.MODIFIER_GROUP_IDS;
  }

  return null;
};

export const diffIndividualItems = (
  oldItem: MenuItemWithOptions,
  newItem: MenuItemWithOptions
): MenuItemWithOptions => {
  const changesArray: CATEGORY_ITEM_PROPERTIES[] = [];

  if (
    oldItem.title.translations.en_uk.trim() !==
    newItem.title.translations.en_uk.trim()
  ) {
    changesArray.push(CATEGORY_ITEM_PROPERTIES.TITLE);
  }

  if (
    oldItem.description.translations.en_uk.trim() !==
    newItem.description.translations.en_uk.trim()
  ) {
    changesArray.push(CATEGORY_ITEM_PROPERTIES.DESCRIPTION);
  }

  if (oldItem.price_info.price !== newItem.price_info.price) {
    changesArray.push(CATEGORY_ITEM_PROPERTIES.PRICE);
  }

  const diffedModifierIds: unknown = diffItemModifierIds(
    Object.values(oldItem.modifier_group_ids.ids),
    Object.values(newItem.modifier_group_ids.ids)
  );
  if (diffedModifierIds) {
    changesArray.push(
      diffedModifierIds as CATEGORY_ITEM_PROPERTIES.MODIFIER_GROUP_IDS
    );
  }
  // ENTIRE ITEM HAS CHANGED
  if (changesArray.length > 0) {
    return {
      ...oldItem,
      isChanged: true,
      changes: changesArray,
    };
  }

  return oldItem;
};

export const deepDiffItems = (
  diffedItems: MenuItemsCollection,
  newItems: MenuItemsCollection
): MenuItemsCollection => {
  const changedOrMovedItems: MenuItemsCollection = {};
  const newOrDeletedItems: MenuItemsCollection = {};
  //soring items
  for (const i in diffedItems) {
    const fItem = diffedItems[i];
    if (!fItem.isNew && !fItem.isDeleted) {
      changedOrMovedItems[i] = fItem;
    } else {
      newOrDeletedItems[i] = fItem;
    }
  }
  //if there are items that are changed or moved
  if (Object.keys(changedOrMovedItems).length > 0) {
    const deepDiffedItems: MenuItemsCollection = {};
    for (const key in changedOrMovedItems) {
      const item: MenuItemWithOptions = changedOrMovedItems[key];
      const newItem: MenuItemWithOptions = newItems[item.id];
      if (!!newItem) {
        const diffMenuItem = diffIndividualItems(item, newItem);
        deepDiffedItems[diffMenuItem.id] = diffMenuItem;
      } else {
        deepDiffedItems[item.id] = item;
      }
    }
    const returnItems = { ...deepDiffedItems, ...newOrDeletedItems };
    return returnItems;
  }

  return diffedItems;
};

export const diffArrays = (
  oldArray: CategoryOrItemsCollection,
  newArray: CategoryOrItemsCollection
): CategoryOrItemsCollection => {
  // DELETED CHANGED OR MOVED ITEMS
  const movedDeletedChangedItems = (): CategoryOrItemsCollection => {
    const mapMovedDeletedChangedItems = (
      newItem: CategoryOrItem | MenuCategoryWithOptions | MenuItemWithOptions,
      key: string
    ): CategoryOrItem => {
      // DELETED - category is not present in new categories
      if (!newItem || newItem === undefined) {
        return {
          ...oldArray[key],
          isDeleted: true,
        };
      }
      const itemIndexOld = Object.values(oldArray).findIndex(
        (oItem: CategoryOrItem) => oItem.id === newItem.id
      );

      let extraNewItem = { ...newItem };

      // MOVED - category is present in both categories, but position is different
      const itemIndexNew = Object.values(newArray).findIndex(
        (nItem: CategoryOrItem) => nItem.id === newItem.id
      );
      if (itemIndexOld !== itemIndexNew) {
        extraNewItem = {
          ...extraNewItem,
          isMoved: true,
        };
      }

      // CHANGED - check if category has changed or is the same
      if ((newItem as MenuCategoryWithOptions).children) {
        // IF ITEM HAS CHILDREN - diff items
        // SHALLOW DIFF category items
        const shallowDiffedItems: CategoryOrItemsCollection = diffArrays(
          (oldArray[newItem.id] as MenuCategoryWithOptions).children,
          (newItem as MenuCategoryWithOptions).children
        );
        // DEEP DIFF - if items are not new or deleted - diff each one against it's twin in the synced menu
        const deepDiffedItems = deepDiffItems(
          shallowDiffedItems as MenuItemsCollection,
          (oldArray[newItem.id] as MenuCategoryWithOptions).children
        );
        extraNewItem = {
          ...extraNewItem,
          children: deepDiffedItems,
          isChanged: checkIfItemsInCategoryHaveChanged(deepDiffedItems),
        };
      }

      return extraNewItem;
    };
    const resultArray: CategoryOrItemsCollection = {};
    for (const key in oldArray) {
      let catOrItem = mapMovedDeletedChangedItems(newArray[key], key);
      resultArray[catOrItem.id] = catOrItem;
    }
    return resultArray;
  };

  // NEW ITEMS
  const newMappedItems = (): CategoryOrItemsCollection => {
    const filterNewCategories = (newItem: CategoryOrItem) => {
      if (newItem.id in oldArray) {
        return false;
      }
      return true;
    };

    const mapNewItems = (newItem: CategoryOrItem) => {
      return {
        ...newItem,
        isNew: true,
      };
    };

    let catsOrItems: CategoryOrItemsCollection = {};
    let filteredArray = {};
    for (let key in newArray) {
      if (filterNewCategories(newArray[key])) {
        filteredArray[key] = newArray[key];
        catsOrItems[key] = mapNewItems(newArray[key]);
      }
    }
    return catsOrItems;
  };

  return { ...movedDeletedChangedItems(), ...newMappedItems() };
};
