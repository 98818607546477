import React, { useEffect, useState } from "react";
import styles from "../styles/Repeat.module.scss";
import { RepeatData, ScheduleTabNames } from "../Utils/types";
import { CustomStylesTextField } from "../Utils/CustomUI";
import { Weekly } from "./Weekly";
import Monthly from "./Monthly";
import Yearly from "./Yearly";
import { useQuery } from "@apollo/client";
import { CUSTOMIZATION_SCHEDULE } from "../../../../apollo/operations/queries/localQueries";
import { cache } from "../../../../apollo/cache";

type Props = {
  activeTab: ScheduleTabNames;
};
/**
 *
 * @param activeTab - tab active in parent component
 * @returns React Component for Section Repeat
 * Section repeat has 4 variations based on the active Tab
 * Variations : Daily, Weekly, Monthly, Yearly
 */
const Repeat = ({ activeTab }: Props) => {
  const {
    data: { schedule },
  }: any = useQuery(CUSTOMIZATION_SCHEDULE);

  const [data, setData] = useState<RepeatData>({
    interval: schedule.interval || 1,
  });
  //Text label based on the active tab.
  const getLabel = (): string => {
    switch (activeTab) {
      case "Daily":
        return "days";
      case "Weekly":
        return "weeks";
      case "Monthly":
        return "months on the";
      case "Yearly":
        return "years on the";

      default:
        return "days";
    }
  };

  //helper text based on the active tab
  const getHelperText = (): string => {
    switch (activeTab) {
      case "Daily":
        return "This customization will be activated after every X days";
      case "Weekly":
        return "This customization will be activated after every  X weeks on specified days";
      case "Monthly":
        return "This customization will be activated after every X months on specified day of the month";
      case "Yearly":
        return "This customization will be activated after every X years on specified day of the specific month";

      default:
        return "Days";
    }
  };

  //on Change in interval
  const onIntervalChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);
    setData({
      interval: value > 1 ? value : 1,
    });
  };

  useEffect(() => {
    if (schedule) {
      cache.writeQuery({
        query: CUSTOMIZATION_SCHEDULE,
        data: {
          schedule: {
            ...schedule,
            interval: data.interval,
          },
        },
      });
    }
  }, [data, schedule]);

  return (
    <>
      <div className={styles.repeat_row}>
        <h2>
          Repeat Every
          <CustomStylesTextField
            name="interval"
            id="interval-field"
            type="number"
            size="small"
            step="1"
            min="1"
            variant="outlined"
            defaultValue={1}
            value={data.interval}
            onChange={onIntervalChange}
          />
          {getLabel()}
        </h2>
      </div>
      {/* Variations based on the tab active */}
      {/* MONTHLY VARIANT */}
      {activeTab === "Monthly" && <Monthly />}
      {/* YEARLY VARIANT */}
      {activeTab === "Yearly" && <Yearly />}
      <span className={styles.helper_text}>{getHelperText()}</span>
      {/* WEEKLY VARIANT */}
      {activeTab === "Weekly" && <Weekly />}
    </>
  );
};

export default Repeat;
