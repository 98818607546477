import React, { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import FadeLoader from "react-spinners/FadeLoader";
import { Button } from "@material-ui/core";
import { GET_UPLOAD_URL } from "../../../apollo/operations/queries/serverQueries";
import uploadFileToBucket from "../../../menuApi/uploadFileToBucket";
import cameraIcon from "../../../media/images/ic-camera.svg";
import { parseImageName } from "../../../helpers/menu";
import { STATUS } from "../../../constants/constants";

import style from "./styles/ItemImageUpload.module.scss";

interface Props {
  updateImage: (url: any) => void;
}

const ModalImageUpload: React.FC<Props> = ({ updateImage }) => {
  const [uploadMessage, setUploadMessage] = useState("" as STATUS);
  const [getImageUrl, { data }] = useLazyQuery(GET_UPLOAD_URL);
  const [file, setFile] = useState({} as any);

  const getImage = (e: any) => {
    const { files } = e.target;
    if (files && files.length > 0) {
      const fileInstance = files[0];
      setFile(fileInstance);
      uploadFile(fileInstance);
    }
  };

  const uploadFile = (image: any) => {
    const params = {
      assetType: "image",
      fileName: parseImageName(image.name),
      fileType: image.type,
    };
    getImageUrl({ variables: params });
  };

  useEffect(() => {
    if (data && data.uploadUrl) {
      const params = {
        uploadUrl: data.uploadUrl,
        file,
        fileName: parseImageName(file.name),
        setUploadMessage,
        updateImage,
      };
      uploadFileToBucket(params);
    }
  }, [data, file, updateImage]);

  return (
    <>
      <div className={style.uiMealPopupImg}>
        {uploadMessage !== STATUS.PENDING && (
          <Button
            variant="contained"
            component="label"
            className={style.uiBtnCamera}
          >
            <img alt="Select" src={cameraIcon} />
            <input
              type="file"
              id="upload-image"
              accept="image/*"
              onChange={getImage}
              hidden
            />
          </Button>
        )}

        {uploadMessage === STATUS.PENDING && (
          <FadeLoader
            height={8}
            width={8}
            radius={100}
            margin={1}
            color="#336B6B"
            loading={uploadMessage === STATUS.PENDING}
          />
        )}
      </div>
    </>
  );
};

export default ModalImageUpload;
