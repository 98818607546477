import { ReactiveVar, makeVar } from "@apollo/client";

export const authFields = {
  isAuthenticated: {
    read() {
      return isAuthenticatedVar();
    },
  },
  userGroup: {
    read() {
      return userGroupVar();
    },
  },
};

export const isAuthenticatedVar: ReactiveVar<boolean> = makeVar<boolean>(false);

export const userGroupVar: ReactiveVar<string> = makeVar<string>("");
