/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";
import { changeItemInJsonItems } from "../../../apollo/operations/actions/actions";
import { modalValueVar } from "../../../apollo/cache";
import ModalTextArea from "./ModalTextArea";
import ModalModifiers from "./ModalModifiers";
import ModalImageUpload from "./ModalImageUpload";
import ModalNutritionalData from "./ModalNutritionalData";
import { ChangedIcon } from "../../shared/MenuDiffIcons";
import { CATEGORY_ITEM_PROPERTIES } from "../../../constants/constants";
import { MenuItemWithOptions } from "../../../models/menu";
import placeholderImage from "../../../media/prestau-placeholder.png";
import DateTimePicker from "../../shared/DateTimePicker";
import { parseImageName } from "../../../helpers/menu";

import style from "./styles/ModalForm.module.scss";

type Props = {
  modalItem: any;
  newImage: string;
  updateNewImage: (img: string) => void;
  handleClose: () => void;
};

const ModalForm = forwardRef(
  (
    {
      modalItem: { item, category },
      newImage,
      updateNewImage,
      handleClose,
    }: Props,
    ref
  ) => {
    const [form, updateForm] = useState({
      description: item.description.translations.en_uk,
      price: item.price_info.price / 100,
      kcal_lower: item.nutritional_info?.calories_per_serving?.lower_range || 0,
      kcal_upper: item.nutritional_info?.calories_per_serving?.upper_range || 0,
      servings: item.nutritional_info?.number_of_servings,
      expiryDate: item.expiryDate,
      effectiveDate: item.effectiveDate,
    });

    const [inputChanged, changeInput] = useState(false);
    const [priceChanged, changePrice] = useState(false);

    const handleChange = (
      e: React.ChangeEvent<
        HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
      >
    ) => {
      updateForm({
        ...form,
        [e.target.name]: e.target.value,
      });
      changeInput(true);
      if (e.target.name === "price") changePrice(true);
    };

    const updateImage = (imageUrl: string) => {
      const imageUrlToPass = parseImageName(imageUrl);
      updateNewImage(imageUrlToPass);

      modalValueVar({
        item: {
          ...item,
          image_url: imageUrlToPass,
        },
        category,
      });
    };

    const handleSubmit = () => {
      if (!inputChanged && !newImage) {
        handleClose();
      } else {
        const newItem: MenuItemWithOptions = {
          ...item,
          price_info: {
            price: priceChanged
              ? Math.round(form.price * 100)
              : item.price_info.price,
          },
          nutritional_info: {
            allergens: item.nutritional_info.allergens,
            number_of_servings: Number(form.servings),
            kilojoules: {
              lower_range: Math.round(
                Number(form.kcal_lower) * 4.184 * Number(form.servings)
              ),
              upper_range: Math.round(
                Number(form.kcal_upper) * 4.184 * Number(form.servings)
              ),
              display_type: item?.nutritional_info.kilojoules?.display_type,
            },
            kilojoules_per_serving: {
              lower_range: Math.round(Number(form.kcal_lower) * 4.184),
              upper_range: Math.round(Number(form.kcal_upper) * 4.184),
              display_type:
                item?.nutritional_info.kilojoules_per_serving?.display_type,
            },
            calories: {
              lower_range: Number(form.kcal_lower) * Number(form.servings),
              upper_range: Number(form.kcal_upper) * Number(form.servings),
              display_type: item?.nutritional_info.calories?.display_type,
            },
            calories_per_serving: {
              lower_range: Number(form.kcal_lower),
              upper_range: Number(form.kcal_upper),
              display_type:
                item?.nutritional_info.calories_per_serving?.display_type,
            },
          },
          image_url: newImage || item.image_url,
          description: { translations: { en_uk: form.description } },
          expiryDate: `${form.expiryDate}Z`,
          effectiveDate: `${form.effectiveDate}Z`,
        };

        handleClose();
        changeItemInJsonItems(newItem);
      }
    };

    useImperativeHandle(ref, () => ({
      handleSubmit,
    }));

    useEffect(() => {
      updateForm({
        description: item.description.translations.en_uk,
        price: item.price_info.price / 100,
        kcal_lower:
          item.nutritional_info?.calories_per_serving?.lower_range || 0,
        kcal_upper:
          item.nutritional_info?.calories_per_serving?.upper_range || 0,
        servings: Number(item.nutritional_info.number_of_servings),
        effectiveDate: item.effectiveDate,
        expiryDate: item.expiryDate,
      });
    }, [item]);

    return (
      <>
        <ModalImageUpload updateImage={updateImage} />
        <div className={style.modalImageRow}>
          <div className={style.modalImageColumn}>
            <ChangedIcon
              item={item}
              change={CATEGORY_ITEM_PROPERTIES.IMAGE_URL}
            />
            {(newImage || item.image_url) && (
              <img
                className={style.modalImage}
                src={newImage || item.image_url}
                alt="meal"
              />
            )}
            {!newImage && !item.image_url && (
              <img
                className={style.modalImage}
                src={placeholderImage}
                alt="placeholder"
              />
            )}
          </div>
        </div>
        <div>
          <div>
            <label className="col-div-label ml-2">Description:</label>
            <ChangedIcon
              item={item}
              change={CATEGORY_ITEM_PROPERTIES.DESCRIPTION}
            />
            <ModalTextArea
              name="description"
              value={form.description}
              onChange={handleChange}
            />
          </div>

          {form.price !== 0 && (
            <div>
              <label className="col-form-label ml-2">Price:</label>
              <ChangedIcon
                item={item}
                change={CATEGORY_ITEM_PROPERTIES.PRICE}
              />
              <input
                type="text"
                name="price"
                className="form-control"
                value={form.price}
                onChange={handleChange}
              />
            </div>
          )}

          <ModalNutritionalData form={form} handleChange={handleChange} />

          <div>
            <label className="col-form-label ml-2">Effective date:</label>
            <ChangedIcon
              item={item}
              change={CATEGORY_ITEM_PROPERTIES.EFFECTIVE_DATE}
            />
            <DateTimePicker
              value={form.effectiveDate}
              onChange={handleChange}
              name="effectiveDate"
            />
          </div>
          <div>
            <label className="col-form-label ml-2">Expiry date:</label>
            <ChangedIcon
              item={item}
              change={CATEGORY_ITEM_PROPERTIES.EXPIRY_DATE}
            />
            <DateTimePicker
              value={form.expiryDate}
              onChange={handleChange}
              name="expiryDate"
            />
          </div>

          <ModalModifiers item={item} category={category} />
        </div>
      </>
    );
  }
);

export default ModalForm;
