import React from "react";
import { DragDropContext } from "react-beautiful-dnd";
import {
  helpReorderList,
  computeOriginalIndex,
  computeOriginalIndexAfterDrop,
} from "../helpers/reorderListHelpers";
import invokeOnDragEnd from "../helpers/invokeOnDragEnd";
import CategoryItemList from "./CategoryItemList";
import dragItemToMealSet from "../helpers/dragItemToMealSet";
import maxItemsByScreenSize from "../helpers/maxItemsByScreenSize";
import style from "./styles/CategoryList.module.scss";
import { MenuCategory, ModifierOption } from "../../../models/json";

interface Props {
  categoryIndex: number;
  category: MenuCategory;
  getUpdatedCategory: (
    categoryIndex: number,
    categoryItems: ModifierOption[],
    category: MenuCategory
  ) => void;
}

const CategoryItems = ({
  category,
  getUpdatedCategory,
  categoryIndex,
}: Props) => {
  const numberOfItemsToDisplay = maxItemsByScreenSize();

  const reorderList = (result: any) => {
    if (result.combine) {
      if (
        !result.draggableId.includes("set") &&
        result.combine.draggableId.includes("set")
      ) {
        //adding category as prop to be used by component
        dragItemToMealSet(
          numberOfItemsToDisplay,
          result,
          category,
          Object.values(category.entities),
          categoryIndex,
          getUpdatedCategory
        );
        return;
      }
      return;
    }

    if (!result.destination) {
      return;
    }

    const sourceIndex = computeOriginalIndex(
      numberOfItemsToDisplay,
      result.source.id,
      result.source.index
    );
    const destinationIndex = computeOriginalIndexAfterDrop(
      numberOfItemsToDisplay,
      result.source.id,
      result.destination.id,
      result.destination.index
    );

    const list = helpReorderList(
      sourceIndex,
      destinationIndex,
      Object.values(category.entities)
    );
    getUpdatedCategory(categoryIndex, list, category);
  };

  return (
    <DragDropContext onDragEnd={invokeOnDragEnd(reorderList)}>
      <div className={style.innerCategory}>
        <CategoryItemList category={category} />
      </div>
    </DragDropContext>
  );
};

export default CategoryItems;
