import { useEffect } from "react";

export default function useOutsideAlerter(ref, shouldOutsideClickEnable, onClickOutside) {
    useEffect(() => {

      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target) && shouldOutsideClickEnable) {
          onClickOutside();
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
          // Unbind the event listener on clean up
          document.removeEventListener("mousedown", handleClickOutside);
      };
    
        
    }, [onClickOutside, ref, shouldOutsideClickEnable]);
}