import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import { useLazyQuery, useQuery } from "@apollo/client";
import axios from "axios";
import { GET_SYNC_MENU_URL } from "../../../apollo/operations/queries/serverQueries";
import { MODAL_OPEN } from "../../../config/config";
import {
  GET_MENU_KEY,
  openModalVar,
  savedMenuNameVar,
} from "../../../apollo/cache";
import { loadNewMenu } from "../../../helpers/menu";
import { STATUS } from "../../../constants/constants";
import LoaderHandler from "../../shared/LoaderHandler";

type Props = {
  posKey: string;
  status: STATUS;
};

const ActionButtons = ({ posKey, status }: Props) => {
  const { data } = useQuery(GET_MENU_KEY);
  const [getDownloadUrl, downloadUrl]: any = useLazyQuery(GET_SYNC_MENU_URL);
  const [isPending, setPending] = useState(false);

  const handleClick = () => {
    setPending(true);
    getDownloadUrl({
      variables: {
        posKey,
        masterMenu: data.menuKey,
      },
    });
  };

  useEffect(() => {
    const menuUrl = downloadUrl.data && downloadUrl.data.syncMenu.data.url;
    if (menuUrl) {
      axios({
        method: "get",
        url: `${menuUrl}`,
      }).then((res) => {
        loadNewMenu(res.data);
        savedMenuNameVar(res.data.menuName);
        setPending(false);
        openModalVar(MODAL_OPEN.NONE);
      });
    }
  }, [downloadUrl]);

  return (
    <LoaderHandler loading={isPending} error="">
      <Button
        onClick={() => openModalVar(MODAL_OPEN.NONE)}
        color="primary"
        variant="outlined"
        size="large"
      >
        Cancel
      </Button>
      <Button
        autoFocus
        color="primary"
        variant="contained"
        disableElevation
        size="large"
        onClick={handleClick}
        disabled={status !== STATUS.FULFILLED}
      >
        Review incoming changes
      </Button>
    </LoaderHandler>
  );
};

export default ActionButtons;
