import React from "react";
import { Checkbox, TextField, Chip } from "@material-ui/core";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { jsonVar } from "../../../apollo/cache";
import {
  mapItemsToOption,
  mapSelectedItemsToOptions,
} from "../../../helpers/autocompletes";
import { CheckIcon, UncheckIcon } from "../../shared/Icons";
import { AutocompleteValueType } from "../../../models/stores";
import { mapItemsToObjectWithKeys } from "../../../helpers/mapJsonToArray";
import { itemsArrayFromObject } from "../../MenuManagement/helpers/arraysAndObjects";

type Props = {
  selectedItems: string[];
  getSelectedItems: (items: string[]) => void;
};

const CollectionItemsAutocomplete = ({
  getSelectedItems,
  selectedItems,
}: Props) => {
  const handleSelection = (key: string) => {
    if (selectedItems.includes(key)) {
      const itemIndex = selectedItems.indexOf(key);
      const newItems = [...selectedItems];
      newItems.splice(itemIndex, 1);
      getSelectedItems(newItems);
    } else {
      const newItems = [...selectedItems];
      newItems.push(key);
      getSelectedItems(newItems);
    }
  };

  return (
    <Autocomplete
      multiple
      options={mapItemsToOption(itemsArrayFromObject(jsonVar().items))}
      onChange={(_, values: AutocompleteValueType[]) => {
        values.forEach((value) => handleSelection(value.id));
      }}
      value={mapSelectedItemsToOptions(
        selectedItems,
        mapItemsToObjectWithKeys(itemsArrayFromObject(jsonVar().items))
      )}
      disableCloseOnSelect
      disableClearable
      getOptionLabel={(option) => option.name}
      renderOption={(option) => (
        <>
          <Checkbox
            icon={<UncheckIcon />}
            checkedIcon={<CheckIcon />}
            style={{ marginRight: 8 }}
            color="default"
            checked={selectedItems.includes(option.id)}
          />
          {option.name}
        </>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label="Items"
          placeholder="Search Items"
        />
      )}
      renderTags={(values: AutocompleteValueType[], getTagProps) =>
        values.map((value: AutocompleteValueType, index: number) => (
          <Chip
            variant="default"
            label={value.name}
            color="primary"
            {...getTagProps({ index })}
            onDelete={() => handleSelection(value.id)}
          />
        ))
      }
      filterOptions={createFilterOptions({ limit: 35 })}
    />
  );
};

export default CollectionItemsAutocomplete;
