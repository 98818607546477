import React from "react";
import { IconButton } from "@material-ui/core";
import ReactTooltip from "react-tooltip";
import { useQuery } from "@apollo/client";
import { GET_IS_MASTER_MENU, openModalVar } from "../../../apollo/cache";
import { MODAL_OPEN } from "../../../config/config";
import syncFileIcon from "../../../media/images/ic-modal-sync.svg";
import DiffToggle from "./DiffToggle";

const SyncControls = ({ toggleDiff, isDiff }) => {
  const { data } = useQuery(GET_IS_MASTER_MENU);

  const toggle = () => (isDiff ? toggleDiff(false) : toggleDiff(true));

  if (!data.isMasterMenu) {
    //not master menu

    return null;
  }

  return (
    <div>
      <IconButton
        onClick={() => openModalVar(MODAL_OPEN.SYNC)}
        color="primary"
        size="small"
      >
        <img src={syncFileIcon} alt="sync file" />
      </IconButton>
      <DiffToggle toggleDiff={toggle} isDiff={isDiff} />
      <ReactTooltip
        id="sync"
        place="bottom"
        type="dark"
        effect="float"
        globalEventOff="click"
      >
        <span>sync changes from source</span>
      </ReactTooltip>
    </div>
  );
};

export default SyncControls;
