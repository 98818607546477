import React, { useEffect, useState } from "react";
import { TableCell, TableRow } from "@material-ui/core";
import ChangeStatusButton from "./ChangeStatusButton";
import { StoreType } from "../../../models/stores";
import iconEnabled from "../../../media/images/ic-enabled.svg";
import iconDisabled from "../../../media/images/ic-disabled.svg";
import MasterMenuDropdown from "./MasterMenuDropdown";

import style from "./styles/MissionControlTable.module.scss";

type Props = {
  store: StoreType;
  index: number;
};

const MissionControlTableRow = ({ store, index }: Props) => {
  const [isSpinner, toggleSpinner] = useState(false);

  const handleSpinnerToggle = (action: boolean) => toggleSpinner(action);

  useEffect(() => {
    toggleSpinner(false);
  }, [store, toggleSpinner]);

  const lastCheckedDate = store.lastChecked && new Date(store.lastChecked);
  const date =
    lastCheckedDate &&
    lastCheckedDate.toLocaleDateString("en-US", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    });
  return (
    <TableRow key={index} style={{ width: "100%" }}>
      <TableCell align="center">{store.restaurantName}</TableCell>

      <TableCell align="center">
        <MasterMenuDropdown store={store} />{" "}
      </TableCell>
      <TableCell align="center">{date || "unknown"}</TableCell>
      <TableCell align="center">
        {store.autoAcceptEnabled ? (
          <img src={iconEnabled} alt="enabled" />
        ) : (
          <img src={iconDisabled} alt="disabled" />
        )}
      </TableCell>
      <TableCell align="center">
        {store.onlineStatus ? (
          <img src={iconEnabled} alt="enabled" />
        ) : (
          <span style={{ color: "crimson" }}>
            {store.offlineReason ? store.offlineReason : "no reason"}
          </span>
        )}
      </TableCell>
      <TableCell align="center">
        {store.partnerStoreId ? store.partnerStoreId : "-"}
      </TableCell>
      <TableCell align="center">
        {store.posIntegrated ? (
          <span className={style.enabled}>Enabled</span>
        ) : (
          <span className={style.disabled}>Disabled</span>
        )}
      </TableCell>
      <TableCell align="center" style={isSpinner ? { padding: 5 } : undefined}>
        <ChangeStatusButton
          store={store}
          toggleSpinner={handleSpinnerToggle}
          isSpinner={isSpinner}
        />
      </TableCell>
    </TableRow>
  );
};

export default MissionControlTableRow;
