import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import produce, { Draft } from "immer";
import { GET_JSON } from "../../apollo/operations/queries/localQueries";
import { GET_MENU_TO_SYNC, jsonVar } from "../../apollo/cache";
import { diffArrays } from "./helpers/menuDiffHelpers";
import mapJsonToArray from "../../helpers/mapJsonToArray";
import {
  MenuItemWithOptions,
  MenuCategoryWithOptions,
  MenuLocal,
  MenuCategoryCollection,
} from "../../models/menu";
import Category from "./CategoryList/Category";
import MealItem from "./MealItem";
import LoaderHandler from "../shared/LoaderHandler";
import { MasterMenu } from "../../models/json";
type Props = {
  menuKey: string;
};
const DiffCategoryList = ({ menuKey }: Props) => {
  const {
    data: { json },
  } = useQuery(GET_JSON);
  const {
    data: { menuToSync },
  }: any = useQuery(GET_MENU_TO_SYNC);
  const [diffedJson, setDiffedJson] = useState({} as MenuLocal);

  useEffect(() => {
    if (!json) throw new Error("Json Missing");
    //getting required categories
    const jsonCats = {},
      menuToSyncCats = {};
    if (json.categories && menuToSync.categories) {
      for (let categoryId in json.menus[menuKey].category_ids) {
        jsonCats[categoryId] = json.categories[categoryId];
        menuToSyncCats[categoryId] = menuToSync.categories[categoryId];
      }
      const localJsonToMap: MasterMenu = {
        ...json,
        categories: {
          ...jsonCats,
        },
        modifier_groups: {
          ...json.modifier_groups,
          ...menuToSync.modifier_groups,
        },
      };

      // FIRST MAP BOTH JSONS - SO THAT ENTITIES ARE SWAPPED WITH ITEMS
      const mappedLocalJson = mapJsonToArray(localJsonToMap);
      const mappedSyncedJson = mapJsonToArray({
        ...menuToSync,
        categories: { ...menuToSyncCats },
      });

      setDiffedJson(
        produce(mappedLocalJson, (draftJson: MenuLocal) => {
          draftJson.children = diffArrays(
            mappedSyncedJson.children,
            mappedLocalJson.children
          ) as Draft<MenuCategoryCollection>;
        })
      );
      jsonVar(localJsonToMap);
    }
  }, [menuToSync, json, menuKey]);

  useEffect(() => {
    return () => {
      jsonVar(json);
    };
  }, [json]);

  return (
    <LoaderHandler loading={Object.keys(diffedJson)?.length === 0} error="">
      {Object.keys(diffedJson)?.length > 0 &&
        Object.values(diffedJson.children).map(
          (category: MenuCategoryWithOptions) => (
            <Category
              category={category}
              key={category.id}
              jsonItems={json?.items}
              isReadOnly
              menuKey={""}
            >
              {Object.values(category.children).map(
                (item: MenuItemWithOptions) => (
                  <MealItem
                    key={item.id}
                    item={item}
                    category={category.title}
                    isReadOnly
                  />
                )
              )}
            </Category>
          )
        )}
    </LoaderHandler>
  );
};

export default DiffCategoryList;
