/* eslint-disable radix */
import { HoursType } from "../../../models/stores";

// VALIDATE HOURS
/* eslint-disable no-mixed-operators */
export const validateHours = (hours: HoursType) =>
  parseInt(hours.closeHours) <= 23 &&
  parseInt(hours.closeHours) >= 0 &&
  parseInt(hours.openHours) <= 23 &&
  parseInt(hours.openHours) >= 0;

export const validateMinutes = (hours: HoursType) =>
  parseInt(hours.openMinutes) <= 59 &&
  parseInt(hours.openMinutes) >= 0 &&
  parseInt(hours.closeMinutes) <= 59 &&
  parseInt(hours.closeMinutes) >= 0;

export const validateEmptyField = (v: string) => v === "";

// HOURS TO STRING
export const stringToHours = (
  hours: any
): { hours: string; minutes: string } => ({
  hours: hours.slice(0, 2),
  minutes: hours.slice(2, 4),
});

export const hoursToString = (hours: any, minutes: any) =>
  hours.slice(0, 2) + minutes.slice(0, 2);

export const generatePaginationPages = (
  listLength: number,
  listPointers: { start: number; end: number }
): number => {
  const itemsPerPage = listPointers.end - listPointers.start;
  return Math.floor(
    listLength % itemsPerPage !== 0
      ? listLength / itemsPerPage + 1
      : listLength / itemsPerPage
  );
};
