import React from "react";
import { Button } from "@material-ui/core";
import { useMutation } from "@apollo/client";
import { useCustomizationsState } from "./CustomizationsProvider";
import {
  CREATE_CUSTOMIZATION,
  EDIT_CUSTOMIZATION,
} from "../../../apollo/operations/mutations/mutations";
import { MODAL_OPEN } from "../../../config/config";
import { customizationToEditVar, openModalVar } from "../../../apollo/cache";
import { Customization } from "../../../models/customizations";
import { CUSTOMIZATION } from "./utils/config";

type Props = {
  action: string;
};

const CustomizationsSubmitButton = ({ action }: Props) => {
  const { state } = useCustomizationsState();
  const [createCustomization] = useMutation(CREATE_CUSTOMIZATION);
  const [editCustomization] = useMutation(EDIT_CUSTOMIZATION);

  const addCustomization = () => {
    const input = {
      ...state,
      //if json is null send en empty string
      ...(!state.json && { json: "" }),
      aggregators: state.aggregators,
      storeGroups: state.storeGroups,
    };

    const { clone, ...customizationInput } = input;

    // console.log("input", input);
    if (action === "Add") {
      createCustomization({ variables: { input: customizationInput } });
    } else if (action === "Clone") {
      const { sk, ...otherFields } = customizationInput;
      createCustomization({ variables: { input: otherFields } });
    } else {
      editCustomization({ variables: { input: customizationInput } });
    }

    customizationToEditVar({} as Customization);
    openModalVar(MODAL_OPEN.NONE);
  };

  /*   const noIndividualAction =
    state.add.length === 0 &&
    state.remove.length === 0 &&
    state.edit.length === 0; */
  return (
    <Button
      onClick={addCustomization}
      color="primary"
      variant="contained"
      size="large"
      disabled={!state[CUSTOMIZATION.SLUG]}
    >
      Save
    </Button>
  );
};

export default CustomizationsSubmitButton;
