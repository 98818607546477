import React from "react";
import StoreSearchbar from "../../shared/StoreSearchbar";
import ChannelDropdown from "./ChannelDropdown";

import style from "./styles/OpeningHoursNav.module.scss";

const OpeningHoursNav = () => (
  <div className={style.topRow}>
    <StoreSearchbar />
    <ChannelDropdown />
  </div>
);

export default OpeningHoursNav;
