const omitDeep = (value, key) => {
  if (Array.isArray(value)) {
    return value.map((i) => omitDeep(i, key));
  }
  if (typeof value === "object" && value !== null) {
    return Object.keys(value).reduce((newObject, k) => {
      // eslint-disable-next-line eqeqeq
      if (k === key) return newObject;
      return { [k]: omitDeep(value[k], key), ...newObject };
    }, {});
  }

  return value;
};

export default omitDeep;
