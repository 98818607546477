import { gql } from "@apollo/client";

export const RESTAURANT_DATA_FRAGMENT = gql`
  fragment restaurantData on RestaurantData {
    aggregatorUUID
    ms2StoreNumber
    refId
    menuName
    menuSlug
    masterMenu
    uuid
    restaurantName
    inSyncWithMaster
    monday {
      open
      close
    }
    tuesday {
      open
      close
    }
    wednesday {
      open
      close
    }
    thursday {
      open
      close
    }
    friday {
      open
      close
    }
    saturday {
      open
      close
    }
    sunday {
      open
      close
    }
    posIntegrated
    posIntegratedSince
    lastChecked
    partnerStoreId
    orderReleaseEnabled
    autoAcceptEnabled
    onlineStatus
    offlineReason
    closed
    flytId
    geolocation {
        latitude
        longitude
    }
    deliverooUrl
    justEatUrl
    uberEatsUrl
  }
`;