export enum ROUTES {
  "/" = "Menu Management",
  "/menu-source" = "Menu Source",
  "/store-management" = "Store Management",
  "/opening-hours" = "Opening Hours",
  "/mission-control" = "Mission Control",
  "/menu-analytics" = "Menu Analytics",
  "/alert-list" = "Alert List",
}

export enum MODAL_OPEN {
  NONE = 0,
  MEAL_ITEM = 1,
  SAVE_MENU = 2,
  PUBLISH_MENU = 3,
  SUSPEND_ITEM = 4,
  STORE_GROUP = 5,
  WARNING = 6,
  SYNC = 7,
  CUSTOMIZATION = 8,
  HISTORY = 9,
  TIME_TRAVEL = 10,
  COLLECTIONS = 11,
  SUSPEND_CATEGORY = 12,
}

//Role based access permissions
export const PERMISSIONS = {
  Auditors: {
    save_menu: false,
    save_opening_hours: false,
    mission_control_access: false,
    create_product_collection: false,
    not_allowed_routes: ["/menu-source"],
  },
  Operators: {
    save_menu: true,
    save_opening_hours: true,
    mission_control_access: true,
    create_product_collection: true,
    not_allowed_routes: [],
  },
  Admin: {
    save_menu: true,
    save_opening_hours: true,
    mission_control_access: true,
    create_product_collection: true,
    not_allowed_routes: [],
  },
};
// TO DO does all this info get bundled into a release or only the relevant staging part?
const ENV_CONFIG = {
  staging: {
    ASSETS_URL: "https://prestau-assets-dev.s3.eu-west-2.amazonaws.com",
    CLIENT_ID: "kfc-uk-dev",
    GRAPHQL_URL: "https://dev.prestau.net/graphql",
    AUTH_URL: "https://auth.dev.prestau.net",
    AUTH_CLIENT_ID: "5valitb94vpgn8v2l4jt06qrsf",
    REDIRECT_URI: "https://www.prestau.net/publisher/index.html",
  },
  dev: {
    ASSETS_URL: "https://prestau-assets-dev.s3.eu-west-2.amazonaws.com",
    CLIENT_ID: "kfc-uk-dev",
    GRAPHQL_URL: "https://dev.prestau.net/graphql",
    AUTH_URL: "https://auth.dev.prestau.net",
    AUTH_CLIENT_ID: "5valitb94vpgn8v2l4jt06qrsf",
    REDIRECT_URI: "http://localhost:3000/",
  },
  production: {
    ASSETS_URL: "https://prestau-assets-prod.s3.eu-west-2.amazonaws.com",
    CLIENT_ID: "kfc-uk-prod",
    GRAPHQL_URL: "https://prod.prestau.net/graphql",
    AUTH_URL: "https://auth.prod.prestau.net",
    AUTH_CLIENT_ID: "1qt8ml4r7vcu0quo7u1pdh7t75",
    REDIRECT_URI: "https://www.prestau.net/publisher/index.html",
  },
};

export enum ENV_VARS {
  ASSETS_URL = ENV_CONFIG[process.env.REACT_APP_ENV as string].ASSETS_URL,
  CLIENT_ID = ENV_CONFIG[process.env.REACT_APP_ENV as string].CLIENT_ID,
  GRAPHQL_URL = ENV_CONFIG[process.env.REACT_APP_ENV as string].GRAPHQL_URL,
  AUTH_URL = ENV_CONFIG[process.env.REACT_APP_ENV as string].AUTH_URL,
  AUTH_CLIENT_ID = ENV_CONFIG[process.env.REACT_APP_ENV as string]
    .AUTH_CLIENT_ID,
  REDIRECT_URI = ENV_CONFIG[process.env.REACT_APP_ENV as string].REDIRECT_URI,
}
