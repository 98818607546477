const invokeOnDragEnd = (onDragEnd: (result: any) => void) => {
  return function (result: any): void {
    if (
      result.destination !== undefined &&
      result.destination !== null &&
      !result.combine
    ) {
      const newResult: any = {
        source: {
          id: result.source.droppableId,
          index: result.source.index,
        },
        destination: {
          id: result.destination.droppableId,
          index: result.destination.index,
        },
      };
      onDragEnd(newResult);
    } else {
      onDragEnd(result);
    }
  };
};

export default invokeOnDragEnd;
