import React, { useState } from "react";
import ReactTooltip from "react-tooltip";
import classnames from "classnames";
import { v4 as uuid } from "uuid";
import { useQuery } from "@apollo/client";
import { Button } from "@material-ui/core";
import { GET_RECENTLY_DELETED } from "../../apollo/operations/queries/localQueries";
import {
  addCategory,
  addCategoryId,
  addItemIdToModifierGroup,
  addItemToCategory,
} from "../../apollo/operations/actions/actions";
import { recentlyDeletedVar } from "../../apollo/cache";
import { RecentDeletedItem, MenuItemWithOptions } from "../../models/menu";
import { MenuCategory, TranslationObject } from "../../models/json";

import style from "./styles/Nav.module.scss";

const RecentlyDeleted: React.FC = () => {
  const {
    data: { recentlyDeletedItems },
  }: any = useQuery(GET_RECENTLY_DELETED);

  const [isDropdownVisible, showDropdown] = useState(false);

  const handleToggle = () => {
    if (isDropdownVisible) {
      showDropdown(false);
    } else {
      showDropdown(true);
    }
  };

  const trashCanstyle = classnames(style.trashCan, {
    [style.toggledTrashCan]: isDropdownVisible,
  });
  const dropdownstyle = classnames(style.dropdown, {
    [style.visibleDropdown]: isDropdownVisible,
  });
  return (
    <div data-tip data-for="trashCan" className="mx-3">
      {recentlyDeletedItems.length !== 0 && (
        <div>
          <button
            type="button"
            className={trashCanstyle}
            onClick={handleToggle}
          >
            Trash Can <i className="fas fa-angle-right" />
          </button>
          <div className={dropdownstyle}>
            {recentlyDeletedItems.map(
              (item: RecentDeletedItem, index: number) => {
                const itemTitle =
                  item.item &&
                  "title" in item.item &&
                  ((item.item.title as TranslationObject).translations
                    ? (item.item.title as TranslationObject).translations.en_uk
                    : (item.item.title as any));
                return (
                  <div className={style.trashCanItem} key={index}>
                    <p className={style.trashCanItemText}>
                      <b>
                        {item.menuKey
                          ? `${itemTitle} from ${item.menuKey}`
                          : `${itemTitle}`}
                      </b>{" "}
                      deleted to trash can
                    </p>
                    <Button
                      variant="contained"
                      color="primary"
                      disableElevation
                      className={style.trashCanItemBtn}
                      style={{ minWidth: 0 }}
                      key={uuid()}
                      onClick={() => restoreItem(item)}
                    >
                      Restore
                    </Button>
                  </div>
                );
              }
            )}
          </div>
        </div>
      )}
      <ReactTooltip
        id="trashCan"
        place="bottom"
        type="dark"
        effect="float"
        globalEventOff="click"
      >
        <span>review deleted items</span>
      </ReactTooltip>
    </div>
  );
};
export const restoreItem = ({
  item,
  category,
  modifier,
  menuKey,
}: RecentDeletedItem) => {
  recentlyDeletedVar(
    recentlyDeletedVar().filter((i: RecentDeletedItem) => {
      if (menuKey) return i.item.id !== item.id || menuKey !== i.menuKey;
      else return i.item.id !== item.id;
    })
  );

  if (modifier) {
    addItemIdToModifierGroup(item.id, modifier);
    return;
  }

  if (category) {
    addItemToCategory(item as MenuItemWithOptions, category);
  }

  if (!category && "title" in item) {
    addCategory(item as MenuCategory);
    addCategoryId(item.title.translations.en_uk as string, menuKey);

    //when a category is restored, check if it has an associated deleted menu
    // if (
    //   deletedMenu &&
    //   deletedMenu.title.translations.en_uk ===
    //     getNewMenuNameForCategory(item.title.translations.en_uk)
    // ) {
    //   //rerun the logic to create a new menu
    //   addNewMenuToJson(
    //     item.title.translations.en_uk,
    //     deletedMenu.service_availability
    //   );
    // }
  }
};

export default RecentlyDeleted;
