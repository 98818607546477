import { gql } from "@apollo/client";

export const GET_ENTIRE_MENU = gql`
  query GetEntireMenu {
    menuCategories @client {
      title
      children
    }
  }
`;

export const GET_JSON = gql`
  query GetJson {
    json @client
  }
`;

export const GET_MODAL_VALUE = gql`
  query GetModalValue {
    modalValue @client
  }
`;

export const GET_SAVED_MENU_NAME = gql`
  query GetSavedMenuName {
    savedMenuName @client
  }
`;

export const GET_SELECTED_CHANNEL = gql`
  query GetSelectedChannel {
    selectedChannel @client
  }
`;

export const GET_STORE_SEARCH_VALUE = gql`
  query GetStoreSearchValue {
    storeSearch @client
  }
`;

export const GET_STORE_LIST_PAGINATION = gql`
  query GetStoreListPagination {
    storeListPagination @client
  }
`;

export const GET_LOCAL_OPENING_HOURS = gql`
  query GetLocalOpeningHours {
    localOpeningHours @client
  }
`;

export const GET_ARE_CHANGES_SAVED = gql`
  query GetAreChangesSaved {
    areChangesSaved @client
  }
`;
export const GET_ARE_CHANGES_PUBLISHED = gql`
  query GetAreChangesPublished {
    areChangesPublished @client
  }
`;

export const GET_RECENTLY_DELETED = gql`
  query GetRecentlyDeleted {
    recentlyDeletedItems @client
  }
`;

export const GET_SELECTED_GROUPS = gql`
  query GetSelectedGroups {
    selectedGroups @client
  }
`;

export const GET_STORE_GROUPS = gql`
  query GetStoreGroups {
    storeGroups @client
  }
`;

export const GET_CHANGED_STORES = gql`
  query GetChangedStores {
    changedStores @client
  }
`;

export const GET_SAVED_MENUS_LOCAL = gql`
  query GetSavedMenus {
    savedMenus @client
  }
`;

export const GET_IS_AUTH = gql`
  query GetIsAuth {
    isAuthenticated @client
  }
`;

export const GET_RANDOM_CODE = gql`
  query GetRandomCode {
    randomCode @client
  }
`;

export const GET_LIVE_TOGGLE = gql`
  query GetLiveToggle {
    liveToggle @client
  }
`;
export const GET_SUSPEND_CATEGORY = gql`
  query GetSuspendCategory {
    category_id @client
  }
`;
/**
 * Local Query used to save the state of menu
 * If user changes menu, @isMenuModified is updated
 */
export const GET_IS_MENU_MODIFIED = gql`
  query GetIsMenuModified {
    isMenuModified @client
  }
`;
export const CUSTOMIZATION_SCHEDULE = gql`
  query CustomizationSchedule {
    schedule @client
  }
`;

export const GET_ACTIVE_MENU_TAB = gql`
  query GetActiveMenuTab {
    activeMenuTab @client
  }
`;
