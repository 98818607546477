/* eslint-disable react/require-default-props */
import React from "react";
import { TextField } from "@material-ui/core";
import { useCustomizationsState } from "../CustomizationsProvider";
import { CUSTOMIZATION } from "../utils/config";

import style from "../styles/CustomizationModal.module.scss";

type Props = {
  name: string;
  moreInfoComponent?: JSX.Element;
};

const CustomizationsMainTextField: React.FC<Props> = ({
  name,
  moreInfoComponent,
}) => {
  const { state, dispatch } = useCustomizationsState();

  const handleInput = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    dispatch({
      type: e.target.name as CUSTOMIZATION,
      payload: e.target.value as string,
    });
  };

  return (
    <>
      <span className={style.inputLabel}>
        {name === CUSTOMIZATION.SLUG ? "short name" : name} {moreInfoComponent}
      </span>
      <div className={style.inputContainer}>
        <TextField
          name={name}
          variant="outlined"
          placeholder={name}
          value={state[name] || ""}
          onChange={handleInput}
          fullWidth
        />
      </div>
    </>
  );
};

export default CustomizationsMainTextField;
