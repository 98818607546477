import React, { useEffect } from "react";
import { IconButton, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { storeSearchVar } from "../../apollo/cache";

const useStyles = makeStyles({
  search: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 268,
    height: 35,
  },
  input: {
    height: 35,
    color: "#3456ff",
    ".MuiOutlinedInput-input::placeholder": {
      color: "#9ff09a",
      opacity: "1",
    },
    "&::placeholder": {
      color: "gray",
    },
  },
  iconButton: {
    marginTop: "22px",
    marginLeft: "-40px",
  },
});

const StoreSearchbar = () => {
  const classes = useStyles();

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    storeSearchVar(e.target.value.toLowerCase());
  };

  useEffect(() => {
    return () => {
      storeSearchVar("");
    };
  }, []);

  return (
    <div className={classes.search}>
      <TextField
        variant="outlined"
        placeholder="Search stores"
        label="Search stores"
        className={classes.input}
        fullWidth
        onChange={handleSearch}
      />
      <IconButton
        type="submit"
        className={classes.iconButton}
        aria-label="search"
        size="medium"
      >
        <i className="fas fa-search" style={{ fontSize: 18 }} />
      </IconButton>
    </div>
  );
};

export default StoreSearchbar;
