import React from "react";
import { useMutation } from "@apollo/client";
import { UPDATE_STORE_GROUP } from "../../../../apollo/operations/mutations/mutations";
import TitleWithInput from "../../../shared/TitleWithInput";
import { replaceGroup } from "../../../../apollo/operations/actions/actions";
import { StoreGroupType } from "../../../../models/stores";
import icon from "../../../../media/images/ic-card-menu.svg";
import style from "../styles/StoreGroups.module.scss";

interface Props {
  group: StoreGroupType;
  deleteIconComponent: any;
}

const StoreGroupHeader: React.FC<Props> = ({ group, deleteIconComponent }) => {
  const [updateStoreGroup] = useMutation(UPDATE_STORE_GROUP);

  const changeTitle = (groupParam: StoreGroupType) => {
    replaceGroup(groupParam);
    const { id, ...restOfGroup } = groupParam;
    updateStoreGroup({ variables: { input: restOfGroup } });
  };

  return (
    <>
      <div className={style.menuCardBodyContent}>
        <div className={style.uiFlexGrow}>
          <img src={icon} className={style.itemImg} alt="store group" />
          <h1 className={style.itemTitle}>
            <TitleWithInput
              item={group}
              stateUpdate={changeTitle}
              style={style}
            />
          </h1>
          <p className={style.itemDesc}>
            {group.storeIds.UberEats.length} stores
          </p>
        </div>
        <div className={style.uiTextRight}>{deleteIconComponent}</div>
      </div>
    </>
  );
};

export default StoreGroupHeader;
