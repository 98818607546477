export const DAYS = [
  "First",
  "Second",
  "Third",
  "Fourth",
  "Fifth",
  "Sixth",
  "Seventh",
  "Eighth",
  "Nineth",
  "Tenth",
  "Eleventh",
  "Twelveth",
  "Thirteenth",
  "Fourteenth",
  "Fifteenth",
  "Sixteenth",
  "Seventeenth",
  "Eighteenth",
  "Nineteenth",
  "Twentieth",
  "Twenty-first",
  "Twenty-second",
  "Twenty-third",
  "Twenty-fourth",
  "Twenty-fifth",
  "Twenty-sixth",
  "Twenty-seventh",
  "Twenty-eighth",
  "Twenty-nineth",
  "Thirty",
  "Thirty-first",
];
export const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
export const WeekDaysArray = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
export const WeeksInMonth = ["First", "Second", "Third", "Fourth"];

export const DaysOfWeekDefault = {
  Monday: { long: "Monday", short: "Mon", isSelected: false },
  Tuesday: { long: "Tuesday", short: "Tue", isSelected: false },
  Wednesday: { long: "Wednesday", short: "Wed", isSelected: false },
  Thursday: {
    long: "Thursday",
    short: "Thu",
    isSelected: false,
  },
  Friday: {
    long: "Friday",
    short: "Fri",
    isSelected: false,
  },
  Saturday: {
    long: "Saturday",
    short: "Sat",
    isSelected: false,
  },
  Sunday: {
    long: "Sunday",
    short: "Sun",
    isSelected: false,
  },
};
export const DaysOfWeekDefaultArray = Object.values(DaysOfWeekDefault);
