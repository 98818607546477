// _ represents the nested path
// . is for camelCase
export enum CUSTOMIZATIONS_ACTION {
  ITEMMODIFIERGROUPIDS_APPLICABLE_ITEMS = "ITEM.MODIFIER.GROUP.IDS_APPLICABLE.ITEMS",
  CONTEXT = "CONTEXT",
  ITEMMODIFIERGROUPIDS_FROM = "ITEM.MODIFIER.GROUP.IDS_FROM",
  ITEMMODIFIERGROUPIDS_TO = "ITEM.MODIFIER.GROUP.IDS_TO",
  ITEMMODIFIERGROUPIDS_ITEMIDS = "ITEM.MODIFIER.GROUP.IDS_ITEM.IDS",
  ITEMMODIFIERGROUPIDS_POSITION = "ITEM.MODIFIER.GROUP.IDS_POSITION",
  ITEMIDS = "ITEM.IDS",
  MODIFIERGROUPS_ID = "MODIFIER.GROUPS_ID",
  MODIFIERGROUPS_TITLE = "MODIFIER.GROUPS_TITLE",
  MODIFIERGROUPS_EXTERNAL_DATA = "MODIFIER.GROUPS_EXTERNAL.DATA",
  MODIFIERGROUPS_SELECTED_OPTIONS = "MODIFIER.GROUPS_MODIFIER.OPTIONS",
  MODIFIERGROUPS_MIN_PERMITTED = "MODIFIER.GROUPS_MIN.PERMITTED",
  MODIFIERGROUPS_MAX_PERMITTED = "MODIFIER.GROUPS_MAX.PERMITTED",
  CLEAR_ALL = "CLEARALL",
}

export enum CUSTOMIZATION {
  GLOBAL = "global",
  SLUG = "slug",
  DESCRIPTION = "description",
  STORES = "storeIds",
  STORE_GROUPS = "storeGroups",
  ADD = "add",
  EDIT = "edit",
  REMOVE = "remove",
  AGGREGATORS = "aggregators",
  SK = "sk",
  CLONE = "clone",
  JSON = "json",
  NONE = "none",
  SCHEDULE = "schedule",
}

export const CUSTOMIZATIONS_FORM_LABELS = {
  [CUSTOMIZATION.ADD]: {
    Header: "Add a modifier group to menu item(s)",
    // [CUSTOMIZATIONS_ACTION.ITEMIDS]: "Add item(s):",
    AttachComponentsToMealItemsHeader:
      "Assign the modifier group to menu item(s) :",
    [CUSTOMIZATIONS_ACTION.ITEMMODIFIERGROUPIDS_APPLICABLE_ITEMS]:
      "applicable to :",
    [CUSTOMIZATIONS_ACTION.ITEMMODIFIERGROUPIDS_ITEMIDS]: "member items :",
    [CUSTOMIZATIONS_ACTION.ITEMMODIFIERGROUPIDS_POSITION]:
      "at which position :",
    ModifierGroupsHeader: "Add modifier group",
    [CUSTOMIZATIONS_ACTION.MODIFIERGROUPS_ID]: "id :",
    [CUSTOMIZATIONS_ACTION.MODIFIERGROUPS_TITLE]: "title :",
    [CUSTOMIZATIONS_ACTION.MODIFIERGROUPS_EXTERNAL_DATA]: "external data :",
    [CUSTOMIZATIONS_ACTION.MODIFIERGROUPS_SELECTED_OPTIONS]:
      "modifier options :",
    [CUSTOMIZATIONS_ACTION.MODIFIERGROUPS_MIN_PERMITTED]: "min. permitted :",
    [CUSTOMIZATIONS_ACTION.MODIFIERGROUPS_MAX_PERMITTED]: "max. permitted :",
    SaveButton: "SUBMIT",
  },
  [CUSTOMIZATION.EDIT]: {
    Header: "Adjust a modifier group for specific menu item(s)",
    [CUSTOMIZATIONS_ACTION.ITEMMODIFIERGROUPIDS_APPLICABLE_ITEMS]:
      "applicable to :",
    // [CUSTOMIZATIONS_ACTION.ITEMIDS]: "Edit item(s):",
    AttachComponentsToMealItemsHeader: "Adjust modifier group",
    [CUSTOMIZATIONS_ACTION.ITEMMODIFIERGROUPIDS_FROM]: "find :",
    [CUSTOMIZATIONS_ACTION.ITEMMODIFIERGROUPIDS_TO]: "and replace with :",
    [CUSTOMIZATIONS_ACTION.ITEMMODIFIERGROUPIDS_ITEMIDS]:
      "modifier group to edit :",
    // "ModifierGroupsHeader": "Edit modifier groups",
    // [CUSTOMIZATIONS_ACTION.MODIFIERGROUPS_ID]: "ID",
    // [CUSTOMIZATIONS_ACTION.MODIFIERGROUPS_EXTERNAL_DATA]: "external data :",
    // [CUSTOMIZATIONS_ACTION.MODIFIERGROUPS_SELECTED_OPTIONS]: "Modifier options:",
    // [CUSTOMIZATIONS_ACTION.MODIFIERGROUPS_MIN_PERMITTED]: "Min permitted",
    // [CUSTOMIZATIONS_ACTION.MODIFIERGROUPS_MAX_PERMITTED]: "Max permitted",
    SaveButton: "SUBMIT",
  },
  [CUSTOMIZATION.REMOVE]: {
    Header: "Remove item(s) from the menu",
    [CUSTOMIZATIONS_ACTION.CONTEXT]: "Applicable to :",
    [CUSTOMIZATIONS_ACTION.ITEMIDS]: "Remove item(s) :",
    // "AttachComponentsToMealItemsHeader": "Remove components in meal items",
    // [CUSTOMIZATIONS_ACTION.ITEMMODIFIERGROUPIDS_ITEMID]: "Remove component(s):",
    // "ModifierGroupsHeader": "Remove modifier groups",
    // [CUSTOMIZATIONS_ACTION.MODIFIERGROUPS_ID]: "ID",
    // [CUSTOMIZATIONS_ACTION.MODIFIERGROUPS_EXTERNAL_DATA]: "external data :",
    // [CUSTOMIZATIONS_ACTION.MODIFIERGROUPS_SELECTED_OPTIONS]: "Modifier options:",
    // [CUSTOMIZATIONS_ACTION.MODIFIERGROUPS_MIN_PERMITTED]: "Min permitted",
    // [CUSTOMIZATIONS_ACTION.MODIFIERGROUPS_MAX_PERMITTED]: "Max permitted",
    SaveButton: "SUBMIT",
  },
};

export enum POSITION_OPTIONS {
  AT_THE_BEGINNING = "at the beginning",
  AT_THE_END = "at the end",
}
