import { gql } from "@apollo/client";

export const GET_RESTAURANT_DATA = gql`
  query listRestaurantData {
    listRestaurantData(filter: { closed: { ne: true } }, limit: 1000) {
      items {
        aggregatorUUID
        uuid
        ms2StoreNumber
        refId
        restaurantName
        menuName
        menuSlug
        masterMenu
        posIntegrated
        posIntegratedSince
        lastChecked
        autoAcceptEnabled
        onlineStatus
        offlineReason
        partnerStoreId
        monday {
          close
          open
        }
        tuesday {
          close
          open
        }
        wednesday {
          close
          open
        }
        thursday {
          close
          open
        }
        friday {
          close
          open
        }
        saturday {
          close
          open
        }
        sunday {
          close
          open
        }
        __typename
      }
    }
  }
`;

export const GET_UPLOAD_URL = gql`
  query MyQuery(
    $assetType: String!
    $fileName: String!
    $fileType: String!
    $commitMessage: String
    $regenerate: Boolean
    $versionChange: VersionChange
  ) {
    uploadUrl(
      assetType: $assetType
      fileName: $fileName
      fileType: $fileType
      commitMessage: $commitMessage
      regenerate: $regenerate
      versionChange: $versionChange
    ) {
      url
      posKey
    }
  }
`;

export const PUBLISH_MENU = gql`
  query MyQuery(
    $action: String!
    $menuKey: String!
    $storeGroups: [String]
    $storeIds: [String]
    $aggregators: [Aggregators]!
    $publishTimestamp: AWSDateTime
  ) {
    publishMenu(
      action: $action
      menuKey: $menuKey
      storeGroups: $storeGroups
      storeIds: $storeIds
      aggregators: $aggregators
      publishTimestamp: $publishTimestamp
    ) {
      data
    }
  }
`;

export const PUBLISH_MENU_LIVE = gql`
  query MyQuery(
    $action: String!
    $menuKey: String!
    $storeGroups: [String]
    $storeIds: [String]
    $aggregators: [Aggregators]!
    $publishTimestamp: AWSDateTime
  ) {
    publishMenuLive(
      action: $action
      menuKey: $menuKey
      storeGroups: $storeGroups
      storeIds: $storeIds
      aggregators: $aggregators
      publishTimestamp: $publishTimestamp
    ) {
      data
    }
  }
`;

export const GET_SAVED_MENUS = gql`
  query MyQuery {
    getClientMenus {
      menus {
        menuName
        menuKey
        versionId
        storeGroups
        __typename
      }
    }
  }
`;

export const GET_SAVED_MENU_URL = gql`
  query MyQuery($fileName: String!) {
    getUrl(fileName: $fileName, assetType: "menu") {
      url
    }
  }
`;

export const GET_STORE_GROUPINGS = gql`
  query MyQuery {
    listRestaurantGroupings {
      items {
        id
        title
        storeIds {
          UberEats
        }
        __typename
      }
    }
  }
`;

export const GET_POS_STATUS = gql`
  query getPosStatus($aggregatorUUID: String!) {
    getPosStatus(aggregatorUUID: $aggregatorUUID) {
      data {
        lastChecked
        posIntegrated
      }
    }
  }
`;

export const GET_POS_STATUS_LIVE = gql`
  query getPosStatus($aggregatorUUID: String!) {
    getPosStatusLive(aggregatorUUID: $aggregatorUUID) {
      data {
        lastChecked
        posIntegrated
      }
    }
  }
`;

export const GET_SYNC_MENU_URL = gql`
  query MyQuery($posKey: String!, $masterMenu: String!) {
    syncMenu(posKey: $posKey, masterMenu: $masterMenu) {
      data {
        url
        menuKey
      }
    }
  }
`;

export const GET_SEARCHBAR = gql`
  query MyQuery {
    searchBar {
      data {
        name
        type
        items {
          name
          value
        }
      }
    }
  }
`;

export const GET_MENU = gql`
  query MyQuery($fileName: String!, $versionId: String) {
    getMenu(fileName: $fileName, versionId: $versionId) {
      data {
        url
      }
    }
  }
`;

export const GET_CLIENT_CUSTOMISATIONS = gql`
  query MyQuery {
    listClientCustomisations {
      nextToken
      items {
        clientId
        sk
        slug
        storeIds
        storeGroups
        description
        schedule {
          repeat
          interval
          weekDays
          day
          pos
          month
          until
          count
          closingTime
          openingTime
        }
        global
        aggregators
        json
        __typename
      }
    }
  }
`;

export const GET_CLIENT_CONFIG = gql`
  query MyQuery {
    getClientConfig {
      clientId
      countryCode
      posType
      uberEatsTemplates {
        singleItemTemplate
        mealItemTemplate
        mealComponentTemplate
        modifierGroupTemplate
        modifierNameTemplate
      }
      deliverooTemplates {
        singleItemTemplate
        mealItemTemplate
        mealComponentTemplate
        modifierGroupTemplate
        modifierNameTemplate
      }
      justEatTemplates {
        singleItemTemplate
        mealItemTemplate
        mealComponentTemplate
        modifierGroupTemplate
        modifierNameTemplate
      }
    }
  }
`;

export const GET_MENU_HISTORY = gql`
  query MyQuery($menuKey: String!) {
    getMenus(menuKey: $menuKey) {
      items {
        clientId
        s3VersionId
        savedTimestamp
        commitMessage
        authorName
        menuVersion
      }
      nextToken
    }
  }
`;

export const GET_MENU_TRAVEL = gql`
  query MyQuery($dateTime: AWSDateTime!, $menuKey: String!) {
    menuTravel(dateTime: $dateTime, menuKey: $menuKey) {
      data {
        url
      }
    }
  }
`;

export const GET_ITEM_COLLECTIONS = gql`
  query MyQuery {
    listItemCollections {
      items {
        id
        itemIds
        title
        __typename
      }
    }
  }
`;
export const GET_INITIAL_DATA = gql`
  query InitialQuery {
    stores @client
    isAuthenticated @client
    listItemCollections {
      items {
        id
        itemIds
        title
        __typename
      }
    }
    listRestaurantGroupings {
      items {
        id
        title
        storeIds {
          UberEats
        }
        __typename
      }
    }
    listClientCustomisations {
      nextToken
      items {
        clientId
        sk
        slug
        storeIds
        storeGroups
        description
        schedule {
          repeat
          interval
          weekDays
          day
          pos
          month
          until
          count
          closingTime
          openingTime
        }
        global
        aggregators
        json
        __typename
      }
    }
    getClientMenus {
      menus {
        menuName
        menuKey
        versionId
        storeGroups
        __typename
      }
    }
    listRestaurantData(filter: { closed: { ne: true } }, limit: 1000) {
      items {
        aggregatorUUID
        uuid
        ms2StoreNumber
        refId
        restaurantName
        menuName
        menuSlug
        masterMenu
        posIntegrated
        posIntegratedSince
        lastChecked
        autoAcceptEnabled
        onlineStatus
        offlineReason
        partnerStoreId
        monday {
          close
          open
        }
        tuesday {
          close
          open
        }
        wednesday {
          close
          open
        }
        thursday {
          close
          open
        }
        friday {
          close
          open
        }
        saturday {
          close
          open
        }
        sunday {
          close
          open
        }
        __typename
      }
    }
  }
`;

export const GET_AUTH_GRAFANA = gql`
  query MyQuery {
    getAuthGrafana {
      location
      setCookie
    }
  }
`;
