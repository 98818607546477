import { gql } from "@apollo/client";

export const UPDATE_RESTAURANT_DATA = gql`
  mutation updateRestaurantData(
    $updaterestaurantdatainput: UpdateRestaurantDataInput!
  ) {
    updateRestaurantData(input: $updaterestaurantdatainput) {
      aggregatorUUID
      uuid
      clientId
      ms2StoreNumber
      restaurantName
      masterMenu
      menuName
      menuSlug
      posIntegrated
      posIntegratedSince
      lastChecked
      inSyncWithMaster
      uberEatsUrl
      justEatUrl
      deliverooUrl
      closed
      flytId
      refId
      partnerStoreId
      orderReleaseEnabled
      autoAcceptEnabled
      onlineStatus
      offlineReason
      geolocation {
        latitude
        longitude
      }
      monday {
        close
        open
      }
      tuesday {
        close
        open
      }
      wednesday {
        close
        open
      }
      thursday {
        close
        open
      }
      friday {
        close
        open
      }
      saturday {
        close
        open
      }
      sunday {
        close
        open
      }
    }
  }
`;

/* {clientId: "kfc-uk-staging", clusterName: "HalalVegan", storeIds: {UberEats: ["b5eddbe8-11f5-40a6-a788-39359ba31b24"]} */

export const ADD_STORE_GROUP = gql`
  mutation MyMutation($input: CreateRestaurantGroupingsInput!) {
    createRestaurantGroupings(input: $input) {
      id
      clientId
      title
      storeIds {
        UberEats
      }
      __typename
    }
  }
`;

export const UPDATE_STORE_GROUP = gql`
  mutation MyMutation($input: UpdateRestaurantGroupingsInput!) {
    updateRestaurantGroupings(input: $input) {
      id
      clientId
      title
      storeIds {
        UberEats
      }
      __typename
    }
  }
`;

export const DELETE_STORE_GROUP = gql`
  mutation MyMutation($input: DeleteRestaurantGroupingsInput!) {
    deleteRestaurantGroupings(input: $input) {
      id
      clientId
      title
      __typename
    }
  }
`;

export const PATCH_POS_STATUS = gql`
  mutation MyMutation($action: Boolean!, $aggregatorUUID: String!) {
    patchPosStatus(action: $action, aggregatorUUID: $aggregatorUUID) {
      data {
        lastChecked
        posIntegrated
      }
    }
  }
`;
export const PATCH_POS_STATUS_LIVE = gql`
  mutation MyMutation($action: Boolean!, $aggregatorUUID: String!) {
    patchPosStatusLive(action: $action, aggregatorUUID: $aggregatorUUID) {
      data {
        lastChecked
        posIntegrated
      }
    }
  }
`;

export const SUSPEND_ITEM = gql`
  mutation MyMutation($input: SuspendProductInput!) {
    suspendProduct(input: $input) {
      data {
        suspended
      }
    }
  }
`;

export const CREATE_CUSTOMIZATION = gql`
  mutation MyMutation($input: CustomisationsInput!) {
    createClientCustomisations(input: $input) {
      sk
      slug
      clientId
      storeIds
      storeGroups
      description
      global
      schedule {
        repeat
        interval
        weekDays
        day
        pos
        month
        until
        count
        closingTime
        openingTime
      }
      json
    }
  }
`;

export const EDIT_CUSTOMIZATION = gql`
  mutation MyMutation($input: UpdateCustomisationsInput!) {
    updateClientCustomisations(input: $input) {
      sk
      slug
      clientId
      storeIds
      storeGroups
      description
      global
      schedule {
        repeat
        interval
        weekDays
        day
        pos
        month
        until
        count
        closingTime
        openingTime
      }
      json
    }
  }
`;

export const DELETE_CUSTOMIZATION = gql`
  mutation MyMutation($input: DeleteCustomisationsInput!) {
    deleteClientCustomisations(input: $input) {
      sk
      slug
      clientId
      storeIds
      storeGroups
      description
      global
      json
    }
  }
`;

export const CREATE_CLIENT_CONFIG = gql`
  mutation MyMutation($input: ClientConfigInput!) {
    createClientConfig(input: $input) {
      posType
    }
  }
`;

export const UPDATE_CLIENT_CONFIG = gql`
  mutation MyMutation($input: ClientConfigInput!) {
    updateClientConfig(input: $input) {
      posType
    }
  }
`;

export const UPSERT_ITEM_COLLECTION = gql`
  mutation MyMutation($itemIds: [String]!, $title: String!, $id: String) {
    upsertItemCollection(input: { itemIds: $itemIds, title: $title, id: $id }) {
      id
      itemIds
      title
      __typename
    }
  }
`;

export const DELETE_ITEM_COLLECTION = gql`
  mutation MyMutation($id: String!) {
    deleteItemCollection(input: { id: $id }) {
      id
      itemIds
      title
      __typename
    }
  }
`;

export const SUSPEND_CATEGORY = gql`
  mutation MyMutation($input: SuspendCategoryInput!) {
    SuspendProduct(input: $input) {
      data {
        json
      }
    }
  }
`;
