import React from "react";
import { useQuery } from "@apollo/client";
import { Route, Redirect } from "react-router-dom";
import { GET_IS_AUTH } from "../../apollo/operations/queries/localQueries";
import { userGroupVar } from "../../apollo/cache";
import { PERMISSIONS } from "../../config/config";

interface Props {
  component: any;
  path: string;
}

const AuthPrivateRoute: React.FC<Props> = ({
  component: Component,
  path,
  ...rest
}) => {
  const { data } = useQuery(GET_IS_AUTH);
  if (PERMISSIONS[userGroupVar()]["not_allowed_routes"].includes(path)) {
    return <Redirect to="/" />;
  }
  return (
    <Route
      {...rest}
      render={(props: any) =>
        data.isAuthenticated === true ? (
          <Component {...props} />
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};

export default AuthPrivateRoute;
