import React from "react";

import { Button, ButtonGroup } from "@material-ui/core";
import { ScheduleTabNames } from "./Utils/types";

interface Props {
  activeTab: ScheduleTabNames;
  onTabChanged: (value: ScheduleTabNames) => void;
}
/**
 *
 * @param activeTab - Tab that is currently Active
 * @param onTabChanged - callback for changing tab
 * @returns
 */
const ScheduleTabs = ({ activeTab, onTabChanged }: Props) => {
  const tabs: ScheduleTabNames[] = ["Daily", "Weekly", "Monthly", "Yearly"];

  return (
    <ButtonGroup color="primary" aria-label="outlined primary button group">
      {tabs.map((value, index) => (
        <Button
          variant={activeTab === value ? "contained" : "outlined"}
          onClick={() => onTabChanged(value)}
        >
          {value}
        </Button>
      ))}
    </ButtonGroup>
  );
};
export default ScheduleTabs;
