/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { Link } from "react-router-dom";
import { Checkbox, TextField, Chip } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { StoreGroupType } from "../../models/stores";
import { CheckIcon, UncheckIcon } from "./Icons";

interface Props {
  storeGroups: StoreGroupType[];
  selectedGroups: StoreGroupType[];
  getSelectedGroups: (groups: StoreGroupType[]) => void;
}

const StoreGroupsAutocomplete: React.FC<Props> = ({
  storeGroups,
  selectedGroups,
  getSelectedGroups,
}) => {
  const handleSelection = (group: StoreGroupType) => {
    if (selectedGroups.includes(group)) {
      const storeIndex = selectedGroups.findIndex(
        (item: StoreGroupType) => item.title === group?.title
      );
      const newGroups = [...selectedGroups];
      newGroups.splice(storeIndex, 1);
      getSelectedGroups(newGroups);
    } else {
      const newGroups = [...selectedGroups];
      newGroups.push(group);
      getSelectedGroups(newGroups);
    }
  };

  if (storeGroups.length === 0) {
    return (
      <div>
        You haven't created any menu groupings yet. Go ahead and create one
        <Link to="/store-management"> here</Link>.
      </div>
    );
  }

  return (
    <Autocomplete
      multiple
      options={storeGroups}
      onChange={(_, values: StoreGroupType[]) => {
        values.forEach((value) => handleSelection(value));
      }}
      value={selectedGroups}
      disableCloseOnSelect
      disableClearable
      getOptionSelected={(option, value) => option.title === value.title}
      getOptionLabel={(option) => option.title}
      renderOption={(option) => (
        <>
          <Checkbox
            icon={<UncheckIcon />}
            checkedIcon={<CheckIcon />}
            style={{ marginRight: 8 }}
            color="default"
            checked={
              !!selectedGroups.find(
                (item: StoreGroupType) => item.title === option.title
              )
            }
          />
          {option.title}
        </>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label="Groups"
          placeholder="Search Groups"
        />
      )}
      renderTags={(values: StoreGroupType[], getTagProps) => {
        // eslint-disable-next-line no-console
        return values.map((value: StoreGroupType, index: number) => (
          <Chip
            variant="default"
            color="primary"
            label={value?.title}
            {...getTagProps({ index })}
            onDelete={() => handleSelection(value)}
          />
        ));
      }}
    />
  );
};

export default StoreGroupsAutocomplete;
