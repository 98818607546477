import { useEffect } from "react";
import { useQuery } from "@apollo/client";
import { GET_IS_AUTH } from "../../apollo/operations/queries/localQueries";
import { ENV_VARS } from "../../config/config";
//import logOutUser from "../../helpers/logOutUser";
import { isAuthenticatedVar } from "../../apollo/cache";

const AuthRedirect = ({ children }: any) => {
  const { data } = useQuery(GET_IS_AUTH);

  useEffect(() => {
    const noLocalAuth = !localStorage.getItem("token");
    if (noLocalAuth && window.location.href === `${ENV_VARS.REDIRECT_URI}`) {
      const code = Math.floor(Math.random() * Math.floor(10000));
      localStorage.setItem("randomCode", code.toString());

      if (!data.isAuthenticated) {
        window.location.replace(
          `${ENV_VARS.AUTH_URL}/login?response_type=code&client_id=${ENV_VARS.AUTH_CLIENT_ID}&redirect_uri=${ENV_VARS.REDIRECT_URI}&state=${code}`
        );
      }
    }

    if (
      !data.isAuthenticated &&
      window.location.href !== `${ENV_VARS.REDIRECT_URI}` &&
      !localStorage.getItem("randomCode")
    ) {
      window.location.replace(`${ENV_VARS.REDIRECT_URI}`);
    }
    isAuthenticatedVar(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return children;
};

export default AuthRedirect;
