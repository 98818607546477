import { useQuery } from "@apollo/client";
import { Card, CardContent } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { cache } from "../../../apollo/cache";
import { CUSTOMIZATION_SCHEDULE } from "../../../apollo/operations/queries/localQueries";

import styles from "./styles/ScheduleTime.module.scss";
import { CustomStylesTextField } from "./Utils/CustomUI";

const ScheduleTime = () => {
  const {
    data: { schedule },
  }: any = useQuery(CUSTOMIZATION_SCHEDULE);
  const [data, setData] = useState({
    openingTime: schedule.openingTime,
    closingTime: schedule.closingTime,
  });

  //checks if the start itme < end time
  const isTimeOkay = (start: string, end: string) => {
    if (!start || !end) return false;
    const timefrom = new Date();
    let temp = start.split(":");
    timefrom.setHours((parseInt(temp[0]) - 1 + 24) % 24);
    timefrom.setMinutes(parseInt(temp[1]));

    const timeto = new Date();
    temp = end.split(":");
    timeto.setHours((parseInt(temp[0]) - 1 + 24) % 24);
    timeto.setMinutes(parseInt(temp[1]));
    return timeto > timefrom;
  };
  const onChange = (name: string, payload: any) => {
    setData({ ...data, [name]: payload });
  };
  useEffect(() => {
    if (schedule && isTimeOkay(data.openingTime, data.closingTime)) {
      //update cache
      cache.writeQuery({
        query: CUSTOMIZATION_SCHEDULE,
        data: {
          schedule: {
            ...schedule,
            ...data,
          },
        },
      });
    }
  }, [data, schedule]);
  return (
    <div>
      <h2>Timings</h2>
      <Card variant="outlined">
        <CardContent>
          <div className={styles.times_item}>
            <div className={styles.times_row}>
              <h3 className={styles.times_label}>From</h3>
              <CustomStylesTextField
                name="openingTime"
                type="time"
                value={data.openingTime}
                placeholder="until"
                size={"large"}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  onChange(e.target.name, e.target.value)
                }
              />
            </div>
            <span className={styles.helper_text}>
              {" "}
              The setting will be in effect till this time each specified day{" "}
            </span>
          </div>
          <div className={styles.times_item}>
            <div className={styles.times_row}>
              <h3 className={styles.times_label}>To</h3>
              <CustomStylesTextField
                type="time"
                value={data.closingTime}
                name="closingTime"
                size={"large"}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  onChange(e.target.name, e.target.value)
                }
              />
            </div>
            <span className={styles.helper_text}>
              {" "}
              The setting will be in effect till this time each specified day{" "}
            </span>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};
export default ScheduleTime;
